import { sortBy } from "lodash";

import * as unitsTypes from "../constants/units.types";
import { PURGE } from "redux-persist";

const initialState = { bigLoad: false };

const reducer = {
  [unitsTypes.LOADING]: (state, action) => ({
    ...state,
    [action.projectId]: {
      ...(state[action.projectId] || {}),
      loading: true,
      loadingDetails: true,
    },
  }),

  [unitsTypes.BIGLOADFETCHING]: (state, action) => ({
    ...state,
    bigLoad: true
  }),
  [unitsTypes.DONE_FETCH]: (state, action = { payload: { lastSeen: '', data: [] } }) => ({
    ...state,
    bigLoad: false,
    [action.projectId]: {
      ...(state[action.projectId] || {}),
      data: action.payload.data.data,
      lastSeen: action.payload.data.lastSeen,
      error: "",
      loading: false,
      loadingDetails: false,
      sync: true,
    },
  }),
  [unitsTypes.DONE_FETCH_DETAILS]: (state, action) => {
    const stateData = state[action.projectId].data;
    let newData = [];

    if (stateData.length > 0) {
      newData = stateData.map((value, index) => {
        const unitInstance = action.payload.data.find(
          (data) => data.id === value.id
        );

        if (!unitInstance) {
          const unitInstanceSerial = action.payload.data.find(
            (data) => data.deviceSerial === value.deviceSerial
          );
          // Fallback for system that runs still under device serial TODO: deprecate!
          if (!unitInstanceSerial)
            return { ...stateData[index], dataAvailable: false };
          else return { ...unitInstance, ...value, dataAvailable: true };
        }

        return { ...unitInstance, ...value, dataAvailable: true };
      });
    }

    return {
      ...state,
      bigLoad: false,
      [action.projectId]: {
        ...(state[action.projectId] || {}),
        data: sortBy(newData, "id"),
        error: "",
        loading: false,
        loadingDetails: false,
      },
    };
  },
  [unitsTypes.ERROR_FETCH]: (
    state,
    action = { payload: { error: "Fetching error" } }
  ) => ({
    ...state,
    bigLoad: false,
    [action.projectId]: {
      ...(state[action.projectId] || {}),
      error: action.payload.error,
      loading: false,
      loadingDetails: true,
    },
  }),
  [unitsTypes.ERROR_FETCH]: (
    state,
    action = { payload: { error: "Fetching error" } }
  ) => ({
    ...state,
    bigLoad: false,
    [action.projectId]: {
      ...(state[action.projectId] || {}),
      error: action.payload.error,
      loadingDetails: false,
    },
  }),
  [unitsTypes.CLEAN_DATA]: () => ({
    ...initialState,
  }),
  [PURGE]: () => ({
    ...initialState,
  }),
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
