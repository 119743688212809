import React, { useState } from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import PropTypes from 'prop-types';

import Label from '../../../../../components/shared/Label';
import TextareaForm from '../../../../../components/Inputs/TextareaForm';
import Button from '../../../../../components/shared/Button';
import SingleSelectForm from '../../../../../components/Inputs/SingleSelectForm';
import { emptyField } from '../../../../../utils/validators';

const ContactForm = props => {
  const [showSuccessMessage, setShowSuccessMessage] = useState('');
  const submit = async values => {
    try {
      await props.sendMessage({
        contactId: values.type.value,
        message: values.message
      });
      setShowSuccessMessage('Message has been send');
      setTimeout(() => {
        setShowSuccessMessage('');
      }, 2000);
      props.reset();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const {
    handleSubmit,
    submitting,
    submitFailed,
    error,
    invalid,
    options
  } = props;
  return (
    <Form onSubmit={handleSubmit(submit)} className="form__item">
      <Field
        name="type"
        component={SingleSelectForm}
        options={options}
        type="select"
        className="contact"
      />
      {showSuccessMessage && (
        <Label
          type="info"
          className="form__item__info"
          label={showSuccessMessage}
        />
      )}
      <Field
        name="message"
        component={TextareaForm}
        type="textarea"
        placeholder="How can we help you?"
      />
      {!submitting && submitFailed && <Label type="error" label={error} />}
      <Button
        type="submit"
        tag="primary"
        className="form__submit"
        disabled={submitting || invalid}
      >
        {submitting ? 'Sending' : 'Send message'}
      </Button>
    </Form>
  );
};

ContactForm.propTypes = {
  initialValues: PropTypes.object
};

export default reduxForm({
  form: 'contactForm',
  validate: values => ({
    message: emptyField(values.message, 'Message is required')
  })
})(ContactForm);
