import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as session from "../../utils/auth/session";
import { APP_VERSION } from "../../config";
import storeConfig from "../../store";
import * as authActions from "../../actions/auth.actions";
import * as appActions from "../../actions/appVersion.actions";
import Navbar from "../Navbar";
import "./_layout.scss";

class Layout extends Component {
  componentDidMount = () => {
    const { appVersion } = this.props;

    if (appVersion !== APP_VERSION) {
      this.props.logout();
      const persistor = storeConfig.persistor;
      persistor.purge();
      session.clearSession();
      this.props.setCurrentVersion(APP_VERSION);
      this.props.history.push("/auth/login");
    }
  };

  render() {
    return (
      <div className="inspira-farms-app">
        <div className="layout">

          <Navbar />


          <div className="layout__content">{this.props.children}</div>



        </div>


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appVersion: state.app.version,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentVersion: bindActionCreators(appActions.setCurrentVersion, dispatch),
  logout: bindActionCreators(authActions.logout, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
