import React from 'react';
import PropTypes from 'prop-types';

import FormGroup from '../shared/FormGroup';
import Textarea from '../shared/Textarea';
import Label from '../shared/Label';

const TextareaForm = props => {
  const { className = '', label, type, placeholder, input, meta } = props;

  return (
    <FormGroup>
      <label>{label}</label>
      <Textarea
        className={` form-input ${className}
          ${meta.touched && meta.error ? 'has-error' : ''}
          ${meta.active ? 'is-active' : ''} ${meta.touched ? 'is-visited' : ''}
        `}
        placeholder={placeholder}
        type={type}
        {...input}
      />
      {meta.error && meta.touched && <Label type="error" label={meta.error} />}
    </FormGroup>
  );
};

TextareaForm.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string
};

export default TextareaForm;
