import {
    FETCHING_LATEST_LIVE_ALARMS,
    LATEST_LIVE_ALARMS,
    SAVED_LATEST_LIVE_ALARMS
} from "../../constants/alarms.types";
import api from '../../utils/api';


export const setLiveAlarms = (projectUid, alarms) => {
    return {
        type: LATEST_LIVE_ALARMS,
        payload: { projectUid, alarms }
    };
};

export const fetchSavedLatestLiveAlarms = (projectUids) => {
    return (dispatch) => {
        dispatch({
            type: FETCHING_LATEST_LIVE_ALARMS,
        });
        api
            .get(`live/latest`, {
                params: {
                    projectUids
                }
            })
            .then((res) => {
                dispatch({
                    type: SAVED_LATEST_LIVE_ALARMS,
                    payload: res.data
                });
            })
            .catch((err) => { });
    };
};

