import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isTokenValid } from '../../utils/auth/session';
import { getUserRoles } from '../../utils/auth/user';
import { hasOneOrMoreSameValues } from '../../utils/arrays/compareTwoArrays';
import { urlRoute } from '../../utils/url';

const AuthRequiredRoute = props => {
  const { component, path, exact, allowedRoles } = props;

  const authorized = isTokenValid();
  const roles = getUserRoles();

  const authRequired = () => {
    const redirectPath =
      authorized && !hasOneOrMoreSameValues(allowedRoles, roles)
        ? urlRoute('/')
        : urlRoute('/auth/login');

    return <Redirect to={redirectPath} replace />;
  };

  return authorized && hasOneOrMoreSameValues(allowedRoles, roles) ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    authRequired()
  );
};

AuthRequiredRoute.defaultProps = {
  component: null,
  path: '/',
  exact: false,
  allowedRoles: []
};

AuthRequiredRoute.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
  exact: PropTypes.bool,
  allowedRoles: PropTypes.array
};

export default AuthRequiredRoute;
