import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DraggableTableRows from './DraggableTableRows';

const defaultConfig = {
  rowClick: () => null,
  showHeader: true,
  footer: [
    {
      key: '',
      label: '',
      component: null,
      width: '',
      textAlignHeader: 'left',
      textAlignItem: 'left'
    }
  ],
  headers: [
    {
      key: '',
      label: '',
      component: null,
      width: '',
      textAlignHeader: 'left',
      textAlignItem: 'left',
      suffix: '',
      prefix: '',
      className: ''
    }
  ]
};

const DraggableTable = ({
  items,
  className = '',
  config = defaultConfig,
  ...props
}) => {
  const [isDragged, setIsDragged] = useState(false);

  const columnNumbers = (config.headers || []).length;
  const customeWidths = config.headers.reduce((acc, v) => {
    acc += v.width || 0;
    return acc;
  }, 0);
  const customeCounter = config.headers.reduce((acc, v) => {
    acc += v.width ? 1 : 0;
    return acc;
  }, 0);
  const columnWidth = (100 - customeWidths) / (columnNumbers - customeCounter);

  const { accepts, updateStockItem, coldStorageId } = props;

  const handleDragging = (props, monitor) => {
    setIsDragged(!isDragged);
    return props.item;
  };
  const renderHeaders = () => {
    const { headers, showHeader } = config;

    return (
      showHeader &&
      headers.map((header, index) => (
        <div
          key={index}
          className="table__header__th"
          style={{
            flexBasis: `${header.width || columnWidth}%`,
            textAlign: header.textAlignHeader || 'left'
          }}
        >
          {header.label}
        </div>
      ))
    );
  };

  const renderFooter = () => {
    const { footer } = config;

    return (
      footer && (
        <div className="table__footer__row">
          {footer.map((footer, i) => (
            <div
              key={i}
              className="table__footer__row__td"
              style={{
                flexBasis: `${footer.width || columnWidth}%`,
                textAlign: footer.textAlignItem || 'left'
              }}
            >
              {footer.component ? footer.component() : footer.label}
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div>
      <div className={`table ${className}`}>
        <div className="table__header">{renderHeaders()}</div>
        <div className="table__body">
          {items
            .filter(x => x.status !== x.accepts)
            .map((item, i) => (
              <div key={i}>
                <DraggableTableRows
                  index={item.id}
                  config={config}
                  item={item}
                  columnWidth={columnWidth}
                  accepts={accepts}
                  updateStockItem={updateStockItem}
                  coldStorageId={coldStorageId}
                  handleDragging={handleDragging}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="table__footer">{renderFooter()}</div>
    </div>
  );
};

DraggableTable.propTypes = {
  items: PropTypes.array,
  config: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingComponent: PropTypes.node
};

export default DraggableTable;
