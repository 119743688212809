export const SET_BASE_CONFIG = 'set-base-config';
export const UPDATE_ROOT_CONFIG = 'update-root-config';
export const UPDATE_COM_PORT = 'update-com-port';
export const ADD_DEVICE_COUNT = 'add-device-count';
export const ADD_OR_UPDATE_DEVICE_ID = 'add-or-update-device-id';
export const ADD_OR_UPDATE_DEVICE_DETAILS = 'add-or-update-device-details';
export const SET_DEVICE_UNDER_MEASUREMENT = 'set-device-under-measurement';
export const UPDATE_DEVICE_MEASURE = 'update-device-measure';
export const UPDATE_PEGO_ROOM = 'update-pego-room';

export const ADD_OTHER_UNIT_DROPDOWN = 'add-other-unit-dropdown';
export const REMOVE_OTHER_UNIT_DROPDOWN = 'remove-other-unit-dropdown';

export const DELETE_DEVICE_AT_INDEX = 'delete-device-at-index';

export const UPDATE_SENOSR_DEFAULTS_AT_INDEX = 'update-sensor-defaults-at-index'

export const UPDATE_CONFIG_FROM_CLOUD = 'update-config-from-cloud'
