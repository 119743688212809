import { FINGERPRINT } from "../constants/fingerprint.types";
import { PURGE } from "redux-persist";

const INITIAL = {
  fingerprint: "",
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    case FINGERPRINT:
      return { ...state, fingerprint: action.payload };

    case PURGE:
      return {
        ...INITIAL
      }

    default:
      return state;
  }
};
