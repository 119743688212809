import {
    DONE_FETCHING_COMPANY_CONTACT,
    DONE_UPDATING_COMPANY_CONTACT,
    FETCHING_COMPANY_CONTACT,
    UPDATE_COMPANY_CONTACT_VALUES,
    UPDATING_COMPANY_CONTACT,
    VERIFYING_COMPANY_CONTACT,
    DONE_VERIFYING_COMPANY_CONTACT,
    CONTACT_ADD,
    CONTACT_REMOVE,
    CONTACT_DELETING,
    FETCHING_COMPANY_SMS_STATUS,
    DONE_FETCHING_COMPANY_SMS_STATUS,
    UPDATING_COMPANY_SMS_STATUS,
    DONE_UPDATING_COMPANY_SMS_STATUS
} from "../../constants/alarms.types";
import api from "../../utils/api";




export const fetchCompanyContact = () => {
    return (dispatch) => {
        dispatch({
            type: FETCHING_COMPANY_CONTACT,
        });

        api
            .get(`companycontact`)
            .then((res) => {



                dispatch({
                    type: DONE_FETCHING_COMPANY_CONTACT,
                    payload: res.data
                });
            })
            .catch((err) => { });
    };
};

export const updateCompanyContact = (dataT, index) => {

    return (dispatch) => {
        dispatch({
            type: UPDATING_COMPANY_CONTACT,
        });

        api.post(`companycontact`, dataT)


            .then(res => {

                dispatch({
                    type: DONE_UPDATING_COMPANY_CONTACT,
                    payload: res.data,
                    payloadIndex: index
                });
            }).catch(err => { })

    };
};

export const newCompanyContactValues = (key, value, index) => {


    return {
        type: UPDATE_COMPANY_CONTACT_VALUES,
        payloadKey: key,
        payloadValue: value,
        payloadIndex: index
    };

}

export const verifyData = (data, index) => {
    return (dispatch) => {
        dispatch({
            type: VERIFYING_COMPANY_CONTACT,
        });

        api.post(`companycontact/verify`, data)


            .then(res => {

                dispatch({
                    type: DONE_VERIFYING_COMPANY_CONTACT,
                    payload: res.data,
                    payloadIndex: index
                });
            }).catch(err => { })

    };
}

export const contactInputAdd = () => {

    return { type: CONTACT_ADD }

}

export const contactInputDelete = (id, index) => {

    if (id) {

        return (dispatch) => {
            dispatch({
                type: CONTACT_DELETING,
            });

            api.delete(`companycontact`, {
                params: { id }
            })


                .then(res => {

                    dispatch({
                        type: CONTACT_REMOVE, payloadIndex: index
                    });
                }).catch(err => { })

        };

    } else {

        return { type: CONTACT_REMOVE, payloadIndex: index }

    }



}


export const fetchCompanySmsStatus = () => {
    return (dispatch) => {
      dispatch({
        type: FETCHING_COMPANY_SMS_STATUS,
      });
  
      api
        .get(`company/sms`)
  
        .then((res) => {
          dispatch({
            type: DONE_FETCHING_COMPANY_SMS_STATUS,
            payload: res.data,
          });
        })
        .catch((err) => { });
    };
  };
  
  export const updateCompanySmsStatus = (id, sendingMode) => {

    if (id) {

        return (dispatch) => {
            dispatch({
              type: UPDATING_COMPANY_SMS_STATUS,
            });
        
            api
              .post(`company/sms`, {
                id,
                sendingMode,
              })
        
              .then((res) => {
                dispatch({
                  type: DONE_UPDATING_COMPANY_SMS_STATUS,
                  payload: res.data,
                });
              })
              .catch((err) => { });
          };

    }

    return (dispatch) => {
        dispatch({
          type: UPDATING_COMPANY_SMS_STATUS,
        });
    
        api
          .post(`company/sms`, {
            sendingMode,
          })
    
          .then((res) => {
            dispatch({
              type: DONE_UPDATING_COMPANY_SMS_STATUS,
              payload: res.data,
            });
          })
          .catch((err) => { });
      };
   
  };


