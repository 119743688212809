import Cookies from 'js-cookie';

const TOKEN = 'insp-token';
const TOKEN_EXPIRES = 'insp-token-expires';

const REFRESH_TOKEN = 'insp-refresh-token';
const REFRESH_TOKEN_EXPIRES = 'insp-refresh-token-expires';

export function setSession(
  authData = { token: '', refreshToken: '', expires: 360000 }
) {
  setToken(authData.token, authData.expires);
  setRefreshToken(authData.refreshToken, 604800); // 7 days
}

export function getSession() {
  return {
    token: getToken(),
    tokenExpires: getTokenExpires(),
    refreshToken: getRefreshToken(),
    refreshTokenExpires: getRefreshTokenExpires()
  };
}

export function isTokenValid() {
  const token = getToken();
  const expToken = getTokenExpires();

  return token && expToken && Date.now() < expToken;
}

export function isRefreshTokenValid() {
  const refreshToken = getRefreshToken();
  const expRefreshToken = getRefreshTokenExpires();

  return refreshToken && expRefreshToken && Date.now() < expRefreshToken;
}

export function clearSession() {
  setToken(undefined);
  setRefreshToken(undefined);
  Cookies.remove(TOKEN, { path: '' });
  Cookies.remove(TOKEN_EXPIRES, { path: '' });
  Cookies.remove(REFRESH_TOKEN, { path: '' });
  Cookies.remove(REFRESH_TOKEN_EXPIRES, { path: '' });
}

function getToken() {
  return Cookies.get(TOKEN);
}

function getTokenExpires() {
  return Cookies.get(TOKEN_EXPIRES);
}

function getRefreshToken() {
  return Cookies.get(REFRESH_TOKEN);
}

function getRefreshTokenExpires() {
  return Cookies.get(REFRESH_TOKEN_EXPIRES);
}

function setToken(token = '', expires = 36000) {
  Cookies.set(TOKEN, token, { expires: expires / 36000 });
  const timestamp = token ? Date.now() + expires * 1000 : 0;
  Cookies.set(TOKEN_EXPIRES, timestamp, { expires: expires / 36000 });
}

function setRefreshToken(refreshToken = '', expires = 604800) {
  Cookies.set(REFRESH_TOKEN, refreshToken, { expires: expires / 36000 });
  const timestamp = refreshToken ? Date.now() + expires * 1000 : 0;
  Cookies.set(REFRESH_TOKEN_EXPIRES, timestamp, { expires: expires / 36000 });
}
