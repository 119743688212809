import * as unitsTypes from '../../constants/unitDetails.types';
import { PURGE } from 'redux-persist';

const initialState = {
  loading: false
};

const reducer = {
  [unitsTypes.LOADING_LIVE_DATA]: state => ({
    ...state,
    loading: true
  }),
  [unitsTypes.DONE_FETCH_LIVE_DATA]: (
    state,
    action = { coldStorageId: 0, payload: { data: {} } }
  ) => ({
    ...state,
    loading: false,
    [action.coldStorageId]: {
      ...(state[action.coldStorageId] || {}),
      data: action.payload.data,
      sync: true
    }
  }),
  [unitsTypes.ERROR_FETCH_LIVE_DATA]: (
    state,
    action = { coldStorageId: 0, payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    [action.coldStorageId]: {
      ...(state[action.coldStorageId] || {}),
      error: action.payload.error
    }
  }),
  [unitsTypes.CLEAN_DATA_LIVE_DATA]: (
    state,
    action = { coldStorageId: 0, payload: { data: [] } }
  ) => ({
    ...state,
    loading: false,
    [action.coldStorageId]: {
      data: {},
      sync: false
    }
  }),
  [PURGE]: () => ({
    ...initialState
  })
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
