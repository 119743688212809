import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/units.actions';
import * as projectActions from '../../../../actions/projects.actions';
import { getProjectId } from '../../../../utils/auth/user';
import Component from './MyUnitsList';

const mapStateToProps = (state, props) => {
  const moreThenOneProject = state.projects.data.length > 1;
  const projectId =
    (props.match.params || { projectId: null }).projectId ||
    getProjectId() ||
    null;

  const unitsSelector = state.units || {};
  // currentProject is the first project fetched by /projects
  const unitsByProjectId =
    (unitsSelector || {})[projectId || state.projects.currentProject] || {};

  const unitsByProjectIdData = unitsByProjectId.data || [];

  const showPulpTemp = unitsByProjectIdData.some(
    unit => unit.dataProfile?.pulpTemp
  );
  const showEthylene = unitsByProjectIdData.some(
    unit => unit.dataProfile?.ethylene
  );

  return {
    moreThenOneProject: moreThenOneProject || false,
    projectId: projectId,
    showPulpTemp,
    showEthylene,
    lastSeen: unitsByProjectId.lastSeen,
    data: unitsByProjectId.data || [],
    sync: unitsByProjectId.sync || false,
    error: unitsByProjectId.error || '',
    loading: unitsByProjectId.loading,
    loadingDetails: unitsByProjectId.loadingDetails,
    userRoles: state.auth.roles,
    userPaymentStatus: state.auth.paymentStatus
  };
};

const mapDispatchToProps = dispatch => ({
  fetchUnits: bindActionCreators(actions.fetchUnits, dispatch),
  fetchProjects: bindActionCreators(projectActions.fetchProjects, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
