import {
  DONE_FETCHING_SITE_ALARMS,
  FETCHING_SITE_ALARMS,
} from "../../constants/alarms.types";
import { PURGE } from "redux-persist";

const INITIAL = {
  fetchingData: false,
  data: {},
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    case FETCHING_SITE_ALARMS:
      return { ...state, fetchingData: true };

    case DONE_FETCHING_SITE_ALARMS:
      const dataObject = {};

      for (const element of action.payload) {
        if (dataObject[element.projectUid]) {
          dataObject[element.projectUid].push(element);
          continue;
        }

        dataObject[element.projectUid] = [element];
      }
      return {
        ...state,
        data: dataObject,
        fetchingData: false,
      };

    case PURGE:
      return {
        ...INITIAL
      }

    default:
      return state;
  }
};
