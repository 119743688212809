import {
  FETCHING_SITE_ALARMS_STATUS,
  DONE_FETCHING_SITE_ALARMS_STATUS,
  FETCHING_INDIVIDUAL_USER_ALARM_STATUS,
  DONE_FETCHING_INDIVIDUAL_USER_ALARM_STATUS,
  UPDATING_INDIVIDUAL_USER_ALARM_STATUS,
  FETCHING_SITE_THRESHOLD,
  DONE_FETCHING_SITE_THRESHOLD,
  UPDATE_THRESHOLD_VALUES,
  FETCHING_COLD_ROOM_THRESHOLD,
  DONE_FETCHING_COLD_ROOM_THRESHOLD,
  UPDATING_COLD_ROOM_THRESHOLD,
  DONE_UPDATING_COLD_ROOM_THRESHOLD,
  UPDATE_COLD_ROOM_VALUES,
  DONE_UPDATING_COLD_ROOM_ALARM_STATUS,
  DONE_UPDATING_SITE_ALARMS_STATUS
} from "../../constants/alarms.types";

import api from "../../utils/api";

export const fetchSiteAlarmSendingStatus = (sites) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_SITE_ALARMS_STATUS,
    });

    api
      .get(`project/sitealarmstatus`, {
        params: {
          sites,
        },
      })

      .then((res) => {
        dispatch({
          type: DONE_FETCHING_SITE_ALARMS_STATUS,
          payload: res.data,
        });
      })
      .catch((err) => { });
  };
};

export const fetchUserAlarmSendingStatus = (userId) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_INDIVIDUAL_USER_ALARM_STATUS,
    });

    api
      .get(`useralarmstatus/${userId}`)

      .then((res) => {
        dispatch({
          type: DONE_FETCHING_INDIVIDUAL_USER_ALARM_STATUS,
          payload: res.data,
        });
      })
      .catch((err) => { });
  };
};

export const updateUserAlarmSendingStatus = (userId, sendingMode) => {
  return (dispatch) => {
    dispatch({
      type: UPDATING_INDIVIDUAL_USER_ALARM_STATUS,
    });

    api
      .put(`individualuseralarmstatus/${userId}`, {
        userId,
        sendingMode,
      })

      .then((res) => {
        dispatch({
          type: DONE_FETCHING_INDIVIDUAL_USER_ALARM_STATUS,
          payload: res.data,
        });
      })
      .catch((err) => { });
  };
};

export const updateSiteAlarmSendingStatus = (
  projectUid,
  sendingMode,
  siteAlarmType,
  id
) => {
  return (dispatch) => {
    dispatch({
      type: UPDATING_INDIVIDUAL_USER_ALARM_STATUS,
    });

    api
      .put(`sitealarmstatus/${projectUid}`, {
        projectUid,
        sendingMode,
        siteAlarmType,
      })

      .then((res) => {
        dispatch({
          type: DONE_UPDATING_SITE_ALARMS_STATUS,
          payload: { id, sendingMode }
        });
      })
      .catch((err) => { });
  };
};

export const fetchSiteThreshold = (projectUid, type) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_SITE_THRESHOLD,
    });

    api
      .get(`sitealarmthreshold/${projectUid}`, {
        params: {
          siteAlarmThreshold: {
            projectUid,
            type,
          },
        },
      })

      .then((res) => {

        dispatch({
          type: DONE_FETCHING_SITE_THRESHOLD,
          payload: Object.keys(res.data).length === 0 ? {
            idealValue: "",
            allowedNegativeDeviation: "",
            allowedPositiveDeviation: ""
          } : res.data,
        });
      })
      .catch((err) => { });
  };
};

export const updateSiteThreshold = ({
  idealValue,
  allowedNegativeDeviation,
  allowedPositiveDeviation,
  id,
  type,
  projectUid,
}) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_SITE_THRESHOLD,
    });


    api
      .post(`sitealarmthreshold/${projectUid}`, {
        idealValue,
        allowedNegativeDeviation,
        allowedPositiveDeviation,
        id,
        type,
        projectUid,
      })

      .then((res) => {
        dispatch({
          type: DONE_FETCHING_SITE_THRESHOLD,
          payload: res.data,
        });
      })
      .catch((err) => { });
  };
};

export const updateThresholdNumberValues = (key, value) => {
  return {
    type: UPDATE_THRESHOLD_VALUES,
    payload: { [key]: value },
  };
};



export const fetchColdRoomThreshold = (coldStorageId, projectUid) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_COLD_ROOM_THRESHOLD,
    });

    api
      .get(`coldstoragealarmthreshold/${projectUid}`, {
        params: {
          coldStorageAlarmThreshold: {
            projectUid,
            coldStorageId
          },
        },
      })

      .then((res) => {

        const hum = res.data.thresholds.find(el => el.type === 'humidity');
        const temp = res.data.thresholds.find(el => el.type === 'temperature');

        const coldStorageAlarmStatus = res.data.status
        dispatch({
          type: DONE_FETCHING_COLD_ROOM_THRESHOLD,
          humPayload: hum ? hum : { idealValue: '', maximumAllowedValue: '', minimumAllowedValue: '', id: '', setPointValue: '' },
          tempPayload: temp ? temp : { idealValue: '', maximumAllowedValue: '', minimumAllowedValue: '', id: '', setPointValue: '' },
          coldStorageAlarmStatus
        });
      })
      .catch((err) => { });
  };
};

export const updateColdStorageThreshold = (projectUid, temp, hum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATING_COLD_ROOM_THRESHOLD,
    });

    const url = `coldstoragealarmthreshold/${projectUid}`
    // const humReq = api.post(url, hum)
    const tempReq = api.post(url, temp)

    Promise.all([tempReq]).then(resArray => {
      const tempRes = resArray[0];
      // const humRes = resArray[1];

      dispatch({
        type: DONE_UPDATING_COLD_ROOM_THRESHOLD,
        // humPayload: humRes.data,
        tempPayload: tempRes.data,
      });
    }).catch(err => { })

  };
};

export const newColdStoargeThresholdValue = (key, value) => {

  return {
    type: UPDATE_COLD_ROOM_VALUES,
    payloadKey: key,
    payloadValue: value
  };

}


export const updateColdStorageAlarmSendingStatus = (
  projectUid,
  sendingMode,
  doorSendingMode,
  coldStorageId,
  id
) => {
  return (dispatch) => {
    dispatch({
      type: UPDATING_INDIVIDUAL_USER_ALARM_STATUS,
    });

    api
      .put(`coldstoragealarmstatus/${projectUid}`, {
        projectUid,
        sendingMode,
        doorSendingMode,
        coldStorageId,
        id: id ? id : null
      })

      .then((res) => {
        dispatch({
          type: DONE_UPDATING_COLD_ROOM_ALARM_STATUS,
          payload: {
            projectUid,
            sendingMode,
            coldStorageId,
            doorSendingMode
          },
        });
      })
      .catch((err) => { });
  };
};



