
import { PURGE } from "redux-persist";
import { SHIPMENT_ACTIVE_SHIPMENT_ID, SHIPMENT_DATA, SHIPMENT_LOGGERS_DATA, SHIPMENT_STATS_DATA, SHIPMENT_STATS_GET } from "../../constants/shipments.types";


const INITIAL = {
    shipmentActiveShipment: null,
    shipmentData: [],
    shipmentLoggerLiveData: [],
    shipmentDataLoad: false
};

export default (state = INITIAL, action) => {
    switch (action.type) {


        case SHIPMENT_ACTIVE_SHIPMENT_ID:
            return {
                ...state,
                shipmentActiveShipment: action.payload
            };

        case SHIPMENT_DATA:
            return {
                ...state,
                shipmentData: action.payload
            };

        case SHIPMENT_LOGGERS_DATA:
            return {
                ...state,
                shipmentLoggerLiveData: action.payload
            };

        case SHIPMENT_STATS_GET:
            return {
                ...state,
                shipmentDataLoad: true,
            };

        case SHIPMENT_STATS_DATA:
            return {
                ...state,
                shipmentDataLoad: false,
                shipmentData: action.payload
            };

        case PURGE:
            return {
                ...INITIAL
            };

        default:
            return state;
    }
};
