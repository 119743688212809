import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { chain, isUndefined } from "lodash";

import ChartTooltip from "../../../../../../../components/Charts/components/ChartTooltip";
import BarredChart from "../../../../../../../components/Charts/BarredChart";
import * as colors from "../../../../../../../constants/colors";
import { userAllowedByRole } from "../../../../../../../utils/auth/user";
import * as roles from "../../../../../../../constants/roles";
import statisticsTypes from "../../../../../../../constants/statisticsTypes";
import {
  getListFromData,
  getObjectItemForConsumption,
} from "../../../../../../../helpers/object.helper";
import { getLabelNameFormat } from "../../../../../../../helpers/chart.helper";
import { cleanChartDataToBeContinous } from "../../../../../../../utils/chartUtils";

export const EnergyConsumptionChart = ({ syncId, data }) => {
  const legends = [{ key: "energy", color: colors.ORANGE, label: "MAIN" }];
  const rangeLines = [];

  const _showRoom = () =>
    userAllowedByRole([
      roles.CLIENT,
      roles.CLIENT_ADMIN,
      roles.INSPIRA_FARMS_TECH,
      roles.INSPIRA_FARMS_EMPLOYEE,
      roles.DEMO_ACCOUNT,
      roles.INSPIRA_FARMS_ADMIN,
      roles.UNDER_THE_RADAR, roles.INSPIRA_FARMS_TECH_REGION
    ]);

  const _showCompressor = () =>
    //userAllowedByRole([roles.INSPIRA_FARMS_TECH, roles.INSPIRA_FARMS_EMPLOYEE, roles.CLIENT_ADMIN]);
    userAllowedByRole([
      roles.CLIENT,
      roles.CLIENT_ADMIN,
      roles.INSPIRA_FARMS_TECH,
      roles.INSPIRA_FARMS_EMPLOYEE,
      roles.DEMO_ACCOUNT,
      roles.INSPIRA_FARMS_ADMIN,
      roles.UNDER_THE_RADAR,
      roles.INSPIRA_FARMS_TECH_REGION
    ]);

  _showRoom() &&
    legends.push({
      key: "roomEnergy",
      color: colors.FIR_GREEN,
      label: "ROOM",
    });

  _showCompressor() &&
    legends.push({
      key: "compressorEnergy",
      color: colors.RED,
      label: "COMPRESSOR",
    });

  const _getCustomTooltip = (e) => {
    const data = ((e.payload || [])[0] || {}).payload || {};

    return (
      <ChartTooltip>
        <p>
          MAIN:{" "}
          <strong>
            {data.energy ? Number.parseFloat(data.energy.toFixed(2)) : 0} kWh
          </strong>
        </p>
        {_showRoom() && (
          <p>
            ROOM:{" "}
            <strong>
              {data.roomEnergy
                ? Number.parseFloat(data.roomEnergy.toFixed(2))
                : 0}{" "}
              kWh
            </strong>
          </p>
        )}
        {_showCompressor() && (
          <p>
            COMPRESSOR:
            <strong>
              {data.compressorEnergy
                ? Number.parseFloat(data.compressorEnergy.toFixed(2))
                : 0}{" "}
              kWh
            </strong>
          </p>
        )}
        <small>{data.startDate}</small>
        <small>{data.endDate}</small>
      </ChartTooltip>
    );
  };

  const _isLoading = () => {
    const mainEnergyConsumptionData =
      data[statisticsTypes.mainEnergyConsumption];
    const roomEnergyConsumptionData =
      data[statisticsTypes.roomEnergyConsumption];
    const compressorEnergyConsumption =
      data[statisticsTypes.compressorEnergyConsumption];

    if (
      (!isUndefined(mainEnergyConsumptionData) &&
        mainEnergyConsumptionData.loading === false) ||
      (!isUndefined(roomEnergyConsumptionData) &&
        roomEnergyConsumptionData.loading === false) ||
      (!isUndefined(compressorEnergyConsumption) &&
        compressorEnergyConsumption.loading === false)
    ) {
      return false;
    }

    return true;
  };

  const _getEnergyData = () => {
    let mainPresent = false;
    let roomPresent = false;
    let compressorPresent = false;

    const mainEnergyConsumption = getListFromData(
      data[statisticsTypes.mainEnergyConsumption]
    );

    if (mainEnergyConsumption.length > 0) {
      mainPresent = true;
    }

    const roomEnergyConsumption = getListFromData(
      data[statisticsTypes.roomEnergyConsumption]
    );

    if (roomEnergyConsumption.length > 0) {
      roomPresent = true;
    }

    const compressorEnergyConsumption = getListFromData(
      data[statisticsTypes.compressorEnergyConsumption]
    );

    if (compressorEnergyConsumption.length > 0) {
      compressorPresent = true;
    }

    let concatedValues = mainEnergyConsumption;

    if (_showRoom()) {
      concatedValues = [...concatedValues, ...roomEnergyConsumption];
    }

    if (_showCompressor()) {
      concatedValues = [...concatedValues, ...compressorEnergyConsumption];
    }
    const nameLabelFormat = getLabelNameFormat(
      getListFromData(data[statisticsTypes.mainEnergyConsumption])
    );

    const groupedValues = chain(concatedValues)
      .groupBy("recDateTime")
      .map((element) => {
        const startDate = moment.utc(element[0].recDateTime).local();

        return {
          name: startDate.format(nameLabelFormat),
          startDate: startDate.format("HH:mm"),
          endDate: moment
            .utc(element[0].recDatetimeEnd)
            .local()
            .format(" - HH:mm - DD.MM.YYYY"),
          energy: mainPresent ? getObjectItemForConsumption(element[0]) : 0,
          roomEnergy: roomPresent
            ? mainPresent
              ? getObjectItemForConsumption(element[1])
              : getObjectItemForConsumption(element[0])
            : 0,
          compressorEnergy: compressorPresent
            ? roomPresent
              ? mainPresent
                ? getObjectItemForConsumption(element[2])
                : getObjectItemForConsumption(element[1])
              : mainPresent
                ? getObjectItemForConsumption(element[1])
                : getObjectItemForConsumption(element[0])
            : 0,
        };
      })
      .value();

    return cleanChartDataToBeContinous(groupedValues, "energy");
  };

  return (
    <BarredChart
      title="Consumption"
      syncId={syncId}
      data={_getEnergyData()}
      tooltip={_getCustomTooltip}
      legends={legends}
      rangeLines={rangeLines}
      loading={_isLoading()}
      maxValue={5}
      minValue={0}
      yFormatter={(v) => (Number(v) / 1000).toFixed(2)}
    />
  );
};

EnergyConsumptionChart.propTypes = {
  syncId: PropTypes.string,
  data: PropTypes.object,
};

EnergyConsumptionChart.defaultProps = {
  syncId: "syncId",
  data: undefined,
};

export default EnergyConsumptionChart;
