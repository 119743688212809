import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../../actions/auth.actions';
import Component from './ChangePassword';

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isDemo: state.auth.isDemo,
  };
};

const mapDispatchToProps = dispatch => ({
  changePassword: bindActionCreators(actions.changePassword, dispatch)
});

const ComponentWithRouter = withRouter(Component);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithRouter);
