import React from 'react';
import PropTypes from 'prop-types';

import RadioInput from '../shared/RadioInput';
import Label from '../shared/Label';

const RadioButtonInputForm = props => {
  const { className = '', label, type, placeholder, input, meta } = props;

  return (
    <div className={`radio-button ${className}`}>
      <RadioInput
        id={input.value}
        placeholder={placeholder}
        type={type}
        {...input}
      />
      <label htmlFor={input.value}>{label}</label>
      <div className="radio-button__error">
        {meta.error && meta.touched && (
          <Label type="error" label={meta.error} />
        )}
      </div>
    </div>
  );
};

RadioButtonInputForm.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string
};

export default RadioButtonInputForm;
