import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import AuthRequiredRoute from "./components/Routes/AuthRequiredRoute";

import HomePage from "./pages/Home";
import LoginPage from "./pages/Auth/Login";
import MyUnitsGeneralPage from "./pages/MyUnits/General";
import MyUnitsDetailsPage from "./pages/MyUnits/Details";
import AlarmsPage from "./pages/Alarms";
import Settings from "./pages/Settings";
import ServicePage from "./pages/Service";
import NotFoundPage from "./pages/NotFound";
import StockPage from "./pages/Stock/StockPage";

import NonAuthRequiredRoute from "./components/Routes/NonAuthRequiredRoute";
import { urlRoute as url } from "./utils/url";
import * as roles from "./constants/roles";
import ChangePassword from "./pages/Auth/ChangePassword";
import UsageInsights from "./pages/UsageInsights/UsageInsights";

import withTracker from "./withTracker";
import Devices from "./pages/devices/Devices";
import AnonLogin from "./pages/anon-login/AnonLogin";
import Reefer from "./pages/reefer/Reefer";
import ProjectsList from "./pages/Projects/ProjectsList";
import ShipmentList from "./pages/Shipments/ShipmentList";
import GeneralAdmin from "./pages/admin/GeneralAdmin";
import AccountConfirmation from "./pages/Auth/AccountConfirmation/AccountConfirmation";
import PasswordRecovery from "./pages/Auth/PasswordRecovery/PasswordRecovery";

class Routes extends Component {
  render() {
    const allUsers = [
      roles.CLIENT,
      roles.CLIENT_ADMIN,
      roles.INSPIRA_FARMS_EMPLOYEE,
      roles.INSPIRA_FARMS_TECH,
      roles.DEMO_ACCOUNT,
      roles.INSPIRA_FARMS_ADMIN,
      roles.UNDER_THE_RADAR,
      roles.INSPIRA_FARMS_TECH_REGION

    ];

    const insightAllowedUsers = [roles.INSPIRA_FARMS_TECH, roles.INSPIRA_FARMS_ADMIN,
    roles.UNDER_THE_RADAR, roles.INSPIRA_FARMS_TECH_REGION];

    // const tech = [roles.INSPIRA_FARMS_TECH, roles.INSPIRA_FARMS_EMPLOYEE];
    //const tech_and_clientAdmins = [roles.INSPIRA_FARMS_TECH, roles.INSPIRA_FARMS_EMPLOYEE, roles.CLIENT_ADMIN, roles.DEMO_ACCOUNT];

    return (
      <Switch>
        <Route exact path={url("/")} component={HomePage} />
        <Route
          exact
          path={url("/auth/account-confirmation/:hash")}
          component={withTracker(AccountConfirmation)}
        />
        <Route
          exact
          path={url("/anon")}
          component={withTracker(AnonLogin)}
        />
        <NonAuthRequiredRoute
          exact
          path={url("/auth/login")}
          component={withTracker(LoginPage)}
        />
        <AuthRequiredRoute
          path={url("/units")}
          component={withTracker(MyUnitsGeneralPage)}
          allowedRoles={allUsers}
        />
        <AuthRequiredRoute
          path={url("/units-details/:coldStorageId")}
          component={withTracker(MyUnitsDetailsPage)}
          allowedRoles={allUsers}
        />
        <AuthRequiredRoute
          path={url("alarms/coldrooms")}
          component={withTracker(AlarmsPage)}
          allowedRoles={allUsers}
        />

        <AuthRequiredRoute
          path={url("alarms/live")}
          component={withTracker(AlarmsPage)}
          allowedRoles={allUsers}
        />

        <AuthRequiredRoute
          path={url("alarms")}
          component={withTracker(AlarmsPage)}
          allowedRoles={allUsers}
        />

        <AuthRequiredRoute
          path={url("alarms/sites")}
          component={withTracker(AlarmsPage)}
          allowedRoles={allUsers}
        />

        <AuthRequiredRoute
          path={url("/alarms/settings")}
          component={withTracker(AlarmsPage)}
          allowedRoles={allUsers}
        />

        <AuthRequiredRoute
          path={url("stock")}
          component={withTracker(StockPage)}
          allowedRoles={allUsers}
        />

        <AuthRequiredRoute
          path={url("/usageinsights")}
          component={withTracker(UsageInsights)}
          allowedRoles={insightAllowedUsers}
        />
        <AuthRequiredRoute
          path={url("/service")}
          component={withTracker(ServicePage)}
          allowedRoles={allUsers}
        />
        <AuthRequiredRoute
          path={url("/settings")}
          component={withTracker(Settings)}
          allowedRoles={allUsers}
        />
        <AuthRequiredRoute
          path={url("/projects/:projectId/units")}
          component={withTracker(MyUnitsGeneralPage)}
          allowedRoles={allUsers}
        />
        {/* <AuthRequiredRoute
          path={url("/projects/:projectId/units-details/:coldStorageId")}
          component={withTracker(MyUnitsDetailsPage)}
          allowedRoles={allUsers}
        /> */}
        <AuthRequiredRoute
          exact
          path={url("/projects")}
          component={withTracker(ProjectsList)}
          allowedRoles={allUsers}
        />

        <AuthRequiredRoute
          exact
          path={url("/devices")}
          component={withTracker(Devices)}
          allowedRoles={insightAllowedUsers}
        />

        <AuthRequiredRoute
          exact
          path={url("/devices/config")}
          component={withTracker(Devices)}
          allowedRoles={insightAllowedUsers}
        />

        <AuthRequiredRoute
          exact
          path={url("/devices/commercial")}
          component={withTracker(Devices)}
          allowedRoles={insightAllowedUsers}
        />

        <AuthRequiredRoute
          exact
          path={url("/devices/files")}
          component={withTracker(Devices)}
          allowedRoles={insightAllowedUsers}
        />

        <AuthRequiredRoute
          exact
          path={url("/admin")}
          component={withTracker(GeneralAdmin)}
          allowedRoles={insightAllowedUsers}
        />


        <AuthRequiredRoute
          exact
          path={url("/admin/users")}
          component={withTracker(GeneralAdmin)}
          allowedRoles={insightAllowedUsers}
        />

        <AuthRequiredRoute
          exact
          path={url("/admin/user/invite")}
          component={withTracker(GeneralAdmin)}
          allowedRoles={insightAllowedUsers}
        />



        <AuthRequiredRoute
          exact
          path={url("/shipments")}
          component={withTracker(ShipmentList)}
          allowedRoles={insightAllowedUsers}
        />

        <AuthRequiredRoute
          exact
          path={url("/reefer/devices")}
          component={withTracker(Reefer)}
          allowedRoles={insightAllowedUsers}
        />

        <AuthRequiredRoute
          exact
          path={url("/reefer/statistics")}
          component={withTracker(Reefer)}
          allowedRoles={insightAllowedUsers}
        />

        <AuthRequiredRoute
          exact
          path={url("/reefer/locations")}
          component={withTracker(Reefer)}
          allowedRoles={insightAllowedUsers}
        />

        <AuthRequiredRoute
          exact
          path={url("/reefer/alarms")}
          component={withTracker(Reefer)}
          allowedRoles={insightAllowedUsers}
        />


        <AuthRequiredRoute
          exact
          path={url("/shipments/:shipmentId/live")}
          component={withTracker(Reefer)}
          allowedRoles={insightAllowedUsers}
        />



        <NonAuthRequiredRoute
          exact
          path={url("/auth/password-recovery")}
          component={withTracker(PasswordRecovery)}
        />
        <NonAuthRequiredRoute
          path={url("/reset-password/:hash")}
          component={withTracker(ChangePassword)}
        />
        <Route path="*" component={withTracker(NotFoundPage)} />
      </Switch>
    );
  }
}

export default withRouter(Routes);
