import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

function Paper({classes, children}) {
    return (
        <div className={`paper ${classes}`}>
            {children}
        </div>
    )
}

Paper.propTypes = {
    classes: PropTypes.string
}

export default Paper

