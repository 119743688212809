import React from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
  emptyField,
  numberValidation,
  numberOrEmptyValidation
} from '../../../utils/validators';
import InputForm from '../../../components/Inputs/InputForm';
import DateTimePickerForm from '../../../components/Inputs/DateTimePickerForm';
import Label from '../../../components/shared/Label';
import Button from '../../../components/shared/Button';

import ReactGA from "react-ga";

import { STOCK } from '../../../constants/analyticsEventTypes';


//TODO change this implementation to use only one file called withTracker

ReactGA.initialize("UA-123201104-3", { debug: false });
ReactGA.pageview("/stock");


const AddStockItemForm = props => {
  const submit = async values => {
    ReactGA.event({
      category: STOCK.Category,
      action: STOCK.CreateStock,
      label: STOCK.CreateStock
    });
    try {
      await props.addStockItem(props.coldStorageId, props.projectId, values);
      props.reset();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const { handleSubmit, submitting, submitFailed, error, invalid } = props;
  return (
    <Form onSubmit={handleSubmit(submit)} className="stock__form form">
      <div className="form__inputs">
        <div className="form__inputs-name">
          <Field
            name="name"
            component={InputForm}
            type="text"
            placeholder="Name"
            label="BATCH"
            className="batch"
          />
        </div>
        <div className="form__inputs-name">
        <Field
            name="weight"
            component={InputForm}
            type="number"
            placeholder="Weight"
            label="KG"
            min={0}
            step={0.01}
          />
        </div>
       
        {/* <div className="form__mobile">
          <Field
            name="weight"
            component={InputForm}
            type="number"
            placeholder="Weight"
            label="KG"
            min={0}
            step={0.01}
          />
          <Field
            name="amount"
            component={InputForm}
            type="number"
            placeholder="Amount"
            label="PIECES"
            min={0}
          />
        </div> */}
        <div className="form__mobile">
          <Field name="in" component={DateTimePickerForm} label="INPUT TIME" />
          <Button
            type="submit"
            tag="primary"
            className="form__submit"
            disabled={submitting || invalid} >
            {submitting ? 'Adding' : 'Add To Stock'}
          </Button>
        </div>
      </div>
      {!submitting && submitFailed && <Label type="error" label={error} />}
      <Button
        type="submit"
        tag="primary"
        className="form__submit"
        disabled={submitting || invalid}>
        {submitting ? 'Adding' : 'Add To Stock'}
      </Button>
    </Form>
  );
};

AddStockItemForm.propTypes = {
  addStockItem: PropTypes.func,
  coldStorageId: PropTypes.number
};

export default reduxForm({
  form: 'addStockItemForm',
  validate: values => ({
    name: emptyField(values.name, 'Name is required'),
    weight: numberValidation(
      values.weight,
      'Weight is required',
      "Value shouldn't be negative"
    ),
    amount: numberOrEmptyValidation(
      values.amount,
      "Value shouldn't be negative",
      'Value should be integer'
    )
  })
})(AddStockItemForm);
