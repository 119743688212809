import {
  REPORT_SUBSCRIPTION_FETCHING,
  REPORT_SUBSCRIPTION_FETCH_SUCCESS,
} from "../../constants/report-subscription.types";
import api from "../../utils/api";

export const fetchReportSubscriptionStatus = (userId, projectId) => {
  return (dispatch) => {
    dispatch({
      type: REPORT_SUBSCRIPTION_FETCHING,
    });

    const getUserReportSubscription = api.get(
      `/report-subscription?userId=${userId}&projectId=${projectId}`
    );

    getUserReportSubscription
      .then((res) => {
        dispatch({
          type: REPORT_SUBSCRIPTION_FETCH_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => []);
  };
};

export const saveReportSubscriptionStatus = (
  userId,
  emailFrequency,
  emailAddress,
  projectId
) => {
  return (dispatch) => {
    let bodyData = {
      userId,
      emailFrequency,
      emailAddress,
      projectId,
    };

    const createUserReportSubscription = api.post(
      "/report-subscription",
      bodyData
    );

    createUserReportSubscription
      .then((res) => {
        dispatch({
          type: REPORT_SUBSCRIPTION_FETCH_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {});
  };
};

export const updateReportSubscriptionStatus = (
  userId,
  emailFrequency,
  emailAddress,
  projectId
) => {
  return (dispatch) => {
    let bodyData = {
      userId,
      emailFrequency,
      emailAddress,
      projectId,
    };
    const createUserReportSubscription = api.put(
      "/report-subscription",
      bodyData
    );

    createUserReportSubscription.then((res) => {}).catch((err) => {});
  };
};
