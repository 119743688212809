import {
  DONE_FETCHING_PROJECT_ALARMS,
  FETCHING_PROJECT_ALARMS,
} from "../../constants/alarms.types";

import api from "../../utils/api";

export const fetchProjectColdRooms = (selectedPeriod, projectUid) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_PROJECT_ALARMS,
    });

    api
      .get(`site/coldroomalarms/${selectedPeriod}`, {
        params: {
          projectUid,
        },
      })

      .then((res) => {
        dispatch({
          type: DONE_FETCHING_PROJECT_ALARMS,
          payload: res.data,
        });
      })
      .catch((err) => { });
  };
};
