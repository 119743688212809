import React from 'react';
import classnames from 'classnames'
import Collapse from 'react-collapse';

const CollapseSection = ({ name, isOpened, children, onToggleCollapse }) => {
  const _toggleCollapse = () => {
    onToggleCollapse(name);
  }

  const titleClassNames = classnames(
    'collapse__title',
    { 'closed': !isOpened }
  )

  return (
    <div className="collapse">
      <h2 onClick={_toggleCollapse} className={titleClassNames}>{name}</h2>
      <Collapse isOpened={isOpened}>
        {children}
      </Collapse>
    </div>
  )
}

export default CollapseSection;
