import { DONE_FETCHING_ALL_CONFIG, FETCHING_ALL_CONFIG } from "../../constants/feature-types";
import api from "../../utils/api";



export const fetchAllCompaniesConfiguration = () => {
    return (dispatch) => {
        dispatch({
            type: FETCHING_ALL_CONFIG,
        });

        api
            .get(`feature/all/config`)
            
            .then((res) => {
                dispatch({
                    type: DONE_FETCHING_ALL_CONFIG,
                    payload: res,
                });
            })
            .catch((err) => { });
    };
};
