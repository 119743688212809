import axios from "axios";
import { API_URL } from "../config";
import { getSession, clearSession, isRefreshTokenValid } from "./auth/session";
import { refreshToken } from "../actions/auth.actions";
import { urlRoute } from "./url";

import store from "../store";

const api = axios.create({
  baseURL: API_URL,
});

const onRequest = (config) => {
  const { token } = getSession();

  const {fingerprint } = store.store.getState().fingerPrintReducer;

  const {userCompanyRegion} = store.store.getState().auth;

  config.headers = {
    ...config.headers,
    userCompanyRegion,
    fingerprint,
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : "",
  };

  

  // config.request.user = {"1": 1}
  return config;
};

const onResponse = async (response) => {
  if (response.status === 402) {
    if (isRefreshTokenValid()) {
      await refreshToken()();
    } else {
      clearSession();
      window.location = urlRoute("/auth/login");
    }
  }
  return response;
};

const onError = (error) => {
  return Promise.reject(error);
};

api.interceptors.request.use(onRequest, onError);

api.interceptors.response.use(onResponse, onError);

export default api;
