import React, { useEffect, useState } from 'react'
// import CollapseSection from "../../../../components/shared/CollapseSection";
import { Table, Dropdown, Input, Button, Icon, Menu, Message } from 'semantic-ui-react'
import { connect } from "react-redux";
import './admin.scss'
import {
    fectchCompanies,
    updateCompanyLocalData,
    updateProjectLocalData,
    saveCompanyData,
    saveProjectData,
    onUnitProjectSelect,
    addInternalRoom,
    updateProfilesInternal,
    updateRoomsInternal,
    updateActiveRoomForUnitGroups,
    addUnitToRoom,
    removeUnitFromRoom,
    deleteRoomAtIndex,
    updateUnitInRoom,
    saveRoomData,
    saveOtherUnitsData,
    fetchUnitConfig,
    updateRoomLocalData,
    setActiveCompanyTab,
    setActiveProjectsTab,
    clearAllUnits,
    createCompanyEmailAction
} from '../../../../actions/projects.actions'
import { otherRoomUnitsDropdown } from '../../../../constants/config-field-key';
import { useIsMount } from '../../../../hooks/useIsFirstRender';
import { setBaseConfig } from '../../../../actions/config/config.action';
import { deleteDeice } from '../../../../api/sms.api';

function Admin(props) {

    // const [showCollapse, setShowCollapse] = useState('companies')
    const {
        fectchCompanies,
        companies,
        updateCompanyLocalData,
        updateProjectLocalData,
        companyCommercial,
        saveCompanyData,
        projectsDataDropDown,
        projectCommercial,
        saveProjectData,
        roomsCommercial,
        onUnitProjectSelect,
        deleteRoomAtIndex,
        addInternalRoom,
        updateProfilesInternal,
        updateRoomsInternal,
        addUnitToRoom,
        postingCompanyCommercial,
        postingCompanyCommercialError,
        postingCompanyCommercialMessage,
        postingCompanyCommercialMessageCounter,
        postingProjectCommercial,
        postingProjectCommercialError,
        postingProjectCommercialMessage,
        postingProjectCommercialMessageCounter,
        setBaseConfig,
        removeUnitFromRoom,
        updateUnitInRoom,
        postingRoomsCommercial,
        postingRoomsCommercialError,
        postingRoomsCommercialMessage,
        postingRoomsCommercialMessageCounter,
        saveRoomData,
        postingOtherUnitsCommercial,
        postingOtherUnitsCommercialError,
        postingOtherUnitsCommercialMessage,
        postingOtherUnitsCommercialMessageCounter,
        saveOtherUnitsData,
        fetchUnitConfig,
        updateRoomLocalData,
        setActiveCompanyTab,
        setActiveProjectsTab,
        activeCompanyTab,
        activeProjectsTab,
        clearAllUnits,
        createCompanyEmailAction,
        createCompanyEmail
    } = props


    const [showCreateCompanyMessage, setShowCreateCompanyMessage] = useState(false)
    const [showCreateProjectMessage, setShowCreateProjectMessage] = useState(false)
    const [showCreateRoomsMessage, setShowCreateRoomsMessage] = useState(false)
    const [showCreateOtherUnitMessage, setShowCreateOtherUnitMessage] = useState(false)


    const isFirstMount = useIsMount()

    useEffect(() => {
        fectchCompanies()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if (!isFirstMount) {

            setShowCreateCompanyMessage(true);

            const timer = setTimeout(() => {
                // function of api call 
                setShowCreateCompanyMessage(false);
            }, 3000);

            return () => {
                clearInterval(timer);
            }

        }

        // eslint-disable-next-line
    }, [postingCompanyCommercialMessageCounter])


    useEffect(() => {

        if (!isFirstMount) {

            setShowCreateRoomsMessage(true);

            const timer = setTimeout(() => {
                // function of api call 
                setShowCreateRoomsMessage(false);
            }, 3000);

            return () => {
                clearInterval(timer);
            }

        }

        // eslint-disable-next-line
    }, [postingRoomsCommercialMessageCounter])



    useEffect(() => {

        if (!isFirstMount) {

            setShowCreateOtherUnitMessage(true);

            const timer = setTimeout(() => {
                // function of api call 
                setShowCreateOtherUnitMessage(false);
            }, 3000);

            return () => {
                clearInterval(timer);
            }

        }

        // eslint-disable-next-line
    }, [postingOtherUnitsCommercialMessageCounter])



    useEffect(() => {

        if (!isFirstMount) {

            setShowCreateProjectMessage(true);
            if (!postingProjectCommercialError) {
                setBaseConfig()
                // onUnitProjectSelect(roomsCommercial?.projectId)
            }
            const timer = setTimeout(() => {
                // function of api call 
                setShowCreateProjectMessage(false);
            }, 3000);

            return () => {
                clearInterval(timer);
            }

        }

        // eslint-disable-next-line
    }, [postingProjectCommercialMessageCounter])



    const filterCompanyNameById = (companyId) => {

        const find = companies.find(el => el.value === companyId);
        if (find) {
            updateCompanyLocalData('name', find?.text)
        } else {
            updateCompanyLocalData('name', '')
            updateCompanyLocalData('id', '')
        }

    }

    const filterCompanyByProjectId = (projectId) => {

        const find = projectsDataDropDown.find(el => el.value === projectId);

        if (find) {
            updateCompanyLocalData('id', find.companyId)
            filterCompanyNameById(find.companyId)
        }

    }

    const dataProfileOptions = [
        { key: 0, text: 'STANDARD', value: 1 },
        { key: 1, text: 'EXTENDED', value: 2 },
        { key: 2, text: 'DEMO', value: 3 },
        { key: 3, text: 'PHOTOVOLTAIC_STANDARD', value: 4 },
        { key: 4, text: 'PHOTOVOLTAIC_EXTENDED', value: 5 },
        { key: 5, text: 'ON_DEMAND', value: 6 },
        { key: 6, text: 'ON_DEMAND_OFF_GRID', value: 7 },
        { key: 7, text: 'KAKUZI', value: 8 },
        { key: 8, text: 'LAUETTA', value: 9 },
        { key: 9, text: 'KHE', value: 10 },
        { key: 10, text: 'WISEROW', value: 11 },
        { key: 11, text: 'INSTAVEG', value: 12 },

    ]

    const unitNameOptions = [
        { key: 0, text: 'ColdRoom', value: 'ColdRoom' }
    ]


    const filterProjectById = (id) => {

        const find = projectsDataDropDown.find(el => el.value === id);
        if (find) {
            updateProjectLocalData('title', find?.text)
            updateProjectLocalData('id', find?.value)
            updateProjectLocalData('uid', find?.uid)
            updateProjectLocalData('companyId', find?.companyId)
        } else {
            updateProjectLocalData('title', '')
            updateProjectLocalData('id', '')
            updateProjectLocalData('uid', '')
            updateProjectLocalData('companyId', '')

        }

    }


    const filterProjectByIdIgnoreCompany = (id) => {

        const find = projectsDataDropDown.find(el => el.value === id);
        if (find) {
            updateProjectLocalData('title', find?.text)
            updateProjectLocalData('id', find?.value)
            updateProjectLocalData('uid', find?.uid)
            updateProjectLocalData('companyId', companyCommercial.id)

        } else {
            updateProjectLocalData('title', '')
            updateProjectLocalData('id', '')
            updateProjectLocalData('uid', '')
            updateProjectLocalData('companyId', companyCommercial.id)


        }

    }

    const filterProjectByCompanyId = (companyId) => {

        const find = projectsDataDropDown.find(el => el.companyId === companyId);
        if (find) {
            updateProjectLocalData('title', find?.text)
            updateProjectLocalData('id', find?.value)
            updateProjectLocalData('uid', find?.uid)
            updateProjectLocalData('companyId', find?.companyId)
            updateRoomLocalData('projectId', find?.value)
            onRoomProjectSelect(find?.value)
        } else {
            updateProjectLocalData('title', '')
            updateProjectLocalData('id', '')
            updateProjectLocalData('uid', '')
            updateProjectLocalData('companyId', '')
            updateRoomLocalData('projectId', '')
            onRoomProjectSelect('')

        }

    }

    const onRoomProjectSelect = (id) => {

        if (id) {
            const find = projectsDataDropDown.find(el => el.value === id);
            if (find) {

                onUnitProjectSelect(id, find?.units, find?.dataProfile, find?.otherUnits);
                fetchUnitConfig(find?.uid)
            }
            return;

        } else {
            setBaseConfig()
            onUnitProjectSelect('', [], [], []);
        }


    }

    const deleteOtherUnitAtIndex = (index, unitFieldId) => {

        const find = projectsDataDropDown.find(el => el.value === roomsCommercial?.projectId);

        if (find) {
            if (unitFieldId) deleteOtherUnit(unitFieldId)
            removeUnitFromRoom(index, find?.otherUnits, unitFieldId, roomsCommercial?.projectId);

        }

    }

    const filterRoomByUid = (uid) => {
        return roomsCommercial?.units?.find(unit => unit.uid === uid)
    }


    const canRoomsBeSaved = () => {

        let res = false;

        for (let i = 0; i < roomsCommercial?.units?.length; i++) {

            if (roomsCommercial?.units[i]?.title === '' || roomsCommercial?.units[i]?.uid === '' || roomsCommercial?.profiles[i]?.id === '') {
                return false;
            } else {

                res = true
            }
        }
        return res;
    }


    const canOtherUnitsBeSaved = () => {

        let res = false;

        for (let i = 0; i < roomsCommercial?.unitFields?.length; i++) {

            if (roomsCommercial?.unitFields[i]?.name === '' || roomsCommercial?.unitFields[i]?.uid === '' || roomsCommercial?.unitFields[i]?.unitUid === '') {
                return false;
            } else {

                res = true
            }
        }
        return res;
    }

    const deleteOtherUnit = async (id) => {
        await deleteDeice(id)
    }
    return (
        <>

            <div>
                <h2>
                    Companies
                </h2>
            </div>

            <Menu pointing secondary size='small'>
                <Menu.Item
                    name='CREATE'
                    active={activeCompanyTab === 'create'}
                    onClick={() => {
                        setActiveCompanyTab('create')
                        setActiveProjectsTab('create')
                        // updateCompanyLocalData('id', null)
                        filterCompanyNameById(null)
                        filterProjectById(null)
                        clearAllUnits()
                    }}
                    color={activeCompanyTab === 'create' ? 'yellow' : null}

                />
                <Menu.Item
                    name='UPDATE'
                    active={activeCompanyTab === 'update'}
                    onClick={() => {
                        setActiveCompanyTab('update')
                        setActiveProjectsTab('update')
                        // updateProjectLocalData('companyId', null)
                        filterCompanyNameById(companyCommercial?.id)
                        filterProjectByCompanyId(companyCommercial?.id)
                    }}
                    color={activeCompanyTab === 'update' ? 'yellow' : null}

                />

            </Menu>


            {activeCompanyTab === 'update' && <div className='enabled-disabled-container'>
                <div className='selector-label'>
                    <small className='input-label-color'>Select a company to update, ignore this field to create a new company</small>
                    <Dropdown
                        placeholder={"Select a company to update"}
                        search
                        selection
                        options={companies}
                        clearable

                        // defaultValue={activeDeviceForMeasure}
                        onChange={(event, { value }) => {

                            updateCompanyLocalData('id', value)
                            filterCompanyNameById(value)
                            filterProjectByCompanyId(value)
                            if (activeProjectsTab === 'create') setActiveProjectsTab('update')

                            // if (!value) onRoomProjectSelect(value)

                        }}


                        value={companyCommercial?.id}
                        fluid
                    />
                </div>
            </div>}
            <div className='enabled-disabled-container'>
                <div className='add-company-input'>

                    <small className='input-label-color'>Company Name</small>
                    <Input

                        onChange={(e) => {


                            updateCompanyLocalData('name', e.target.value)

                        }}


                        placeholder="Add a company name"
                        value={companyCommercial?.name}
                        fluid
                    >
                        <input />

                    </Input>
                </div>

            </div>
            {activeCompanyTab === 'create' && <div className='enabled-disabled-container'>
                <div className='add-company-input'>

                    <small className='input-label-color'>Initial company email address</small>
                    <Input

                        onChange={(e) => {

                            createCompanyEmailAction(e.target.value)

                        }}

                        placeholder="Add a company user email"
                        value={createCompanyEmail}
                        fluid
                        type='email'
                    >
                        <input />
                    </Input>
                </div>
            </div>
            }
            {showCreateCompanyMessage && <Message
                content={postingCompanyCommercialMessage}
                color={postingCompanyCommercialError ? 'red' : 'green'}
                size='mini'
            />}

            <div className="enabled-disabled-container">


                <div className='add-company-button'>
                    <Button
                        onClick={(e) => {

                            const data = { ...companyCommercial, id: activeCompanyTab === 'create' ? '' : companyCommercial.id }
                            data.email = createCompanyEmail

                            saveCompanyData(data)

                        }}
                        size="large"
                        color="yellow"
                        disabled={!companyCommercial?.name}
                    >
                        <div className='button-plus-spinner'>
                            {postingCompanyCommercial ? 'saving company data' : activeCompanyTab === 'update' ? 'Update selected company' : 'Create new company'}

                            <div className='spinner-button'>
                                {postingCompanyCommercial ? <Icon name='circle notched' color='white' loading={postingCompanyCommercial} /> : null}
                            </div>
                        </div>
                    </Button>
                </div>
            </div>


            <div className='custom-title-margin'>
                <h2>
                    Projects
                </h2>
            </div>


            <Menu pointing secondary size='small'>
                <Menu.Item
                    name='CREATE'
                    active={activeProjectsTab === 'create'}
                    onClick={() => {
                        setActiveProjectsTab('create')
                        clearAllUnits()
                        activeCompanyTab === 'update' ? filterProjectByIdIgnoreCompany(null) : filterProjectById(null)

                    }}
                    color={activeProjectsTab === 'create' ? 'yellow' : null}

                />
                <Menu.Item
                    name='UPDATE'
                    active={activeProjectsTab === 'update'}
                    onClick={() => {
                        setActiveProjectsTab('update')
                        setActiveCompanyTab('update')
                        filterProjectByCompanyId(companyCommercial?.id)


                    }}
                    color={activeProjectsTab === 'update' ? 'yellow' : null}


                />



            </Menu>

            {activeProjectsTab === 'update' && <div className='enabled-disabled-container'>
                <div className='selector-label'>
                    <small>Select the project to update</small>
                    <Dropdown
                        placeholder="Select a project to update"
                        search
                        selection
                        // clearable
                        options={projectsDataDropDown}
                        // defaultValue={selectedProject}
                        onChange={(event, { value }) => {
                            // setSelectedProject(value);
                            console.log(value)
                            updateProjectLocalData('id', value)
                            filterProjectById(value)
                            updateRoomLocalData('projectId', value)
                            onRoomProjectSelect(value)
                            filterCompanyByProjectId(value)



                        }}
                        value={projectCommercial.id ? projectCommercial.id : ''}
                        fluid
                    />
                </div>
            </div>}

            <div className='enabled-disabled-container'>
                <div className='selector-label'>
                    <small className='input-label-color'>Project Company</small>
                    <Dropdown
                        placeholder="Give the project a company..."
                        search
                        selection
                        clearable
                        options={companies}

                        onChange={(event, { value }) => {

                            updateProjectLocalData('companyId', value)
                        }}

                        fluid
                        value={projectCommercial.companyId ? projectCommercial.companyId : ''}
                        disabled
                    />
                </div>
            </div>

            <div className='enabled-disabled-container'>
                <div className='add-company-input'>
                    <small className='input-label-color'>Project Title</small>
                    <Input

                        onChange={(e) => {


                            updateProjectLocalData('title', e.target.value)

                        }}


                        placeholder="Project title"
                        value={projectCommercial?.title}
                        fluid
                    >
                        <input />

                    </Input>
                </div>

            </div>

            <div className='enabled-disabled-container'>
                <div className='add-company-input'>
                    <small className='input-label-color'>Project UID</small>
                    <Input

                        onChange={(e) => {

                            updateProjectLocalData('uid', e.target.value)


                        }}

                        placeholder="Project UID"
                        value={projectCommercial?.uid}
                        fluid
                    >
                        <input />

                    </Input>
                </div>

            </div>

            {showCreateProjectMessage && <Message
                content={postingProjectCommercialMessage}
                color={postingProjectCommercialError ? 'red' : 'green'}
                size='mini'
            />}

            <div className="enabled-disabled-container">
                <div className='company'>
                    <Button
                        onClick={(e) => {

                            const data = { ...projectCommercial, id: activeProjectsTab === 'create' ? null : projectCommercial.id }

                            saveProjectData(data)
                        }}
                        size="large"
                        color="yellow"


                        disabled={activeProjectsTab === 'update' ? !projectCommercial?.title || !projectCommercial?.uid : !projectCommercial?.title || !projectCommercial?.uid || !projectCommercial?.companyId}
                    >
                        <div className='button-plus-spinner'>
                            {postingProjectCommercial ? 'saving project data' : activeProjectsTab === 'update' ? 'Update selected project' : 'Create new project'}

                            <div className='spinner-button'>
                                {postingProjectCommercial ? <Icon name='circle notched' color='white' loading={postingProjectCommercial} /> : null}
                            </div>
                        </div>
                    </Button>
                </div>
            </div>



            <div className='custom-title-margin'>
                <h2>
                    Rooms
                </h2>
            </div>

            <div className='enabled-disabled-container'>
                <div className='selector-label'>
                    <small className='input-label-color'>Created/Updated project</small>
                    <Dropdown
                        placeholder="Select a project to view rooms..."
                        search
                        selection
                        clearable
                        options={projectsDataDropDown}

                        onChange={(event, { value }) => {
                            onRoomProjectSelect(value)
                        }}
                        value={roomsCommercial.projectId ? roomsCommercial.projectId : ''}
                        disabled
                        fluid
                    />
                </div>
            </div>

            <div className='enabled-disabled-container'>

                <Table>


                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>UNIT</Table.HeaderCell>
                            <Table.HeaderCell>UID</Table.HeaderCell>
                            <Table.HeaderCell>TITLE</Table.HeaderCell>
                            <Table.HeaderCell>DATA PROFILE</Table.HeaderCell>
                            <Table.HeaderCell />


                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {roomsCommercial?.units?.map((room, index) => (
                            <Table.Row>

                                <Table.Cell> <Dropdown
                                    placeholder="Select a unit"
                                    search
                                    selection
                                    clearable
                                    options={unitNameOptions}
                                    // defaultValue={selectedProject}
                                    onChange={(event, { value }) => {
                                        // updateProfilesInternal('id', value, index)
                                        value = value ? value : 'Coldroom'
                                        updateRoomsInternal('key', index, index)
                                        updateRoomsInternal('text', value + '_' + room?.uid + '_' + room?.title, index)
                                        updateRoomsInternal('value', value + '_' + room?.uid, index)
                                        updateRoomsInternal('unitName', value, index)
                                        updateRoomsInternal('projectId', roomsCommercial?.projectId, index)
                                    }}
                                    value={room?.unitName}


                                />
                                </Table.Cell>


                                <Table.Cell>
                                    <Input

                                        onChange={(e) => {

                                            const val = e.target.value

                                            updateRoomsInternal('uid', val, index)
                                            updateRoomsInternal('key', index, index)
                                            updateRoomsInternal('text', room?.unitName + '_' + val + '_' + room?.title, index)
                                            updateRoomsInternal('value', room?.unitName + '_' + val, index)
                                            updateRoomsInternal('projectId', roomsCommercial?.projectId, index)

                                        }}

                                        placeholder="00"
                                        value={room?.uid} />
                                </Table.Cell>

                                <Table.Cell>
                                    <Input

                                        onChange={(e) => {

                                            const val = e.target.value


                                            updateRoomsInternal('title', val, index)
                                            updateRoomsInternal('text', room?.unitName + '_' + room?.uid + '_' + val, index)
                                            updateRoomsInternal('projectId', roomsCommercial?.projectId, index)

                                        }}
                                        // fluid
                                        placeholder="Title"
                                        value={room?.title} />
                                </Table.Cell>
                                <Table.Cell> <Dropdown
                                    placeholder="Data profile"
                                    search
                                    selection
                                    clearable
                                    options={dataProfileOptions}

                                    onChange={(event, { value }) => {
                                        updateProfilesInternal('id', value, index)
                                        updateRoomsInternal('dataProfileId', value, index)
                                        updateRoomsInternal('projectId', roomsCommercial?.projectId, index)


                                    }}


                                    value={roomsCommercial?.profiles[index]?.id}

                                    fluid
                                />
                                </Table.Cell>
                                <Table.Cell>
                                    {index > 0 ? <Icon name='trash' color='red' onClick={() => { deleteRoomAtIndex(index) }} /> : null}

                                </Table.Cell>

                            </Table.Row>

                        ))}

                        <Table.Row>
                            <Table.Cell colSpan='5'>
                                <Button
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    color='yellow'
                                    size='small'

                                    onClick={() => { addInternalRoom() }}
                                >
                                    <Icon name='add circle' /> Add another unit room
                                </Button>

                            </Table.Cell>

                        </Table.Row>


                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>

                            <Table.HeaderCell colSpan='5'>

                                {showCreateRoomsMessage && <Message
                                    content={postingRoomsCommercialMessage}
                                    color={postingRoomsCommercialError ? 'red' : 'green'}
                                    size='mini'
                                />}

                                <Button
                                    floated='left'

                                    color='yellow'

                                    disabled={!canRoomsBeSaved()}
                                    onClick={() => {

                                        const data = roomsCommercial?.units
                                        const profiles = roomsCommercial?.profiles

                                        let req = []

                                        for (let i = 0; i < data.length; i++) {
                                            const obj = { ...data[i], dataProfileId: profiles[i].id, projectId: roomsCommercial?.projectId }
                                            req.push({ ...obj, })
                                        }


                                        saveRoomData(req)

                                    }}
                                >

                                    <div className='button-plus-spinner'>
                                        {postingRoomsCommercial ? 'Updating rooms data' : 'Update rooms data'}

                                        <div className='spinner-button'>
                                            {postingRoomsCommercial ? <Icon name='circle notched' color='white' loading={postingRoomsCommercial} /> : null}
                                        </div>
                                    </div>

                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>


            <div className='custom-title-margin'>
                <h2>
                    Room + Other Units mapping
                </h2>
            </div>

            <>

                <div className='enabled-disabled-container'>

                    <Table>


                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>UNIT</Table.HeaderCell>
                                <Table.HeaderCell>UID</Table.HeaderCell>
                                <Table.HeaderCell>ROOM</Table.HeaderCell>
                                <Table.HeaderCell>ACTUAL</Table.HeaderCell>
                                <Table.HeaderCell />



                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {roomsCommercial?.unitFields?.map((unitField, index) => (


                                <Table.Row>

                                    <Table.Cell> <Dropdown
                                        placeholder="Select a unit"
                                        search
                                        selection
                                        clearable
                                        options={otherRoomUnitsDropdown}
                                        // defaultValue={selectedProject}
                                        onChange={(event, { value }) => {

                                            updateUnitInRoom('name', value, index)
                                            updateUnitInRoom('key', index, index)

                                            if (unitField?.unitUid && unitField?.uid) {
                                                updateUnitInRoom('text', value + '_' + unitField?.unitUid, index)
                                                updateUnitInRoom('value', value + '_' + unitField?.unitUid, index)
                                            }
                                        }}
                                        value={unitField?.name}

                                    // fluid
                                    />
                                    </Table.Cell>

                                    <Table.Cell>
                                        <Input

                                            onChange={(e) => {

                                                const val = e.target.value

                                                updateUnitInRoom('key', index, index)
                                                updateUnitInRoom('unitUid', val, index)

                                                if (unitField?.name && unitField?.uid) {
                                                    updateUnitInRoom('text', unitField?.name + '_' + val, index)
                                                    updateUnitInRoom('value', unitField?.name + '_' + val, index)
                                                }

                                            }}
                                            placeholder="UID"
                                            value={unitField?.unitUid} />
                                    </Table.Cell>


                                    <Table.Cell>
                                        <Dropdown
                                            placeholder="Select a room"
                                            search
                                            selection
                                            clearable
                                            options={roomsCommercial?.units}
                                            onChange={(event, { value }) => {

                                                if (value) {

                                                    const values = value.split('_')

                                                    let uid = values?.length > 1 ? value?.split('_').slice(1).join("_") : values[1]

                                                    updateUnitInRoom('uid', uid, index)
                                                    updateUnitInRoom('key', index, index)



                                                    if (unitField?.name && unitField?.unitUid) {
                                                        updateUnitInRoom('text', unitField?.name + '_' + unitField?.unitUid, index)
                                                        updateUnitInRoom('value', unitField?.name + '_' + unitField?.unitUid, index)
                                                    }

                                                } else {

                                                    updateUnitInRoom('uid', value, index)
                                                    updateUnitInRoom('key', index, index)

                                                }


                                            }}
                                            value={`ColdRoom_${unitField?.uid}`}


                                        />
                                    </Table.Cell>

                                    <Table.Cell>
                                        <Input

                                            disabled
                                            placeholder="Title"
                                            value={unitField.value ? unitField.value : ''}
                                        />
                                    </Table.Cell>

                                    <Table.Cell>

                                        <Icon name='trash' color='red' onClick={() => {

                                            deleteOtherUnitAtIndex(index, unitField.id)
                                        }} />

                                    </Table.Cell>

                                </Table.Row>




                            ))}

                            <Table.Row>
                                <Table.Cell colSpan='4'>
                                    <Button
                                        floated='right'
                                        icon
                                        labelPosition='left'
                                        color='yellow'
                                        size='small'
                                        onClick={() => { addUnitToRoom() }}
                                    >
                                        <Icon name='add circle' /> Add another unit room mapping
                                    </Button>

                                </Table.Cell>

                            </Table.Row>


                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>

                                <Table.HeaderCell colSpan='5'>

                                    {showCreateOtherUnitMessage && <Message
                                        content={postingOtherUnitsCommercialMessage}
                                        color={postingOtherUnitsCommercialError ? 'red' : 'green'}
                                        size='mini'
                                    />}

                                    <Button
                                        floated='left'

                                        color='yellow'

                                        disabled={!canOtherUnitsBeSaved()}
                                        onClick={() => {

                                            const data = roomsCommercial?.unitFields

                                            let req = []

                                            for (let i = 0; i < data.length; i++) {

                                                const obj = { ...data[i], uid: data[i]?.unitUid, coldstorageId: filterRoomByUid(data[i]?.uid)?.id }
                                                req.push({ ...obj, })
                                            }


                                            saveOtherUnitsData(req)

                                        }}
                                    >

                                        <div className='button-plus-spinner'>
                                            {postingOtherUnitsCommercial ? 'Updating other units data' : 'Update other units data'}

                                            <div className='spinner-button'>
                                                {postingOtherUnitsCommercial ? <Icon name='circle notched' color='white' loading={postingOtherUnitsCommercial} /> : null}
                                            </div>
                                        </div>

                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div>


            </>



            <>


                <div className='custom-title-margin'>
                    <h2>
                        Available Rooms + Other unit ids matrix
                    </h2>
                </div>


                <div className="project-grid-separator">


                    <div className="project-grid-separator-right">




                        {roomsCommercial.units.map(room => {
                            return (<div className='inner-unit-card'>
                                {
                                    room?.uid && roomsCommercial?.unitFields?.find(el => el?.uid === room?.uid) ?
                                        <>
                                            <div className='inner-unit-card-left'>
                                                <p>{room?.uid}</p>
                                            </div>
                                            {roomsCommercial?.unitFields.map(unit => {
                                                return (
                                                    (room?.uid && unit?.name && unit?.uid && unit?.uid === room?.uid) ?
                                                        <div className='inner-unit-card-button'>
                                                            <Button
                                                                color='yellow'>
                                                                {`${unit?.name}_${unit?.unitUid}`}
                                                            </Button>
                                                        </div>
                                                        : null
                                                )

                                            })}</>
                                        : null
                                }
                            </div>)
                        })}







                    </div>
                </div>




            </>

        </>
    )
}

const mapStateToProps = ({ projects, alarms, auth }) => {
    const {
        data,
        companies,
        companyCommercial,
        projectCommercial,
        roomsCommercial,
        postingCompanyCommercial,
        postingCompanyCommercialError,
        postingCompanyCommercialMessage,
        postingCompanyCommercialMessageCounter,
        postingProjectCommercial,
        postingProjectCommercialError,
        postingProjectCommercialMessage,
        postingProjectCommercialMessageCounter,
        postingRoomsCommercial,
        postingRoomsCommercialError,
        postingRoomsCommercialMessage,
        postingRoomsCommercialMessageCounter,
        postingOtherUnitsCommercial,
        postingOtherUnitsCommercialError,
        postingOtherUnitsCommercialMessage,
        postingOtherUnitsCommercialMessageCounter,
        activeCompanyTab,
        activeProjectsTab,
        createCompanyEmail
    } = projects;


    const { id, isDemo, features, roles, paymentStatus } = auth;

    const { fetchingData, fetchingIndividualData } = alarms.setting;



    const arrDropDown = data.map((e, i) => {

        return {
            key: i,
            text: `${e.projectUid} - ${e.title}`,
            value: e.id,
            uid: e.projectUid,
            units: e.units,
            companyId: e.companyId,
            dataProfile: e.dataProfile,
            otherUnits: e.otherUnits
        };
    });

    return {

        projectsDataDropDown: arrDropDown,
        siteAlarms: alarms.setting.data,
        userAlarms: alarms.setting.individualData,
        fetchingData,
        userId: id,
        isDemo,
        fetchingIndividualData,
        fetchingThreshold: alarms.setting.fetchingThreshold,
        thresholdData: alarms.setting.thresholdData,
        tempThresholdData: alarms.setting.tempThresholdData,
        humThresholdData: alarms.setting.humThresholdData,
        coldStorageAlarmStatus: alarms.setting.coldStorageAlarmStatus,
        features,
        postingColdRoomThreshold: alarms.setting.postingColdRoomThreshold,
        fetchingCompanyContact: alarms.companyContact.fetchingData,
        updatingCompanyContact: alarms.companyContact.updatingData,
        companyContact: alarms.companyContact.data,
        roles,
        paymentStatus,
        companySmsStatus: alarms.companyContact.companySmsStatus,
        companies,
        companyCommercial,
        projectCommercial,
        roomsCommercial,
        postingCompanyCommercial,
        postingCompanyCommercialError,
        postingCompanyCommercialMessage,
        postingCompanyCommercialMessageCounter,
        postingProjectCommercial,
        postingProjectCommercialError,
        postingProjectCommercialMessage,
        postingProjectCommercialMessageCounter,
        postingRoomsCommercial,
        postingRoomsCommercialError,
        postingRoomsCommercialMessage,
        postingRoomsCommercialMessageCounter,
        postingOtherUnitsCommercial,
        postingOtherUnitsCommercialError,
        postingOtherUnitsCommercialMessage,
        postingOtherUnitsCommercialMessageCounter,
        activeCompanyTab,
        activeProjectsTab,
        createCompanyEmail
    };
};

export default connect(mapStateToProps, {
    fectchCompanies,
    updateCompanyLocalData,
    updateProjectLocalData,
    saveCompanyData,
    saveProjectData,
    onUnitProjectSelect,
    addInternalRoom,
    updateProfilesInternal,
    updateRoomsInternal,
    updateActiveRoomForUnitGroups,
    addUnitToRoom,
    removeUnitFromRoom,
    setBaseConfig,
    deleteRoomAtIndex,
    updateUnitInRoom,
    saveRoomData,
    saveOtherUnitsData,
    fetchUnitConfig,
    updateRoomLocalData,
    setActiveCompanyTab,
    setActiveProjectsTab,
    clearAllUnits,
    createCompanyEmailAction
})(Admin);





