import React, { Component } from 'react';
import { reduxForm, Form } from 'redux-form';
import PropTypes from 'prop-types';

import { emailValidate } from '../../../../../utils/validators';
import { Modal } from '../../../../../components/shared/Modal';
import Spinner from '../../../../../components/shared/Spinner';

import {SETTINGS} from '../../../../../constants/analyticsEventTypes';
import ReactGA from 'react-ga';

class AddUserModalWrapper extends Component {
  submit = async values => {
    ReactGA.event({
      category: SETTINGS.Category,
      action: SETTINGS.CreateUser,
      label: SETTINGS.CreateUser
    });

    try {
      await this.props.inviteUser({
        email: values.email,
        role: values.role
      });
      this.props.onCancel();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  render() {
    const { handleSubmit, loading } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.submit)}>
        <Modal
          header={this.props.header}
          confirmText={this.props.confirmText}
          cancelText="Cancel"
          onCancel={this.props.onCancel}
          onAccept={this.props.submit}
        >
          <Spinner active={loading} overlay />
          {this.props.children}
        </Modal>
      </Form>
    );
  }
}

AddUserModalWrapper.propTypes = {
  header: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  content: PropTypes.any,
  inviteUser: PropTypes.func,
  loading: PropTypes.bool
};

export default reduxForm({
  form: 'addUserModalWrapper',
  validate: values => ({
    email: emailValidate(
      values.email,
      'Email is required',
      'Please enter valid adress!'
    ),
    role: values.role ? undefined : 'Role is required'
  })
})(AddUserModalWrapper);
