export const LOADING_ACTIVE = "@alarms/active/LOADING";
export const DONE_FETCH_ACTIVE = "@alarms/active/DONE_FETCH";
export const ERROR_FETCH_ACTIVE = "@alarms/active/ERROR_FETCH";
export const CLEAN_DATA_ACTIVE = "@alarms/active/CLEAN_DATA";

export const LOADING_RESOLVE = "@alarms/active/resolve/LOADING";
export const DONE_RESOLVE = "@alarms/active/resolve/DONE_FETCH";
export const ERROR_RESOLVE = "@alarms/active/resolve/ERROR_FETCH";

export const LOADING_SOLVED = "@alarms/solved/LOADING";
export const DONE_FETCH_SOLVED = "@alarms/solved/DONE_FETCH";
export const ERROR_FETCH_SOLVED = "@alarms/solved/ERROR_FETCH";

export const LOADING_THRESHOLDS = "@alarms/thresholds/LOADING";
export const DONE_FETCH_THRESHOLDS = "@alarms/thresholds/DONE_FETCH";
export const ERROR_FETCH_THRESHOLDS = "@alarms/thresholds/ERROR_FETCH";
export const CLEAN_DATA_THRESHOLDS = "@alarms/thresholds/CLEAN_DATA";

export const DONE_UPDATE_THRESHOLDS = "@alarms/thresholds/DONE_UPDATE";
export const CLEAN_MESSAGES_THRESHOLDS = "@alarms/thresholds/CLEAN_MESSAGES";
export const UPDATE_VALUES_THRESHOLDS = "@alarms/thresholds/UPDATE_VALUES";
export const ERROR_UPDATE_THRESHOLDS = "@alarms/thresholds/ERROR_UPDATE";

export const SET_EMAIL_NOTIFICATION_THRESHOLDS =
  "@alarms/thresholds/SET_EMAIL_NOTIFICATION";

export const FETCHING_PROJECT_ALARMS = "fetchingProjectAlarms";
export const DONE_FETCHING_PROJECT_ALARMS = "doneFetchingProjectAlarms";
export const FAIL_FETCHING_PROJECT_ALARMS = "failFetchingProjectAlarms";

export const FETCHING_SITE_ALARMS = "fetchingSiteAlarms";
export const DONE_FETCHING_SITE_ALARMS = "doneFetchingSiteAlarms";
export const FAIL_FETCHING_SITE_ALARMS = "failFetchingSiteAlarms";

export const FETCHING_SITE_ALARMS_STATUS = "fetchingSiteAlarmsStatus";
export const DONE_FETCHING_SITE_ALARMS_STATUS = "doneFetchingSiteAlarmsStatus";
export const FAIL_FETCHING_SITE_ALARMS_STATUS = "failFetchingSiteAlarmsStatus";
export const DONE_UPDATING_SITE_ALARMS_STATUS = "doneUpdatingSiteAlarmsStatus";

export const FETCHING_INDIVIDUAL_USER_ALARM_STATUS =
  "fecthingIndividualUserAlarmStatus";

export const DONE_FETCHING_INDIVIDUAL_USER_ALARM_STATUS =
  "doneFecthingIndividualUserAlarmStatus";

export const FAIL_FETCHING_INDIVIDUAL_USER_ALARM_STATUS =
  "failFecthingIndividualUserAlarmStatus";

export const UPDATING_INDIVIDUAL_USER_ALARM_STATUS =
  "updatingIndividualUserAlarmStatus";

export const DONE_UPDATING_INDIVIDUAL_USER_ALARM_STATUS =
  "doneUpdatingIndividualUserAlarmStatus";

export const FAIL_UPDATING_INDIVIDUAL_USER_ALARM_STATUS =
  "failUpdatingIndividualUserAlarmStatus";

export const UPDATING_SITE_THRESHOLD = "updatingSiteThreshold";

export const FETCHING_SITE_THRESHOLD = "fetchingSiteThreshold";

export const DONE_FETCHING_SITE_THRESHOLD = "doneFetchingSiteThreshold";

export const UPDATE_THRESHOLD_VALUES = "updateThresholdValues";


export const UPDATING_COLD_ROOM_THRESHOLD = "updatingColdRoomThreshold";

export const FETCHING_COLD_ROOM_THRESHOLD = "fetchingColdRoomThreshold";

export const DONE_FETCHING_COLD_ROOM_THRESHOLD = "doneFetchingColdRoomThreshold";

export const DONE_UPDATING_COLD_ROOM_THRESHOLD = "doneUpdatingColdRoomThreshold";

export const UPDATE_COLD_ROOM_VALUES = "updateColdRoomThresholdValues";

export const UPDATE_COMPANY_CONTACT_VALUES = "updateCompanyContactValues";

export const FETCHING_COMPANY_CONTACT = "fetchingCompanyContact";

export const DONE_FETCHING_COMPANY_CONTACT = "doneFetchingCompanyContact";

export const UPDATING_COMPANY_CONTACT = "updatingCompanyContact";

export const DONE_UPDATING_COMPANY_CONTACT = "doneUpdatingCompanyContact";

export const DONE_UPDATING_COLD_ROOM_ALARM_STATUS = "doneUpdatingColdRoomAlarmStatus";

export const FAIL_UPDATING_COLD_ROOM_ALARM_STATUS = "failUpdatingColdRoomAlarmStatus";

export const VERIFYING_COMPANY_CONTACT = "verifyingCompanyContact";

export const DONE_VERIFYING_COMPANY_CONTACT = "doneVerifyingCompanyContact";

export const DONE_VERIFYING_COLD_ROOM_ALARM_STATUS = "doneVerifyingColdRoomAlarmStatus";

export const FAIL_VERIFYING_COLD_ROOM_ALARM_STATUS = "failVerifyingColdRoomAlarmStatus";

export const CONTACT_ADD = "contactAdd";

export const CONTACT_REMOVE = "contactRemove";

export const CONTACT_DELETE = "contactDelete";

export const CONTACT_DELETING = "contactDeleting";

export const FETCHING_COMPANY_SMS_STATUS = "fetchingCompanySmsStatus";
export const DONE_FETCHING_COMPANY_SMS_STATUS = "doneFetchingCompanySmsStatus";
export const UPDATING_COMPANY_SMS_STATUS = "updatingCompanySmsStatus";
export const DONE_UPDATING_COMPANY_SMS_STATUS = "doneUpdatingCompanySmsStatus";

export const FETCHING_LATEST_LIVE_ALARMS = "fetchingLatestLiveAlarms";
export const LATEST_LIVE_ALARMS = "latestLiveAlarms";

export const SAVED_LATEST_LIVE_ALARMS = "savedLatestLiveAlarms";
