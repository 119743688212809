import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

import ChartTooltip from '../../../../../../../components/Charts/components/ChartTooltip';
import AreaLinearChart from '../../../../../../../components/Charts/AreaChart';
import * as colors from '../../../../../../../constants/colors';
import { roundTwoDigits } from '../../../../../../../utils/numberFormat';
import { getLabelNameFormat } from '../../../../../../../helpers/chart.helper';
import { getListFromData } from '../../../../../../../helpers/object.helper';
import { cleanChartDataToBeContinous } from '../../../../../../../utils/chartUtils';

export const BatteryChargeChart = ({ syncId, data }) => {
  const legends = [
    { key: 'batteryCharge', color: colors.ORANGE, label: 'BATTERY CHARGE' }
  ];

  const rangeLines = [
    // { x: 0, y: 60, label: '60% MAX', color: colors.LIGHT_GREY },
    // { x: 0, y: 40, label: '40% MIN', color: colors.LIGHT_GREY }
  ];

  const _getbatteryChargeData = () => {
    const dataList = getListFromData(data);
    const nameLabelFormat = getLabelNameFormat(dataList)

    return cleanChartDataToBeContinous(dataList, 'value').map(element => {
      const startDate = moment.utc(element.recDateTime).local();

      return {
        name: startDate.format(nameLabelFormat),
        startDate: startDate.format('HH:mm - DD.MM.YYYY'),
        batteryCharge: element.value
      }
    });
  };

  const _getCustomTooltip = e => {
    const data = ((e.payload || [])[0] || {}).payload || {};

    return (
      <ChartTooltip>
        <p>
          BATTERY CHARGE: <strong>{roundTwoDigits(data.batteryCharge)}%</strong>
        </p>
        <small>{data.startDate}</small>
      </ChartTooltip>
    );
  };

  const _isLoading = () => {
    return !isUndefined(data) && data.loading === true;
  }

  return (
    <AreaLinearChart
      title="Battery charge"
      syncId={syncId}
      data={_getbatteryChargeData()}
      tooltip={_getCustomTooltip}
      legends={legends}
      rangeLines={rangeLines}
      loading={_isLoading()}
      maxValue={100}
      minValue={0} />
  );
}


BatteryChargeChart.propTypes = {
  syncId: PropTypes.string,
  data: PropTypes.object
};

BatteryChargeChart.defaultProps = {
  syncId: 'syncId',
  data: undefined
};

export default BatteryChargeChart;
