import * as roles from "../../constants/roles";

export const hasOneOrMoreSameValues = (arr1 = [], arr2 = []) => {
  let result = [];

  arr1.forEach((v) => {
    const res = arr2.find((s) => s === v);
    if (res) {
      result = [...result, res];
    }
  });

  return result.length > 0;
};

export const checkIfRoleIsInspiraFarmsTech = (arr = []) => {
  if (arr.find(el => el === roles.INSPIRA_FARMS_TECH) || arr.find(el => el === roles.UNDER_THE_RADAR) || arr.find(el => el === roles.INSPIRA_FARMS_TECH_REGION)) {
    return true;
  }

  return false
};

export const checkIfAccountIsDemo = (arr = []) => {
  return arr.includes(roles.DEMO_ACCOUNT);
};
