import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as authActions from '../../actions/auth.actions';
import { getUserId, getUserEmail, getProjectId } from '../../utils/auth/user';
import Component from './Navbar';

const mapStateToProps = (state, props) => {
  const moreThenOneProject = state.projects.data.length > 1;
  const projectId = (props.match.params || { projectId: null }).projectId || getProjectId() || null;
  let activeAlarms = [], solvedAlarms = [];

  var all_alarms = state.alarms.active.data ? state.alarms.active.data : [];

  //loop through state and set active alarms
  all_alarms.map(x => x.alarmStatus===1 ? activeAlarms.push(x.alarmStatus) : solvedAlarms.push(x.alarmStatus));

  return {
    projectId: projectId,
    moreThenOneProject: moreThenOneProject || false,
    userLoggedIn: state.auth.userIsLoggedIn || !!getUserId(),
    userEmail: state.auth.email || getUserEmail(),
    activeAlerts: activeAlarms.length,
    features: state.auth.features,
    paymentStatus: state.auth.paymentStatus
  }
};

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(authActions.logout, dispatch)
});

const ComponentWithRouter = withRouter(Component);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentWithRouter)
);
