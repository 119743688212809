import React, { Component } from "react";
import PropTypes from "prop-types";
import { DragDropContext } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/lib/HTML5toTouch";
import { Route, Switch } from "react-router-dom";

import Page from "../../../components/Page";
import Title from "../../../components/shared/Title";
import { urlRoute } from "../../../utils/url";
import Navigation from "../../../components/shared/Navigation";
// import LiveData from "./routes/LiveData";
// import Statistics from "./routes/Statistics";
import Stock from "./routes/Stock";
// import Thresholds from './routes/Thresholds';
import MobileNavigation from "./routes/Statistics/components/MobileNavigation";
import { checkIfAccountIsDemo } from "../../../utils/arrays/compareTwoArrays";
// import ArrowLink from '../../../components/shared/ArrowLink';
import * as roles from "../../../constants/roles";
import "./_my-unit-details.scss";

class MyUnitDetails extends Component {
  componentDidMount() {
    this.props.fetchUnits(this.props.projectId);
  }

  getLinkToDetails = (page) => {
    const { projectId, coldStorageId } = this.props;
    if (this.props.moreThenOneProject) {
      return urlRoute(
        `/projects/${projectId}/units-details/${coldStorageId}${page}`
      );
    }

    return urlRoute(`/units-details/${coldStorageId}${page}`);
  };

  getPathToDetails = (page) => {
    if (this.props.moreThenOneProject) {
      return urlRoute(
        `/projects/:projectId/units-details/:coldStorageId${page}`
      );
    }

    return urlRoute(`/units-details/:coldStorageId${page}`);
  };

  getBackUrl = () => {
    const { projectId } = this.props;
    if (projectId) {
      return urlRoute(`/projects/${projectId}/units`);
    }

    return urlRoute("/units");
  };

  allowedRoles = [roles.CLIENT, roles.CLIENT_ADMIN, roles.INSPIRA_FARMS_TECH, roles.INSPIRA_FARMS_ADMIN,
    roles.UNDER_THE_RADAR, roles.INSPIRA_FARMS_TECH_REGION];
  adminAndTechRoles = [roles.CLIENT_ADMIN, roles.INSPIRA_FARMS_TECH,  roles.INSPIRA_FARMS_ADMIN,
    roles.UNDER_THE_RADAR, roles.INSPIRA_FARMS_TECH_REGION];
  routes = () => ({
    // liveData: {
    //   label: "Live Data",
    //   link: this.getLinkToDetails(""),
    //   path: this.getPathToDetails(""),
    // },
    // statistics: {
    //   label: "Statistics",
    //   link: this.getLinkToDetails("/statistics"),
    //   path: this.getPathToDetails("/statistics"),
    // },
    inventory: {
      label: "Stock",
      link: this.getLinkToDetails("/stock"),
      path: this.getPathToDetails("/stock"),
      onlyFor: this.allowedRoles,
    },
    // thresholds: {
    //   label: 'Alarm Thresholds',
    //   link: this.getLinkToDetails('/thresholds'),
    //   path: this.getPathToDetails('/thresholds'),
    //   onlyFor: this.adminAndTechRoles
    // }
  });

  newRoutesWithoutInventory = () => ({
    // liveData: {
    //   label: "Live Data",
    //   link: this.getLinkToDetails(""),
    //   path: this.getPathToDetails(""),
    // },
    // statistics: {
    //   label: "Statistics",
    //   link: this.getLinkToDetails("/statistics"),
    //   path: this.getPathToDetails("/statistics"),
    // },
    // thresholds: {
    //   label: 'Alarm Thresholds',
    //   link: this.getLinkToDetails('/thresholds'),
    //   path: this.getPathToDetails('/thresholds'),
    //   onlyFor: this.adminAndTechRoles
    // }
  });

  navigation = {
    units: this.getBackUrl(),
  };

  render() {
    const { moreThenOneProject, projectId } = this.props;

    return (
      <Page isContainer className="my-unit-details">
        {/* <ArrowLink
          linkTo={this.navigation.units}
          direction="left"
          text={moreThenOneProject ? 'Project' : 'My Units'}
        /> */}
        <Title title={`${this.props.unitDetails().name}`} />
        <Navigation
          routes={
            checkIfAccountIsDemo
              ? this.newRoutesWithoutInventory()
              : this.routes()
          }
        />
        <Switch>
          {/* <Route
            exact
            path={this.routes().liveData.path}
            component={LiveData}
          /> */}
          {/* <Route
            exact
            path={this.routes().statistics.path}
            component={Statistics}
          /> */}

          {checkIfAccountIsDemo ? null : (
            <Route
              exact
              path={this.routes().inventory.path}
              component={Stock}
            />
          )}
        </Switch>
        <MobileNavigation
          projectId={projectId}
          isMultiProjectAccount={moreThenOneProject}
          showReturnButton={true}
        />
      </Page>
    );
  }
}

MyUnitDetails.propTypes = {
  moreThenOneProject: PropTypes.bool,
  coldStorageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fetchUnits: PropTypes.func,
};

export default DragDropContext(MultiBackend(HTML5toTouch))(MyUnitDetails);
