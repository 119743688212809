import React, { Fragment, useEffect } from "react";
import TemperatureChart from "./charts/TemperatureChart";
import moment from "moment";
import HumidityChart from "./charts/HumidityChart";
import EthyleneChart from "./charts/EthyleneChart";
import StockWeightChart from "./charts/StockWeightChart";
import statisticsTypes from "../../../../../../constants/statisticsTypes";
import CollapseSection from "../../../../../../components/shared/CollapseSection";
import {
  ROOM,
  ENERGY,
  WEATHER,
  ENERGY_STORAGE,
} from "../../../../../../constants/statistics-section.types";
import EnergyConsumptionChart from "./charts/EnergyConsumptionChart";
// import DoorOpenCloseChart from "./charts/DoorOpenCloseChart";
import DoorOpenDoorClosedNewChart from "./charts/DoorOpenDoorClosedNewChart";
import BatteryChargeChart from "./charts/BatteryChargeChart";
import ExternalTempChart from "./charts/ExternalTempChart";
import ExternalHumChart from "./charts/ExternalHumChart";
import SunIrradiationChart from "./charts/SunIrradiationChart";
import RainGaugeChart from "./charts/RainGaugeChart";
import ThermalStorageChart from "./charts/ThermalStorageChart";

import NumberIndicatorModule from "../../../../../../components/Charts/NumberIndicatorModule";
import * as colors from "../../../../../../constants/colors";
import { CSV_DATA_EXPORT } from "../../../../../../constants/analyticsEventTypes";
import ReactGA from "react-ga";

import ReactExport from "react-export-excel-xlsx-fix";
import Co2Chart from "./charts/CO2Chart";
import { chain } from "lodash";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const UserStatistics = ({
  data,
  csvData,
  timePeriod,
  dataProfile,
  onToggleCollapse,
  openedSections,
}) => {
  useEffect(() => {
    onToggleCollapse(ROOM);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const hasDataProfileAccess = (name) => {
    const restrictedValues = Object.keys(
      chain(dataProfile)
        .omit("id", "name")
        .omitBy((value) => value === false)
        .value()
    );

    return dataProfile && restrictedValues.includes(name);
  };


  const _isOpened = (sectionType) =>
    !!openedSections.find((value) => value === sectionType);
  function downloadWeatherStationData() {
    ReactGA.event({
      category: CSV_DATA_EXPORT.Category,
      action: CSV_DATA_EXPORT.DownloadWeatherStationData,
      label: CSV_DATA_EXPORT.DownloadWeatherStationData,
    });

    var rain_data = csvData[statisticsTypes.rainGauge]
      ? csvData[statisticsTypes.rainGauge].list
      : null;
    var external_temp = csvData[statisticsTypes.externalTemp]
      ? csvData[statisticsTypes.externalTemp].list
      : null;
    var external_hum = csvData[statisticsTypes.externalHum]
      ? csvData[statisticsTypes.externalHum].list
      : null;
    var sun_irradiation = csvData[statisticsTypes.sunIrradiation]
      ? csvData[statisticsTypes.sunIrradiation].list
      : null;

    let external_hum_dataset, sun_irradiation_dataset, rain_dataset;
    let all_data = [];

    if (rain_data && external_temp && external_hum && sun_irradiation) {
      external_temp.map((data, index) => {
        if (typeof rain_data[index] !== "undefined") {
          rain_dataset = rain_data[index];
        }
        external_hum_dataset = external_hum[index];
        sun_irradiation_dataset = sun_irradiation[index];

        var date_obj = moment
          .utc(data.recDateTime)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
          .split("T");
        var actual_date = date_obj[0];
        var from_time = date_obj[1];
        var to_time = moment
          .utc(data.recDatetimeEnd)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
          .split("T")[1];

        var split_from_date = from_time.split(".")[0];
        if (to_time) {
          var split_to_date = to_time.split(".")[0];
        }

        all_data.push({
          rain_gauge: rain_dataset.value ? rain_dataset.value.toFixed(2) : "N/A",
          external_temperature: data.value ? data.value.toFixed(2) : "N/A",
          external_humidity: external_hum_dataset.value
            ? external_hum_dataset.value.toFixed(2)
            : "N/A",
          sun_irradiation: sun_irradiation_dataset.value
            ? sun_irradiation_dataset.value.toFixed(2)
            : "N/A",
          date: actual_date,
          from: split_from_date,
          to: split_to_date,
        });
        return all_data;
      });

      return (
        <ExcelFile
          element={
            <button
              style={{
                backgroundColor: "orange",
                color: "white",
                textDecoration: "none",
                padding: "6px 12px",
                marginBottom: "0",
                fontSize: "14px",
                fontWeight: "normal",
                lineHeight: "1.428571429",
                textAlign: "center",
                whiteSpace: "nowrap",
                verticalAlign: "middle",
                cursor: "pointer",
                border: "1px solid transparent",
                borderRadius: "4px",
                userSelect: "none",
              }}
            >
              Download this data as spreadsheet
            </button>
          }
        >
          <ExcelSheet data={all_data} name="Weather Station Data">
            <ExcelColumn label="Rainfall(mm2)" value="rain_gauge" />
            <ExcelColumn
              label="External Temperature(°C)"
              value="external_temperature"
            />
            <ExcelColumn
              label="External Humidity(%)"
              value="external_humidity"
            />
            <ExcelColumn
              label="Sun Irradiation(w/m2)"
              value="sun_irradiation"
            />
            <ExcelColumn label="Date" value="date" />
            <ExcelColumn label="From" value="from" />
            <ExcelColumn label="To" value="to" />
          </ExcelSheet>
        </ExcelFile>
      );
    }
  }

  function downloadTempHumidityData() {

    ReactGA.event({
      category: CSV_DATA_EXPORT.Category,
      action: CSV_DATA_EXPORT.DownloadTempHumData,
      label: CSV_DATA_EXPORT.DownloadTempHumData,
    });
    var temp_data =
      csvData[statisticsTypes.cellTemp] &&
      csvData[statisticsTypes.cellTemp].list;
    var humidity_data =
      csvData[statisticsTypes.cellHum] && csvData[statisticsTypes.cellHum].list;
    var probe_data =
      csvData[statisticsTypes.piercingProbeTemp] &&
      csvData[statisticsTypes.piercingProbeTemp].list;
    var probe_data_2 =
      csvData[statisticsTypes.piercingProbeTemp2] &&
      csvData[statisticsTypes.piercingProbeTemp2].list;
    let all_data = [];
    let hum_dat;
    let probe_dat;
    let probe_dat_2;

    if (typeof temp_data !== "undefined") {
      temp_data.map((data, index) => {
        var empty_hum_data = {
          value: 0,
        };

        var empty_prob_data = {
          value: 0,
        };

        var empty_prob_data_2 = {
          value: 0,
        };

        if (typeof humidity_data !== "undefined") {
          hum_dat = humidity_data[index] || empty_hum_data;
        } else {
          hum_dat = empty_hum_data;
        }

        if (typeof probe_data !== "undefined") {
          probe_dat = probe_data[index] || empty_prob_data;
        } else {
          probe_dat = empty_prob_data;
        }

        if (typeof probe_data_2 !== "undefined") {
          probe_dat_2 = probe_data_2[index] || empty_prob_data_2;
        } else {
          probe_dat_2 = empty_prob_data_2;
        }

        var date_obj = moment
          .utc(data.recDateTime)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
          .split("T");
        var actual_date = date_obj[0];
        var from_time = date_obj[1];
        var to_time = moment
          .utc(data.recDatetimeEnd)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
          .split("T")[1];

        var split_from_date = from_time.split(".")[0];
        if (to_time) {
          var split_to_date = to_time.split(".")[0];
        }

        all_data.push({
          temperature: data && data.value ? data.value.toFixed(2) : 0,
          humidity: hum_dat && hum_dat.value ? hum_dat.value.toFixed(2) : 0,
          probe1: probe_dat && probe_dat.value ? probe_dat.value.toFixed(2) : 0,
          probe2: probe_dat_2 && probe_dat_2.value ? probe_dat_2.value.toFixed(2) : 0,
          date: actual_date,
          from: split_from_date,
          to: split_to_date,
        });
        return all_data;
      });

      return (
        <ExcelFile
          element={
            <button
              style={{
                backgroundColor: "orange",
                color: "white",
                textDecoration: "none",
                padding: "6px 12px",
                marginBottom: "0",
                fontSize: "14px",
                fontWeight: "normal",
                lineHeight: "1.428571429",
                textAlign: "center",
                whiteSpace: "nowrap",
                verticalAlign: "middle",
                cursor: "pointer",
                border: "1px solid transparent",
                borderRadius: "4px",
                userSelect: "none",
              }}
            >
              Download this data as spreadsheet
            </button>
          }
        >
          <ExcelSheet data={all_data} name="Temperature And Humidity Data">
            <ExcelColumn label="Temperature(°C)" value="temperature" />
            <ExcelColumn label="Humidity(%)" value="humidity" />
            <ExcelColumn label="Probe 1 Temperature(°C)" value="probe1" />
            <ExcelColumn label="Probe 2 Temperature(°C)" value="probe2" />
            <ExcelColumn label="Date" value="date" />
            <ExcelColumn label="From" value="from" />
            <ExcelColumn label="To" value="to" />
          </ExcelSheet>
        </ExcelFile>
      );
    }
  }

  return (
    <Fragment>
      <CollapseSection
        name={ROOM}
        isOpened={_isOpened(ROOM)}
        onToggleCollapse={onToggleCollapse}
      >
        <div className="statistics__charts-row">
          <NumberIndicatorModule
            title="Average Room Temperature"
            subTitle="for the chosen time span"
            color={colors.ORANGE}
            entity="°C"
            value={
              data[statisticsTypes.cellTemp] &&
              data[statisticsTypes.cellTemp].averageValue
            }
          ></NumberIndicatorModule>
          {
            dataProfile?.humAmbient && data[statisticsTypes?.cellHum]?.averageValue && (
              <NumberIndicatorModule
                title="Average Humidity"
                subTitle="for the chosen time span"
                color={colors.FIR_GREEN}
                entity="%"
                value={
                  data[statisticsTypes.cellHum] &&
                  data[statisticsTypes.cellHum].averageValue
                }
              ></NumberIndicatorModule>

            )
          }
        </div>
        <div className="statistics__charts-row">
          {hasDataProfileAccess(statisticsTypes.ethyleneProbe) && (
            <NumberIndicatorModule
              title="Average Ethylene"
              color={colors.ORANGE}
              subTitle="for the chosen time span"
              entity="PPM"
              value={
                data[statisticsTypes.ethyleneProbe] &&
                data[statisticsTypes.ethyleneProbe].averageValue
              }
            ></NumberIndicatorModule>
          )}
          {hasDataProfileAccess(statisticsTypes.CO2ambient) && (
            <NumberIndicatorModule
              title="Average CO2"
              color={colors.ORANGE}
              subTitle="for the chosen time span"
              entity="PPM"
              value={
                data[statisticsTypes.CO2ambient] &&
                data[statisticsTypes.CO2ambient].averageValue
              }
            ></NumberIndicatorModule>
          )}
        </div>
        <div className="statistics__charts-row">

          <TemperatureChart
            syncId="statistics"
            cellTempStatisticsType={statisticsTypes.cellTemp}
            piercingProbeTemp={statisticsTypes.piercingProbeTemp}
            piercingProbeTemp2={statisticsTypes.piercingProbeTemp2}
            tempSetpoint={statisticsTypes.tempSetpoint}
            tempSetpointData={data[statisticsTypes.tempSetpoint]}
            cellTempData={data[statisticsTypes.cellTemp]}
            piercingProbeData={data[statisticsTypes.piercingProbeTemp]}
            piercingProbeData2={data[statisticsTypes.piercingProbeTemp2]}
            timePeriod={timePeriod}
            dataProfile={dataProfile}
          />





          <HumidityChart
            syncId="statistics"
            statisticsType={statisticsTypes.cellHum}
            data={data[statisticsTypes.cellHum]}
          // humSetpoint={statisticsTypes.humSetpoint}
          // humSetpointData={data[statisticsTypes.humSetpoint]}
          />


        </div>
        <div className="statistics__charts-row">
          {hasDataProfileAccess(statisticsTypes.ethyleneProbe) && (
            <EthyleneChart
              syncId="statistics"
              statisticsType={statisticsTypes.ethyleneProbe}
              data={data[statisticsTypes.ethyleneProbe]}
            />
          )}
          {hasDataProfileAccess(statisticsTypes.CO2ambient) && (
            <Co2Chart
              syncId="statistics"
              statisticsType={statisticsTypes.CO2ambient}
              data={data[statisticsTypes.CO2ambient]}
            />
          )}

        </div>

        <div className="statistics__charts-row">
          {dataProfile?.doorRoom && (
            <DoorOpenDoorClosedNewChart data={data[statisticsTypes.doorRoom]} />
          )}
        </div>
        <div className="statistics__charts-row">

          <StockWeightChart
            syncId="statistics"
            statisticsType={statisticsTypes.stockWeights}
            data={data[statisticsTypes.stockWeights]}
            timePeriod={timePeriod}
          />
        </div>
        {downloadTempHumidityData()}
      </CollapseSection>
      <CollapseSection
        name={ENERGY}
        isOpened={_isOpened(ENERGY)}
        onToggleCollapse={onToggleCollapse}
      >
        <div className="statistics__charts-row">
          <NumberIndicatorModule
            title="Total Main Consumption"
            color={colors.ORANGE}
            subTitle="for the chosen time span"
            entity="kWh"
            value={
              data[statisticsTypes.mainEnergyConsumption] &&
                data[statisticsTypes.mainEnergyConsumption].consumedValue
                ? parseInt(
                  data[statisticsTypes.mainEnergyConsumption].consumedValue
                )
                : 0
            }
          ></NumberIndicatorModule>
          <NumberIndicatorModule
            title="Total Room Consumption"
            color={colors.FIR_GREEN}
            subTitle="for the chosen time span"
            entity="kWh"
            value={
              data[statisticsTypes.roomEnergyConsumption] &&
                data[statisticsTypes.roomEnergyConsumption].consumedValue
                ? parseInt(
                  data[statisticsTypes.roomEnergyConsumption].consumedValue
                )
                : 0
            }
          ></NumberIndicatorModule>
          <NumberIndicatorModule
            title="Total Compressor Consumption"
            color={colors.RED}
            subTitle="for the chosen time span"
            entity="kWh"
            value={
              data[statisticsTypes.compressorEnergyConsumption] &&
                data[statisticsTypes.compressorEnergyConsumption].consumedValue
                ? parseInt(
                  data[statisticsTypes.compressorEnergyConsumption]
                    .consumedValue
                )
                : 0
            }
          ></NumberIndicatorModule>
        </div>
        <div className="statistics__charts-row">
          <EnergyConsumptionChart
            syncId="statistics"
            mainEnergyConsumptionStatisticsType={
              statisticsTypes.mainEnergyConsumption
            }
            compressorEnergyConsumptionStatisticsType={
              statisticsTypes.compressorEnergyConsumption
            }
            roomEnergyConsumptionStatisticsType={
              statisticsTypes.roomEnergyConsumption
            }
            data={data}
            timePeriod={timePeriod}
          />
          {dataProfile?.battery && (
            <BatteryChargeChart
              syncId="statistics"
              statisticsType={statisticsTypes.batteryCharge}
              data={data[statisticsTypes.batteryCharge]}
              timePeriod={timePeriod}
            />
          )}
        </div>
      </CollapseSection>
      {dataProfile?.tempWaterTank1 && (
        <CollapseSection
          name={ENERGY_STORAGE}
          isOpened={_isOpened(ENERGY_STORAGE)}
          onToggleCollapse={onToggleCollapse}
        >
          <div className="statistics__charts-row">
            <ThermalStorageChart
              syncId="statistics"
              statisticsType={statisticsTypes.thermalStorageColdTemp}
              tempWaterTank1Data={data[statisticsTypes.tempWaterTank1]}
              tempWaterTank2Data={data[statisticsTypes.tempWaterTank2]}
              thermalStorageColdTempData={
                data[statisticsTypes.thermalStorageColdTemp]
              }
              timePeriod={timePeriod}
              dataProfile={dataProfile}
            />
          </div>
        </CollapseSection>
      )}
      <CollapseSection
        name={WEATHER}
        isOpened={_isOpened(WEATHER)}
        onToggleCollapse={onToggleCollapse}
      >
        <div className="statistics__charts-row">
          <NumberIndicatorModule
            title="Accumulated Rainfall"
            subTitle="for the chosen time span"
            color={colors.CYAN}
            entity="mm2"
            value={
              data[statisticsTypes.rainGauge] &&
              data[statisticsTypes.rainGauge].accumulatedValue
            }
          ></NumberIndicatorModule>
        </div>
        <div className="statistics__charts-row">
          <ExternalTempChart
            syncId="statistics"
            data={data[statisticsTypes.externalTemp]}
          />
          <ExternalHumChart
            syncId="statistics"
            data={data[statisticsTypes.externalHum]}
          />
        </div>
        <div className="statistics__charts-row">
          <SunIrradiationChart
            syncId="statistics"
            data={data[statisticsTypes.sunIrradiation]}
          />
          <RainGaugeChart
            syncId="statistics"
            data={data[statisticsTypes.rainGauge]}
          />
        </div>
        {downloadWeatherStationData()}
      </CollapseSection>
    </Fragment>
  );
};

export default UserStatistics;
