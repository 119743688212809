export const AUTH = {
    Category: "Authentication",
    Login: "Login",
    PasswordReset: "Password Reset",
    ContactSupport: "Contact Support"
};
export const UNITS_REPORT = {
    Category: "Units Report",
    ReportDateSelect: "Report Date Selection Component",
    ReportDataIntervalSelect: "Report Data Interval Selection Component"
};
export const STATISTICS = {
    Category: "Statistics",
    StatisticsDateSelect: "Statistics Date Selection Component"
};
export const ALARMS = {
    Category: "Alarms",
    ResolveAlarm: "Resolve Alarm Component"
};
export const THRESHOLDS = {
    Category: "Thresholds",
    UpdateThreshold: "Update Threshold Component"
};
export const SETTINGS = {
    Category: "Settings",
    CreateUser: "Create User Component",
    DeleteUser: "Delete User Component"
};
export const STOCK = {
    Category: "Stock",
    CreateStock: "Create Stock Component",
    EditStock: "Edit Stock Component",
    DispatchStock: "Dispatch Stock Component",
    DeleteStock: "Delete Stock Component",
    SplitStock: 'Split Stock Component'
};
export const CSV_DATA_EXPORT = {
    Category: "Data Export",
    DownloadTempHumData: "Download Temperature and Humidity CSV Data",
    DownloadWeatherStationData: "Download Weather Station CSV Data",
};

