import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./auth.reducer";
import appVersionReducer from "./appVersionReducer";
import unitsReducer from "./units.reducer";
import unitsDetailsLiveDataReducer from "./unitDetails/liveData.reducer";
import unitsDetailsStatisticsReducer from "./unitDetails/statistics.reducer";
import unitsDetailsStockReducer from "./unitDetails/stock.reducer";
import unitsDetailsCsvReducer from "./unitDetails/csvData.reducer";
import activeReducer from "./alarms/active.reducer";
import solvedReducer from "./alarms/solved.reducer";
import thresholdsReducer from "./alarms/thresholds.reducer";
import projectsReducer from "./projects.reducer";
import usersReducer from "./settings/users.reducer";
import contactReducer from "./service/contact.reducer";
import reportsReducer from "./reports.reducer";
import devicesReducer from "./devices.reducer";
import fingerPrintReducer from "./fingerPrintReducer";
import userInsightReducer from "./userInsights/userInsight.reducer";
import reportsSubscriptionReducer from "./report-subscription/reportSubscriptionReducer";
import alarmColdRoomReducer from "./alarms/cold-room.reducer";
import alarmSiteReducer from "./alarms/site.reducer";
import alarmSettingReducer from "./alarms/setting.reducer";
import featureConfigReducer from "./features/config.reducer";
import companyContactReducer from "./alarms/sms.reducer"
import deviceConfigReducerTest from "./config/deviceConfig.reducer"
import shipmentsReducer from "./shipments/shipment.reducer";
import adminUsersReducer from "./admin/admin.reducer";
import liveAlarmsReducer from "./alarms/live.reducer"

export default combineReducers({
  app: appVersionReducer,
  form: formReducer,
  auth: authReducer,
  units: unitsReducer,
  reports: reportsReducer,
  devices: devicesReducer,
  unitDetails: combineReducers({
    liveData: unitsDetailsLiveDataReducer,
    statistics: unitsDetailsStatisticsReducer,
    stock: unitsDetailsStockReducer,
    csvData: unitsDetailsCsvReducer,
  }),
  alarms: combineReducers({
    active: activeReducer,
    solved: solvedReducer,
    thresholds: thresholdsReducer,
    coldRooms: alarmColdRoomReducer,
    site: alarmSiteReducer,
    setting: alarmSettingReducer,
    companyContact: companyContactReducer,
    liveAlarms: liveAlarmsReducer,
  }),
  projects: projectsReducer,
  settings: combineReducers({
    users: usersReducer,
  }),
  service: combineReducers({
    contact: contactReducer,
  }),
  fingerPrintReducer,
  userInsightReducer,
  reportsSubscriptionReducer,
  featureConfigReducer,
  deviceConfigReducerTest,
  shipments: combineReducers({
    shipmentsReducer: shipmentsReducer,
  }),
  admin: combineReducers({
    adminUsersReducer: adminUsersReducer,
  })
});
