import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { isUndefined } from "lodash";

import ChartTooltip from "../../../../../../../components/Charts/components/ChartTooltip";
import AreaLinearChart from "../../../../../../../components/Charts/AreaChart";
import * as colors from "../../../../../../../constants/colors";
import { roundTwoDigits } from "../../../../../../../utils/numberFormat";
import { cleanChartDataToBeContinous } from "../../../../../../../utils/chartUtils";
import { getLabelNameFormat } from "../../../../../../../helpers/chart.helper";
import { getListFromData } from "../../../../../../../helpers/object.helper";

export const Co2Chart = ({ syncId, data }) => {
  const _getData = () => {
    const dataList = data ? data.list : [];

    const nameLabelFormat = getLabelNameFormat(getListFromData(data));

    return cleanChartDataToBeContinous(dataList, "value").map((element, index) => {
      const startDate = moment.utc(element.recDateTime).local();

      return {
        name: startDate.format(nameLabelFormat),
        startDate: startDate.format("HH:mm - DD.MM.YYYY"),
        co2: element.value ? Math.round(((element.value) / 10000) * 100) / 100 : null,
      };
    });
  };

  const _getCustomTooltip = (e) => {
    const data = ((e.payload || [])[0] || {}).payload || {};

    return (
      <ChartTooltip>
        <p>
          C02: <strong>{data.co2 ? `${roundTwoDigits(data.co2)}%` : '--'}</strong>
        </p>
        <small>{data.startDate}</small>
      </ChartTooltip>
    );
  };

  const _isLoading = () => {
    return ((!isUndefined(data) && data.loading === true));
  };

  const getLegends = () => {
    let arrLegends = [{ key: "co2", color: colors.FIR_GREEN, label: "CO₂" }];
    return arrLegends
  };

  const rangeLines = [
    // { x: 0, y: 60, label: '60% MAX', color: colors.LIGHT_GREY },
    // { x: 0, y: 40, label: '40% MIN', color: colors.LIGHT_GREY }
  ];

  return (
    <AreaLinearChart
      title="Carbon dioxide"
      syncId={syncId}
      data={_getData()}
      tooltip={_getCustomTooltip}
      legends={getLegends()}
      rangeLines={rangeLines}
      loading={_isLoading()}
      maxValue={1}
      minValue={0}
    />
  );
};

Co2Chart.propTypes = {
  syncId: PropTypes.string,
  data: PropTypes.object,
};

Co2Chart.defaultProps = {
  syncId: "syncId",
  data: undefined,
};

export default Co2Chart;
