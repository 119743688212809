import React from 'react';
import PropTypes from 'prop-types';
import arrow from '../../assets/images/arrow-right.svg';
import { Link } from 'react-router-dom';

const ArrowLink = ({ text, direction, button, linkTo }) => {
  return (
    !button && (
      <Link to={linkTo}>
        {direction === 'right' && `${text} `}
        <img
          src={arrow}
          className={`arrow arrow-${direction}`}
          alt={`${direction}-arrow`}
        />
        {direction === 'left' && ` ${text}`}
      </Link>
    )
  );
};

ArrowLink.propTypes = {
  text: PropTypes.string,
  direction: PropTypes.string,
  button: PropTypes.bool,
  linkTo: PropTypes.string
};

ArrowLink.defaultProps = {
  direction: 'right',
  text: 'text',
  button: false,
  linkTo: '#'
};

export default ArrowLink;
