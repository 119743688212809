import React from "react";
import ChartTooltip from "../../../components/Charts/components/ChartTooltip";
import LinearChart from "../../../components/Charts/LinearChart"
import * as colors from "../../../constants/colors";
import { roundTwoDigits } from "../../../utils/numberFormat";
import { getReeferLabelNameFormat } from "../../../helpers/chart.helper";
import moment from "moment";

export const ReeferLightAmbientChart = (props) => {

    const {
        syncId,
        shipmentData,
        shipmentDataLoad
    } = props;


    const legends = [{ key: "meanAmbientLight", color: colors.ORANGE, label: "CONTAINER LIGHT" }];


    const _isLoading = () => {
        return shipmentDataLoad;
    };

    const _getLightAmbientData = () => {

        return shipmentData.map(el => ({
            ...el,
            name: moment.utc(el.time).local().format(getReeferLabelNameFormat(shipmentData))
        }))
    }

    const _getCustomTooltip = (e) => {
        const data = ((e.payload || [])[0] || {}).payload || {};

        return (
            <ChartTooltip>
                <small>{moment(data.time).format('DD/MM HH:mm')}</small>
                <p>
                    LIGHT: <strong>{roundTwoDigits(data?.meanAmbientLight)}</strong>
                </p>

            </ChartTooltip>
        );
    };



    return (
        <LinearChart
            title="Light"
            syncId={syncId}
            data={_getLightAmbientData()}
            tooltip={_getCustomTooltip}
            legends={legends}
            rangeLines={[]}
            loading={_isLoading()}
            maxValue={500}
            minValue={0}
            xkey='time'
        />
    );

};

export default ReeferLightAmbientChart;



