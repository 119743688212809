import * as alarmsTypes from '../../constants/alarms.types';
import { mergeArraysBy } from '../../utils/arrays/mergeArraysBy';
import { PURGE } from 'redux-persist';

const initialState = {
  data: [],
  loading: false,
  sync: false,
  error: null
};

const reducer = {
  [alarmsTypes.LOADING_SOLVED]: state => ({
    ...state,
    loading: true
  }),
  [alarmsTypes.DONE_FETCH_SOLVED]: (
    state,
    action = { payload: { data: [] } }
  ) => ({
    ...state,
    loading: false,
    sync: true,
    data: mergeArraysBy(action.payload.data, state.data.items, 'id')
  }),
  [alarmsTypes.ERROR_FETCH_SOLVED]: (
    state,
    action = { payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [alarmsTypes.CLEAN_DATA_ACTIVE]: state => ({
    ...state,
    loading: false,
    sync: false,
    data: []
  }),
  [PURGE]: () => ({
    ...initialState
  })
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
