const statisticsTypes = {
  ethyleneProbe: 'ethyleneProbe',
  mainEnergyConsumption: 'mainEnergyConsumption',
  roomEnergyConsumption: 'roomEnergyConsumption',
  compressorEnergyConsumption: 'compressorEnergyConsumption',
  mainPower: 'mainPower',
  maxRoomPower: 'maxRoomPower',
  compressorPower: 'compressorPower',
  batteryTemp: 'batteryTemp',
  cellTemp: 'cellTemp',
  piercingProbeTemp: 'piercingProbeTemp',
  piercingProbeTemp2: 'piercingProbeTemp2',
  cellHum: 'cellHum',
  stockWeights: 'stockWeights',
  thermalStorageColdTemp: 'thermalStorageColdTemp',
  inverterVoltage: 'inverterVoltage',
  batteryVoltage: 'batteryVoltage',
  batteryCurrent: 'batteryCurrent',
  energyPvAux: 'energyPvAux',
  pressSwitch: 'pressSwitch',
  thr: 'thr',
  cprValve: 'cprValve',
  ethValve: 'ethValve',
  ge: 'ge',
  powerUsed: 'powerUsed',
  powerConsuption: 'powerConsuption',
  externalTemp: 'externalTemp',
  externalHum: 'externalHum',
  sunIrradiation: 'sunIrradiation',
  rainGauge: 'rainGauge',
  batteryCharge: 'batteryCharge',
  pressLp: 'pressLp',
  pressHp: 'pressHp',
  doorRoom: 'doorRoom',
  tempWaterTank1: 'tempWaterTank1',
  tempWaterTank2: 'tempWaterTank2',
  generatorStatus: 'generatorStatus',
  statusGrid: 'statusGrid',
  humSetpoint: 'humSetpoint',
  tempSetpoint: 'tempSetpoint',
  CO2ambient: 'CO2ambient'
};

export default statisticsTypes;
