import React from 'react';

export function ReferenceLabel(props) {
  const { fill, value, textAnchor, fontSize, viewBox, dy } = props;
  const y = value.includes('MAX') ? viewBox.y + 18 : viewBox.y - 6;
  return (
    <text
      x={40}
      y={y}
      dy={dy}
      dx={20}
      fill={fill}
      fontSize={fontSize || 10}
      textAnchor={textAnchor}
    >
      {value}
    </text>
  );
}

export default ReferenceLabel;
