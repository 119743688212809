export const LOADING_LIVE_DATA = '@unit-details/LIVE_DATA/LOADING';
export const DONE_FETCH_LIVE_DATA = '@unit-details/LIVE_DATA/DONE_FETCH';
export const ERROR_FETCH_LIVE_DATA = '@unit-details/LIVE_DATA/ERROR_FETCH';
export const CLEAN_DATA_LIVE_DATA = '@unit-details/LIVE_DATA/CLEAN_DATA';

export const LOADING_STATISTICS = '@unit-details/STATISTICS/LOADING';
export const DONE_FETCH_STATISTICS = '@unit-details/STATISTICS/DONE_FETCH';
export const ERROR_FETCH_STATISTICS = '@unit-details/STATISTICS/ERROR_FETCH';
export const CLEAN_DATA_STATISTICS = '@unit-details/STATISTICS/CLEAN_DATA';

export const LOADING_CSV_DATA = '@unit-details/CSVDATA/LOADING';
export const DONE_FETCH_CSV_DATA = '@unit-details/CSVDATA/DONE_FETCH';
export const ERROR_FETCH_CSV_DATA = '@unit-details/CSVDATA/ERROR_FETCH';

export const LOADING_STOCK = '@unit-details/STOCK/LOADING';
export const DONE_FETCH_STOCK = '@unit-details/STOCK/DONE_FETCH';
export const ERROR_FETCH_STOCK = '@unit-details/STOCK/ERROR_FETCH';
export const CLEAN_DATA_STOCK = '@unit-details/STOCK/CLEAN_DATA';

export const DONE_ADD_STOCK_ITEM = '@unit-details/STOCK/DONE_ADD_STOCK_ITEM';
export const ERROR_ADD_STOCK_ITEM = '@unit-details/STOCK/ERROR_ADD_STOCK_ITEM';

export const DONE_UPDATE_STOCK_ITEM =
  '@unit-details/STOCK/DONE_UPDATE_STOCK_ITEM';
export const ERROR_UPDATE_STOCK_ITEM =
  '@unit-details/STOCK/ERROR_UPDATE_STOCK_ITEM';
