import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form, Field, SubmissionError } from 'redux-form';
import InputForm from '../../../../components/Inputs/InputForm';
import Label from '../../../../components/shared/Label';
// import Button from '../../../../components/shared/Button';
import { Button } from 'semantic-ui-react'
import * as validators from '../../../../utils/validators';
import Spinner from '../../../../components/shared/Spinner';

import './_change-password.scss';

class ChangePassword extends Component {
  submit = async values => {

    console.log(values, this.props.isDemo)

    if (this.props.isDemo) {
      return
    }
    try {
      await this.props.changePassword(values);
      this.props.reset();
    } catch (e) {
      throw new SubmissionError({
        _error: 'Password is incorrect'
      });
    }
  };

  render() {
    const {
      handleSubmit,
      submitting,
      submitFailed,
      error,
      loading,
      isDemo
    } = this.props;



    return (
      <div className="change-password">
        <div className="change-password__header">
          <div className="section-title">Change Password</div>
        </div>
        <Form
          onSubmit={handleSubmit(this.submit)}
          className="change-password__form"
        >
          <Spinner active={loading} overlay />
          <Field
            name="password"
            component={InputForm}
            type="password"
            label="Old password"
            placeholder="Enter old password"
          />
          <Field
            name="newPassword"
            component={InputForm}
            type="password"
            label="New password"
            placeholder="Enter new password"
          />
          <Field
            name="passwordRepeat"
            component={InputForm}
            type="password"
            label="Repeat password"
            placeholder="Please repeat new password"
          />
          {!submitting && submitFailed && <Label type="error" label={error} />}
          <Button type={`${!isDemo ? 'submit' : ''}`} color='yellow' disabled={submitting || isDemo}>
            {submitting ? 'Loading' : 'Change'}
          </Button>
        </Form>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  changePassword: PropTypes.func,
  isDemo: PropTypes.bool
};

export default reduxForm({
  form: 'changePassword',
  validate: values => ({
    password: validators.passwordValidate(
      values.password,
      'Old password is required',
      'Password is too short'
    ),
    newPassword: validators.passwordValidate(
      values.newPassword,
      'New password is required',
      'New password is too short'
    ),
    passwordRepeat: validators.passwordRepeatValidate(
      values.newPassword,
      values.passwordRepeat,
      'Password repeat is required',
      'New password and password repeat should be the same'
    )
  })
})(ChangePassword);
