import React from 'react';
import PropTypes from 'prop-types';

import Label from '../shared/Label';
import Dropdown from '../shared/Dropdown';

const SingleSelectForm = props => {
  const { className, label, options, input, meta } = props;
  return (
    <div>
      <label>{label}</label>
      <Dropdown
        options={options}
        className={className}
        value={input.value}
        onChange={v => {
          // TODO: Here is still a bug. When choosing a contact the name disappears
          input.onChange(v);
          input.onBlur(v);
          input.onFocus(v);
        }}
      />
      {meta.error && meta.touched && <Label type="error" label={meta.error} />}
    </div>
  );
};

SingleSelectForm.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  error: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object
};

export default SingleSelectForm;
