
import { FEATURE_PRICING, USER_PAYMENT_STATUS, FEATURE_MODE } from '../constants/feature-traits'
import * as availableRoles from '../constants/roles'
import store from '../store';

export const toRenderFeature = (featureName, userPaymentStatus, allowedFeatures) => {




    if (!featureName || !userPaymentStatus || !allowedFeatures) {
        return false;
    }

    const foundFeature = allowedFeatures.find(feature => feature.name === featureName);

    if (!foundFeature) {
        return false;
    }


    if (foundFeature.pricing === FEATURE_PRICING.FREE || foundFeature.pricing === "") {
        return true;
    }

    if (foundFeature.pricing === FEATURE_PRICING.PAID && userPaymentStatus === USER_PAYMENT_STATUS.PAID) {
        return true;
    }

    return false

};


export const toVarySubFeatureRendering = (featureName, userPaymentStatus, userRoles) => {


    if (!featureName || !userPaymentStatus || !userRoles) {
        return false;
    }

    if (userRoles.length === 1 && userRoles.includes(availableRoles.UNDER_THE_RADAR)) {
        // anyungu and marco
        return true;
    }

    const { featureConfigReducer } = store.store.getState();
    const config = featureConfigReducer.data[featureName]

    if (!config) {
        //no config yet
        return false;
    }


    if (userRoles.length === 1 && userRoles.includes(availableRoles.DEMO_ACCOUNT) && config.roles.includes(availableRoles.DEMO_ACCOUNT) && (config.featureState === FEATURE_MODE.BETA || config.featureState === FEATURE_MODE.STABLE)) {
        // demo account no price checked
        return true;
    }

    if (userRoles.length === 1 && userRoles.includes(availableRoles.INSPIRA_FARMS_TECH) && config.roles.includes(availableRoles.INSPIRA_FARMS_TECH) && (config.featureState === FEATURE_MODE.BETA || config.featureState === FEATURE_MODE.STABLE)) {
        // tech account no price checked
        return true;
    }

    if (userRoles.length === 1 && userRoles.includes(availableRoles.INSPIRA_FARMS_TECH_REGION) && config.roles.includes(availableRoles.INSPIRA_FARMS_TECH_REGION) && (config.featureState === FEATURE_MODE.BETA || config.featureState === FEATURE_MODE.STABLE)) {
        // tech account no price checked
        return true;
    }

    if (userRoles.length === 1 && userRoles.includes(availableRoles.CLIENT) && config.roles.includes(availableRoles.CLIENT) && config.priceModel === FEATURE_PRICING.FREE && (config.featureState === FEATURE_MODE.BETA || config.featureState === FEATURE_MODE.STABLE)) {
        // client account no price checked because feature is free
        return true;
    }

    if (userRoles.length === 1 && userRoles.includes(availableRoles.CLIENT) && config.roles.includes(availableRoles.CLIENT) && config.priceModel === FEATURE_PRICING.PAID && userPaymentStatus === USER_PAYMENT_STATUS.PAID && (config.featureState === FEATURE_MODE.BETA || config.featureState === FEATURE_MODE.STABLE)) {
        // client account price checked because feature is paid
        return true;
    }

    if (userRoles.length === 1 && userRoles.includes(availableRoles.CLIENT_ADMIN) && config.roles.includes(availableRoles.CLIENT_ADMIN) && config.priceModel === FEATURE_PRICING.FREE && (config.featureState === FEATURE_MODE.BETA || config.featureState === FEATURE_MODE.STABLE)) {
        // client admin account no price checked because feature is free
        return true;
    }

    if (userRoles.length === 1 && userRoles.includes(availableRoles.CLIENT_ADMIN) && config.roles.includes(availableRoles.CLIENT_ADMIN) && config.priceModel === FEATURE_PRICING.PAID && userPaymentStatus === USER_PAYMENT_STATUS.PAID && (config.featureState === FEATURE_MODE.BETA || config.featureState === FEATURE_MODE.STABLE)) {
        // client admin account price checked because feature is paid
        return true;
    }

    return false;



};

