import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Component from "./MyUnitsGeneral";
import { getProjectId, getCurrentProjectInfo } from "../../../utils/auth/user";

const mapStateToProps = (state, props) => {
  const projectId =
    (props.match.params || { projectId: null }).projectId ||
    getProjectId() ||
    null;

  const moreThenOneProject = state.projects.data.length > 1;

  const currentProjectInfo = getCurrentProjectInfo();

  return {
    projectId: projectId,
    moreThenOneProject,
    currentProjectInfo,
    features: state.auth.features,
    paymentStatus: state.auth.paymentStatus
  };
};

const ComponentWithRouter = withRouter(Component);

export default connect(mapStateToProps)(ComponentWithRouter);
