import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { isUndefined } from "lodash";

import ChartTooltip from "../../../../../../../components/Charts/components/ChartTooltip";
import AreaLinearChart from "../../../../../../../components/Charts/AreaChart";
import * as colors from "../../../../../../../constants/colors";
import { roundTwoDigits } from "../../../../../../../utils/numberFormat";
import { getLabelNameFormat } from "../../../../../../../helpers/chart.helper";
import { getListFromData } from "../../../../../../../helpers/object.helper";
import { cleanChartDataToBeContinous } from "../../../../../../../utils/chartUtils";

export const ExternalHumChart = ({ syncId, data }) => {
  const legends = [
    { key: "externalHum", color: colors.ORANGE, label: "EXTERNAL HUM" },
  ];

  const rangeLines = [
    // { x: 0, y: 60, label: '60% MAX', color: colors.LIGHT_GREY },
    // { x: 0, y: 40, label: '40% MIN', color: colors.LIGHT_GREY }
  ];

  const _getExternalHumData = () => {
    const dataList = getListFromData(data);
    const nameLabelFormat = getLabelNameFormat(dataList);

    return cleanChartDataToBeContinous(dataList, "value").map((element) => {
      const startDate = moment.utc(element.recDateTime).local();

      return {
        name: startDate.format(nameLabelFormat),
        startDate: startDate.format("HH:mm - DD.MM.YYYY"),
        externalHum: Math.round(element.value * 100) / 100,
      };
    });
  };

  const _getCustomTooltip = (e) => {
    const data = ((e.payload || [])[0] || {}).payload || {};

    return (
      <ChartTooltip>
        <p>
          EXTERNAL HUM: <strong>{roundTwoDigits(data.externalHum)}%</strong>
        </p>
        <small>{data.startDate}</small>
      </ChartTooltip>
    );
  };

  const _isLoading = () => !isUndefined(data) && data.loading === true;

  return (
    <AreaLinearChart
      title="External hum"
      syncId={syncId}
      data={_getExternalHumData()}
      tooltip={_getCustomTooltip}
      legends={legends}
      rangeLines={rangeLines}
      loading={_isLoading()}
      maxValue={50}
      minValue={15}
    />
  );
};

ExternalHumChart.propTypes = {
  syncId: PropTypes.string,
  data: PropTypes.object,
};

ExternalHumChart.defaultProps = {
  syncId: "syncId",
  data: undefined,
};

export default ExternalHumChart;
