
import React, { useEffect, useState } from 'react'
import { Menu, Table, Dropdown, Input, Button, Icon, Accordion, Radio } from 'semantic-ui-react'
import ReactJson from 'react-json-view'
import { connect } from "react-redux";
import './config.scss'


import {
    updateInternalComport,
    updateInternalRootProjectConfig,
    addDeviceCount,
    updateInternalDeviceId,
    updateInternalDeviceFields,
    setDeviceUnderMeasure,
    updatePegoRoom,
    updateDeviceMeasure,
    deleteDeviceAtIndex,
    updateSensorDefaultsAtIndex,
    setBaseConfig
} from '../../../../actions/config/config.action';
import { fieldIdOptions } from '../../../../constants/config-field-key';
import { uploadToS3 } from '../../../../api/sms.api';
import { defaultSensorValues, doesFucntionIncludeNumber } from '../../../../constants/sensor-defaults';
import { fetchUnitConfig } from '../../../../actions/projects.actions';
// import { Tablet } from '@material-ui/icons';
// import measureConfig from "../../../../assets/device-config/measure.json"


const speedOptions = [
    { key: 'slow', text: '19200', value: 19200 },
    { key: 'medium', text: '9600', value: 9600 },
    { key: 'fast', text: '4800', value: 4800 }
]

const parityOptions = [
    { key: 'slow', text: 'None', value: 'none' },
    { key: 'medium', text: 'Even', value: 'even' },
    { key: 'fast', text: 'Odd', value: 'odd' }
]


const deviceIds = [
    { key: 'side', text: 'CAREL_COMPRESSOR', value: 'COMPRESSOR' },
    { key: 'tablet2', text: 'CAREL_CO2', value: 'CO2CAREL' },
    { key: 'carelTemp', text: 'CAREL_TH', value: 'CAREL_TH' },
    { key: 'touch', text: 'DUCATI_MAIN', value: 'DUCATI_MAIN' },
    { key: 'finish', text: 'DUCATI_MONO_L1', value: 'DUCATI_MONO_L1' },
    { key: 'finish1', text: 'DUCATI_MONO_L2', value: 'DUCATI_MONO_L2' },
    { key: 'finish2', text: 'DUCATI_MONO_L3', value: 'DUCATI_MONO_L3' },
    { key: 'start', text: 'DUCATI_POLY', value: 'DUCATI_POLY' },
    { key: 'el', text: 'ELA4IO', value: 'ELA4IO' },
    { key: 'FRER_MAIN', text: 'FRER_MAIN', value: 'FRER_MAIN' },
    { key: 'FRER_MONO_L1', text: 'FRER_MONO_L1', value: 'FRER_MONO_L1' },
    { key: 'FRER_MONO_L2', text: 'FRER_MONO_L2', value: 'FRER_MONO_L2' },
    { key: 'FRER_MONO_L3', text: 'FRER_MONO_L3', value: 'FRER_MONO_L3' },
    { key: 'center', text: 'IME_MAIN', value: 'IME_MAIN' },
    { key: 'right', text: 'IME_MONO_L1', value: 'IME_MONO_L1' },
    { key: 'right1', text: 'IME_MONO_L2', value: 'IME_MONO_L2' },
    { key: 'right2', text: 'IME_MONO_L3', value: 'IME_MONO_L3' },
    { key: 'left', text: 'IME_POLY', value: 'IME_POLY' },
    { key: 'msr', text: 'MSR_ELECT', value: 'MSR_ELECT' },
    { key: 'desktop', text: 'ONE', value: 'ONE' },
    { key: 'read', text: 'PEGO_ECP', value: 'PEGO_ECP' },
    { key: 'mobile', text: 'PEGO_VT', value: 'PEGO_VT' },
    { key: 'smart', text: 'PEGO_NECTROR', value: 'PEGO_NECTOR' },
    { key: 'tablet', text: 'PUMP', value: 'PUMP' },
]

const comPortOptions = [
    { key: 1, text: '1', value: 1 },
    { key: 2, text: '2', value: 2 },
    { key: 3, text: '3', value: 3 },
]

const valTypeOptions = [
    { key: 'digIn', text: 'digIn', value: 'digIn' },
    { key: 'direct', text: 'direct', value: 'direct' },
    { key: 'raw', text: 'raw', value: 'raw' },
    { key: 'linearV', text: 'linearV', value: 'linearV' },
    { key: 'linearI', text: 'linearI', value: 'linearI' }
]

const staticUnitOptions = [
    { key: 1000, text: 'GeneralData_000', value: 'GeneralData_000' },
    { key: 1001, text: 'PowerData_000', value: 'PowerData_000' },
    { key: 1002, text: 'PowerData_100', value: 'PowerData_100' },
    { key: 1003, text: 'PowerData_200', value: 'PowerData_200' },
    { key: 1004, text: 'PowerData_300', value: 'PowerData_300' },
    { key: 1005, text: 'PowerData_400', value: 'PowerData_400' },
    { key: 1006, text: 'PowerData_500', value: 'PowerData_500' },
    { key: 1007, text: 'PowerData_600', value: 'PowerData_600' },
    { key: 1008, text: 'PowerData_700', value: 'PowerData_700' },
    { key: 1009, text: 'PowerData_800', value: 'PowerData_800' },
]

const comPortnames = ['commPort1', 'commPort2', 'commPort3']

const pegoRKeys = ['R09', 'R10', 'R11', 'R12', 'R13', 'R14', 'R15', 'R16', 'R17', 'R18', 'R19', 'R20', 'R21', 'R22']



function Config(props) {

    const {
        projectsDataDropDown,
        roomsCommercial,
        config,
        updateInternalComport,
        updateInternalRootProjectConfig,
        devicesCount,
        addDeviceCount,
        updateInternalDeviceFields,
        updateInternalDeviceId,
        devicesForMeasuresDropdown,
        setDeviceUnderMeasure,
        deviceUnderMeasure,
        updatePegoRoom,
        updateDeviceMeasure,
        finalUnitsArray,
        deleteDeviceAtIndex,
        updateSensorDefaultsAtIndex,
        setBaseConfig,
        fetchUnitConfig
    } = props




    const [currentMenu, setCurrentMenu] = useState('com')
    const [activeMeasure, setActiveMeasure] = useState(null)
    const [uploadingToS3, setUploadingToS3] = useState(false)



    useEffect(() => {

        updateRootProjectConfig('projectID', roomsCommercial?.projectId)

        // eslint-disable-next-line
    }, [roomsCommercial?.projectId])

    // useEffect(() => {



    // }, [deviceUnderMeasure])


    const onUploadToS3Clicked = () => {
        setUploadingToS3(true)
        uploadToS3(config, roomsCommercial)
            .then(res => {

                setUploadingToS3(false)
            })
            .catch(err => {

                setUploadingToS3(false)
            })
    }



    const exportData = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(config)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = `${config.projectID}-configuration.json`;

        link.click();
    };




    const selectAMenu = (value) => {
        setCurrentMenu(value)
    }

    const checkIfLastDeviceElementMakesSense = () => {
        const last = config?.devices[devicesCount - 1]

        if (last && last.commPort && last.deviceID && last.mbAddr) {
            return true
        }

        return false
    }

    const addDevice = () => {
        // setDevices([...devices, { id: '', comPort: '', mbAddr: '', key: devices.length, value: devices.length, configured: false, measures: [] }])
        addDeviceCount()
    }








    const updateComport = (key, value, name) => {

        updateInternalComport(key, value, name)

    }

    const findSingleProject = (id) => {
        return projectsDataDropDown.find((e) => e.value === id);
    };


    const updateRootProjectConfig = (key, value) => {

        if (key === 'projectID') {
            updateInternalRootProjectConfig(key, findSingleProject(value)?.uid);
            updateInternalRootProjectConfig('projectName', findSingleProject(value)?.text);
        }
    }



    const isDevicePego = () => {

        return config?.devices[deviceUnderMeasure]?.deviceID === 'PEGO_ECP' ||
            config?.devices[deviceUnderMeasure]?.deviceID === 'PEGO_VT' ||
            config?.devices[deviceUnderMeasure]?.deviceID === 'PEGO_NECTOR'
    }

    const isDeviceDucatiMain = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'DUCATI_MAIN'

    }

    const isDeviceCompressor = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'COMPRESSOR'
    }
    const isDeviceDucatiMono = () => {

        return config?.devices[deviceUnderMeasure]?.deviceID?.includes('DUCATI_MONO')

    }

    const isDeviceDucatiPoly = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'DUCATI_POLY'
    }

    const isDeviceImeMono = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID?.includes('IME_MONO');
    }

    const isDeviceImePoly = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'IME_POLY'
    }

    const isDeviceImeMain = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'IME_MAIN'
    }

    const isDeviceFrerMono = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID?.includes('FRER_MONO');
    }

    const isDeviceFrerMain = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'FRER_MAIN'
    }

    const isDevicePump = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'PUMP'
    }

    const isDeviceOne = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'ONE'
    }

    const isDeviceCarel = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'CAREL_TH' || config?.devices[deviceUnderMeasure]?.deviceID === 'CO2CAREL'
    }

    const isDeviceELA4IO = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'ELA4IO'
    }

    const isDeviceMsrElect = () => {
        return config?.devices[deviceUnderMeasure]?.deviceID === 'MSR_ELECT'
    }





    return (
        <>

            <div className='enabled-disabled-container'>
                <div className='selector-label'>
                    <small className='input-label-color'>Project under current configuration</small>

                    <Dropdown
                        placeholder={"Select a project to configure"}
                        search
                        selection
                        options={projectsDataDropDown}
                        // options={projectsData}
                        // defaultValue={}
                        onChange={(event, { value }) => {
                            // setSelectedProject(value);

                            updateRootProjectConfig('projectID', value)
                            // setActiveDeviceForMeasure(value)

                        }}
                        disabled
                        value={roomsCommercial?.projectId}
                        fluid
                        clearable
                    />
                </div>

            </div>
            <Menu icon='labeled' fluid widths={4}>
                <Menu.Item
                    name='COM PORTS'
                    active={currentMenu === 'com'}
                    onClick={() => { selectAMenu('com') }}
                    color={currentMenu === 'com' ? 'yellow' : null}
                />
                <Menu.Item
                    name='DEVICES'
                    active={currentMenu === 'devices'}
                    onClick={() => { selectAMenu('devices') }}
                    color={currentMenu === 'devices' ? 'yellow' : null}
                />
                <Menu.Item
                    name='MEASURES'
                    active={currentMenu === 'units'}
                    onClick={() => { selectAMenu('units') }}
                    color={currentMenu === 'units' ? 'yellow' : null}
                />

                <Menu.Item
                    name='CONFIG PREVIEW'
                    active={currentMenu === 'preview'}
                    onClick={() => { selectAMenu('preview') }}
                    color={currentMenu === 'preview' ? 'yellow' : null}
                />
            </Menu>

            <div className='upload-download-button-container'>
                <div>
                    <Button
                        icon labelPosition='redo'
                        onClick={(e) => {
                            setBaseConfig()
                        }}
                        size="large"
                        color="yellow"
                    // fluid

                    // disabled={!isVoltageThresholdInputValid()}
                    >
                        <Icon name='cloud download' />
                        Reset config
                    </Button>

                </div>

                <div>
                    <Button
                        icon labelPosition='left'
                        onClick={(e) => {

                            fetchUnitConfig(config.projectID)

                        }}
                        size="large"
                        color="yellow"
                    // fluid

                    // disabled={}
                    >
                        <Icon name='cloud download' />
                        Restore from cloud
                    </Button>

                </div>

                <div>
                    <Button
                        icon labelPosition='left'
                        onClick={(e) => {
                            exportData()

                            // if (!isVoltageThresholdInputValid()) {
                            //     return
                            // }
                            // updateSiteThreshold({
                            //     ...thresholdData,
                            //     type: "voltageMain",
                            //     projectUid: isDemo ? "DEMO" : thresholdProject,
                            // })
                        }}
                        size="large"
                        color="yellow"
                    // fluid

                    // disabled={!isVoltageThresholdInputValid()}
                    >
                        <Icon name='cloud download' />
                        Download as file
                    </Button>

                </div>

                <div>
                    <Button
                        icon labelPosition='left'
                        onClick={(e) => {
                            onUploadToS3Clicked()
                        }}
                        size="large"
                        color="yellow"


                    >

                        <Icon name='cloud upload' />
                        <div className='button-plus-spinner'>
                            {uploadingToS3 ? 'Uploading' : 'Upload to cloud'}

                            <div className='spinner-button'>
                                {uploadingToS3 ? <Icon name='circle notched' color='white' loading={uploadingToS3} /> : null}
                            </div>
                        </div>

                    </Button>
                </div>
            </div>
            {currentMenu === 'com' && <Table>

                <Table.Header>
                    <Table.Row>

                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Speed</Table.HeaderCell>
                        <Table.HeaderCell>Parity</Table.HeaderCell>

                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {comPortnames.map((port, index) => (
                        <Table.Row>

                            <Table.Cell>{port}</Table.Cell>
                            <Table.Cell>
                                <Dropdown placeholder='Select speed'
                                    // fluid
                                    value={config[port]?.portSpeed}
                                    search
                                    // defaultValue={speedOptions[0].value}
                                    onChange={(event, { value }) => {

                                        updateComport('portSpeed', value, port)
                                    }}
                                    selection
                                    options={speedOptions} />
                            </Table.Cell>
                            <Table.Cell>
                                <Dropdown placeholder='Select parity'
                                    // fluid
                                    value={config[port]?.portParity}
                                    search
                                    // defaultValue={speedOptions[0].value}
                                    onChange={(event, { value }) => {

                                        updateComport('portParity', value, port)
                                    }}
                                    selection
                                    options={parityOptions} />
                            </Table.Cell>

                        </Table.Row>

                    ))}


                </Table.Body>
            </Table>}

            {currentMenu === 'devices' && <Table>


                <Table.Header>
                    <Table.Row>

                        <Table.HeaderCell colSpan='1'>ID</Table.HeaderCell>
                        <Table.HeaderCell colSpan='1'>ComPort</Table.HeaderCell>
                        <Table.HeaderCell colSpan='1'>MbAddr</Table.HeaderCell>
                        <Table.HeaderCell />

                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {[...Array(devicesCount).keys()].map((element, index) => (
                        <Table.Row>


                            <Table.Cell colSpan='1'>
                                <Dropdown placeholder='Select ID'
                                    onChange={(event, { value }) => {
                                        // updateDevice(index, 'id', value)

                                        updateInternalDeviceId(value, element)
                                    }}

                                    value={config?.devices[element]?.deviceID}
                                    // fluid
                                    selection
                                    options={deviceIds} />
                            </Table.Cell>
                            <Table.Cell colSpan='1'>
                                <Dropdown placeholder='Select Com port'
                                    onChange={(event, { value }) => {
                                        // updateDevice(index, 'comPort', value)
                                        updateInternalDeviceFields('commPort', value, element)
                                    }}
                                    value={config?.devices[element]?.commPort}
                                    defaultValue={config?.devices[element]?.commPort}
                                    lazyLoad

                                    // fluid
                                    selection
                                    options={comPortOptions} />
                            </Table.Cell>
                            <Table.Cell colSpan='1'>
                                <Input
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {

                                        const val = Math.abs(e.target.value)

                                        if (val === 0) {


                                            updateInternalDeviceFields('mbAddr', val, element)

                                        } else {


                                            updateInternalDeviceFields('mbAddr', val, element)

                                        }

                                    }}
                                    // fluid
                                    placeholder="00"
                                    value={config?.devices[element]?.mbAddr}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                {index > 0 ? <Icon name="trash" onClick={() => { deleteDeviceAtIndex(index) }} color='red' /> : null}
                            </Table.Cell>


                        </Table.Row>

                    ))}


                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>

                        <Table.HeaderCell colSpan='3'>
                            <Button
                                floated='right'
                                icon
                                labelPosition='left'
                                color='yellow'
                                size='small'
                                disabled={!checkIfLastDeviceElementMakesSense()}
                                onClick={() => { addDevice() }}
                            >
                                <Icon name='add circle' /> Add Device
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>}

            {currentMenu === 'units' &&

                <>
                    <div className='site-notifications'>

                        <Menu pointing secondary size='large' className='site-notifications-menu'>
                            {devicesForMeasuresDropdown?.map((el, idx) => (


                                <Menu.Item
                                    key={idx}
                                    active={deviceUnderMeasure === el?.value}
                                    onClick={() => {
                                        setDeviceUnderMeasure(el?.value)
                                    }}
                                    color={deviceUnderMeasure === el?.value ? 'yellow' : null}
                                    className='site-notifications-menu'


                                >
                                    {el?.text?.toUpperCase()}
                                </Menu.Item>

                            ))}
                        </Menu>
                    </div>


                    {

                        config.devices?.length > 0 &&
                        Object.entries(config.devices[deviceUnderMeasure]?.measure)?.map(([key, measureDetail], index) => (

                            <>
                                {key !== 'RAIN' && (

                                    <>

                                        {(isDevicePego() && index === 0) &&
                                            <div className='enabled-disabled-container'>
                                                <div className='selector-label'>
                                                    <small>
                                                        Select a room id for this device
                                                    </small>
                                                    <Dropdown
                                                        placeholder={"Select a unit id..."}
                                                        search
                                                        selection
                                                        options={roomsCommercial?.units}
                                                        value={measureDetail?.unitID}
                                                        // options={projectsData}
                                                        // defaultValue={activeDeviceForMeasure}
                                                        onChange={(event, { value }) => {
                                                            // setSelectedProject(value);

                                                            // setActiveDeviceForMeasure(value)
                                                            updatePegoRoom(deviceUnderMeasure, value)
                                                        }}
                                                        icon={<Icon name='caret down' />}



                                                    // fluid
                                                    />
                                                </div>
                                            </div>
                                        }


                                        {((isDeviceDucatiMono() && index === 0) || (isDeviceImeMono() && index === 0) || (isDeviceFrerMono() && index === 0)) &&
                                            <div className='enabled-disabled-container'>
                                                <div className='selector-label'>
                                                    <small>
                                                        Select a unit id for your ducati mono
                                                    </small>
                                                    <Dropdown
                                                        placeholder={"Select a unit id..."}
                                                        search
                                                        selection
                                                        options={[...roomsCommercial?.units, ...finalUnitsArray, ...staticUnitOptions]}
                                                        value={measureDetail?.unitID}
                                                        // options={projectsData}
                                                        // defaultValue={activeDeviceForMeasure}
                                                        onChange={(event, { value }) => {
                                                            // setSelectedProject(value);

                                                            // setActiveDeviceForMeasure(value)
                                                            updatePegoRoom(deviceUnderMeasure, value)
                                                        }}
                                                        icon={<Icon name='caret down' />}



                                                    // fluid
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {((index === 0 && isDeviceDucatiMain()) || (isDeviceImeMain() && index === 0) || (isDeviceFrerMain() && index === 0)) &&
                                            <div className='enabled-disabled-container'>
                                                <div className='selector-label'>
                                                    <small>
                                                        Default unit id selected
                                                    </small>
                                                    <Dropdown
                                                        placeholder={"Select a unit id..."}
                                                        search
                                                        selection
                                                        options={staticUnitOptions.filter(el => el.value.includes('PowerData'))}
                                                        value={measureDetail?.unitID}
                                                        // options={projectsData}
                                                        // defaultValue={'PowerData_000'}
                                                        onChange={(event, { value }) => {
                                                            // setSelectedProject(value);

                                                            // setActiveDeviceForMeasure(value)
                                                            updatePegoRoom(deviceUnderMeasure, value)
                                                        }}
                                                        icon={<Icon name='caret down' />}
                                                    // disabled


                                                    // fluid
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {(index === 0 && isDeviceCarel()) &&
                                            <div className='enabled-disabled-container'>
                                                <div className='selector-label'>
                                                    <small>
                                                        Select a unit for this device
                                                    </small>
                                                    <Dropdown
                                                        placeholder={"Select a unit id..."}
                                                        search
                                                        selection
                                                        options={[...roomsCommercial?.units, ...staticUnitOptions.filter(el => el.value.includes('GeneralData'))]}
                                                        value={measureDetail?.unitID}
                                                        // options={projectsData}
                                                        // defaultValue={'PowerData_000'}
                                                        onChange={(event, { value }) => {
                                                            // setSelectedProject(value);

                                                            // setActiveDeviceForMeasure(value)
                                                            updatePegoRoom(deviceUnderMeasure, value)
                                                        }}
                                                        icon={<Icon name='caret down' />}
                                                    // disabled


                                                    // fluid
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {((isDeviceDucatiPoly() || isDeviceImePoly()) && index === 0) &&
                                            <div className='enabled-disabled-container'>

                                                {/* <small>
                                                        Default unit id for ducati main selected
                                                    </small> */}
                                                <Dropdown
                                                    placeholder={"Select a unit id..."}
                                                    search
                                                    selection
                                                    options={[...roomsCommercial?.units, ...finalUnitsArray, ...staticUnitOptions]}
                                                    value={measureDetail?.unitID}
                                                    // options={projectsData}
                                                    // defaultValue={'PowerData_000'}
                                                    onChange={(event, { value }) => {
                                                        // setSelectedProject(value);

                                                        // setActiveDeviceForMeasure(value)
                                                        // updateDeviceMeasure(deviceUnderMeasure, key, 'unitID', value)

                                                        updatePegoRoom(deviceUnderMeasure, value)
                                                    }}
                                                    icon={<Icon name='caret down' />}
                                                // disabled


                                                // fluid
                                                />

                                            </div>
                                        }


                                        <Accordion styled fluid>
                                            <Accordion.Title
                                                active={index === activeMeasure}
                                                index={index}
                                                onClick={() => activeMeasure === index ? setActiveMeasure(null) : setActiveMeasure(index)}
                                                style={{ cursor: 'pointer' }}

                                            >
                                                <div className='device-input-title-text'>
                                                    <Icon name='dropdown' />
                                                    <label > <h2>{isDeviceOne() && key.includes('IN1') ? `${key}_weatherRain` : `${key}_${measureDetail?.fieldID}`}</h2> </label>
                                                </div>

                                                {(isDeviceCompressor()) &&
                                                    <div className='enabled-disabled-container'>

                                                        <Dropdown
                                                            placeholder={"Select a unit id..."}
                                                            search
                                                            selection
                                                            options={finalUnitsArray.filter(el => el.value.includes('CompressorData'))}
                                                            value={measureDetail?.unitID}

                                                            onChange={(event, { value }) => {

                                                                updateDeviceMeasure(deviceUnderMeasure, key, 'unitID', value)
                                                            }}
                                                            icon={<Icon name='caret down' />}

                                                        />

                                                    </div>
                                                }

                                                {(isDevicePump()) &&
                                                    <div className='enabled-disabled-container'>


                                                        <Dropdown
                                                            placeholder={"Select a unit id..."}
                                                            search
                                                            selection
                                                            options={finalUnitsArray.filter(el => el.value.includes('PumpData'))}
                                                            value={measureDetail?.unitID}

                                                            onChange={(event, { value }) => {

                                                                updateDeviceMeasure(deviceUnderMeasure, key, 'unitID', value)
                                                            }}
                                                            icon={<Icon name='caret down' />}

                                                        />

                                                    </div>
                                                }

                                                {(isDeviceELA4IO()) &&
                                                    <div className='enabled-disabled-container'>


                                                        <Dropdown
                                                            placeholder={"Select a unit id..."}
                                                            search
                                                            selection
                                                            options={roomsCommercial?.units}
                                                            value={measureDetail?.unitID}

                                                            onChange={(event, { value }) => {

                                                                updateDeviceMeasure(deviceUnderMeasure, key, 'unitID', value)
                                                            }}
                                                            icon={<Icon name='caret down' />}

                                                        />

                                                    </div>
                                                }



                                                {(isDeviceOne()) &&
                                                    <>
                                                        <div className='enabled-disabled-container'>

                                                            <Dropdown
                                                                placeholder={"Select a unit id..."}
                                                                search
                                                                selection
                                                                options={[...roomsCommercial?.units, ...finalUnitsArray, ...staticUnitOptions]}
                                                                value={key.includes('IN1') ? config.devices[deviceUnderMeasure]?.measure['RAIN']?.unitID : measureDetail?.unitID}
                                                                // options={projectsData}
                                                                // defaultValue={'PowerData_000'}
                                                                onChange={(event, { value }) => {
                                                                    // setSelectedProject(value);

                                                                    // setActiveDeviceForMeasure(value)
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'unitID', value)
                                                                }}
                                                                icon={<Icon name='caret down' />}
                                                                disabled={key.includes('IN1')}
                                                            // disabled


                                                            // fluid
                                                            />

                                                        </div>

                                                        <div className='enabled-disabled-container'>

                                                            <Dropdown
                                                                placeholder={"Select a field id..."}
                                                                search
                                                                selection
                                                                options={fieldIdOptions}

                                                                value={key.includes('IN1') ?
                                                                    config.devices[deviceUnderMeasure]?.measure['RAIN']?.fieldID :
                                                                    doesFucntionIncludeNumber(measureDetail?.fieldID, measureDetail?.lcMinScale, measureDetail?.lcMaxScale)}

                                                                // options={projectsData}
                                                                // defaultValue={activeDeviceForMeasure}
                                                                onChange={(event, { value }) => {
                                                                    // setSelectedProject(value);
                                                                    console.log(value)
                                                                    // setActiveDeviceForMeasure(value)

                                                                    const actualFieldValue = value?.split('-')[0]
                                                                    console.log(actualFieldValue)
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'fieldID', actualFieldValue)

                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'iotEnabled', true)

                                                                    const data = defaultSensorValues[value]

                                                                    if (data) {

                                                                        updateSensorDefaultsAtIndex(deviceUnderMeasure, key, data)

                                                                    }



                                                                    // console.log(data)
                                                                }}
                                                                // disabled={isDevicePego() || isDeviceDucatiMain() || isDeviceCompressor() || isDevicePump() || isDeviceDucatiMono() || isDeviceImeMono()}
                                                                disabled={key.includes('IN1')}

                                                                fluid
                                                            />

                                                        </div>
                                                    </>
                                                }

                                                {(isDeviceMsrElect()) &&
                                                    <>
                                                        <div className='enabled-disabled-container'>

                                                            <Dropdown
                                                                placeholder={"Select a unit id..."}
                                                                search
                                                                selection
                                                                options={[...roomsCommercial?.units, ...finalUnitsArray, ...staticUnitOptions]}
                                                                value={measureDetail?.unitID}

                                                                onChange={(event, { value }) => {

                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'unitID', value)
                                                                }}
                                                                icon={<Icon name='caret down' />}

                                                            />

                                                        </div>
                                                        <div className='enabled-disabled-container'>

                                                            <Dropdown
                                                                placeholder={"Select a field id..."}
                                                                search
                                                                selection
                                                                options={fieldIdOptions}

                                                                value={
                                                                    doesFucntionIncludeNumber(measureDetail?.fieldID, measureDetail?.lcMinScale, measureDetail?.lcMaxScale)
                                                                }

                                                                // options={projectsData}
                                                                // defaultValue={activeDeviceForMeasure}
                                                                onChange={(event, { value }) => {
                                                                    // setSelectedProject(value);
                                                                    console.log(value)
                                                                    // setActiveDeviceForMeasure(value)

                                                                    const actualFieldValue = value?.split('-')[0]
                                                                    console.log(actualFieldValue)
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'fieldID', actualFieldValue)

                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'iotEnabled', true)

                                                                    const data = defaultSensorValues[value]

                                                                    if (data) {

                                                                        updateSensorDefaultsAtIndex(deviceUnderMeasure, key, data)

                                                                    }



                                                                    // console.log(data)
                                                                }}
                                                                // disabled={isDevicePego() || isDeviceDucatiMain() || isDeviceCompressor() || isDevicePump() || isDeviceDucatiMono() || isDeviceImeMono()}
                                                                disabled={key.includes('IN1')}

                                                                fluid
                                                            />

                                                        </div>
                                                    </>

                                                }
                                                {(isDevicePego() && measureDetail?.valType === 'digIn' && pegoRKeys.includes(key)) && <div className='enabled-disabled-container'>

                                                    <Dropdown
                                                        placeholder={"Select a field id..."}
                                                        search
                                                        selection
                                                        options={fieldIdOptions}

                                                        value={key.includes('IN1') ?
                                                            config.devices[deviceUnderMeasure]?.measure['RAIN']?.fieldID :
                                                            doesFucntionIncludeNumber(measureDetail?.fieldID, measureDetail?.lcMinScale, measureDetail?.lcMaxScale)}

                                                        // options={projectsData}
                                                        // defaultValue={activeDeviceForMeasure}
                                                        onChange={(event, { value }) => {
                                                            // setSelectedProject(value);
                                                            console.log(value)
                                                            // setActiveDeviceForMeasure(value)

                                                            const actualFieldValue = value?.split('-')[0]
                                                            console.log(actualFieldValue)
                                                            updateDeviceMeasure(deviceUnderMeasure, key, 'fieldID', actualFieldValue)

                                                            updateDeviceMeasure(deviceUnderMeasure, key, 'iotEnabled', true)

                                                            const data = defaultSensorValues[value]

                                                            if (data) {

                                                                updateSensorDefaultsAtIndex(deviceUnderMeasure, key, data)

                                                            }



                                                            // console.log(data)
                                                        }}
                                                        // disabled={isDevicePego() || isDeviceDucatiMain() || isDeviceCompressor() || isDevicePump() || isDeviceDucatiMono() || isDeviceImeMono()}
                                                        disabled={key.includes('IN1')}

                                                        fluid
                                                    />

                                                </div>}


                                                <div className='radio-buttons-container'>
                                                    <div className='enabled-disabled-container'>


                                                        <Radio

                                                            checked={key.includes('IN1') ?
                                                                config.devices[deviceUnderMeasure]?.measure['RAIN']?.iotEnabled :
                                                                measureDetail?.iotEnabled}
                                                            // checked={measureDetail?.iotEnabled}

                                                            color="yellow"
                                                            toggle
                                                            // xx=xx
                                                            onClick={(e, data) => {

                                                                updateDeviceMeasure(deviceUnderMeasure, key.includes('IN1') ? 'RAIN' : key, 'iotEnabled', data.checked)


                                                            }}
                                                            fluid />
                                                        {`iot ${isDeviceOne() && key.includes('IN1') ?
                                                            config.devices[deviceUnderMeasure]?.measure['RAIN']?.iotEnabled ? 'enabled' : 'disabled' :
                                                            measureDetail?.iotEnabled ? 'enabled' : 'disabled'} `}

                                                    </div>
                                                    {(isDevicePego() && measureDetail?.valType === 'digIn' && pegoRKeys.includes(key) &&
                                                        <div className='enabled-disabled-container'>


                                                            <Radio

                                                                checked={measureDetail?.rtEnabled}

                                                                color="yellow"
                                                                toggle

                                                                onClick={(e, data) => {

                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'rtEnabled', data.checked)


                                                                }}
                                                                fluid />
                                                            {`rtEnabled logic is ${measureDetail?.rtEnabled ? 'true' : 'false'} `}

                                                        </div>)}
                                                    {(measureDetail?.valType === 'digIn' && !key.includes('IN1')) &&
                                                        <div className='enabled-disabled-container'>


                                                            <Radio

                                                                checked={measureDetail?.valBitLogic}

                                                                color="yellow"
                                                                toggle

                                                                onClick={(e, data) => {

                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'valBitLogic', data.checked)


                                                                }}
                                                                fluid />
                                                            {`val bit logic is ${measureDetail?.valBitLogic ? 'true' : 'false'} `}

                                                        </div>
                                                    }
                                                </div>

                                            </Accordion.Title>
                                            <Accordion.Content active={index === activeMeasure}>


                                                <div className='enabled-disabled-container'>
                                                    <div className='selector-label'>
                                                        <small className='input-label-color'>Unit ID</small>
                                                        <Dropdown
                                                            placeholder={"Select a unit id..."}
                                                            search
                                                            selection
                                                            options={[...roomsCommercial?.units, ...roomsCommercial?.unitFields, ...staticUnitOptions]}
                                                            value={isDeviceOne() && key.includes('IN1') ? config.devices[deviceUnderMeasure]?.measure['RAIN']?.unitID : measureDetail?.unitID}
                                                            // options={projectsData}
                                                            // defaultValue={activeDeviceForMeasure}
                                                            onChange={(event, { value }) => {
                                                                // setSelectedProject(value);

                                                                // setActiveDeviceForMeasure(value)
                                                                updateDeviceMeasure(deviceUnderMeasure, key, 'unitID', value)
                                                            }}
                                                            // disabled={isDevicePego() || isDeviceDucatiMain() || isDeviceCompressor() || isDevicePump() || isDeviceDucatiMono() || isDeviceImeMain() || isDeviceDucatiMono()}
                                                            disabled

                                                            fluid
                                                        />
                                                    </div>
                                                </div>


                                                <div className='enabled-disabled-container'>
                                                    <div className='selector-label'>
                                                        <small className='input-label-color'>Field ID</small>
                                                        <Dropdown
                                                            placeholder={"Select a field id..."}
                                                            search
                                                            selection
                                                            options={fieldIdOptions}

                                                            value={isDeviceOne() && key.includes('IN1') ?
                                                                config.devices[deviceUnderMeasure]?.measure['RAIN']?.fieldID :
                                                                doesFucntionIncludeNumber(measureDetail?.fieldID, measureDetail?.lcMinScale, measureDetail?.lcMaxScale)}
                                                            // options={projectsData}
                                                            // defaultValue={activeDeviceForMeasure}
                                                            onChange={(event, { value }) => {
                                                                // setSelectedProject(value);


                                                                // setActiveDeviceForMeasure(value)
                                                                updateDeviceMeasure(deviceUnderMeasure, key, 'fieldID', value)

                                                                // updateSensorDefaultsAtIndex()
                                                            }}
                                                            disabled
                                                            fluid
                                                        />
                                                    </div>
                                                </div>

                                                <div className='enabled-disabled-container'>
                                                    <div className='selector-label'>
                                                        <small className='input-label-color'>Value type</small>

                                                        <Dropdown
                                                            placeholder={"Select a value type..."}
                                                            search
                                                            selection
                                                            options={valTypeOptions}
                                                            value={isDeviceOne() && key.includes('IN1') ?
                                                                config.devices[deviceUnderMeasure]?.measure['RAIN']?.valType :
                                                                measureDetail?.valType}

                                                            // options={projectsData}
                                                            // defaultValue={valTypeOptions[0].value}
                                                            onChange={(event, { value }) => {
                                                                // setSelectedProject(value);

                                                                // setActiveDeviceForMeasure(value)
                                                                updateDeviceMeasure(deviceUnderMeasure, key, 'valType', value)
                                                            }}
                                                            disabled
                                                            fluid
                                                        />
                                                    </div>
                                                </div>

                                                <div className='enabled-disabled-container'>
                                                    <div className='add-company-input'>
                                                        <small className='input-label-color'>Value Multiplier</small>

                                                        <Input
                                                            // onKeyPress={(event) => {
                                                            //     console.log(event.key)
                                                            //     if (!/[0-9]/.test(event.key)) {
                                                            //         event.preventDefault();
                                                            //     }
                                                            // }}
                                                            type='number'
                                                            onChange={(e) => {

                                                                if (!e.target.value) {
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'valMultiplier', '')
                                                                    return;
                                                                }

                                                                if (e.target.value.split('.')[1]?.length > 1) {
                                                                    const val = Math.abs(e.target.value);
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'valMultiplier', val.toFixed(1))
                                                                } else {
                                                                    const val = Math.abs(e.target.value);
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'valMultiplier', val)

                                                                }


                                                            }}
                                                            // labelPosition="right"

                                                            placeholder="Val Multiplier"
                                                            // value={thresholdData?.idealValue}

                                                            value={isDeviceOne() && key.includes('IN1') ?
                                                                config.devices[deviceUnderMeasure]?.measure['RAIN']?.valMultiplier :
                                                                measureDetail?.valMultiplier}
                                                            disabled
                                                            fluid
                                                        >
                                                            <input />
                                                            {/* <Label color="yellow">Volts</Label> */}
                                                        </Input>
                                                    </div>

                                                </div>

                                                <div className='enabled-disabled-container'>
                                                    <div className='add-company-input'>
                                                        <small className='input-label-color'>Value Decimals</small>
                                                        <Input
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            // type="number"
                                                            onChange={(e) => {

                                                                const val = Math.abs(e.target.value)

                                                                // if (val === 0) {
                                                                //     updateThresholdNumberValues(
                                                                //         "idealValue",
                                                                //         ''
                                                                //     )
                                                                // } else {
                                                                //     updateThresholdNumberValues(
                                                                //         "idealValue",
                                                                //         val
                                                                //     );

                                                                // }

                                                                updateDeviceMeasure(deviceUnderMeasure, key, 'valDecimals', val)

                                                            }}
                                                            // labelPosition="right"
                                                            disabled

                                                            placeholder="Val Decimals"
                                                            // value={thresholdData?.idealValue}

                                                            value={isDeviceOne() && key.includes('IN1') ?
                                                                config.devices[deviceUnderMeasure]?.measure['RAIN']?.valDecimals :
                                                                measureDetail?.valDecimals}
                                                            fluid
                                                        >
                                                            <input />
                                                            {/* <Label color="yellow">Volts</Label> */}
                                                        </Input>
                                                    </div>

                                                </div>

                                                <div className='enabled-disabled-container'>
                                                    <div className='add-company-input'>
                                                        <small className='input-label-color'>LC min scale</small>
                                                        <Input
                                                            // onKeyPress={(event) => {
                                                            //     if (!/[0-9]/.test(event.key)) {
                                                            //         event.preventDefault();
                                                            //     }
                                                            // }}
                                                            type='number'
                                                            onChange={(e) => {


                                                                if (!e.target.value) {
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'lcMinScale', '')
                                                                    return;
                                                                }

                                                                if (e.target.value.split('.')[1]?.length > 1) {
                                                                    const val = Number(e.target.value);
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'lcMinScale', val.toFixed(1))
                                                                } else {
                                                                    const val = Number(e.target.value);
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'lcMinScale', val)

                                                                }


                                                            }}
                                                            // labelPosition="right"

                                                            placeholder="LC Min Scale"
                                                            // value={thresholdData?.idealValue}

                                                            value={isDeviceOne() && key.includes('IN1') ?
                                                                config.devices[deviceUnderMeasure]?.measure['RAIN']?.lcMinScale :
                                                                measureDetail?.lcMinScale}
                                                            disabled
                                                            fluid
                                                        >
                                                            <input />
                                                            {/* <Label color="yellow">Volts</Label> */}
                                                        </Input>
                                                    </div>

                                                </div>

                                                <div className='enabled-disabled-container'>
                                                    <div className='add-company-input'>
                                                        <small className='input-label-color'>LC max scale</small>
                                                        <Input
                                                            // onKeyPress={(event) => {
                                                            //     if (!/[0-9]/.test(event.key)) {
                                                            //         event.preventDefault();
                                                            //     }
                                                            // }}
                                                            type='number'
                                                            onChange={(e) => {
                                                                if (!e.target.value) {
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'lcMaxScale', '')
                                                                    return;
                                                                }

                                                                if (e.target.value.split('.')[1]?.length > 1) {
                                                                    const val = Math.abs(e.target.value);
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'lcMaxScale', val.toFixed(1))
                                                                } else {
                                                                    const val = Math.abs(e.target.value);
                                                                    updateDeviceMeasure(deviceUnderMeasure, key, 'lcMaxScale', val)

                                                                }

                                                            }}
                                                            // labelPosition="right"

                                                            placeholder="LC Max Scale"
                                                            // value={thresholdData?.idealValue}
                                                            disabled

                                                            value={isDeviceOne() && key.includes('IN1') ?
                                                                config.devices[deviceUnderMeasure]?.measure['RAIN']?.lcMaxScale :
                                                                measureDetail?.lcMaxScale}
                                                            fluid
                                                        >
                                                            <input />
                                                            {/* <Label color="yellow">Volts</Label> */}
                                                        </Input>
                                                    </div>

                                                </div>

                                                <div className='enabled-disabled-container'>
                                                    <div className='add-company-input'>
                                                        <small className='input-label-color'>GUI Description</small>
                                                        <Input
                                                            // onKeyPress={(event) => {
                                                            //     if (!/[0-9]/.test(event.key)) {
                                                            //         event.preventDefault();
                                                            //     }
                                                            // }}
                                                            onChange={(e) => {
                                                                // const val = Math.abs(e.target.value)

                                                                // if (val === 0) {
                                                                //     updateThresholdNumberValues(
                                                                //         "idealValue",
                                                                //         ''
                                                                //     )
                                                                // } else {
                                                                //     updateThresholdNumberValues(
                                                                //         "idealValue",
                                                                //         val
                                                                //     );

                                                                // }

                                                                updateDeviceMeasure(deviceUnderMeasure, key, 'guiDescr', e.target.value)


                                                            }}
                                                            // labelPosition="right"

                                                            placeholder="GUI Description"
                                                            disabled
                                                            // value={thresholdData?.idealValue}


                                                            value={isDeviceOne() && key.includes('IN1') ?
                                                                config.devices[deviceUnderMeasure]?.measure['RAIN']?.guiDescr :
                                                                measureDetail?.guiDescr}
                                                            fluid
                                                        >
                                                            <input />
                                                            {/* <Label color="yellow">Volts</Label> */}
                                                        </Input>
                                                    </div>

                                                </div>

                                                <div className='enabled-disabled-container'>
                                                    <div className='add-company-input'>
                                                        <small className='input-label-color'>GUI M</small>
                                                        <Input
                                                            // onKeyPress={(event) => {
                                                            //     if (!/[0-9]/.test(event.key)) {
                                                            //         event.preventDefault();
                                                            //     }
                                                            // }}
                                                            onChange={(e) => {

                                                                // const val = Math.abs(e.target.value)

                                                                // if (val === 0) {
                                                                //     updateThresholdNumberValues(
                                                                //         "idealValue",
                                                                //         ''
                                                                //     )
                                                                // } else {
                                                                //     updateThresholdNumberValues(
                                                                //         "idealValue",
                                                                //         val
                                                                //     );

                                                                // }

                                                                updateDeviceMeasure(deviceUnderMeasure, key, 'guiUM', e.target.value)

                                                            }}
                                                            // labelPosition="right"

                                                            placeholder="GUI M"
                                                            // value={thresholdData?.idealValue}


                                                            value={isDeviceOne() && key.includes('IN1') ?
                                                                config.devices[deviceUnderMeasure]?.measure['RAIN']?.guiUM :
                                                                measureDetail?.guiUM}
                                                            disabled
                                                            fluid
                                                        >
                                                            <input />
                                                            {/* <Label color="yellow">Volts</Label> */}
                                                        </Input>
                                                    </div>

                                                </div>

                                                {/* <div className="enabled-disabled-container">

                                            <Button
                                                onClick={(e) => {

                                                    // if (!isVoltageThresholdInputValid()) {
                                                    //     return
                                                    // }
                                                    // updateSiteThreshold({
                                                    //     ...thresholdData,
                                                    //     type: "voltageMain",
                                                    //     projectUid: isDemo ? "DEMO" : thresholdProject,
                                                    // })
                                                }}
                                                size="large"
                                                color="yellow"
                                            // disabled={!isVoltageThresholdInputValid()}
                                            >
                                                Update Configuration
                                            </Button>
                                        </div> */}



                                            </Accordion.Content>
                                        </Accordion>
                                        <br></br>
                                    </>)}</>
                        ))

                    }

                </>}

            {currentMenu === 'preview' && <div className="enabled-disabled-container">
                <ReactJson theme='monokai' src={config} iconStyle='triangle' />
            </div>}

        </>
    )
}

const mapStateToProps = ({ projects, deviceConfigReducerTest }) => {
    const { data, roomsCommercial } = projects;
    const { config, devicesCount, devicesForMeasuresDropdown, deviceUnderMeasure } = deviceConfigReducerTest


    let taken = {}

    let finalUnitsArray = [];




    for (let i = 0; i < roomsCommercial.unitFields.length; i++) {

        if (!taken[roomsCommercial.unitFields[i].value]) {

            taken[roomsCommercial.unitFields[i].value] = true

            finalUnitsArray = finalUnitsArray.concat(roomsCommercial.unitFields[i])

        }

    }

    const arrDropDown = data.map((e, i) => {
        return {
            key: i,
            text: e.title,
            value: e.id,
            uid: e.projectUid,
            units: e.units
        };
    });

    return {
        // projectsData: arr,
        projectsDataDropDown: arrDropDown,
        roomsCommercial,
        config,
        devicesCount,
        devicesForMeasuresDropdown,
        deviceUnderMeasure,
        finalUnitsArray

    };
};

export default connect(mapStateToProps, {
    updateInternalComport,
    updateInternalRootProjectConfig,
    addDeviceCount,
    updateInternalDeviceFields,
    updateInternalDeviceId,
    setDeviceUnderMeasure,
    updatePegoRoom,
    updateDeviceMeasure,
    deleteDeviceAtIndex,
    updateSensorDefaultsAtIndex,
    setBaseConfig,
    fetchUnitConfig
})(Config);

