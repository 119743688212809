import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

import '../../styles/_modal.scss';

export const Modal = props => {
  return (
    <div className="modal">
      <div className="modal-background" onClick={props.onCancel} />
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{props.header}</h5>
        </div>
        <div className="modal-body">{props.children}</div>
        <div className="modal-footer">
          <Button type="button" tag="opposite" onClick={props.onCancel}>
            {props.cancelText}
          </Button>
          <Button type="button" tag="primary" onClick={props.onAccept}>
            {props.confirmText}
          </Button>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.number,
  className: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string
};
