import React from 'react';
import Initializer from './components/Initializer';
import Layout from './components/Layout';
import Routes from './routes';


import './styles/main.scss';


function App() {


  return <MainApp />;
}

function MainApp() {
  return (
    <div className='mother-relative-page'>
      <Initializer />
      <Layout>
        <Routes />
      </Layout>

    </div>
  );
}




export default App;







