import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../../../../components/shared/Modal';
import Spinner from '../../../../../components/shared/Spinner';

import {SETTINGS} from '../../../../../constants/analyticsEventTypes';
import ReactGA from 'react-ga';

class DeleteUserModalWrapper extends Component {
  submit = async () => {
    ReactGA.event({
      category: SETTINGS.Category,
      action: SETTINGS.DeleteUser,
      label: SETTINGS.DeleteUser
    });
    try {
      await this.props.deleteUser(this.props.id);
      this.props.onCancel();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  render() {
    const { loading } = this.props;

    return (
      <Modal
        header={this.props.header}
        confirmText={this.props.confirmText}
        cancelText="Cancel"
        onCancel={this.props.onCancel}
        onAccept={this.submit}
      >
        <Spinner active={loading} overlay />
        {this.props.content}
      </Modal>
    );
  }
}

DeleteUserModalWrapper.propTypes = {
  header: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  content: PropTypes.string,
  deleteUser: PropTypes.func,
  loading: PropTypes.bool,
  id: PropTypes.number
};

export default DeleteUserModalWrapper;
