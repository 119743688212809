import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import reducer from "./reducers";

const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "inspiraFarmsApp",
  storage,
  blacklist: ["form", "alarms", "unitDetails"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(
  persistedReducer,
  {},
  composer(applyMiddleware(thunk))
);
const persistor = persistStore(store);

export default { store, persistor };
