import api from "../utils/api";
import { getSession } from "../utils/auth/session";
import * as session from "../utils/auth/session";
import * as authTypes from "../constants/auth.types";
import { getUserDataFormRawToken } from "../utils/auth/user";
import { fetchActiveAlarms } from "./alarms/active.actions";
import { cleanUpStateOnLogout } from "./state.actions";

function fetchDataAfterLogin(dispatch) {
  dispatch(fetchActiveAlarms());
}

function setSessionByResponse(data, dispatch) {
  const { email, roles, id } = getUserDataFormRawToken(data["accessToken"]);

  const { isDemo, features, paymentStatus, userCompanyRegion } = data;

  const sessionData = {
    token: data["accessToken"],
    refreshToken: data["refreshToken"],
    expired: data["accessTokenExpiresIn"],
  };

  session.setSession(sessionData);
  dispatch(updateAuthUseData({ ...sessionData, email, roles, id, isDemo, features, paymentStatus, userCompanyRegion }));
}

export const login = ({ email, password }) => async (dispatch) => {
  try {
    const response = await api.post("/auth/login", { email, password });

    setSessionByResponse(response.data, dispatch);
    fetchDataAfterLogin(dispatch);
    return Promise.resolve(response);
  } catch (e) {

    dispatch(updateAuthUseData({}));
    return Promise.reject(e.response);
  }
};

export const anonLogin = (key) => async (dispatch) => {
  try {
    const response = await api.post(`/auth/anon-login?logi=${key}`);

    setSessionByResponse(response.data, dispatch);
    fetchDataAfterLogin(dispatch);
    return Promise.resolve(response);
  } catch (e) {

    dispatch(updateAuthUseData({}));
    return Promise.reject(e.response);
  }
};


export const refreshToken = () => async (dispatch) => {
  try {
    const { refreshToken } = getSession();
    const response = await api.post("/auth/token", { refreshToken });
    setSessionByResponse(response.data, dispatch);
    return Promise.resolve(response.data);
  } catch (err) {
    session.clearSession();
    dispatch(updateAuthUseData({}));
    return Promise.reject(err);
  }
};

export const logout = () => async (dispatch) => {
  try {
    session.clearSession();
    dispatch(cleanUpStateOnLogout());
    return Promise.resolve();
  } catch (err) {
    return Promise.reject();
  }
};

export const confirmRegistration = (values) => async () => {
  const body = {
    hash: values.hash,
    password: values.newPassword,
  };
  try {
    const response = await api.post("/auth/accountConfirm", body);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject();
  }
};

export function cleanAuthData() {
  return {
    type: authTypes.USER_AUTH_CLEAN,
  };
}

const updateAuthUseData = ({ token, email, roles, id, isDemo, features, paymentStatus, userCompanyRegion }) => ({
  type: authTypes.USER_AUTH_UPDATE,
  payload: { token, email, roles, id, isDemo, features, paymentStatus, userCompanyRegion },
});

export const changePassword = (values) => async (dispatch) => {
  dispatch({ type: authTypes.LOADING });
  try {
    const response = await api.post("/auth/changePassword", {
      password: values.password,
      newPassword: values.newPassword,
    });
    dispatch({
      type: authTypes.DONE_CHANGE_PASSWORD,
      payload: response,
    });
    return Promise.resolve(response.data);
  } catch (e) {
    const errorMessage = (e.response.data || {}).message;
    dispatch({
      type: authTypes.ERROR_CHANGE_PASSWORD,
      payload: {
        error:
          typeof errorMessage !== "object"
            ? errorMessage
            : "Change password error",
      },
    });
    return Promise.reject(e.response);
  }
};

export const recoverPasswordReq = (value) => async (dispatch) => {
  dispatch({ type: authTypes.LOADING });
  try {
    const response = await api.post("/auth/resetPasswordReq", {
      email: value.email,
    });
    dispatch({
      type: authTypes.DONE_RECOVER_PASSWORD,
      payload: response,
    });
    return Promise.resolve(response.data);
  } catch (e) {
    const errorMessage = (e.response.data || {}).message;
    dispatch({
      type: authTypes.ERROR_RECOVER_PASSWORD,
      payload: {
        error:
          typeof errorMessage !== "object"
            ? errorMessage
            : "Recover password error",
      },
    });
    return Promise.reject(e.response);
  }
};

export const recoverPassword = (value, hash) => async (dispatch) => {
  dispatch({ type: authTypes.LOADING });
  try {
    const response = await api.post("/auth/resetPassword", {
      password: value.newPassword,
      passwordResetHash: hash,
    });
    dispatch({
      type: authTypes.DONE_RESET_PASSWORD,
      payload: response,
    });
    return Promise.resolve(response.data);
  } catch (e) {
    const errorMessage = (e.response.data || {}).message;
    dispatch({
      type: authTypes.ERROR_RESET_PASSWORD,
      payload: {
        error:
          typeof errorMessage !== "object"
            ? errorMessage
            : "Password reset error",
      },
    });
    return Promise.reject(e.response);
  }
};
