import React from 'react'
import PropTypes from 'prop-types'
import Paper from '../../shared/Paper'
import PaperHeader from '../../shared/Card/Header'
import PaperContent from '../../shared/Card/Content'
import { roundTwoDigits } from '../../../utils/numberFormat';
import { isUndefined } from 'lodash';
import "./styles.scss"

function NumberIndicatorModule({ title, subTitle, value, entity, color, isLoading }) {

    const hasLoaded = () => {
        return !isUndefined(value) && !isLoading;
    }


    return (
        <Paper classes="number-indicator-module">
            <PaperHeader>{title}</PaperHeader>
            {hasLoaded() ? (
                <PaperContent>
                    <span className="number-indicator-side-info">{subTitle}</span>
                    <h1 className="number-indicator-number" style={{ color: color }}>{value || value === 0 ? roundTwoDigits(value) : value} {entity && entity}</h1>
                </PaperContent>
            ) : (
                <PaperContent>
                    <span className="number-indicator-side-info">Loading ...</span>
                </PaperContent>
            )}
        </Paper>
    )
}

NumberIndicatorModule.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    entity: PropTypes.string,
    isLoading: PropTypes.bool
}

export default NumberIndicatorModule

