import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { chain, isUndefined } from "lodash";

import ChartTooltip from "../../../../../../../components/Charts/components/ChartTooltip";
import LinearChart from "../../../../../../../components/Charts/LinearChart";
import * as colors from "../../../../../../../constants/colors";
import { roundTwoDigits } from "../../../../../../../utils/numberFormat";
import { cleanChartDataToBeContinous } from "../../../../../../../utils/chartUtils";
import {
  getObjectItem,
  getListFromData,
} from "../../../../../../../helpers/object.helper";
import { getLabelNameFormat } from "../../../../../../../helpers/chart.helper";

export const TemperatureChart = ({
  syncId,
  cellTempData,
  piercingProbeData,
  piercingProbeData2,
  dataProfile,
  tempSetpointData,
}) => {
  const legends = [{ key: "roomTemp", color: colors.CYAN, label: "ROOM TEMP" }];


  const _showPulpTemp = () => dataProfile?.piercingProbeTemp;
  const _showPulpTemp2 = () => dataProfile?.piercingProbeTemp2;

  const _showTempSetpoint = () => (!isUndefined(tempSetpointData) && tempSetpointData.loading === false && tempSetpointData?.list.length > 0) ? tempSetpointData?.list[0]['value'] < 101 || tempSetpointData?.list[0]['value'] > 0 : false

  _showPulpTemp() &&
    legends.push({ key: "pulp", color: colors.ORANGE, label: "PULP" });
  _showPulpTemp2() &&
    legends.push({ key: "pulp2", color: colors.BROWN, label: "PULP 2" });

  _showTempSetpoint &&
    legends.push({ key: "setpoint", color: colors.RED, label: "SETPOINT" })

  const rangeLines = [
    // { x: 0, y: 70, label: '70°C MAX', color: colors.LIGHT_GREY },
    // { x: 0, y: 20, label: '20°C MIN', color: colors.LIGHT_GREY }
  ];

  const _isLoading = () => {
    if (
      (!isUndefined(cellTempData) && cellTempData.loading === false) ||
      (!isUndefined(piercingProbeData) &&
        piercingProbeData.loading === false) ||
      (!isUndefined(piercingProbeData2) && piercingProbeData2.loading === false) ||
      (!isUndefined(tempSetpointData) && tempSetpointData.loading === false)
    ) {
      return false;
    }
    return true;
  };

  const _getCustomTooltip = (e) => {
    const data = ((e.payload || [])[0] || {}).payload || {};

    return (
      <ChartTooltip>
        <p>
          ROOM TEMP: <strong>{roundTwoDigits(data.roomTemp)}°C</strong>
        </p>
        {_showPulpTemp() && (
          <p>
            PULP TEMP: <strong>{roundTwoDigits(data.pulp)}°C</strong>
          </p>
        )}
        {_showPulpTemp2() && (
          <p>
            PULP TEMP 2: <strong>{roundTwoDigits(data.pulp2)}°C</strong>
          </p>
        )}
        {_showTempSetpoint() && (
          <p>
            SETPOINT: <strong>{roundTwoDigits(data.setpoint)}°C</strong>
          </p>
        )}
        <small>{data.startDate}</small>
      </ChartTooltip>
    );
  };

  const _getTemperatureData = () => {
    const piercingProbeDataList =
      piercingProbeData && piercingProbeData.list ? piercingProbeData.list : [];

    const piercingProbeDataList2 =
      piercingProbeData2 && piercingProbeData2.list
        ? piercingProbeData2.list
        : [];
    // console.log("piercingProbeData2.list", piercingProbeData2.list);

    const tempSetpointDataList = _showTempSetpoint() ? tempSetpointData.list : [];

    const cellTempDataList =
      cellTempData && cellTempData.list ? cellTempData.list : [];


    let concatedValues = cellTempDataList;

    let nameLabelFormat = "";

    const showRoomTemp = _showPulpTemp();

    function isTemperatureDataAvailable() {
      if (cellTempDataList.length > 0) {
        return true;
      } else {
        return false;
      }
    }

    const showSetpointTemp = _showTempSetpoint();

    if (cellTempDataList.length > 0) {
      nameLabelFormat = getLabelNameFormat(getListFromData(cellTempData));
    }

    if (showRoomTemp) {
      concatedValues = [...piercingProbeDataList, ...concatedValues];
      if (cellTempDataList.length === 0) {
        nameLabelFormat = getLabelNameFormat(
          getListFromData(piercingProbeData)
        );
      }
    }
    if (_showPulpTemp2) {
      concatedValues = [...concatedValues, ...piercingProbeDataList2];
      if (cellTempDataList.length === 0 && piercingProbeDataList.length === 0) {
        nameLabelFormat = getLabelNameFormat(
          getListFromData(piercingProbeData2)
        );
      }
    }

    concatedValues = [...concatedValues, ...tempSetpointDataList];

    const groupedValues = chain(concatedValues)
      .groupBy("recDateTime")
      .map((element) => {
        const startDate = moment.utc(element[0].recDateTime).local();
        return {
          name: startDate.format(nameLabelFormat),
          startDate: startDate.format("HH:mm - DD.MM.YYYY"),
          pulp:
            showRoomTemp && piercingProbeDataList.length > 0
              ? getObjectItem(element[0])
              : null,
          roomTemp: isTemperatureDataAvailable()
            ? piercingProbeDataList.length === 0
              ? getObjectItem(element[0])
              : getObjectItem(element[1])
            : null,
          pulp2: !_showPulpTemp2
            ? null
            : isTemperatureDataAvailable()
              ? piercingProbeDataList.length > 0
                ? getObjectItem(element[2])
                : getObjectItem(element[1])
              : piercingProbeDataList.length > 0
                ? getObjectItem(element[1])
                : getObjectItem(element[0]),
          setpoint: !showSetpointTemp ? null :
            piercingProbeDataList2.length > 0
              ? isTemperatureDataAvailable() ?
                showRoomTemp && piercingProbeDataList.length > 0 ?
                  getObjectItem(element[3]) :
                  getObjectItem(element[2]) :
                showRoomTemp && piercingProbeDataList.length > 0 ?
                  getObjectItem(element[2]) :
                  getObjectItem(element[1]) :
              isTemperatureDataAvailable() ?
                showRoomTemp && piercingProbeDataList.length > 0 ?
                  getObjectItem(element[2]) :
                  getObjectItem(element[1]) :
                showRoomTemp && piercingProbeDataList.length > 0 ?
                  getObjectItem(element[1]) :
                  getObjectItem(element[0])


        };
      })
      .value();
    return cleanChartDataToBeContinous(groupedValues, "roomTemp");
  };

  return (
    <LinearChart
      title="Temperature"
      syncId={syncId}
      data={_getTemperatureData()}
      tooltip={_getCustomTooltip}
      legends={legends}
      rangeLines={rangeLines}
      loading={_isLoading()}
      maxValue={30}
      minValue={-4}
    />
  );
};

TemperatureChart.propTypes = {
  syncId: PropTypes.string,
  data: PropTypes.array,
  cellTempStatisticsType: PropTypes.string.isRequired,
  cellTempData: PropTypes.object,
  piercingProbeData: PropTypes.object,
  timePeriod: PropTypes.string,
  dataProfile: PropTypes.object,
};

TemperatureChart.defaultProps = {
  syncId: "syncId",
  data: undefined,
};

export default TemperatureChart;
