import React from 'react';
import PropTypes from 'prop-types';
import { Message, Icon } from 'semantic-ui-react'
import './_page.scss';
import { getUserPaymentStatus, getUserRoles } from '../../utils/auth/user';
import { checkIfRoleIsInspiraFarmsTech } from '../../utils/arrays/compareTwoArrays';

const Page = ({ className, isContainer, children }) => {


  const userRoles = getUserRoles()
  const paymentStatus = getUserPaymentStatus()


  const isUserTech = checkIfRoleIsInspiraFarmsTech(userRoles)

  if (isUserTech) {

    return (

      <div className={`page ${isContainer ? 'container' : ''} ${className || ''}`}>

        <Message icon size='mini'>
          <Icon name='warning' className='icon-red' />

          <Message.Content className='message-color'>
            You are currently accessing live client data
          </Message.Content>


        </Message>
        {children}
      </div>
    )

  } else if (paymentStatus === 'defaulted') {

    return (

      <div className={`page ${isContainer ? 'container' : ''} ${className || ''}`}>

        <Message icon size='mini'>
          <Icon name='linkify' className='icon-red' />

          <Message.Content className='message-color'>

            You are on a free tier, please contact your InspiraFarms sales representative to activate locked features

          </Message.Content>


        </Message>

        {children}
      </div>
    )

  } else {

    return (
      <div className={`page ${isContainer ? 'container' : ''} ${className || ''}`}>

        {children}


      </div>
    )
  }


};

Page.propTypes = {
  isContainer: PropTypes.bool
};

export default Page;
