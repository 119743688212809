import api from '../../utils/api';
import * as alarmsTypes from '../../constants/alarms.types';
import { getProjectId } from '../../utils/auth/user';

//TODO: create wrapper for handle in progress fetch and cancel next request
let fetchInProgress = false;

export const fetchActiveAlarms = () => async dispatch => {
  if (fetchInProgress) return Promise.resolve();
  fetchInProgress = true;
  dispatch({ type: alarmsTypes.LOADING_ACTIVE });

  // try {
  //   const response = await api.get(`/project/${getProjectId()}/livealarms`);
  //   dispatch({ type: alarmsTypes.DONE_FETCH_ACTIVE, payload: response });

  //   fetchInProgress = false;
  //   return Promise.resolve(response.data || '');
  // } catch (e) {
  //   dispatch({
  //     type: alarmsTypes.ERROR_FETCH_ACTIVE,
  //     payload: { error: 'Fetch alarms data error' }
  //   });
  //   fetchInProgress = false;
  //   return Promise.reject(e.response);
  // }
};

export const resolveAlarm = id => async dispatch => {
  dispatch({ type: alarmsTypes.LOADING_RESOLVE, alarmId: id });

  try {
    const response = await api.post(
      `/project/${getProjectId()}/alarms/resolved`,
      {
        alarmId: id
      }
    );
    dispatch({ type: alarmsTypes.DONE_RESOLVE, alarmId: id });
    dispatch(fetchActiveAlarms());
    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: alarmsTypes.ERROR_RESOLVE,
      alarmId: id,
      payload: { error: 'Resolve active alarms error' }
    });
    return Promise.reject(e.response);
  }
};

export function cleanActiveAlarms() {
  return {
    type: alarmsTypes.CLEAN_DATA_ACTIVE
  };
}
