import React from 'react';
import PropTypes from 'prop-types';
import ReactLoaders from 'react-loaders';

const Spinner = props =>
  typeof props.active === 'boolean' &&
  props.active && (
    <div className={`spinner__container ${props.overlay ? 'overlay' : ''}`}>
      <ReactLoaders type="ball-spin-fade-loader" active />
    </div>
  );

Spinner.defaultProps = {
  active: true,
  overlay: false
};

Spinner.propTypes = {
  active: PropTypes.bool,
  overlay: PropTypes.bool
};

export default Spinner;
