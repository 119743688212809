import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions/units.actions';
import { fetchUnitLiveData } from '../../../actions/unitDetails/liveData.actions';
import Component from './MyUnitDetails';
import { getProjectId } from '../../../utils/auth/user';

const mapStateToProps = (state, props) => {
  const unitId = Number(
    (props.match.params || { coldStorageId: null }).coldStorageId
  );

  const projectId = props.match.params.projectId || getProjectId() || null;

  const moreThenOneProject = state.projects.data.length > 1;

  const unitDetails = () => {
    return state.units[projectId]
      ? state.units[projectId].data.find(unit => unit.id === unitId)
      : { name: '' };
  };

  return {
    unitDetails: unitDetails,
    coldStorageId: unitId,
    projectId: projectId,
    moreThenOneProject
  };
};

const mapDispatchToProps = dispatch => ({
  fetchUnits: bindActionCreators(actions.fetchUnits, dispatch),
  fetchUnitLiveData: bindActionCreators(fetchUnitLiveData, dispatch)
});

const ComponentWithRouter = withRouter(Component);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithRouter);
