import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isTokenValid } from '../../utils/auth/session';
import { urlRoute } from '../../utils/url';

const NonAuthRequiredRoute = props => {
  const { component, path, exact } = props;

  const authorized = isTokenValid();

  const nonAuthRequired = () => {
    const redirectPath = urlRoute('/');

    return <Redirect to={redirectPath} replace />;
  };

  return !authorized ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    nonAuthRequired()
  );
};

NonAuthRequiredRoute.defaultProps = {
  component: null,
  path: '/',
  exact: false
};

export default NonAuthRequiredRoute;
