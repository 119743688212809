import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import Label from "../../../../components/shared/Label";
// import Spinner from "../../../../components/shared/Spinner";
// import { Table } from 'semantic-ui-react'
// import { useMediaQuery } from 'react-responsive'
import * as colors from "../../../../constants/colors";
import NumberIndicatorModule from "../../../../components/Charts/NumberIndicatorModule";
import io from 'socket.io-client';
import { API_URL } from "../../../../config";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";

function NewLive(props) {

    const [filteredData, setFilteredData] = useState({});
    console.log(filteredData)


    const {
        projectsData,
        projectsDropDown,
        currentProjectDetail,
    } = props;

    const [selectedRoom, setSelectedRoom] = useState(projectsDropDown[0]?.value);
    console.log(selectedRoom)

    useEffect(() => {

        const socket = io(API_URL);

        projectsData.forEach(obj => {
            // console.log(obj)
            socket.on(`dldata/${obj.projectUid}`, message => {
                // console.log(obj.projectUid)
                if (obj.projectUid === currentProjectDetail?.projectUid) {
                    // console.log(JSON.parse(message))
                    const roomData = JSON.parse(message)
                    const coldRoomKey = `ColdRoom_${selectedRoom}`;
                    const { id, timestamp, [coldRoomKey]: coldRoomData } = roomData;
                    setFilteredData({ id, timestamp, [coldRoomKey]: coldRoomData });

                }
                // console.log(JSON.parse(message))

            });
        });

        return () => socket.disconnect();
        // eslint-disable-next-line
    }, [selectedRoom]);

    return (
        <div className="my-units__container">

            <label className="table-text">Select a room:</label>
            <Dropdown
                className="dropdown-extras-container"
                compact

                placeholder="Select a room"
                search
                selection
                options={projectsDropDown}
                defaultValue={projectsDropDown[0]?.value}
                onChange={(event, { value }) => {
                    setSelectedRoom(value)
                }}
                fluid
            />




            <div className="statistics__charts-row">
                <NumberIndicatorModule
                    title="Temperature"
                    subTitle="for the chosen time span"
                    color={colors.ORANGE}
                    entity="°C"
                    value={
                        filteredData[`ColdRoom_${selectedRoom}`]?.tempAmbient
                    }
                ></NumberIndicatorModule>

                <NumberIndicatorModule
                    title="Humidity"
                    subTitle="for the chosen time span"
                    color={colors.ORANGE}
                    entity="%"
                    value={
                        filteredData[`ColdRoom_${selectedRoom}`]?.humAmbient
                    }
                ></NumberIndicatorModule>

                <NumberIndicatorModule
                    title="Door"
                    subTitle="for the chosen time span"
                    color={colors.ORANGE}
                    entity=""
                    value={
                        filteredData[`ColdRoom_${selectedRoom}`]?.statusDoorRom === true ? 1 : 0
                    }
                ></NumberIndicatorModule>



            </div>


        </div>
    );

}

const mapStateToProps = ({ projects, }) => {
    const { data, currentProject } = projects;

    let currentProjectDetail = data.find(project => currentProject === project.id)


    let projectsDropDown = currentProjectDetail?.units?.map((single) => ({
        key: single.uid,
        text: single.customTitle ?? single.uid,
        value: single.uid
    }))

    return {
        projectsData: data,
        projectsDropDown,
        currentProject,
        currentProjectDetail,

    };
};

export default connect(mapStateToProps, {})(NewLive);



