import {
  DONE_FETCHING_PROJECT_ALARMS,
  FETCHING_PROJECT_ALARMS,
} from "../../constants/alarms.types";
import { PURGE } from "redux-persist";

const INITIAL = {
  fetchingData: false,
  data: {},
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    case FETCHING_PROJECT_ALARMS:
      return { ...state, fetchingData: true };

    case DONE_FETCHING_PROJECT_ALARMS:
      const dataObject = {};
      const createdObjects = {};

      for (const element of action.payload) {

        if (
          dataObject[element.coldstorage.customTitle] &&
          createdObjects[
          `${element.coldstorage.customTitle}-${element.type}-${element.createdAt}`
          ] !== 1
        ) {
          dataObject[element.coldstorage.customTitle].push(element);
          createdObjects[
            `${element.coldstorage.customTitle}-${element.type}-${element.createdAt}`
          ] = 1;
          continue;
        }

        if (
          dataObject[element.coldstorage.customTitle] &&
          createdObjects[
          `${element.coldstorage.customTitle}-${element.type}-${element.createdAt}`
          ] === 1
        ) {
          continue;
        }

        dataObject[element.coldstorage.customTitle] = [element];
        createdObjects[
          `${element.coldstorage.customTitle}-${element.type}-${element.createdAt}`
        ] = 1;
      }
      return {
        ...state,
        data: dataObject,
        fetchingData: false,
      };

    case PURGE:
      return {
        ...INITIAL
      }

    default:
      return state;
  }
};
