import React from 'react';
import { Link } from 'react-router-dom';

import Page from '../../components/Page';
import './_not-found.scss';

const NotFound = () => (
  <Page className="not-found">
    <h2>404</h2>
    <h3>Page Not Found</h3>
    <div className="not-found__back-link">
      Go back to <Link to="/">Home</Link>
    </div>
  </Page>
);

export default NotFound;
