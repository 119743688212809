import React from 'react'
import './styles.scss'
// import PropTypes from 'prop-types'

function PaperContent({children}) {
    return (
        <div className="card-content">
            {children}
        </div>
    )
}

// PaperContent.propTypes = {
//     title: PropTypes.string,
//     component: PropTypes.elementType
// }

export default PaperContent

