import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

import ChartTooltip from '../../../../../../../components/Charts/components/ChartTooltip';
import LinearChart from '../../../../../../../components/Charts/LinearChart';
import * as colors from '../../../../../../../constants/colors';
import { getListFromData } from '../../../../../../../helpers/object.helper';
//import { getTimeFormat } from '../../../../../../../utils/timeFormat';
import { roundTwoDigits } from '../../../../../../../utils/numberFormat';
import { getLabelNameFormat } from '../../../../../../../helpers/chart.helper';
import { cleanChartDataToBeContinous } from '../../../../../../../utils/chartUtils';

export const EthyleneChart = ({ syncId, data }) => {
  const _getCustomTooltip = e => {
    const data = ((e.payload || [])[0] || {}).payload || {};

    return (
      <ChartTooltip>
        <p>
          ETHYLENE: <strong>{roundTwoDigits(data.ethy)}ppm</strong>
        </p>
        <small>{data.startDate}</small>
      </ChartTooltip>
    );
  };

  const _getEthyleneData = () => {
    const nameLabelFormat = getLabelNameFormat(getListFromData(data));
    const dataList = getListFromData(data);

    return cleanChartDataToBeContinous(dataList, 'value').map(element => {
      const startDate = moment.utc(element.recDateTime).local();

      return {
        name: startDate.format(nameLabelFormat),
        startDate: startDate.format('HH:mm - DD.MM.YYYY'),
        ethy: element.value ? roundTwoDigits(element.value) : null
      }
    });
  };

  const _isLoading = () => {

    return !isUndefined(data) && data.loading === true;
  };

  const legends = [{ key: 'ethy', color: colors.ORANGE, label: 'ETHYLENE' }];
  const rangeLines = [
    // { x: 0, y: 150, label: '150 ppm MAX', color: colors.LIGHT_GREY }
  ];

  return (
    <LinearChart
      title="Ethylene"
      syncId={syncId}
      data={_getEthyleneData()}
      tooltip={_getCustomTooltip}
      legends={legends}
      rangeLines={rangeLines}
      // maxValue={14}
      // minValue={11}
      loading={_isLoading()}
    />
  );
}

EthyleneChart.propTypes = {
  syncId: PropTypes.string,
  data: PropTypes.object,
  statisticsType: PropTypes.string.isRequired
};

EthyleneChart.defaultProps = {
  syncId: 'syncId',
  data: undefined
};

export default EthyleneChart;
