import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from './Settings';

const mapStateToProps = state => {
  return {
    data: state.alarms.active.data,
    features: state.auth.features,
    paymentStatus: state.auth.paymentStatus
  };
};

const ComponentWithRouter = withRouter(Component);

export default connect(mapStateToProps)(ComponentWithRouter);
