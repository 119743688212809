import React from "react";
import PropTypes from "prop-types";

const Title = ({ className = "", title = "", subTitle = "" }) => {
  return (
    <>
      <h2 className={`page-title ${className}`}>{title}</h2>
      {subTitle === "" ? null : <p>{subTitle}</p>}
    </>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;
