import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import Page from "../../components/Page";
import Title from "../../components/shared/Title";
import { urlRoute } from "../../utils/url";
import { toRenderFeature } from '../../utils/featureUtil'
import Navigation from "../../components/shared/Navigation";
import * as featureTypes from '../../constants/feature-types'
//import Contact from "../Service/routes/Contact";
// import Solved from './routes/Solved';
// import Active from './routes/Active';
// import Thresholds from './routes/Thresholds';
import ColdRooms from "./routes/Coldrooms/ColdRooms";
import Site from "./routes/Site/Site";
import Settings from "./routes/Settings/Settings";
import { withStyles } from "@material-ui/core/styles";

import { Tab } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import "./_alarms.scss";
//import Spinner from "../../components/shared/Spinner";
import moment from "moment";
import LiveAlarms from "./routes/live/LiveAlarms";

const useStyles = (theme) => ({
  root: {
    width: "50%",
    maxWidth: 100,
    backgroundColor: theme.palette.background.paper,
  },
  margin: {
    margin: theme.spacing(2),
  },
  customBadge: {
    backgroundColor: "#52c41a",
    color: "white",
  },
});

let intervalId = 0;

class Alarms extends Component {
  constructor(props) {
    super(props);
    this.state = { windowWidth: window.innerWidth };
  }


  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    // this.props.fetchActiveAlarms();

    // intervalId = setInterval(() => {
    //   this.props.fetchActiveAlarms();
    // }, 5000);
    window.addEventListener("resize", this.handleResize);
  }



  componentWillUnmount() {
    clearInterval(intervalId);
    window.addEventListener("resize", this.handleResize);
  }

  render() {
    const { data, features, paymentStatus } = this.props;
    const { windowWidth } = this.state;

    let routes = {
      liveAlarms: {
        label: "Live Alarms",
        link: urlRoute(`/alarms/live`),
        path: urlRoute(`/alarms/live`),
      },
      coldRooms: {
        label: "ColdRooms",
        link: urlRoute(`/alarms/coldrooms`),
        path: urlRoute(`/alarms/coldrooms`),
      },
      site: {
        label: "Site",
        link: urlRoute(`/alarms/sites`),
        path: urlRoute(`/alarms/sites`),
      }
    };

    if (toRenderFeature(featureTypes.ALARMS_SETTINGS, paymentStatus, features)) {
      routes.settings = {
        label: "Settings",
        link: urlRoute(`/alarms/settings`),
        path: urlRoute(`/alarms/settings`),
      }
    }

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    const coldrooms = [];
    var groupByColdStorage = groupBy(data, "coldstorageName");

    Object.keys(groupByColdStorage).forEach(function (category) {
      coldrooms.push(category);
    });

    let group = data.reduce((r, a) => {
      r[a.coldstorageName] = [...(r[a.coldstorageName] || []), a];
      return r;
    }, {});

    let panes = [];
    Object.keys(group).forEach(function (key) {
      panes.push({
        menuItem: key,
        render: () => (
          <Tab.Pane>
            <table class="ui celled table">
              <thead>
                {windowWidth > 1224 ? (
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                ) : null}
              </thead>
              <tbody>
                {group[key].map((alarm, index) => (
                  <tr class={alarm.alarmStatus === 0 ? "positive" : "negative"}>
                    <td>{alarm.alarmTitle}</td>
                    <td>
                      {alarm.alarmStatus === 0 ? (
                        <i class="check circle icon"></i>
                      ) : (
                        <i class="bell icon"></i>
                      )}{" "}
                    </td>
                    <td>
                      {alarm.alarmStatus === 0
                        ? `Nominal Since ${moment
                          .unix(alarm.modifiedAtUnix)
                          .local()
                          .format("HH:mm - DD.MM.YYYY")}`
                        : `Not resolved since ${moment
                          .unix(alarm.createdAtUnix)
                          .local()
                          .format("HH:mm - DD.MM.YYYY")}`}{" "}
                    </td>

                    {/* <td>{alarm.alarmStatus===0? `Nominal Since ${alarm.modifiedAtUnix}` : `Not resolved since ${alarm.createdAtUnix}`} </td>
                     */}
                  </tr>
                ))}
              </tbody>
            </table>
          </Tab.Pane>
        ),
      });
    });

    return (
      <Page isContainer>
        <Title title="Alerts" />

        <Navigation routes={routes} />
        <Switch>

          <Route exact path={routes.liveAlarms.path} component={LiveAlarms} />
          <Route exact path={routes.coldRooms.path} component={ColdRooms} />
          <Route exact path={routes.site.path} component={Site} />
          {toRenderFeature(featureTypes.ALARMS_SETTINGS, paymentStatus, features) && <Route exact path={routes.settings.path} component={Settings} />}

          <Route exact path="/alarms">
            <Redirect to={routes.liveAlarms.path} />
          </Route>
        </Switch>
      </Page>
    );
  }
}

Alarms.propTypes = {
  fetchActiveAlarms: PropTypes.func,
  data: PropTypes.array,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  sync: PropTypes.bool,
  features: PropTypes.array
};
Alarms.defaultProps = {
  data: [],
};

export default withStyles(useStyles)(Alarms);
