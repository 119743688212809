import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import * as colors from '../../constants/colors';
import { getUserRoles } from '../../utils/auth/user';
import { hasOneOrMoreSameValues } from '../../utils/arrays/compareTwoArrays';

const SingleValueComponent = props => {
  return !props.isDisabled ? (
    <div {...props.innerProps} className="input">
      {props.data.label}
    </div>
  ) : null;
};
const ControlComponent = props => {
  const { menuIsOpen } = props;

  return (
    <components.Control
      className={`control ${menuIsOpen ? 'is-open' : ''}`}
      {...props}
    />
  );
};

const ValueContainerComponent = ({ children, className, ...props }) => (
  <components.ValueContainer className="value-container" {...props}>
    {children}
  </components.ValueContainer>
);

const DropdownIndicatorComponent = ({ className, ...props }) => {
  return <components.DropdownIndicator className="indicator" {...props} />;
};

const MenuComponent = ({ className, ...props }) => {
  return <components.Menu className="menu" {...props} />;
};

const MenuListComponent = ({ className, ...props }) => {
  return (
    <components.MenuList className="menuList" {...props}>
      {props.selectProps.profile && (
        <div className="menuList__profile"> {props.selectProps.profile} </div>
      )}
      {props.children}
    </components.MenuList>
  );
};

const OptionComponent = ({ className, selectProps, data, ...props }) => {
  return (
    <components.Option
      className={`option ${selectProps.selected === data.link ? 'active' : ''}`}
      {...props}
    />
  );
};

const Dropdown = ({
  className = '',
  value,
  options,
  onChange,
  isSearchable,
  placeholder,
  profile,
  selected
}) => {
  const getOptions = () => {
    return options.filter(x =>
      x.onlyFor ? hasOneOrMoreSameValues(x.onlyFor, getUserRoles()) : x
    );
  };

  return (
    <div className={`dropdown ${className}`}>
      <Select
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        selected={selected}
        options={getOptions()}
        isSearchable={isSearchable}
        styles={styles}
        profile={profile}
        menuPlacement="auto"
        components={{
          IndicatorsContainer: DropdownIndicatorComponent,
          IndicatorSeparator: null,
          SingleValue: SingleValueComponent,
          Control: ControlComponent,
          ValueContainer: ValueContainerComponent,
          Menu: MenuComponent,
          MenuList: MenuListComponent,
          Option: OptionComponent
        }}
      />
    </div>
  );
};

const styles = {
  singleValue: styles => ({
    ...styles,
    color: colors.WHITE,
    fontSize: '15px',
    fontWeight: 'normal'
  }),
  valueContainer: styles => ({
    ...styles,
    position: 'initial',
    maxWidth: 30,
    borderRadius: 0
  }),
  menu: styles => ({
    ...styles,
    boxShadow:
      '0 3px 7px 1px rgba(2,41,39,0.18), 0 0 3px 0 rgba(243, 243, 243, 0.5)',
    borderRadius: 2,
    border: 'white'
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = colors.WHITE;
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? colors.CYAN
        : isFocused
        ? colors.CYAN
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? color
        : isFocused
        ? color
        : colors.CYAN,
      fontSize: '15px',
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? colors.CYAN
            : isFocused
              ? colors.CYAN
              : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? color
            : isFocused
              ? color
              : colors.CYAN,
      }
    };
  }
};

Dropdown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  isSearchable: PropTypes.bool
};

Dropdown.defaultProps = {
  options: [],
  isSearchable: false
};

export default Dropdown;
