import api from "../utils/api"


export const verifyCodeApi = (data) => {
    return api.post(`companycontact/verify`, data)
}

export const uploadToS3 = (config, roomsCommercial) => {

    let body = {
        config,
        projectId: roomsCommercial.projectId,
        rooms: roomsCommercial.units,
        otherUnits: roomsCommercial.unitFields
    }
    return api.post(`configurator/uploadconfig`, body)
}

export const deleteDeice = async (id) => {
    await api.delete(`other-units/delete?id=${id}`)
}

export const sendMailInvite = (email, companyId, role) => {
    let body = {
        email,
        companyId,
        role
    }
    return api.post(`user/mail-invite`, body)
}

export const accountConfirm = (hash, password) => {
    let body = {
        hash,
        password
    }
    return api.post(`auth/account-confirm`, body)
}



