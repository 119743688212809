import {
    LATEST_LIVE_ALARMS,
    FETCHING_LATEST_LIVE_ALARMS,
    SAVED_LATEST_LIVE_ALARMS
} from "../../constants/alarms.types";
import { PURGE } from "redux-persist";

const INITIAL = {
    fetchingLiveData: false,
    latestLiveAlarms: {},
};

export default (state = INITIAL, action) => {
    switch (action.type) {
        case FETCHING_LATEST_LIVE_ALARMS:
            return { ...state, fetchingLiveData: true };

        case LATEST_LIVE_ALARMS:
            const dataObject = state.latestLiveAlarms;
            const newAlarms = JSON.parse(action.payload.alarms)

            if (dataObject[action.payload.projectUid]) {
                dataObject[action.payload.projectUid] = { ...dataObject[action.payload.projectUid], ...newAlarms };
            } else {
                dataObject[action.payload.projectUid] = { ...newAlarms };
            }

            return {
                ...state,
                latestLiveAlarms: { ...dataObject },
                fetchinfetchingLiveDataData: false,
            };

        case SAVED_LATEST_LIVE_ALARMS:
            const savedDataObject = state.latestLiveAlarms;

            return {
                ...state,
                latestLiveAlarms: { ...savedDataObject, ...action.payload },
                fetchinfetchingLiveDataData: false,
            };

        case PURGE:
            return {
                ...INITIAL
            }

        default:
            return state;
    }
};
