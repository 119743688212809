import { cleanAuthData } from './auth.actions';
import { cleanUnits } from './units.actions';
import { cleanProjects } from './projects.actions';
import { cleanActiveAlarms } from './alarms/active.actions';

export const cleanUpStateOnLogout = () => dispatch => {
  dispatch(cleanAuthData());
  dispatch(cleanUnits());
  dispatch(cleanProjects());
  dispatch(cleanActiveAlarms());
};
