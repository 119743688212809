import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

import ChartTooltip from '../../../../../../../components/Charts/components/ChartTooltip';
import BarredChart from '../../../../../../../components/Charts/BarredChart';
import * as colors from '../../../../../../../constants/colors';
import { roundTwoDigits } from '../../../../../../../utils/numberFormat';
import { getLabelNameFormat } from '../../../../../../../helpers/chart.helper';
import { getListFromData } from '../../../../../../../helpers/object.helper';
import { cleanChartDataToBeContinous } from '../../../../../../../utils/chartUtils';

export const RainGaugeChart = ({ syncId, data }) => {
  const legends = [
    { key: 'rainGauge', color: colors.CYAN, label: 'PRECIPITATION' }
  ];

  const rangeLines = [
    // { x: 0, y: 60, label: '60% MAX', color: colors.LIGHT_GREY },
    // { x: 0, y: 40, label: '40% MIN', color: colors.LIGHT_GREY }
  ];

  const maxValue = data.maxValue < 0.5 ? 0.5 : data.maxValue < 1 ? 1 : data.maxValue < 4 ? 4 : data.maxValue < 8 ? 8 : 10

  const _getData = () => {
    //console.log("data", data)
    const dataList = getListFromData(data);
    //console.log("dataList", dataList)
    const nameLabelFormat = getLabelNameFormat(dataList)

    return cleanChartDataToBeContinous(dataList, 'value').map(element => {
      const startDate = moment.utc(element.recDateTime).local();

      return {
        name: startDate.format(nameLabelFormat),
        startDate: startDate.format('HH:mm - DD.MM.YYYY'),
        rainGauge: element.value
      }
    });
  };

  const _getCustomTooltip = e => {
    const data = ((e.payload || [])[0] || {}).payload || {};

    return (
      <ChartTooltip>
        <p>
          PRECIPITATION: <strong>{roundTwoDigits(data.rainGauge)}mm</strong>
        </p>
        <small>{data.startDate}</small>
      </ChartTooltip>
    );
  };

  const _isLoading = () => !isUndefined(data) && data.loading === true;

  return (
    <BarredChart
      title="Precipitation"
      syncId={syncId}
      data={_getData()}
      tooltip={_getCustomTooltip}
      legends={legends}
      rangeLines={rangeLines}
      loading={_isLoading()}
      minValue={0}
      maxValue={maxValue} />
  );
}

RainGaugeChart.propTypes = {
  syncId: PropTypes.string,
  data: PropTypes.object
};

RainGaugeChart.defaultProps = {
  syncId: 'syncId',
  data: undefined
};

export default RainGaugeChart;
