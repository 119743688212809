import React, { useState, useEffect } from 'react'
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import RangeDatePicker from "../../../../components/shared/RangeDatePicker";
import { ReeferTemperatureChart } from '../../charts/ReeferTempChart'
import { fetchShipmentStatsData } from '../../../../actions/shipments/shipments.actions';
import { connect } from 'react-redux'
import { ReeferHumChart } from '../../charts/ReeferHumChart';
import ReeferLightAmbientChart from '../../charts/ReeferLightAmbientChart';

function ReferStatistics(props) {

    const {
        fetchShipmentStatsData,
        shipmentData,
        shipmentDataLoad,
        shipmentActiveShipment,
    } = props;

    const [pickerStartDate, setPickerStartDate] = useState(
        localStorage.getItem("pickerStartDate") !== null
            ? moment(localStorage.getItem("pickerStartDate"))
            : moment()
                .subtract(1, "days")
                .startOf("day")
    )

    const [pickerEndDate, setPickerEndDate] = useState(
        localStorage.getItem("pickerEndDate") !== null
            ? moment(localStorage.getItem("pickerEndDate"))
            : moment()
    )

    const fetchData = async (loggerId, startDate, endDate) => {
        const startDateString = startDate.toISOString()
        const endDateString = endDate.toISOString()
        fetchShipmentStatsData(loggerId, startDateString, endDateString)
    };

    const [activeLogger, setActiveLogger] = useState(shipmentActiveShipment?.loggerIds[0])


    useEffect(() => {

        fetchData(activeLogger, pickerStartDate, pickerEndDate);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLogger, pickerStartDate, pickerEndDate])



    const deviceOptions = () => {
        return shipmentActiveShipment?.loggerIds?.map((el, idx) => ({
            key: idx,
            text: el,
            value: el
        }))
    }

    const onDatePickerAccept = ({ startDate, endDate }) => {
        const startDateValue = startDate.clone().startOf("day");
        const endDateValue = endDate
            .clone()
            .add(1, "day")
            .startOf("day");

        localStorage.setItem("pickerEndDate", endDateValue);
        localStorage.setItem("pickerStartDate", startDateValue);

        setPickerStartDate(startDateValue)
        setPickerEndDate(endDateValue)


    }



    return (
        <>
            <div className="reports">
                <div className="reports-select-container">

                    <div className="report-datepicker">

                        <div className="report-datepicker-tablet">

                            <div className="report-datepicker-tablet-x">
                                <p className="table-text">Select a logger and a date:</p>
                                <Dropdown
                                    className="dropdown-extras-container"
                                    compact

                                    placeholder="Select a device"
                                    search
                                    selection
                                    options={deviceOptions()}
                                    value={activeLogger}
                                    onChange={(event, { value }) => {
                                        setActiveLogger(value)
                                    }}
                                />
                            </div>
                            <div className="report-datepicker-tablet-x">


                                <RangeDatePicker
                                    // onAccept={this._onRangePickerAccept}
                                    onAccept={onDatePickerAccept}

                                    starterDate={
                                        pickerStartDate ? pickerStartDate : null
                                    }
                                    enderDate={
                                        pickerEndDate ? pickerEndDate : null
                                    }
                                />

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="statistics">
                <div className="statistics__charts">
                    <ReeferTemperatureChart
                        shipmentData={shipmentData}
                        shipmentDataLoad={shipmentDataLoad}
                    />

                </div>

            </div>

            <div className="statistics">
                <div className="statistics__charts">
                    <ReeferHumChart
                        shipmentData={shipmentData}
                        shipmentDataLoad={shipmentDataLoad}
                    />

                </div>

            </div>

            <div className="statistics">
                <div className="statistics__charts">
                    <ReeferLightAmbientChart
                        shipmentData={shipmentData}
                        shipmentDataLoad={shipmentDataLoad}
                    />

                </div>

            </div>
        </>
    )
}

const mapStateToProps = ({ shipments }) => {
    const {
        shipmentData,
        shipmentDataLoad,
        shipmentActiveShipment
    } = shipments.shipmentsReducer;

    return {
        shipmentData,
        shipmentDataLoad,
        shipmentActiveShipment
    };
};

export default connect(mapStateToProps, {
    fetchShipmentStatsData
})(ReferStatistics);

