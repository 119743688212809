import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom";

import Page from "../../components/Page";
import Title from "../../components/shared/Title";
import { urlRoute } from "../../utils/url";
import Navigation from "../../components/shared/Navigation";
import User from './routes/users/User';
import Invite from './routes/invite/Invite';




let routes = {
    user: {
        label: "Users",
        link: urlRoute(`/admin/users`),
        path: urlRoute(`/admin/users`),
    },
    invite: {
        label: "Invite",
        link: urlRoute(`/admin/user/invite`),
        path: urlRoute(`/admin/user/invite`),
    }
};


function GeneralAdmin() {
    return (
        <Page isContainer>
            <Title title="Admin" />

            <Navigation routes={routes} />
            <Switch>

                <Route exact path={routes.user.path} component={User} />
                <Route exact path={routes.invite.path} component={Invite} />
                <Route exact path="/admin">
                    <Redirect to={routes.user.path} />
                </Route>
            </Switch>
        </Page>
    )
}

export default GeneralAdmin;