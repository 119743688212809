import React from 'react';
import { NavLink } from 'react-router-dom';
import Badge from './Badge';
import * as roles from '../../constants/roles';
import RenderByRoles from '../RenderBy/RenderByRoles';
import { useMediaQuery } from 'react-responsive'


const allUsers = [
  roles.CLIENT,
  roles.CLIENT_ADMIN,
  roles.INSPIRA_FARMS_EMPLOYEE,
  roles.INSPIRA_FARMS_TECH,
  roles.INSPIRA_FARMS_ADMIN,
  roles.UNDER_THE_RADAR,
  roles.INSPIRA_FARMS_TECH_REGION
];
const Navigation = ({ routes }) => {
  const routesArray = Object.keys(routes).map(key => ({ ...routes[key] }));
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });


  return (
    <div className="navigation">
      {(routesArray || []).map((v, i) => (
        <RenderByRoles roles={v.onlyFor || allUsers} key={i}>
          <NavLink
            exact
            key={i}
            to={v.link}
            className="navigation__link"
            activeClassName="active">
            <div className="navigation__link__word" style={{
              fontSize: isTabletOrMobileDevice ? '12px' : null,
            }}>{v.label}</div>
            {v.counter && <Badge>{v.counter}</Badge>}
          </NavLink>
        </RenderByRoles>
      ))}
    </div>
  );
};

export default Navigation;
