import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { urlRoute as url } from '../../../../utils/url';
import LogoImg from '../../../../assets/images/logo.svg';
import textLogoImg from '../../../../assets/images/inspirafarms_text_logo.svg';

const NavLogo = ({ moreThenOneProject, textLogo }) => {
  const _getMainUrl = () => (
    '/projects'
    // moreThenOneProject
    //   ? `/projects`
    //   : '/units'
  );

  return (
    <div className="logo">
      <Link to={url(_getMainUrl())}>
        <img src={textLogo ? textLogoImg : LogoImg} alt="logo-img" />
      </Link>
    </div>
  );
};

NavLogo.propTypes = {
  textLogo: PropTypes.bool
};

NavLogo.defaultProps = {
  textLogo: false
};

export default NavLogo;
