import api from '../utils/api';
import qs from 'qs';

import * as reportsTypes from '../constants/reports.types';
import currencyTypes from '../constants/currencyTypes';

const convertDateToISO = obj => {
  const result = {};
  result.startDate = obj['startDate'].clone().toISOString();
  result.endDate = obj['endDate'].clone().toISOString();

  return result;
};

export const fetchReportAccess = projectId => async dispatch => {
  dispatch({ type: reportsTypes.LOADING_REPORT_ACCESS });

  try {
    const response = await api.get(`project/${projectId}/report-access`);

    dispatch({
      type: reportsTypes.DONE_REPORT_ACCESS,
      payload: { costReport: response.data.costReport }
    });

    return response && response.data;
  } catch (e) {
    dispatch({ type: reportsTypes.ERROR_FETCH_EN_CONSUMPTION_DATA });

    return false;
  }
}

export const fetchProjectEnergyConsumption = payload => async dispatch => {
  dispatch({ type: reportsTypes.LOADING_EN_CONSUMPTION_DATA });
  let response;
  try {
    //const response = await api.get(`project/${payload.projectId}/energy-consumption?startDate=${parsedDates.startDate}&endDate=${parsedDates.endDate}`);
    if (payload.interval !== 'interval') {
      response = await api.get(`project/${payload.projectId}/${payload.interval}`);
    }
    else {
      const parsedDates = convertDateToISO(payload.dates);
      response = await api.get(`project/${payload.projectId}/interval?startDate=${parsedDates.startDate}&endDate=${parsedDates.endDate}`);
    }
    dispatch({
      type: reportsTypes.DONE_FETCH_EN_CONSUMPTION_DATA,
      payload: { energyConsumption: response.data }
    });
  } catch (e) {
    dispatch({ type: reportsTypes.ERROR_FETCH_EN_CONSUMPTION_DATA });
  }
};

export const fetchTotalHandledProduce = payload => async dispatch => {
  dispatch({ type: reportsTypes.LOADING_TOTAL_HANDLED_PRODUCE });
  const parsedDates = convertDateToISO(payload.dates);

  try {
    const response = await api.get(`project/${payload.projectId}/total-handled-produce?startDate=${parsedDates.startDate}&endDate=${parsedDates.endDate}`);

    dispatch({
      type: reportsTypes.DONE_TOTAL_HANDLED_PRODUCE,
      payload: { totalHandledProduce: response.data.totalHandledProduce }
    });
  } catch (e) {
    dispatch({ type: reportsTypes.ERROR_TOTAL_HANDLED_PRODUCE });
  }
}

export const fetchPricePerKg = payload => async dispatch => {
  dispatch({ type: reportsTypes.LOADING_PRICE_PER_KG_DATA });

  try {
    const response = await api.get(`project/${payload.projectId}/pricing/parameters`, {
      params: {
        currencyTypes: currencyTypes.map(currencyType => currencyType.label)
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    });
    dispatch({
      type: reportsTypes.DONE_FETCH_PRICE_PER_KG_DATA,
      payload: { pricePerKg: response.data.pricePerKg }
    });
  } catch (e) {
    dispatch({ type: reportsTypes.ERROR_FETCH_PRICE_PER_KG_DATA });
  }
};

export const fetchMinThroughput = payload => async dispatch => {
  dispatch({ type: reportsTypes.LOADING_MIN_THROUGHPUT_DATA });

  try {
    const response = await api.get(`project/${payload.projectId}/min-throughput/parameters`);

    dispatch({
      type: reportsTypes.DONE_FETCH_MIN_THROUGHPUT_DATA,
      payload: { minThroughput: Number.parseInt(response.data.minThroughput) }
    });
  } catch (e) {
    dispatch({ type: reportsTypes.ERROR_FETCH_MIN_THROUGHPUT_DATA });
  }
};

export const fetchPricingCalculate = payload => async dispatch => {
  dispatch({ type: reportsTypes.LOADING_CALCULATE_PRICING_DATA });
  const parsedDates = convertDateToISO(payload.dates);

  try {
    const response = await api.get(`project/${payload.projectId}/pricing/calculate?startDate=${parsedDates.startDate}&endDate=${parsedDates.endDate}`);

    dispatch({
      type: reportsTypes.DONE_FETCH_CALCULATE_PRICING_DATA,
      payload: { pricingCalculate: response.data.pricingCalculate }
    });
  } catch (e) {
    dispatch({ type: reportsTypes.ERROR_FETCH_CALCULATE_PRICING_DATA });
  }
};
