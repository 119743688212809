import * as appVersionTypes from '../constants/appVersion.types';

const initialState = {
  version: ''
};

const reducer = {
  [appVersionTypes.APP_VERSION_SET]: (state, action) => {
    return {
      ...state,
      version: action.payload.version
    }
  },
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
