import React from 'react';
import PropTypes from 'prop-types';

import FormGroup from '../shared/FormGroup';
import Input from '../shared/Input';
import Label from '../shared/Label';

const InputForm = props => {
  const {
    className = '',
    label,
    type,
    placeholder,
    input,
    meta,
    min,
    step
  } = props;

  return (
    <FormGroup>
      <label>{label}</label>
      <div>
        <Input
          className={` form-input ${className}
          ${meta.touched && meta.error ? 'has-error' : ''}
          ${meta.active ? 'is-active' : ''} ${meta.touched ? 'is-visited' : ''}
        `}
          placeholder={placeholder}
          type={type}
          min={min}
          step={step}
          {...input}
        />
        {meta.error && meta.touched && (
          <Label type="error" label={meta.error} />
        )}
      </div>
    </FormGroup>
  );
};

InputForm.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string
};

export default InputForm;
