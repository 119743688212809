import React, {  useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { urlRoute } from '../../utils/url';
import './_projects.scss';
import { checkIfRoleIsInspiraFarmsTech } from "../../utils/arrays/compareTwoArrays";
import { Table } from 'semantic-ui-react'
import { getUserRoles } from "../../utils/auth/user";
import { fetchAllCompaniesConfiguration } from '../../actions/features/config.action';
import { fetchProjects, setCurrentProjectId } from '../../actions/projects.actions';
import Page from '../../components/Page/Page';
import Title from '../../components/shared/Title';


function ProjectsList(props) {

  const {
    data,
    // loading,
    // sync,
    fetchAllCompaniesConfiguration,
    fetchProjects,
    setCurrentProjectId,
    moreThenOneProject
  } = props;
  useEffect(() => {

    const fetchData = async () => {
      await fetchAllCompaniesConfiguration()
      const datas = await fetchProjects()
      const id = datas[0]?.id
      await setCurrentProjectId(id)

    }

    fetchData();
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRowClick = row => {
    setCurrentProjectId(row.id);
    props.history.push(moreThenOneProject ? urlRoute(`/projects/${row.id}/units`) : `/units`);
  };



  const roles = getUserRoles();


  return (


    <Page isContainer className="projects">
      <Title title="Projects" />
      <div className="projects__container">
        <Table color={'yellow'} unstackable>


          <Table.Body className='border-1'>

            {data?.map((el, idx) => (
              <>

                <Table.Row className="project-row" onClick={() => { handleRowClick(el) }}>
                  {checkIfRoleIsInspiraFarmsTech(roles) ? <Table.Cell colSpan={1}><p className="table-text">{el.companyName}</p></Table.Cell> : null}
                  <Table.Cell colSpan={1}><p className="table-text">{el.title}</p></Table.Cell>
                  <Table.Cell colSpan={1}><Link className='link-text-left' to={moreThenOneProject ? urlRoute(`projects/${el.id}/units`) : `/units`}><p>Enter</p></Link></Table.Cell>

                </Table.Row>

              </>
            ))}
          </Table.Body>
        </Table>
      </div>
    </Page>
  );

}

const mapStateToProps = ({ projects }) => {

  const moreThenOneProject = projects.data.length > 1;

  return {
    loading: projects.loading,
    data: projects.data,
    sync: projects.sync,
    moreThenOneProject
  };


};

export default connect(mapStateToProps, {
  fetchProjects,
  setCurrentProjectId,
  fetchAllCompaniesConfiguration,
})(ProjectsList);
