import statisticsTypes from '../../../../../constants/statisticsTypes';
import { chain } from 'lodash';

export const dataDisplayTypes = {
  number: 'number',
  onOff: 'onOff',
  openClosed: 'openClosed',
}

export const technicianDataConfig = [
  {
    key: statisticsTypes.cellTemp, label: 'Room temperature', suffix: '°C', parser: v => Number(v || 0).toFixed(1),
    displayType: dataDisplayTypes.number
  },
  {
    key: statisticsTypes.piercingProbeTemp, label: 'Pulp temperature', suffix: '°C', parser: v => Number(v || 0).toFixed(1),
    displayType: dataDisplayTypes.number
  },
  { key: statisticsTypes.cellHum, label: 'Humidity', suffix: '%', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.ethyleneProbe, label: 'Ethylene', suffix: 'PPM', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.stockWeights, label: 'Stock weights', suffix: 'kg', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.mainPower, label: 'Main power', suffix: 'kW' },
  { key: statisticsTypes.maxRoomPower, label: 'Room power', suffix: 'kW' },
  { key: statisticsTypes.compressorPower, label: 'Compressor power', suffix: 'kW' },
  { key: statisticsTypes.powerConsuption, label: 'Total energy consumption', suffix: 'kWh' },
  { key: statisticsTypes.roomEnergyConsumption, label: 'Room energy consumption', suffix: 'kWh' },
  { key: statisticsTypes.compressorEnergyConsumption, label: 'Compressor energy consumption', suffix: 'kWh' },
  { key: statisticsTypes.inverterVoltage, label: 'Inverter output voltage', suffix: 'V', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.batteryVoltage, label: 'Battery voltage', suffix: 'V', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.batteryCurrent, label: 'Battery current', suffix: 'V', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.batteryTemp, label: 'Battery temperature', suffix: '°C', displayType: dataDisplayTypes.number },
  {
    key: statisticsTypes.thermalStorageColdTemp,
    label: 'Thermal Storage Cold Temp',
    suffix: '°C',
    parser: v => Number(v || 0).toFixed(1),
    displayType: dataDisplayTypes.number
  },
  { key: statisticsTypes.batteryCharge, label: 'Battery charge', suffix: '%', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.energyPvAux, label: 'Energy generated from photovoltaic', suffix: 'V', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.pressSwitch, label: 'Pressure switch', suffix: '', displayType: dataDisplayTypes.onOff },
  { key: statisticsTypes.thr, label: 'Compressor motor throttle', suffix: '%', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.cprValve, label: 'Refrigerator gas valve status', suffix: '', displayType: dataDisplayTypes.onOff },
  { key: statisticsTypes.ethValve, label: 'Ethylene valve status', suffix: '', displayType: dataDisplayTypes.onOff },
  { key: statisticsTypes.pressLp, label: 'Low pressure', suffix: 'Bar', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.pressHp, label: 'High pressure', suffix: 'Bar', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.ge, label: 'Generator status', suffix: '', displayType: dataDisplayTypes.onOff },
  { key: statisticsTypes.externalTemp, label: 'External Temperature', suffix: '°C', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.externalHum, label: 'External Humidity', suffix: '%', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.sunIrradiation, label: 'Sun Irradiation', suffix: 'w/m2', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.rainGauge, label: 'Precipitation', suffix: 'mm', displayType: dataDisplayTypes.number },
  { key: statisticsTypes.doorRoom, label: 'Door Open', suffix: '', displayType: dataDisplayTypes.onOff },
  { key: statisticsTypes.statusGrid, label: 'Grid Status', suffix: '', displayType: dataDisplayTypes.onOff },
  { key: statisticsTypes.generatorStatus, label: 'Generator Status', suffix: '', displayType: dataDisplayTypes.onOff },
];

export const clientDataConfig = [
  { key: statisticsTypes.doorRoom, label: 'Door', suffix: '', displayType: dataDisplayTypes.openClosed },
  { key: statisticsTypes.generatorStatus, label: 'Generator Status', suffix: '', displayType: dataDisplayTypes.onOff },
  {
    key: 'statusGrid',
    label: 'Grid Status',
    suffix: '',
    parser: v => Number(v || 0).toFixed(1)
  },
  {
    key: 'cellTemp',
    label: 'Room temperature',
    suffix: '°C',
    parser: v => Number(v || 0).toFixed(1)
  },
  {
    key: 'piercingProbeTemp',
    label: 'Pulp temperature',
    suffix: '°C',
    parser: v => Number(v || 0).toFixed(1)
  },
  {
    key: 'piercingProbeTemp2',
    label: 'Pulp temperature 2',
    suffix: '°C',
    parser: v => Number(v || 0).toFixed(1)
  },
  { key: 'cellHum', label: 'Humidity', suffix: '%' },
  //{ key: 'batteryCharge', label: 'Charge', suffix: '%' },
  { key: 'ethyleneProbe', label: 'Ethylene', suffix: 'PPM' }
];

export const getConfigByDataProfile = (dataConfig, dataProfile) => {
  const dataProfileRestrictedFields = Object.keys(
    chain(dataProfile)
      .omit('id', 'name')
      .omitBy(value => value === true).value()
  );

  return dataConfig.filter(dataConfigItem => (
    !dataProfileRestrictedFields.includes(dataConfigItem.key)
  ));
}
