import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from "../../../../components/shared/Table";
// import Button from "../../../../components/shared/Button";
import { Button } from 'semantic-ui-react';
import AddUserForm from "./components/AddUserForm";
import Dropdown from "../../../../components/shared/Dropdown";
import AddUserModalWrapper from "./components/AddUserModalWrapper";
import DeleteUserModalWrapper from "./components/DeleteUserModalWrapper";
import { Radio } from "semantic-ui-react";

import "./_users.scss";

class Users extends Component {
  state = {
    isAddUserModalOpen: false,
    isDeleteUserModalOpen: false,
    row: 0,
  };

  componentDidMount() {
    this.props.fetchUsers();
  }

  showAddUserModal = () => {
    this.setState({ isAddUserModalOpen: true });
  };

  hideAddUserModal = () => {
    this.props.fetchUsers();
    this.setState({ isAddUserModalOpen: false });
  };

  showDeleteUserModal = (id) => {
    this.setState({ isDeleteUserModalOpen: true, row: id });
  };

  hideDeleteUserModal = () => {
    this.setState({ isDeleteUserModalOpen: false });
  };

  onChange = (id) => (event) => {
    if (event.value === "delete") {
      this.showDeleteUserModal(id);
    }
  };

  render() {
    const {
      data,
      inviteUser,
      loading,
      deleteUser,
      error,
      updateUserEmailAlertStatus,
      isDemo
    } = this.props;

    const tableConfig = {
      headers: [
        {
          key: "email",
        },
        {
          key: "isActive",
          textAlignItem: "center",
          component: (row) => (
            <div className="isActive">
              {row.isActive ? "ACTIVE" : "NOT ACTIVE"}
            </div>
          ),
        },
        {
          key: "roleName",
          textAlignItem: "center",
          component: (row) => <div className="role">{row.roleName}</div>,
        },
        {
          key: "emailSendingMode",
          component: (row) => (
            <div>
              <Radio
                color="yellow"
                toggle
                checked={row.emailSendingMode === 1 ? true : false}
                onClick={(e, data) => {
                  updateUserEmailAlertStatus(
                    row.id,
                    data.checked === true ? 1 : 0
                  );
                }}
              />

              {row.emailSendingMode === 1 ? (
                <div>alerts enabled</div>
              ) : (
                <div>alerts disabled</div>
              )}
            </div>
          ),
        },
        {
          key: "option",
          label: "",
          textAlignItem: "right",
          component: (row) => (
            <Dropdown
              placeholder=""
              className="options"
              onChange={this.onChange(row.id)}
              value="..."
              options={[
                { value: "delete", label: "Delete User" },
                { value: "admin", label: "Make admin" },
              ]}
            />
          ),
        },
      ],
    };

    return (
      <div className="users">
        {this.state.isAddUserModalOpen && (
          <AddUserModalWrapper
            header="Add User"
            confirmText="Invite"
            cancelText="Cancel"
            onCancel={this.hideAddUserModal}
            children={<AddUserForm error={error} />}
            inviteUser={inviteUser}
            loading={loading}
          />
        )}
        {this.state.isDeleteUserModalOpen && (
          <DeleteUserModalWrapper
            header="Delete User"
            confirmText="Delete"
            cancelText="Cancel"
            onCancel={this.hideDeleteUserModal}
            content="Are you sure that you want to delete this user?"
            deleteUser={deleteUser}
            loading={loading}
            id={this.state.row}
          />
        )}
        <div className="users__header">
          <div className="title">Current Users</div>
          <Button color="yellow" disabled={isDemo} onClick={this.showAddUserModal}>
            {"Add User"}
          </Button>
        </div>
        <Table items={data} config={tableConfig} />
      </div>
    );
  }
}

Users.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  sync: PropTypes.bool,
  fetchUsers: PropTypes.func,
  inviteUser: PropTypes.func,
  deleteUser: PropTypes.func,
  updateUserEmailAlertStatus: PropTypes.func,
  isDemo: PropTypes.bool,
};

export default Users;
