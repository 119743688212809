import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form, Field, SubmissionError } from 'redux-form';
import {
  passwordValidate,
  passwordRepeatValidate
} from '../../../utils/validators';

import '../../Settings/routes/ChangePassword/_change-password.scss';
import Spinner from '../../../components/shared/Spinner';
import Button from '../../../components/shared/Button';
import Label from '../../../components/shared/Label';
import InputForm from '../../../components/Inputs/InputForm';
import Page from '../../../components/Page/Page';
import Title from '../../../components/shared/Title';
import { urlRoute } from '../../../utils/url';

import './_change-password.scss';


class ChangePassword extends Component {
  submit = async values => {
    try {
      await this.props.recoverPassword(values, this.props.match.params.hash);
      this.props.history.push(urlRoute(`/`));
    } catch (e) {
      throw new SubmissionError({
        _error: 'Hash has expired'
      });
    }
  };
  render() {
    const {
      handleSubmit,
      submitting,
      submitFailed,
      error,
      loading
    } = this.props;

    return (
      <Page isContainer className="change-password">
        <Title title="Welcome!" />
        <Label
          label={'Please update your password to enter our system safely.'}
          type="info"
          className="change-password__description col-3"
        />
        <Form
          onSubmit={handleSubmit(this.submit)}
          className="change-password__form"
        >
          <Field
            name="newPassword"
            component={InputForm}
            type="password"
            label="Password"
            placeholder="New password"
          />
          <Field
            name="passwordRepeat"
            component={InputForm}
            type="password"
            label="Repeat password"
            placeholder="Enter password"
          />
          {!submitting && submitFailed && <Label type="error" label={error} />}
          <Button type="submit" tag="primary" disabled={submitting}>
            {submitting ? 'Saving Password' : 'Save Password'}
          </Button>
        </Form>
        <a className="link" href="/">
          Contact Support
        </a>
        <Spinner active={loading} overlay />
      </Page>
    );
  }
}

ChangePassword.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  recoverPassword: PropTypes.func
};

export default reduxForm({
  form: 'changePassword',
  validate: values => ({
    newPassword: passwordValidate(
      values.newPassword,
      'New password is required',
      'Password is too short'
    ),
    passwordRepeat: passwordRepeatValidate(
      values.newPassword,
      values.passwordRepeat,
      'Password repeat is required',
      'New password and password repeat should be the same'
    )
  })
})(ChangePassword);
