import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

import ChartTooltip from '../../../../../../../components/Charts/components/ChartTooltip';
import AreaChart from '../../../../../../../components/Charts/AreaChart';
import * as colors from '../../../../../../../constants/colors';
import { getListFromData } from '../../../../../../../helpers/object.helper';
import { getLabelNameFormat } from '../../../../../../../helpers/chart.helper';

export const StockWeightChart = ({ syncId, data }) => {
  const legends = [
    { key: 'stockWeight', color: colors.ORANGE, label: 'STOCK' }
  ];

  const rangeLines = [
    // { x: 0, y: 60, label: '60% MAX', color: colors.LIGHT_GREY },
    // { x: 0, y: 40, label: '40% MIN', color: colors.LIGHT_GREY }
  ];

  const _getStockWeightData = () => {
    const nameLabelFormat = getLabelNameFormat(getListFromData(data));

    return (getListFromData(data) || []).map(element => {
      //const startDate = moment(element.recDateTime).local(true);
      const startDate = moment.utc(element.recDateTime).local();
      //console.log("@@@@", startDate)

      return {
        name: startDate.format(nameLabelFormat),
        startDate: startDate.format('HH:mm - DD.MM.YYYY'),
        stockWeight: Math.round(element.value * 100) / 100,
      }
    });
  };

  const _getCustomTooltip = e => {
    const data = ((e.payload || [])[0] || {}).payload || {};

    return (
      <ChartTooltip>
        <p>
          STOCK: <strong>{Math.floor(data.stockWeight)} kg</strong>
        </p>
        <small>{data.startDate}</small>
      </ChartTooltip>
    );
  };

  const _isLoading = () => {
    return !isUndefined(data) && data.loading === true;
  };

  return (
    <AreaChart
      title="Stock"
      syncId={syncId}
      data={_getStockWeightData()}
      tooltip={_getCustomTooltip}
      legends={legends}
      rangeLines={rangeLines}
      loading={_isLoading()}
      minValue={0}
      yFormatter={v => (v > 1000 ? `${parseFloat(v / 1000).toFixed(1)}t` : v)}

    />
  );
}

StockWeightChart.propTypes = {
  syncId: PropTypes.string,
  data: PropTypes.object
};

StockWeightChart.defaultProps = {
  syncId: 'syncId',
  data: undefined
};

export default StockWeightChart;
