import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../../../../../../components/shared/Spinner';
import { Modal } from '../../../../../../components/shared/Modal';

const DeleteStockItemModal = props => {
  const {
    deleteItem,
    content,
    confirmText,
    onCancel,
    item,
    coldStorageId,
    loading,
    header
  } = props;

  const submit = () => {
    try {
      deleteItem(item.id, item, 'deleted', coldStorageId);
      onCancel();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return (
    <Modal
      header={header}
      confirmText={confirmText}
      cancelText="Cancel"
      onCancel={onCancel}
      onAccept={submit}
    >
      <Spinner active={loading} overlay />
      {content}
    </Modal>
  );
};

DeleteStockItemModal.propTypes = {
  header: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  content: PropTypes.string,
  deleteItem: PropTypes.func,
  loading: PropTypes.bool,
  id: PropTypes.number,
  coldStorageId: PropTypes.string
};

export default DeleteStockItemModal;
