import api from '../../utils/api';
import * as contactTypes from '../../constants/contact.types';

export const fetchContact = () => async dispatch => {
  dispatch({ type: contactTypes.LOADING });

  try {
    const response = await api.get('/service/contacts');
    dispatch({ type: contactTypes.DONE_FETCH, payload: response });
    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: contactTypes.ERROR_FETCH,
      payload: { error: 'Fetch contact data error' }
    });
    return Promise.reject(e.response);
  }
};

export const sendMessage = ({ contactId, message }) => async dispatch => {
  try {
    const response = await api.post('/service/service-message', {
      contactId,
      message
    });
    dispatch({
      type: contactTypes.DONE_SEND_MESSAGE,
      payload: {
        message: `Successful send!`
      }
    });
    return Promise.resolve(response);
  } catch (e) {
    dispatch({
      type: contactTypes.ERROR_SEND_MESSAGE,
      payload: { error: 'Send message error' }
    });
    return Promise.reject(e.response);
  }
};
