import React, { useEffect } from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import io from 'socket.io-client';
import { fetchSavedLatestLiveAlarms, setLiveAlarms } from "../../../../actions/alarms/live.action";
import { API_URL } from "../../../../config";

const color = "yellow";
function LiveAlarms(props) {

    const {
        projectsData,
        setLiveAlarms,
        liveAlarms,
        fetchSavedLatestLiveAlarms,
        projectUids
    } = props;

    const date = new Date();
    const dateAsString = date.toString();
    const timezone = dateAsString.match(/\(([^)]+)\)$/)[1];


    useEffect(() => {

        fetchSavedLatestLiveAlarms(projectUids)

        const socket = io(API_URL);

        projectsData.forEach(obj => {
            // console.log(obj)
            socket.on(obj.projectUid, message => {
                console.log(100, message)
                setLiveAlarms(obj.projectUid, message);
            });
        });

        return () => socket.disconnect();
        // eslint-disable-next-line
    }, []);


    return (

        <div className="reports">
            {(
                Object.entries(liveAlarms).map((e) => (
                    <>

                        {Object.entries(e[1]).length ?
                            (<> <h1> {`${e[0]} Live Alarms`}</h1>
                                <Table color={color} key={color}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={1} textAlign="left">
                                                <p className="table-text">
                                                    Type
                                                </p>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell colSpan={1} textAlign="left">
                                                <p className="table-text">
                                                    Status
                                                </p>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell colSpan={1} textAlign="left" />
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {Object.entries(e[1]).map((el) => (
                                            <Table.Row>
                                                <Table.Cell> <p className="table-text">{el[0]}</p></Table.Cell>
                                                <Table.Cell>
                                                    <p className="table-text">
                                                        {el[1]?.status === false ? (
                                                            <b className="table-text" style={{ color: "green" }}>
                                                                OK {" "}
                                                            </b>
                                                        ) : (
                                                            <b className="table-text" style={{ color: "red" }}>
                                                                ALERTING {" "}
                                                            </b>
                                                        )}
                                                        Since
                                                        {new Date(el[1].ts).toLocaleString("en-AU", {
                                                            day: "numeric",
                                                            month: "numeric",
                                                            year: "numeric",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            timezone,
                                                        })}
                                                    </p>
                                                </Table.Cell>
                                                <Table.Cell />
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table></>) : null
                        }

                    </>
                ))
            )}
        </div>
    );
}

const mapStateToProps = ({ projects, alarms }) => {
    const { data } = projects;
    const projectUids = data.map((dat) => dat.projectUid)

    return {
        projectsData: data,
        projectUids,
        liveAlarms: alarms.liveAlarms.latestLiveAlarms,
    };
};

export default connect(mapStateToProps, { setLiveAlarms, fetchSavedLatestLiveAlarms })(LiveAlarms);
