import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import ButtonLink from './ButtonLink';
import { MOBILE_SIZE } from '../../constants/responsive';
import CodeIcon from '@material-ui/icons/Code';

const RangeDatePicker = props => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [, setLastFocusedInput] = useState(null);
  // const [lastFocusedEndDate, setLastFocusedEndDate] = useState(null);
  // const [lastFocusedStartDate, setLastFocusedStartDate] = useState(null);
  // const [endOutsideDate, setEndOutsideDate] = useState(null);

  const [startDateValue, setStartDate] = useState(props.starterDate ? props.starterDate : moment().subtract(1, 'day'));
  const [endDateValue, setEndDate] = useState(props.enderDate ? props.enderDate : moment());
  const handleDatesChange = (data) => {
    const { startDate, endDate } = data;

    // if (lastFocusedInput === focusedInput && focusedInput === 'endDate' && lastFocusedEndDate === endDate) {
    // console.log(endDate, startDate, focusedInput, lastFocusedInput);
    //   setStartDate(endDate);
    //   setEndDate(endDate);
    //   handleAccept()
    // } else if (lastFocusedInput === focusedInput && focusedInput === 'startDate' && lastFocusedStartDate === startDate) {
    //   console.log(endDate, startDate, focusedInput, lastFocusedInput);
    //   setStartDate(startDate);
    //   setEndDate(startDate);
    //   handleAccept()
    if (focusedInput === 'startDate' && endDate !== null) {

      setStartDate(startDate);
      setEndDate(endDate);
      setFocusedInput('endDate')
    } else if (focusedInput === 'endDate' && endDate !== null) {

      setStartDate(startDate);
      setEndDate(endDate);
      setFocusedInput('endDate');
    } else if (endDate === null) {
      setStartDate(startDate);
      setEndDate(startDate);
    }


    if (focusedInput === 'endDate') {
      // setLastFocusedEndDate(endDate)
    } else if (focusedInput === 'startDate') {
      // setLastFocusedStartDate(startDate);

    }
    setLastFocusedInput(focusedInput)


  };

  const handleAccept = () => {
    setFocusedInput(null);
    // setLastFocusedStartDate(null);
    // setLastFocusedEndDate(null);
    setLastFocusedInput(null)
    props.onAccept({
      startDate: startDateValue,
      endDate: endDateValue
    });
  };

  const handleCancel = () => {
    setFocusedInput(undefined);
  };

  const handleFocusChange = focusInput => {
    if (startDateValue == null) {
      setFocusedInput('startDate')
    } else {
      setFocusedInput(focusInput);
    }

  };

  const outOfRangeDate = day => {
    return !isInclusivelyBeforeDay(day, moment());
  };

  const isMobileWithSize = window.innerWidth < MOBILE_SIZE;

  const customDateArrow = () => {
    return <CodeIcon />;
  }

  return (
    <div className="date-range-picker">
      <DateRangePicker
        numberOfMonths={isMobileWithSize ? 1 : 2}
        keepOpenOnDateSelect
        startDateId="startId"
        endDateId="endId"
        startDate={startDateValue}
        withPortal={isMobileWithSize}
        endDate={endDateValue}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        isOutsideRange={() => null}
        displayFormat={props.format}
        customArrowIcon={customDateArrow()}
        startDatePlaceholderText="dd/mm"
        endDatePlaceholderText="dd/mm"
        isDayBlocked={outOfRangeDate}
        onClose={handleAccept}
        calendarInfoPosition="bottom"
        hideKeyboardShortcutsPanel={true}
        minimumNights={0}
        enableOutsideDays
        renderCalendarInfo={() => (
          <div className="date-range-picker__buttons">
            <ButtonLink tag="secondary" onClick={handleCancel}>
              Cancel
            </ButtonLink>
            <ButtonLink onClick={handleAccept}>Apply</ButtonLink>
          </div>
        )}
      />
    </div>
  );
};

RangeDatePicker.defaultProps = {
  onChange: () => null,
  onAccept: () => null,
  format: 'DD/MM'
};

RangeDatePicker.propTypes = {
  onChange: PropTypes.func,
  onAccept: PropTypes.func,
  format: PropTypes.string
};

export default RangeDatePicker;
