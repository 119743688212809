import {
  USER_INSIGHTS_FETCHING,
  USER_INSIGHTS_FETCH_SUCCESS,
} from "../../constants/userInsights.types";
import api from "../../utils/api";

export const fetchUserInsights = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: USER_INSIGHTS_FETCHING,
    });

    const getUserCompanies = api.get("/user/insights");
    const getAllUserInsights = api.get(
      `/user-behaviour-logging/all?start=${startDate}&end=${endDate}`
    );
    const getSingleUserInsights = api.get(
      `/user-behaviour-logging/single?start=${startDate}&end=${endDate}`
    );
    const getUsersWithoutCompanies = api.get(`user/insights/no-company`);
    const commonExit = api.get(
      `/user-behaviour-logging/common-exit?start=${startDate}&end=${endDate}`
    );
    const commonEntry = api.get(
      `/user-behaviour-logging/common-entry?start=${startDate}&end=${endDate}`
    );
    const mostFrequent = api.get(
      `/user-behaviour-logging/most-frequent?start=${startDate}&end=${endDate}`
    );

    const requestArray = [
      getUserCompanies,
      getAllUserInsights,
      getSingleUserInsights,
      getUsersWithoutCompanies,
      commonExit,
      commonEntry,
      mostFrequent,
    ];

    Promise.all(requestArray)
      .then((resAray) => {
        dispatch({
          type: USER_INSIGHTS_FETCH_SUCCESS,
          payloadCompanies: [...resAray[0].data, ...resAray[3].data],
          payloadAllUsers: resAray[1].data,
          payloadSingleUsers: resAray[2].data,
          payloadCommonExit: resAray[4].data,
          payloadCommonEntry: resAray[5].data,
          payloadMostFrequent: resAray[6].data,
        });
      })
      .catch();
  };
};
