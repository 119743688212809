/* eslint-disable */

import React, { useState, useMemo, Fragment, useRef } from "react";
import moment from "moment";
import CostsReport from "./components/ConstsReport";
import EnergyConsumptionReport from "./components/EnergyConsumptionReport/EnergyConsumptionReport";
import currencyTypes from "../../../../constants/currencyTypes";
import Spinner from "../../../../components/shared/Spinner";
import "./_reports.scss";

import { UNITS_REPORT } from "../../../../constants/analyticsEventTypes";
import ReactGA from "react-ga";
import { Dropdown, Button, Modal, Input } from "semantic-ui-react";
import api from "../../../../utils/api";

const Reports = React.memo(
  ({
    getReportAccess,
    loadingReportAccess,
    reportAccessCost,
    energyConsumption,
    projectId,
    fetchProjectEnergyConsumption,
    fetchPricePerKg,
    pricePerKg,
    pricePerKgLoading,
    fetchMinThroughput,
    minThroughput,
    minThroughputLoading,
    fetchPricingCalculate,
    fetchTotalHandledProduce,
    pricingCalculateLoading,
    pricingCalculate,
    totalHandledProduce,
    totalHandledProduceLoading,
    energyConsumptionLoading,
    auth,
    reportsSubscriptionReducer,
    fetchReportSubscriptionStatus,
    saveReportSubscriptionStatus,
    updateReportSubscriptionStatus,
  }) => {
    const [pickerStartDate, setPickerStartDate] = useState(
      moment()
        .subtract(1, "days")
        .startOf("day")
    );
    const [pickerEndDate, setPickerEndDate] = useState(moment());
    const [selectedPeriod, setSelectedPeriod] = useState(false);
    const [currency, setCurrency] = useState(currencyTypes[0]);
    const [sending, setSending] = useState(false);
    const [sendingTimer, setSendingTimer] = useState(false);
    const [settingFrequency, setSettingFrequency] = useState(false);
    const [selectedFrequency, setSelectedFrequency] = useState(
      reportsSubscriptionReducer.emailFrequency
        ? reportsSubscriptionReducer.emailFrequency
        : "Never"
    );
    const _getReportsDates = (selectedPeriod) => {
      if (selectedPeriod) {
        const endDateDefault = moment();
        setSelectedPeriod(true);

        return {
          startDate: selectedPeriod.startDate.clone().startOf("day"),
          endDate:
            selectedPeriod.endDate &&
              selectedPeriod.endDate.isBefore(endDateDefault)
              ? selectedPeriod.endDate.clone()
              : endDateDefault,
        };
      } else {
        return {
          startDate: pickerStartDate,
          endDate: pickerEndDate,
        };
      }
    };

    const savedInterval = localStorage.getItem("dataInterval");

    useMemo(() => {
      const dates = _getReportsDates();
      getReportAccess(projectId).then((reportAccess) => {
        if (reportAccess && reportAccess.CostsReport === true) {
          fetchPricingCalculate({ dates, projectId: projectId });
          fetchPricePerKg({ projectId: projectId });
          fetchMinThroughput({ projectId: projectId });
          fetchTotalHandledProduce({ dates, projectId: projectId });
        }
      });

      fetchProjectEnergyConsumption({
        dates,
        projectId: projectId,
        interval: savedInterval ? savedInterval : "Today",
      });

      // if (auth.id !== null) {
      fetchReportSubscriptionStatus(auth.id, projectId);
      // }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadPdfDocument = () => {
      setSending(true);
      setSendingTimer(true);
      setTimeout(() => {
        setSendingTimer(false);
      }, 5000);
      api
        .post("mail", {
          email: auth.email,
          ...energyConsumption,
          projectId,
          interval: localStorage.getItem("dataInterval") ? localStorage.getItem("dataInterval").charAt(0).toLowerCase() + localStorage.getItem("dataInterval").slice(1) : "today"

        })
        .then(() => {
          setSending(false);
        })
        .catch(() => {
          setSending(false);
        });
    };

    const saveOrUpdateReportSubscriptionStatus = () => {
      reportsSubscriptionReducer.id
        ? updateReportSubscriptionStatus(
          auth.id,
          selectedFrequency,
          auth.email,
          projectId
        )
        : saveReportSubscriptionStatus(
          auth.id,
          selectedFrequency,
          auth.email,
          projectId
        );
    };

    const getDataInterval = (event, { value }) => {
      ReactGA.event({
        category: UNITS_REPORT.Category,
        action: UNITS_REPORT.ReportDataIntervalSelect,
        label: UNITS_REPORT.ReportDataIntervalSelect,
      });
      //console.log(value);
      localStorage.setItem("dataInterval", value);
      fetchProjectEnergyConsumption({
        dates: null,
        projectId: projectId,
        interval: value,
      });

      //let bird_name = event.target.textContent;
      //console.log(projectId);
    };

    const subscriptionFrequency = [
      { key: 0, text: "Never", value: "Never" },
      { key: 1, text: "Weekly", value: "Weekly" },
      { key: 2, text: "Monthly", value: "Monthly" },
    ];


    let reportsDurationArray = [
      { key: 0, text: "Today", value: "today" },
      { key: 1, text: "Yesterday", value: "yesterday" },
      { key: 2, text: "One Week", value: "oneWeek" },
      { key: 3, text: "One Month", value: "oneMonth" },
      { key: 4, text: "Two Months", value: "twoMonths" },
      { key: 5, text: "One Year", value: "oneYear" },
    ];


    return (
      <>
        <div className="reports">
          {loadingReportAccess ? (
            <Spinner overlay />
          ) : (
            <Fragment>
              <div className="reports-select-container">
                {/* <ReportDatePicker onRangePickerAccept={_onRangePickerAccept} />
            {reportAccessCost && <ReportCurrencyPicker
              setCurrency={_setCurrencyValue}
              currencyOptions={currencyTypes}
              currency={currency} />
            } */}
                <div className="report-datepicker">

                  <div className="report-datepicker-tablet">

                    <div className="report-datepicker-tablet-x">
                      <p className="table-text">Select Data Interval:</p>
                      <Dropdown
                        placeholder="Data Interval"
                        search
                        selection
                        options={reportsDurationArray}
                        defaultValue={savedInterval ? savedInterval : "Today"}
                        onChange={getDataInterval}
                        className="button-full-width"
                      // fluid
                      />
                    </div>
                    <div className="report-datepicker-tablet-x">
                      {sending || sendingTimer ? (
                        <Button
                          text="Sending Email..."
                          loading
                          color="yellow"
                          size="big"
                          className="button-full-width"
                        ></Button>
                      ) : (
                        <Button
                          onClick={() => {
                            downloadPdfDocument();
                          }}
                          color="yellow"
                          size="big"
                          className="button-full-width"
                        >

                          Send Report to email

                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div id="divToDownload">
                {reportAccessCost && (
                  <CostsReport
                    selectedPeriod={selectedPeriod}
                    pickerStartDate={pickerStartDate}
                    pickerEndDate={pickerEndDate}
                    currency={currency}
                    totalHandledProduce={totalHandledProduce}
                    totalHandledProduceLoading={totalHandledProduceLoading}
                    pricePerKgLoading={pricePerKgLoading}
                    pricePerKg={pricePerKg}
                    minThroughputLoading={minThroughputLoading}
                    minThroughput={minThroughput}
                  />
                )}
                <EnergyConsumptionReport
                  energyConsumptionLoading={energyConsumptionLoading}
                  energyConsumption={energyConsumption}
                  pricingCalculateLoading={pricingCalculateLoading}
                  pricingCalculate={pricingCalculate}
                />
              </div>
            </Fragment>
          )}
        </div>
        <hr className="line-break" />

        <div className="reports">
          <div className="reports-select-container">

            <div className="report-datepicker">

              <div className="report-datepicker-tablet">

                <div className="report-datepicker-tablet-x">
                  <p>Subscribe to periodic reports:</p>

                  <Dropdown
                    placeholder="Report frequency"
                    search
                    selection
                    options={subscriptionFrequency}
                    onChange={(event, { value }) => {
                      setSelectedFrequency(value);
                    }}
                    defaultValue={selectedFrequency}
                    className="button-full-width"
                  />
                </div>
                <div className="report-datepicker-tablet-x">
                  {reportsSubscriptionReducer.postingReportSubscription ? (
                    <Button
                      text="Subscribing..."
                      loading
                      color="yellow"
                      size="big"
                      className="button-full-width"
                    >
                      Subscribing
                    </Button>
                  ) : reportsSubscriptionReducer.id ? (


                    <Button
                      onClick={() => {
                        downloadPdfDocument();
                      }}
                      color="yellow"
                      size="big"
                      className="button-full-width"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button

                      onClick={() => saveOrUpdateReportSubscriptionStatus()}
                      color="yellow"
                      size="big"
                      className="button-full-width"
                    >
                      Save{" "}
                    </Button>
                  )

                  }
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* <div>
          Receive periodic report:{" "}
          <Dropdown
            search
            selection
            options={subscriptionFrequency}
            onChange={(event, { value }) => {
              setSelectedFrequency(value);
            }}
            defaultValue={selectedFrequency}
          />{" "}
          {reportsSubscriptionReducer.postingReportSubscription ? (
            <Button loading color="yellow" size="big">
              {" "}
              Sending Email...{" "}
            </Button>
          ) : reportsSubscriptionReducer.id ? (
            <Button
              style={{ width: 150 }}
              onClick={() => saveOrUpdateReportSubscriptionStatus()}
              color="yellow"
              size="big"
            >
              Update{" "}
            </Button>
          ) : (
            <Button
              style={{ width: 150 }}
              onClick={() => saveOrUpdateReportSubscriptionStatus()}
              color="yellow"
              size="big"
            >
              Save{" "}
            </Button>
          )}
        </div> */}
      </>
    );
  }
);

export default Reports;
