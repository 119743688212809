import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as stockActions from '../../actions/unitDetails/stock.actions';
import * as actionsUnits from '../../actions/units.actions'

import Component from './Stock';
import { getProjectId } from '../../utils/auth/user';

const mapStateToProps = (state, props) => {
  const coldStorageId = Number(
    (props.match.params || { coldStorageId: null }).coldStorageId
  );
  const projectId = props.match.params.projectId || getProjectId() || null;

  


  return {
    data: state.unitDetails.stock.data || {},
    coldStorageId: coldStorageId,
    projectId: Number(projectId),
    sync: state.unitDetails.stock.sync || false,
    loading: state.unitDetails.stock.loading,
    unitsData: state.units,
    projects: state.projects.data,
  
  };
};

const mapDispatchToProps = dispatch => ({
  fetchStockData: bindActionCreators(stockActions.fetchStockData, dispatch),
  addStockItem: bindActionCreators(stockActions.addStockItem, dispatch),
  updateStockItem: bindActionCreators(stockActions.updateStockItem, dispatch),
  splitStockItem: bindActionCreators(stockActions.splitStockItem, dispatch),
  fetchUnitsWithId: bindActionCreators(actionsUnits.fetchUnitsWithId, dispatch),
});

const ComponentWithRouter = withRouter(Component);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithRouter);
