import React from 'react';
import PropTypes from 'prop-types';

const RadioInput = ({ className = '', ...rest }) => {
  return <input className={`${className}`} {...rest} />;
};

RadioInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func
};

RadioInput.defaultProps = {
  type: 'radio'
};

export default RadioInput;
