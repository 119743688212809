import React, { Fragment, useEffect } from "react";
import { chain } from "lodash";
import moment from "moment";

import TemperatureChart from "./charts/TemperatureChart";
import HumidityChart from "./charts/HumidityChart";
import EnergyConsumptionChart from "./charts/EnergyConsumptionChart";
import EthyleneChart from "./charts/EthyleneChart";
import DoorOpenDoorClosedNewChart from "./charts/DoorOpenDoorClosedNewChart";
import StockWeightChart from "./charts/StockWeightChart";
import statisticsTypes from "../../../../../../constants/statisticsTypes";
import CollapseSection from "../../../../../../components/shared/CollapseSection";
import {
  ROOM,
  ENERGY,
  WEATHER,
} from "../../../../../../constants/statistics-section.types";
import ExternalTempChart from "./charts/ExternalTempChart";
import ExternalHumChart from "./charts/ExternalHumChart";
import SunIrradiationChart from "./charts/SunIrradiationChart";
import BatteryChargeChart from "./charts/BatteryChargeChart";
import RainGaugeChart from "./charts/RainGaugeChart";
import NumberIndicatorModule from "../../../../../../components/Charts/NumberIndicatorModule";
import * as colors from "../../../../../../constants/colors";

import { CSV_DATA_EXPORT } from "../../../../../../constants/analyticsEventTypes";
import ReactGA from "react-ga"




// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactExport from "react-export-excel"
import Co2Chart from "./charts/CO2Chart";



// const yourModuleName = require('react-export-excel');
// 	const { ReactExport } = yourModuleName;

// const ReactExport = require('react-export-excel');
// import { Button } from "semantic-ui-react";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TechnicianStatistics = ({
  data,
  timePeriod,
  dataProfile,
  csvData,
  customChartSelectedValues,
  onToggleCollapse,
  openedSections,
  onToggleFurtherDataCollapse,
  setCustomChartSelectedValue,
}) => {
  useEffect(() => {
    onToggleCollapse(ROOM);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasDataProfileAccess = (name) => {
    const restrictedValues = Object.keys(
      chain(dataProfile)
        .omit("id", "name")
        .omitBy((value) => value === false)
        .value()
    );

    return dataProfile && restrictedValues.includes(name);
  };

  const _isOpened = (sectionType) =>
    !!openedSections.find((value) => value === sectionType);
  function downloadWeatherStationData() {
    ReactGA.event({
      category: CSV_DATA_EXPORT.Category,
      action: CSV_DATA_EXPORT.DownloadWeatherStationData,
      label: CSV_DATA_EXPORT.DownloadWeatherStationData,
    });

    var rain_data = csvData[statisticsTypes.rainGauge]
      ? csvData[statisticsTypes.rainGauge].list
      : null;
    var external_temp = csvData[statisticsTypes.externalTemp]
      ? csvData[statisticsTypes.externalTemp].list
      : null;
    var external_hum = csvData[statisticsTypes.externalHum]
      ? csvData[statisticsTypes.externalHum].list
      : null;
    var sun_irradiation = csvData[statisticsTypes.sunIrradiation]
      ? csvData[statisticsTypes.sunIrradiation].list
      : null;

    let external_hum_dataset,
      sun_irradiation_dataset,
      rain_dataset,
      external_temp_dataset;
    let all_data = [];

    if (rain_data && external_temp && external_hum && sun_irradiation) {
      external_temp.map((data, index) => {
        if (typeof rain_data[index] !== "undefined") {
          rain_dataset = rain_data[index];
        }
        external_hum_dataset = external_hum[index];
        sun_irradiation_dataset = sun_irradiation[index];
        external_temp_dataset = external_temp[index];

        var date_obj = moment
          .utc(data.recDateTime)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
          .split("T");
        var actual_date = date_obj[0];
        var from_time = date_obj[1];
        var to_time = moment
          .utc(data.recDatetimeEnd)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
          .split("T")[1];

        var split_from_date = from_time.split(".")[0];
        if (to_time) {
          var split_to_date = to_time.split(".")[0];
        }

        all_data.push({
          rain_gauge: rain_dataset.value ? rain_dataset.value.toFixed(2) : "N/A",
          external_temperature: external_temp_dataset.value
            ? external_temp_dataset.value.toFixed(2)
            : "N/A",
          external_humidity: external_hum_dataset.value
            ? external_hum_dataset.value.toFixed(2)
            : "N/A",
          sun_irradiation: sun_irradiation_dataset.value
            ? sun_irradiation_dataset.value.toFixed(2)
            : "N/A",
          date: actual_date,
          from: split_from_date,
          to: split_to_date,
        });
        return all_data;
      });

      return (
        <ExcelFile
          element={
            <button
              style={{
                backgroundColor: "orange",
                color: "white",
                textDecoration: "none",
                padding: "6px 12px",
                marginBottom: "0",
                fontSize: "14px",
                fontWeight: "normal",
                lineHeight: "1.428571429",
                textAlign: "center",
                whiteSpace: "nowrap",
                verticalAlign: "middle",
                cursor: "pointer",
                border: "1px solid transparent",
                borderRadius: "4px",
                userSelect: "none",
              }}
            >
              Download this data as spreadsheet
            </button>
          }
        >
          <ExcelSheet data={all_data} name="Weather Station Data">
            <ExcelColumn label="Rainfall(mm2)" value="rain_gauge" />
            <ExcelColumn
              label="External Temperature(°C)"
              value="external_temperature"
            />
            <ExcelColumn
              label="External Humidity(%)"
              value="external_humidity"
            />
            <ExcelColumn
              label="Sun Irradiation(w/m2)"
              value="sun_irradiation"
            />
            <ExcelColumn label="Date" value="date" />
            <ExcelColumn label="Start Time" value="from" />
            <ExcelColumn label="End Time" value="to" />
          </ExcelSheet>
        </ExcelFile>
      );
    }
  }

  function downloadTempHumidityData() {


    ReactGA.event({
      category: CSV_DATA_EXPORT.Category,
      action: CSV_DATA_EXPORT.DownloadTempHumData,
      label: CSV_DATA_EXPORT.DownloadTempHumData,
    });
    var temp_data = csvData[statisticsTypes.cellTemp]
      ? csvData[statisticsTypes.cellTemp].list
      : null;
    var humidity_data = csvData[statisticsTypes.cellHum]
      ? csvData[statisticsTypes.cellHum].list
      : null;
    var door_data = data[statisticsTypes.doorRoom] ? data[statisticsTypes.doorRoom].list : null

    var piercing_probe = data[statisticsTypes.piercingProbeTemp] ? data[statisticsTypes.piercingProbeTemp].list : []
    var piercing_probe_2 = data[statisticsTypes.piercingProbeTemp2] ? data[statisticsTypes.piercingProbeTemp2].list : []
    var temp_set_point_data = data[statisticsTypes.tempSetpoint] ? data[statisticsTypes.tempSetpoint].list : null
    var hum_set_point_data = data[statisticsTypes.humSetpoint] ? data[statisticsTypes.humSetpoint].list : null
    let hum_dat;
    let door_dat;
    let temp_set_point_dat;
    let hum_set_point_dat;
    let piercing_prob;
    let piercing_prob_2;
    let all_data = [];

    if (temp_data && humidity_data && door_data && data[statisticsTypes?.tempSetpoint]?.loading === false) {

      // eslint-disable-next-line
      temp_data.map((data, index) => {
        hum_dat = humidity_data[index];
        door_dat = door_data[index];
        temp_set_point_dat = temp_set_point_data ? temp_set_point_data[index] : null;
        hum_set_point_dat = hum_set_point_data ? hum_set_point_data[index] : null;

        piercing_prob = piercing_probe ? piercing_probe[index] : null
        piercing_prob_2 = piercing_probe_2 ? piercing_probe_2[index] : null;

        var date_obj = moment
          .utc(data.recDateTime)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
          .split("T");
        var actual_date = date_obj[0];
        var from_time = date_obj[1];
        var to_time = moment
          .utc(data.recDatetimeEnd)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
          .split("T")[1];

        var split_from_date = from_time.split(".")[0];
        if (to_time) {
          var split_to_date = to_time.split(".")[0];
        }

        all_data.push({
          temperature: data && data.value ? data.value.toFixed(2) : "N/A",
          pulpTemp: piercing_prob && piercing_prob.value ? piercing_prob.value.toFixed(2) : "N/A",
          pulpTemp2: piercing_prob_2 && piercing_prob_2.value ? piercing_prob_2.value.toFixed(2) : "N/A",
          tempSetpoint: temp_set_point_dat && temp_set_point_dat.value ? temp_set_point_dat.value.toFixed(2) : "N/A",
          humidity: hum_dat && hum_dat.value ? hum_dat.value.toFixed(2) : "N/A",
          humSetpoint: hum_set_point_dat && hum_set_point_dat.value ? hum_set_point_dat.value.toFixed(2) : "N/A",
          door: door_dat && door_dat.value ? door_dat.value === 1 ? 'Open' : 'Closed' : "N/A",
          date: actual_date,
          from: split_from_date,
          to: split_to_date,
        });
        // return all_data;
      });

      // console.log(1, all_data);

      return (
        <ExcelFile
          element={
            <button
              style={{
                backgroundColor: "orange",
                color: "white",
                textDecoration: "none",
                padding: "6px 12px",
                marginBottom: "0",
                fontSize: "14px",
                fontWeight: "normal",
                lineHeight: "1.428571429",
                textAlign: "center",
                whiteSpace: "nowrap",
                verticalAlign: "middle",
                cursor: "pointer",
                border: "1px solid transparent",
                borderRadius: "4px",
                userSelect: "none",
              }}
            >
              Download this data as spreadsheet
            </button>
          }
        >
          <ExcelSheet data={all_data} name="Temperature And Humidity Data">
            <ExcelColumn label="Date" value="date" />
            <ExcelColumn label="Temperature(°C)" value="temperature" />
            <ExcelColumn label="Pulp Temperature(°C)" value="pulpTemp" />
            <ExcelColumn label="Pulp Temperature 2(°C)" value="pulpTemp2" />
            <ExcelColumn label="Temperature Setpoint(°C)" value="tempSetpoint" />
            <ExcelColumn label="Humidity(%)" value="humidity" />
            <ExcelColumn label="Humidity Setpoint(%)" value="humSetpoint" />
            <ExcelColumn label="Door Status" value="door" />
            <ExcelC-olumn label="Start Time" value="from" />
            <ExcelColumn label="End Time" value="to" />
          </ExcelSheet>
        </ExcelFile>
      );
    }
  }

  return (
    <Fragment>
      <CollapseSection
        name={ROOM}
        isOpened={_isOpened(ROOM)}
        onToggleCollapse={onToggleCollapse}
      >
        <div className="statistics__charts-row">
          <NumberIndicatorModule
            title="Average Room Temperature"
            subTitle="for the chosen time span"
            color={colors.ORANGE}
            entity="°C"
            value={
              data[statisticsTypes.cellTemp] &&
              data[statisticsTypes.cellTemp].averageValue
            }
          ></NumberIndicatorModule>
          {
            dataProfile?.humAmbient && (
              <NumberIndicatorModule
                title="Average Humidity"
                subTitle="for the chosen time span"
                color={colors.FIR_GREEN}
                entity="%"
                value={
                  data[statisticsTypes.cellHum] &&
                  data[statisticsTypes.cellHum].averageValue
                }
              ></NumberIndicatorModule>

            )
          }
        </div>
        <div className="statistics__charts-row">
          {hasDataProfileAccess(statisticsTypes.ethyleneProbe) && (
            <NumberIndicatorModule
              title="Average Ethylene"
              color={colors.ORANGE}
              subTitle="for the chosen time span"
              entity="PPM"
              value={
                data[statisticsTypes.ethyleneProbe] &&
                data[statisticsTypes.ethyleneProbe].averageValue
              }
            ></NumberIndicatorModule>
          )}
          {hasDataProfileAccess(statisticsTypes.CO2ambient) && (
            <NumberIndicatorModule
              title="Average CO2"
              color={colors.ORANGE}
              subTitle="for the chosen time span"
              entity="%"
              value={
                data[statisticsTypes.CO2ambient] &&
                data[statisticsTypes.CO2ambient].averageValue/10000
              }
            ></NumberIndicatorModule>
          )}
        </div>
        <div className="statistics__charts-row">
          <TemperatureChart
            syncId="statistics"
            cellTempStatisticsType={statisticsTypes.cellTemp}
            cellTempData={data[statisticsTypes.cellTemp]}
            piercingProbeData={data[statisticsTypes.piercingProbeTemp]}
            piercingProbeData2={data[statisticsTypes.piercingProbeTemp2]}
            tempSetpoint={statisticsTypes.tempSetpoint}
            tempSetpointData={data[statisticsTypes.tempSetpoint]}
            timePeriod={timePeriod}
            dataProfile={dataProfile}
          />
          {
            dataProfile?.humAmbient && (
              <HumidityChart
                syncId="statistics"
                statisticsType={statisticsTypes.cellHum}
                data={data[statisticsTypes.cellHum]}
              // humSetpoint={statisticsTypes.humSetpoint}
              // humSetpointData={data[statisticsTypes.humSetpoint]}
              />
            )
          }

        </div>
        <div className="statistics__charts-row">
          {hasDataProfileAccess(statisticsTypes.ethyleneProbe) && (
            <EthyleneChart
              syncId="statistics"
              statisticsType={statisticsTypes.ethyleneProbe}
              data={data[statisticsTypes.ethyleneProbe]}
            />
          )}
          {hasDataProfileAccess(statisticsTypes.CO2ambient) && (
            <Co2Chart
              syncId="statistics"
              statisticsType={statisticsTypes.CO2ambient}
              data={data[statisticsTypes.CO2ambient]}
            />
          )}

        </div>
        <div className="statistics__charts-row">
          {dataProfile?.doorRoom && (
            <DoorOpenDoorClosedNewChart data={data[statisticsTypes.doorRoom]} />
          )}
        </div>
        <div className="statistics__charts-row">
          <StockWeightChart
            syncId="statistics"
            statisticsType={statisticsTypes.stockWeights}
            data={data[statisticsTypes.stockWeights]}
          />
        </div>
        <div className="download-excel-button">
          {(downloadTempHumidityData())}
        </div>
      </CollapseSection>
      <CollapseSection
        name={ENERGY}
        isOpened={_isOpened(ENERGY)}
        onToggleCollapse={onToggleCollapse}
      >
        <div className="statistics__charts-row">
          <NumberIndicatorModule
            title="Total Main Consumption"
            color={colors.ORANGE}
            subTitle="for the chosen time span"
            entity="kWh"
            value={
              data[statisticsTypes.mainEnergyConsumption] &&
                data[statisticsTypes.mainEnergyConsumption].consumedValue
                ? parseInt(
                  data[statisticsTypes.mainEnergyConsumption].consumedValue
                )
                : 0
            }
          ></NumberIndicatorModule>
          <NumberIndicatorModule
            title="Total Room Consumption"
            color={colors.FIR_GREEN}
            subTitle="for the chosen time span"
            entity="kWh"
            value={
              data[statisticsTypes.roomEnergyConsumption] &&
                data[statisticsTypes.roomEnergyConsumption].consumedValue
                ? parseInt(
                  data[statisticsTypes.roomEnergyConsumption].consumedValue
                )
                : 0
            }
          ></NumberIndicatorModule>
          <NumberIndicatorModule
            title="Total Compressor Consumption"
            color={colors.RED}
            subTitle="for the chosen time span"
            entity="kWh"
            value={
              data[statisticsTypes.compressorEnergyConsumption] &&
                data[statisticsTypes.compressorEnergyConsumption].consumedValue
                ? parseInt(
                  data[statisticsTypes.compressorEnergyConsumption]
                    .consumedValue
                )
                : 0
            }
          ></NumberIndicatorModule>
        </div>
        <div className="statistics__charts-row">
          <EnergyConsumptionChart
            syncId="statistics"
            mainEnergyConsumptionStatisticsType={
              statisticsTypes.mainEnergyConsumption
            }
            compressorEnergyConsumptionStatisticsType={
              statisticsTypes.compressorEnergyConsumption
            }
            roomEnergyConsumptionStatisticsType={
              statisticsTypes.roomEnergyConsumption
            }
            data={data}
            timePeriod={timePeriod}
          />
          {dataProfile?.battery && (
            <BatteryChargeChart
              syncId="statistics"
              statisticsType={statisticsTypes.batteryCharge}
              data={data[statisticsTypes.batteryCharge]}
              timePeriod={timePeriod}
            />
          )}
        </div>
      </CollapseSection>
      {/* <CollapseSection name={GRID} isOpened={_isOpened(GRID)} onToggleCollapse={onToggleCollapse}>
      <div className="statistics__charts-row">
          <NumberIndicatorModule
              title="Accumulated Consumption"
              subTitle="for the chosen time span"
              color={colors.ORANGE}
              entity="wh"
              value={data[statisticsTypes.mainEnergyConsumption] && data[statisticsTypes.mainEnergyConsumption].accumulatedValue}
              >
          </NumberIndicatorModule> 
        </div>
        <div className="statistics__charts-row">
          <PowerChart
            syncId="statistics"
            data={data} />
        </div>
        <div className="statistics__charts-row">
          <EnergyConsumptionChart
            syncId="statistics"
            data={data} />
        </div>
      </CollapseSection> */}
      {/* <CollapseSection name={ENERGY_STORAGE} isOpened={_isOpened(ENERGY_STORAGE)} onToggleCollapse={onToggleCollapse}>
        <div className="statistics__charts-row">
          <InverterVoltageChart
            syncId="statistics"
            statisticsType={statisticsTypes.inverterVoltage}
            data={data[statisticsTypes.inverterVoltage]} />
          <BatteryVoltageChart
            syncId="statistics"
            statisticsType={statisticsTypes.batteryVoltage}
            data={data[statisticsTypes.batteryVoltage]} />
        </div>
        <div className="statistics__charts-row">
          <BatteryCurrentChart
            syncId="statistics"
            data={data[statisticsTypes.batteryCurrent]} />
          <BatteryTempChart
            syncId="statistics"
            data={data[statisticsTypes.batteryTemp]} />
        </div>
        <div className="statistics__charts-row">
          <ThermalStorageChart
            syncId="statistics"
            statisticsType={statisticsTypes.thermalStorageColdTemp}
            tempWaterTank1Data={data[statisticsTypes.tempWaterTank1]}
            tempWaterTank2Data={data[statisticsTypes.tempWaterTank2]}
            thermalStorageColdTempData={data[statisticsTypes.thermalStorageColdTemp]}
            timePeriod={timePeriod}
            dataProfile={dataProfile} />
          <BatteryChargeChart
            syncId="statistics"
            statisticsType={statisticsTypes.batteryCharge}
            data={data[statisticsTypes.batteryCharge]} />
        </div>
      </CollapseSection> */}
      {/* {hasDataProfileAccess(statisticsTypes.energyPvAux) &&
        <CollapseSection name={PHOTOVOLTAIC} isOpened={_isOpened(PHOTOVOLTAIC)} onToggleCollapse={onToggleCollapse}>
          <div className="statistics__charts-row">
            <EnergyPvAuxChart
              syncId="statistics"
              statisticsType={statisticsTypes.energyPvAux}
              data={data[statisticsTypes.energyPvAux]} />
          </div>
        </CollapseSection>
      }
      <CollapseSection name={MACHINE} isOpened={_isOpened(MACHINE)} onToggleCollapse={onToggleCollapse}>
        <div className="statistics__charts-row">
          <PressureSwitchChart
            syncId="statistics"
            statisticsType={statisticsTypes.pressSwitch}
            data={data[statisticsTypes.pressSwitch]} />
          <ThrChart
            syncId="statistics"
            statisticsType={statisticsTypes.thr}
            data={data[statisticsTypes.thr]} />
        </div>
        <div className="statistics__charts-row">
          <CprValveChart
            syncId="statistics"
            statisticsType={statisticsTypes.cprValve}
            data={data[statisticsTypes.cprValve]} />
          <EthValveChart
            syncId="statistics"
            statisticsType={statisticsTypes.ethValve}
            data={data[statisticsTypes.ethValve]} />
        </div>
        <div className="statistics__charts-row">
          <PressLpChart
            syncId="statistics"
            statisticsType={statisticsTypes.pressLp}
            data={data[statisticsTypes.pressLp]} />
          <PressHpChart
            syncId="statistics"
            statisticsType={statisticsTypes.pressHp}
            data={data[statisticsTypes.pressHp]} />
        </div>
      </CollapseSection> */}
      <CollapseSection
        name={WEATHER}
        isOpened={_isOpened(WEATHER)}
        onToggleCollapse={onToggleCollapse}
      >
        <div className="statistics__charts-row">
          <NumberIndicatorModule
            title="Accumulated Rainfall"
            subTitle="for the chosen time span"
            color={colors.CYAN}
            entity="mm2"
            value={
              data[statisticsTypes.rainGauge] &&
              data[statisticsTypes.rainGauge].accumulatedValue
            }
          ></NumberIndicatorModule>
        </div>
        <div className="statistics__charts-row">
          <ExternalTempChart
            syncId="statistics"
            data={data[statisticsTypes.externalTemp]}
          />
          <ExternalHumChart
            syncId="statistics"
            data={data[statisticsTypes.externalHum]}
          />
        </div>
        <div className="statistics__charts-row">
          <SunIrradiationChart
            syncId="statistics"
            data={data[statisticsTypes.sunIrradiation]}
          />
          <RainGaugeChart
            syncId="statistics"
            data={data[statisticsTypes.rainGauge]}
          />
        </div>
        <br></br>
        {downloadWeatherStationData()}
      </CollapseSection>
      {/* <CollapseSection name={FURTHER_DATA} isOpened={_isOpened(FURTHER_DATA)} onToggleCollapse={onToggleFurtherDataCollapse}>
        <div className="statistics__charts-row">
          <TechnicianChart
            syncId="statistics"
            setSelectedValue={(value) => setCustomChartSelectedValue(value, 0)}
            selectedValue={customChartSelectedValues[0]}
            data={data} />
          <TechnicianChart
            syncId="statistics"
            setSelectedValue={(value) => setCustomChartSelectedValue(value, 1)}
            selectedValue={customChartSelectedValues[1]}
            data={data} />
        </div>
      </CollapseSection> */}
    </Fragment>
  );
};

export default TechnicianStatistics;
