import {
  DONE_FETCHING_SITE_ALARMS,
  FETCHING_SITE_ALARMS,
} from "../../constants/alarms.types";

import api from "../../utils/api";

export const fetchSite = (selectedPeriod, sites) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_SITE_ALARMS,
    });

    api
      .get(`site/sitealarms/${selectedPeriod}`, {
        params: {
          sites,
        },
      })

      .then((res) => {
        dispatch({
          type: DONE_FETCHING_SITE_ALARMS,
          payload: res.data,
        });
      })
      .catch((err) => {});
  };
};
