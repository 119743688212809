import * as unitsTypes from "../../constants/unitDetails.types";
import { PURGE } from "redux-persist";

const initialState = {
  loading: false,
  finalStats: {},

};

const reducer = {
  [unitsTypes.LOADING_STATISTICS]: (state, action) => {
    // const { coldStorageId } = action;

    return {
      ...state,
      finalCurrentStats: {
        ...(state["finalCurrentStats"] || {}),
        [action.statisticsType]: {
          // ...(state['finalCurrentStats'][action.statisticsType] || {}),
          loading: true,
          sync: true,
        },
      },
    };
  },
  [unitsTypes.DONE_FETCH_STATISTICS]: (state, action) => {
    const {
      // coldStorageId,
      payload: { statisticsType, data },
    } = action;

    return {
      ...state,
      finalCurrentStats: {
        ...(state["finalCurrentStats"] || {}),
        [statisticsType]: {
          ...(state["finalCurrentStats"][statisticsType] || {}),
          list: data.dataResult,
          averageValue: data.averageValue,
          accumulatedValue: data.accumulatedValue,
          consumedValue: data.consumedValue,
          maxValue: data.maxValue,
          loading: false,
        },
      },
    };
  },
  [unitsTypes.ERROR_FETCH_STATISTICS]: (
    state,
    action = { coldStorageId: 0, payload: { error: "Fetching error" } }
  ) => ({
    ...state,
    loading: false,
    finalCurrentStats: {
      ...(state["finalCurrentStats"] || {}),
      error: action.payload.error,
    },
  }),
  [unitsTypes.CLEAN_DATA_STATISTICS]: (
    state,
    action = { coldStorageId: 0, payload: { data: [] } }
  ) => ({
    ...state,
    loading: false,
    finalCurrentStats: {
      data: {},
      sync: false,
    },
  }),
  [PURGE]: () => ({
    ...initialState,
  }),
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
