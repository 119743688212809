import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/alarms/active.actions";
import { refreshToken } from "../../actions/auth.actions";
import Component from "./Initializer";
import { saveBrowserFingerPrint } from "../../actions/browserFingerprint.action";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  refreshToken: bindActionCreators(refreshToken, dispatch),
  fetchActiveAlarms: bindActionCreators(actions.fetchActiveAlarms, dispatch),
  saveBrowserFingerPrint: bindActionCreators(saveBrowserFingerPrint, dispatch),
});

const ComponentWithRouter = withRouter(Component);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithRouter);
