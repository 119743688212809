import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Input,
  Button,
  Icon
} from "semantic-ui-react"
import storeConfig from "../../../store";
import Page from '../../../components/Page/Page';
import { accountConfirm } from '../../../api/sms.api';
import { clearSession } from '../../../utils/auth/session';

function AccountConfirmation(props) {

  const { match } = props;
  const { hash } = match.params || { hash: '' };

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onProceedToLoginClick = () => {
    setLoading(true);
    accountConfirm(hash, password)
      .then(res => {
        setLoading(false);
        props.history.push('/auth/login')
      })
      .catch(err => {
        setLoading(false);
        console.log(err)
      })

  }

  const isConfirmValid = () => {
    if (password !== confirmPassword) return false;
    return true
  }

  useEffect(() => {
    storeConfig.persistor.purge()
    clearSession()
    // eslint-disable-next-line
  }, [])
  return (
    <Page isContainer>
      <div className="projects__container">
        <div className='email-input-margin-bottom'>
          <br />
          <br />
          <p>
            Thank you for choosing InspiraFarms.
            <br />
            Kindly create a password to complete your account activation process.
          </p>

        </div>
        <div className='email-input-margin-bottom'>
          <small>password</small>
          <Input
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            placeholder="password"
            fluid
            value={password}
            disabled={loading}
          />
        </div>

        <div className='email-input-margin-bottom'>
          <small>confirm password</small>
          <Input
            onChange={(e) => {

              setConfirmPassword(e.target.value)
            }}
            placeholder="confrim password"
            fluid
            error={!isConfirmValid() && password.length > 0}
            value={confirmPassword}
            disabled={loading}
          />
        </div>

        <div className='add-company-button'>
          <Button
            onClick={() => {
              onProceedToLoginClick()
            }}
            color="yellow"
            size="big"
            className="button-full-width"
            disabled={(!isConfirmValid() && password.length > 0) || (password.length === 0)}
          >
            <div className='button-plus-spinner'>
              {loading ? 'Activating account..' : 'Proceed to login'}
              <div className='spinner-button'>
                {loading ? <Icon name='circle notched' color='white' loading={loading} /> : null}
              </div>
            </div>
          </Button>
        </div>
      </div>
    </Page >
  )
}
const mapStateToProps = () => ({})

export default connect(mapStateToProps, {
})(AccountConfirmation);
