export const tiveDevices = {
    totalRecords: 3,
    pageNumber: 1,
    pageSize: 3,
    data: [
        {
            deviceId: 'ABCD',
            deviceName: 'My Name',
            batteryPercent: '50',
            deviceOwner: 'Inspira',
            gpsEnabled: true,
            wifiEnabled: true,
            powerButtonEnabled: true,
            estimatedBatteryLifeMinutes: 200,
            measurementInterval: 30,
            transmissionInterval: 95,
        },
        {
            deviceId: 'CDE',
            deviceName: 'Loar',
            batteryPercent: '5',
            deviceOwner: 'Inspira',
            gpsEnabled: false,
            wifiEnabled: true,
            powerButtonEnabled: false,
            estimatedBatteryLifeMinutes: 200,
            measurementInterval: 30,
            transmissionInterval: 95
        },
        {
            deviceId: 'GHCD',
            deviceName: 'Another One',
            batteryPercent: '50',
            deviceOwner: 'Inspira',
            gpsEnabled: true,
            wifiEnabled: true,
            powerButtonEnabled: true,
            estimatedBatteryLifeMinutes: 200,
            measurementInterval: 30,
            transmissionInterval: 95
        },
        {
            deviceId: 'ZZZZ',
            deviceName: 'Last But not least',
            batteryPercent: '50',
            deviceOwner: 'Inspira',
            gpsEnabled: true,
            wifiEnabled: true,
            powerButtonEnabled: true,
            estimatedBatteryLifeMinutes: 200,
            measurementInterval: 30,
            transmissionInterval: 95
        }
    ]
}

//List shipments page
export const tiveShipments = {

    totalRecords: 3,
    pageNumber: 1,
    pageSize: 3,
    data: [
        {
            shipmentId: 'CEEF',
            deviceIds: ['DEV-4'],
            autoComplete: true,
            shipFromDate: '25/12/2022',
            etaDate: '15/01/2023',
            isStarted: true,
            isDeparted: true,
            isCompleted: false
        },
        {
            shipmentId: 'FFU',
            deviceIds: ['DEV-3', 'DEV-12', 'DEV-14', 'DEV-5'],
            autoComplete: true,
            shipFromDate: '19/11/2022',
            etaDate: '25/01/2023',
            isStarted: true,
            isDeparted: true,
            isCompleted: false
        },
        {
            shipmentId: 'OWN',
            deviceIds: ['DEV-10', 'DEV-2', 'DEV-15', 'DEV-7', 'DEV-13', 'DEV-6'],
            autoComplete: true,
            shipFromDate: '18/10/2022',
            etaDate: '21/01/2023',
            isStarted: true,
            isDeparted: true,
            isCompleted: false
        }, {
            shipmentId: 'HOW',
            deviceIds: ['DEV-1', 'DEV-8', 'DEV-9', 'DEV-11'],
            autoComplete: true,
            shipFromDate: '13/01/2023',
            etaDate: '31/01/2023',
            isStarted: true,
            isDeparted: true,
            isCompleted: false
        }
    ]

}



export const tiveDevicesStatus = {
    'DEV-1': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46,
    },
    'DEV-2': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-3': {
        currentLocation: {},
        shipmentId: '',
        temperature: 29.6,
        probeTemperature: 18.2,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-4': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-5': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-6': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-7': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-8': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-9': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-10': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-11': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-12': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-13': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-14': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
    'DEV-15': {
        currentLocation: {},
        shipmentId: '',
        temperature: 34.6,
        probeTemperature: 12.6,
        humidity: 46.7,
        pressure: 200,
        light: 400,
        motion: 56,
        acceleration: 46.2,
        batteryPowerPercent: 2,
        estimatedBatteryLifeMinutes: 46

    },
}

export const tiveDeviceLocations = [
    { title: 'DEV-1', latitude: 1.3921, longitude: 37.8219 },
    { title: 'DEV-2', latitude: 1.6921, longitude: 36.8219 },
    { title: 'DEV-3', latitude: 1.7921, longitude: 35.8219 },
    { title: 'DEV-4', latitude: 1.8921, longitude: 34.8219 },
    { title: 'DEV-5', latitude: 1.4921, longitude: 37.219 },
    { title: 'DEV-6', latitude: 1.1921, longitude: 36.219 },
    { title: 'DEV-7', latitude: 1.291, longitude: 38.82 },
    { title: 'DEV-8', latitude: 1.3121, longitude: 36.89 },
    { title: 'DEV-9', latitude: 1.3321, longitude: 36.99 },
    { title: 'DEV-10', latitude: 1.2921, longitude: 39.82 },
]