import React, { useState, useEffect } from "react";
import { Table, Dropdown, Button } from "semantic-ui-react";
import { fetchProjectColdRooms } from "../../../../actions/alarms/cold-room.action";
import { connect } from "react-redux";
// import Spinner from "../../../../components/shared/Spinner";
import { getCurrentProjectInfo } from "../../../../utils/auth/user";

const color = "yellow";

const coldRoomsAlarmsFrequency = [
  { key: 0, text: "Latest", value: "latest" },
  { key: 1, text: "Today", value: "today" },
  { key: 2, text: "Yesterday", value: "yesterday" },
  { key: 3, text: "One Week", value: "oneWeek" },
  { key: 4, text: "One Month", value: "oneMonth" },
];

function ColdRooms(props) {
  const {
    projectsData,
    fetchProjectColdRooms,
    coldRoomsAlarms,
    fetchingData,
  } = props;

  const date = new Date();
  const dateAsString = date.toString();
  const timezone = dateAsString.match(/\(([^)]+)\)$/)[1];

  const [selectedPeriod, setSelectedPeriod] = useState("latest");
  const [selectedProject, setSelectedProject] = useState(
    getCurrentProjectInfo()?.projectUid
  );

  useEffect(() => {
    fetchProjectColdRooms(selectedPeriod, selectedProject);
    // eslint-disable-next-line
  }, []);
  return (

    <div className="reports">
      <div className="reports-select-container">

        <div className="report-datepicker">

          <div className="report-datepicker-tablet">

            <div className="report-datepicker-tablet-x">
              <p className="table-text">Select a Data interval and a project:</p>

              <Dropdown
                placeholder="Data Interval"
                search
                selection
                options={coldRoomsAlarmsFrequency}
                defaultValue={selectedPeriod}
                onChange={(event, { value }) => {
                  setSelectedPeriod(value);
                }}
              />
            </div>

            <div className="report-datepicker-tablet-x">

              <Dropdown
                placeholder="Project"
                search
                selection
                options={projectsData}
                defaultValue={selectedProject}
                onChange={(event, { value }) => {
                  setSelectedProject(value);
                }}
              />
            </div>

            <div className="report-datepicker-tablet-x">

              {fetchingData ? (
                <Button loading color="yellow" size="big">

                  Serching...
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    //   downloadPdfDocument();
                    fetchProjectColdRooms(selectedPeriod, selectedProject);
                  }}
                  color="yellow"
                  size="big"
                >

                  Search Alarms
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      {(
        Object.entries(coldRoomsAlarms).map((e) => (
          <>
            <h3> {`${e[0]} Alarms`}</h3>

            <Table color={color} key={color}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell><p className="table-text">Type</p></Table.HeaderCell>
                  <Table.HeaderCell><p className="table-text">State</p></Table.HeaderCell>
                  <Table.HeaderCell><p className="table-text">Setpoint</p></Table.HeaderCell>
                  <Table.HeaderCell><p className="table-text">Value</p></Table.HeaderCell>
                  {/* <Table.HeaderCell /> */}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {e[1].map((el) => (
                  <Table.Row>
                    <Table.Cell><p className="table-text">{el.type}</p></Table.Cell>
                    <Table.Cell>
                      {el.connectionMode === 1 ? (
                        <p className="table-text"><b className="table-text" style={{ color: "green" }}> OK </b>  Since{" "}
                          {new Date(el.createdAt).toLocaleString("en-AU", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            timezone,
                          })}</p>
                      ) : (
                        <p className="table-text"><b className="table-text" style={{ color: "red" }}> ALERTING </b> Since{" "}
                          {new Date(el.createdAt).toLocaleString("en-AU", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            timezone,
                          })}</p>
                      )}
                    </Table.Cell>
                    <Table.Cell><p className="table-text">{`${!el.alarmCause ? '' : el.alarmCause === 'desired' ? `Desired ${el.alarmBasisValue.toFixed(1)}${el.type === 'humidity' ? '%' : '°C'}` : `${el.alarmBasisValue.toFixed(1)}${el.type === 'humidity' ? '%' : '°C'}`}`}</p></Table.Cell>
                    <Table.Cell><p className="table-text">{`${el.actualValue ? `${el.actualValue.toFixed(1)}${el.type === 'humidity' ? '%' : '°C'}` : ''}`}</p></Table.Cell>
                    {/* <Table.Cell>
                      Since{" "}
                      {new Date(el.createdAt).toLocaleString("en-AU", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        timezone,
                      })}
                    </Table.Cell> */}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

          </>
        ))
      )}
    </div>
  );
}

const mapStateToProps = ({ projects, alarms }) => {
  const { data } = projects;

  const { fetchingData } = alarms.coldRooms;



  const arr = data.map((e, i) => {
    return {
      key: i,
      text: e.title,
      value: e.projectUid,
    };
  });

  return {
    projectsData: arr,
    coldRoomsAlarms: alarms.coldRooms.data,
    fetchingData,
  };
};

export default connect(mapStateToProps, { fetchProjectColdRooms })(ColdRooms);
