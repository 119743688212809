import React, { useMemo } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import flow from 'lodash/flow';

const sourceSpec = {
  beginDrag(props, monitor) {
    return props.handleDragging(props, monitor);
  },

  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }
    const dropResult = monitor.getDropResult();
    if (dropResult) {
      return handleDrop(props.item, props);
    }
  }
};

const handleDrop = (item, props) => {
  props.updateStockItem(item.id, item, props.accepts, props.coldStorageId);
};

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};

const targetSpec = {
  drop() {
    return undefined;
  }
};

const targetCollect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
};

const DraggableTableRows = props => {
  const {
    config,
    item,
    columnWidth,
    index,
    isOver,
    connectDragSource,
    connectDropTarget,
    canDrop
  } = props;
  const { headers, noData } = config;
  const rowClick = config.rowClick ? config.rowClick : () => null;

  const isActive = canDrop && isOver;
  let active = false;
  if (isActive) {
    active = true;
  }

  useMemo(() => {
    const { connectDragPreview } = props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return item ? (
    connectDropTarget(
      connectDragSource(
        <div
          key={index}
          className={`table__body__row`}
          id={`${active ? 'dropdownZone' : ''}`}
          onClick={() => rowClick(item)}
        >
          {headers.map((header, ind) => (
            <div
              key={ind}
              className={`table__body__row__td ${
                header.className ? header.className : ''
                }`}
              style={{
                flexBasis: `${header.width || columnWidth}%`,
                textAlign: header.textAlignItem || 'left',
                justifyContent:
                  header.textAlignItem === 'right' ? 'flex-end' : 'flex-start'
              }}
            >
              {header.component
                ? header.component(item)
                : `${header.prefix || ''}${item[header.key]}${header.suffix ||
                ''}`}
            </div>
          ))}
        </div>
      )
    )
  ) : (
      <div className="table__body__row empty">
        <div className="table__body__row__td">{noData ? noData : 'No data'}</div>
      </div>
    );
};

export default flow(
  DropTarget(props => props.accepts, targetSpec, targetCollect),
  DragSource(props => props.item.status, sourceSpec, collect)
)(DraggableTableRows);
