import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {chain, isUndefined} from 'lodash';

import ChartTooltip from '../../../../../../../components/Charts/components/ChartTooltip';
import * as colors from '../../../../../../../constants/colors';
import { roundTwoDigits } from '../../../../../../../utils/numberFormat';
import { cleanChartDataToBeContinous } from '../../../../../../../utils/chartUtils';
import { getLabelNameFormat } from '../../../../../../../helpers/chart.helper';
import {
  getListFromData,
  getObjectItem
} from '../../../../../../../helpers/object.helper';
import LinearChart
  from "../../../../../../../components/Charts/LinearChart";

export const ThermalStorageChart = ({ syncId, dataProfile, tempWaterTank1Data, tempWaterTank2Data, thermalStorageColdTempData }) => {
  const _tempWaterTank1IsVisible = () => dataProfile.tempWaterTank1;
  const _tempWaterTank2IsVisible = () => dataProfile.tempWaterTank2;
  const _thermalStorageColdTempIsVisible = () => !dataProfile.tempWaterTank1;


  const _getColdWaterData = () => {
    const tempWaterTank1DataList = getListFromData(tempWaterTank1Data);

    const tempWaterTank2DataList = getListFromData(tempWaterTank2Data);
    const thermalStorageColdTempDataList = getListFromData(tempWaterTank2Data);

    let concatedValues = tempWaterTank1DataList;
    if (_thermalStorageColdTempIsVisible()) {
      concatedValues = thermalStorageColdTempDataList;
      legends.push({ key: 'value', color: colors.FIR_GREEN, label: 'COLD TEMP' })
    } else if (_tempWaterTank1IsVisible()) {
      legends.push({ key: 'tempWaterTank1', color: colors.FIR_GREEN, label: 'TEMP 1' })
      concatedValues = tempWaterTank1DataList;
      if (_tempWaterTank2IsVisible()) {
        legends.push({ key: 'tempWaterTank2', color: colors.ORANGE, label: 'TEMP 2' });
        concatedValues = [...tempWaterTank2DataList, ...concatedValues];
      }
    }

    const nameLabelFormat = getLabelNameFormat(getListFromData(tempWaterTank1Data));

    const groupedValues = chain(concatedValues).groupBy('recDateTime').map(element => {
      const startDate = moment.utc(element[0].recDateTime).local();
     

      return {
        name: startDate.format(nameLabelFormat),
        startDate: startDate.format('HH:mm - DD.MM.YYYY'),
        tempWaterTank1: _tempWaterTank2IsVisible() && getObjectItem(element[0]),
        tempWaterTank2: _tempWaterTank2IsVisible() ? getObjectItem(element[1]) : getObjectItem(element[0]),
      }
    }).value()
    return cleanChartDataToBeContinous(groupedValues, _thermalStorageColdTempIsVisible() ? 'value' : 'tempWaterTank1');
  };

const _getCustomTooltip = e => {
  const data = ((e.payload || [])[0] || {}).payload || {};

  return (
    <ChartTooltip>
      <p>
        TEMP 1: <strong>{roundTwoDigits(data.tempWaterTank1)}°C</strong>
      </p>
      {_tempWaterTank2IsVisible() && (
        <p>
          TEMP 2: <strong>{roundTwoDigits(data.tempWaterTank2)}°C</strong>
        </p>
      )}
      <small>{data.startDate}</small>
    </ChartTooltip>
  );
};

  const _isLoading = () => {
    if (
      (!isUndefined(tempWaterTank1Data) && tempWaterTank1Data.loading === false) ||
      (!isUndefined(tempWaterTank2Data) && tempWaterTank2Data.loading === false) ||
      (!isUndefined(thermalStorageColdTempData) && thermalStorageColdTempData.loading === false)
    ) {
      return false;
    }
    return true;
  };

  const legends = [];

  const rangeLines = [
    // { x: 0, y: 60, label: '60% MAX', color: colors.LIGHT_GREY },
    // { x: 0, y: 40, label: '40% MIN', color: colors.LIGHT_GREY }
  ];

  return (
    <LinearChart
      title="Thermal Storage"
      syncId={syncId}
      data={_getColdWaterData()}
      tooltip={_getCustomTooltip}
      legends={legends}
      rangeLines={rangeLines}
      loading={_isLoading()}
      maxValue={22}
      minValue={-4}
    />
  );
}

ThermalStorageChart.propTypes = {
  syncId: PropTypes.string,
  dataProfile: PropTypes.object,
  tempWaterTank1Data: PropTypes.object,
  tempWaterTank2Data: PropTypes.object,
  thermalStorageColdTempData: PropTypes.object
};

ThermalStorageChart.defaultProps = {
  syncId: 'syncId',
  dataProfile: undefined,
  tempWaterTank1Data:undefined,
  tempWaterTank2Data: undefined,
  thermalStorageColdTempData: undefined
};

export default ThermalStorageChart;
