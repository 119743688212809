import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom";

import Page from "../../components/Page";
import Title from "../../components/shared/Title";
import { urlRoute } from "../../utils/url";
import Navigation from "../../components/shared/Navigation";
import Admin from './routes/admin/Admin';
import Config from './routes/config/Config';
import Files from './routes/files/Files';


let routes = {
  admin: {
    label: "Commercial",
    link: urlRoute(`/devices/commercial`),
    path: urlRoute(`/devices/commercial`),
  },
  config: {
    label: "Config",
    link: urlRoute(`/devices/config`),
    path: urlRoute(`/devices/config`),
  },
  files: {
    label: "Files",
    link: urlRoute(`/devices/files`),
    path: urlRoute(`/devices/files`),
  }
};


function Devices() {
  return (
    <Page isContainer>
      <Title title="Devices" />

      <Navigation routes={routes} />
      <Switch>

        <Route exact path={routes.admin.path} component={Admin} />
        <Route exact path={routes.config.path} component={Config} />
        {/* {toRenderFeature(featureTypes.ALARMS_SETTINGS, paymentStatus, features) && <Route exact path={routes.settings.path} component={Settings} />} */}
        <Route exact path={routes.files.path} component={Files} />
        <Route exact path="/devices">
          <Redirect to={routes.admin.path} />
        </Route>
      </Switch>
    </Page>
  )
}

export default Devices;