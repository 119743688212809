import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-dates/initialize';
import 'mapbox-gl/dist/mapbox-gl.css';

import * as serviceWorker from './utils/serviceWorker';
import storeConfig from './store';
import App from './App';


ReactDOM.render(
  <Provider store={storeConfig.store}>
    <PersistGate loading={null} persistor={storeConfig.persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
