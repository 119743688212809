import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";

import RangeDatePicker from "../../../../../components/shared/RangeDatePicker";
import * as roles from "../../../../../constants/roles";
import BottomNavigation from "./components/BottomNavigation";
import {
  getUserRoles,
  getProjectUnitsForDropDown,
} from "../../../../../utils/auth/user";
import { chartOptions } from "../../../../../constants/chartOptions";
import TechnicianStatistics from "./components/TechnicianStatistics";
import {
  getSectionsByDataProfile,
  FURTHER_DATA,
} from "../../../../../constants/statistics-section.types";
import UserStatistics from "./components/UserStatistics";
import { hasOneOrMoreSameValues } from "../../../../../utils/arrays/compareTwoArrays";
import "./_statistics.scss";
// import { getColdRoomName } from "../../../../../utils/auth/user";

import { STATISTICS } from "../../../../../constants/analyticsEventTypes";
import ReactGA from "react-ga";

const endDateDefault = moment();

export class Statistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openedSections: [],
      customChartSelectedValues: [chartOptions[0], chartOptions[1]],
      pickerStartDate:
        localStorage.getItem("pickerStartDate") !== null
          ? moment(localStorage.getItem("pickerStartDate"))
          : moment()
            .subtract(1, "days")
            .startOf("day"),
      pickerEndDate:
        localStorage.getItem("pickerEndDate") !== null
          ? moment(localStorage.getItem("pickerEndDate"))
          : moment(),
    };

    // localStorage.setItem("selectedColdRoom", this.props.coldStorageId);

    window.onbeforeunload = function () {
      //here read current state of dates and set the new date object to new state
      localStorage.setItem("pickerStartDate", this.state.pickerStartDate);
      localStorage.setItem("pickerEndDate", this.state.pickerEndDate);
      //return "";
    }.bind(this);
  }

  componentDidMount() {
    localStorage.setItem("selectedColdRoom", this.props.coldStorageId);
    // this.props.giveSubTitleContent(getColdRoomName(this.props.coldStorageId));
  }

  componentWillUnmount() {
    // this.props.giveSubTitleContent("");
    localStorage.removeItem("pickerStartDate");
    localStorage.removeItem("pickerEndDate");
    localStorage.removeItem("selectedColdRoom");
  }

  render() {
    const {
      data,
      csvData,
      coldStorageId,
      unitsIdList,
      projectId,
      timePeriod,
      dataProfile,
      match,
    } = this.props;

    const { customChartSelectedValues, openedSections } = this.state;

    const isIfUser = hasOneOrMoreSameValues(getUserRoles(), [
      roles.INSPIRA_FARMS_TECH,
      roles.INSPIRA_FARMS_EMPLOYEE,
      roles.INSPIRA_FARMS_ADMIN,
      roles.UNDER_THE_RADAR,
      roles.INSPIRA_FARMS_ADMIN,
      roles.UNDER_THE_RADAR,
      roles.INSPIRA_FARMS_TECH_REGION
    ]);

    const coldRoomDopDownData = getProjectUnitsForDropDown(projectId);

    const modifiedColdRoomDopDownData = coldRoomDopDownData.map((el, i) => {
      return {
        key: el.key,
        text: <div className={`table-text ${el.value === coldStorageId ? 'dropdown-extras-active' : 'dropdown-extras'}`}>{el.text}</div>,
        value: el.value,
      };
    })

    let newData = {
      cellHum: {},
      cellTemp: {},
      doorRoom: {},
      piercingProbeTemp: {},
      piercingProbeTemp2: {},
      stockWeights: {},
      externalTemp: {},
      externalHum: {},
      sunIrradiation: {},
      rainGauge: {},
      batteryCharge: {},
      mainEnergyConsumption: {},
      compressorEnergyConsumption: {},
      roomEnergyConsumption: {},
      tempSetpoint: {},
      humSetpoint: {},
      ethyleneProbe: {},
      CO2ambient: {}
    };

    //  TODO Find better fix for swaps
    if (
      coldStorageId === 48 &&
      !data["cellTemp"]?.loading &&
      !data["piercingProbeTemp"]?.loading
    ) {
      newData["cellHum"] = data["cellHum"];
      newData["cellTemp"] = data["piercingProbeTemp"];
      newData["doorRoom"] = data["doorRoom"];
      newData["piercingProbeTemp"] = data["cellTemp"];
      newData["piercingProbeTemp2"] = data["piercingProbeTemp2"];
      newData["stockWeights"] = data["stockWeights"];
      newData["externalTemp"] = data["externalTemp"];
      newData["externalHum"] = data["externalHum"];
      newData["sunIrradiation"] = data["sunIrradiation"];
      newData["rainGauge"] = data["rainGauge"];
      newData["batteryCharge"] = data["batteryCharge"];
      newData["mainEnergyConsumption"] = data["mainEnergyConsumption"];
      newData["compressorEnergyConsumption"] =
        data["compressorEnergyConsumption"];
      newData["roomEnergyConsumption"] = data["roomEnergyConsumption"];
      newData["tempSetpoint"] = data["tempSetpoint"];
      newData["humSetpoint"] = data["humSetpoint"];
      newData["ethyleneProbe"] = data["ethyleneProbe"];
      newData["CO2ambient"] = data["CO2ambient"];

    } else {
      newData["cellHum"] = data["cellHum"];
      newData["cellTemp"] = data["cellTemp"];
      newData["doorRoom"] = data["doorRoom"];
      newData["piercingProbeTemp"] = data["piercingProbeTemp"];
      newData["piercingProbeTemp2"] = data["piercingProbeTemp2"];
      newData["stockWeights"] = data["stockWeights"];
      newData["externalTemp"] = data["externalTemp"];
      newData["externalHum"] = data["externalHum"];
      newData["sunIrradiation"] = data["sunIrradiation"];
      newData["rainGauge"] = data["rainGauge"];
      newData["batteryCharge"] = data["batteryCharge"];
      newData["mainEnergyConsumption"] = data["mainEnergyConsumption"];
      newData["compressorEnergyConsumption"] =
        data["compressorEnergyConsumption"];
      newData["roomEnergyConsumption"] = data["roomEnergyConsumption"];
      newData["tempSetpoint"] = data["tempSetpoint"];
      newData["humSetpoint"] = data["humSetpoint"];
      newData["ethyleneProbe"] = data["ethyleneProbe"];
      newData["CO2ambient"] = data["CO2ambient"];
    }

    return (
      <>
        <div className="reports">
          <div className="reports-select-container">

            <div className="report-datepicker">

              <div className="report-datepicker-tablet">

                <div className="report-datepicker-tablet-x">
                  <p className="table-text">Select a coldroom and a date:</p>
                  <Dropdown
                    className="dropdown-extras-container"
                    compact

                    placeholder="Select a room"
                    search
                    selection
                    options={modifiedColdRoomDopDownData}
                    defaultValue={coldStorageId}
                    onChange={(event, { value }) => {
                      localStorage.setItem("selectedColdRoom", value);
                      // this.props.giveSubTitleContent(getColdRoomName(value));
                      this._onDropDownSelector(value);
                    }}
                  />
                </div>
                <div className="report-datepicker-tablet-x">


                  <RangeDatePicker
                    onAccept={this._onRangePickerAccept}
                    starterDate={
                      this.state.pickerStartDate ? this.state.pickerStartDate : null
                    }
                    enderDate={
                      this.state.pickerEndDate ? this.state.pickerEndDate : null
                    }
                  />

                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="statistics">
          <div className="statistics__charts">


            {isIfUser ? (
              <TechnicianStatistics
                data={newData}
                csvData={csvData}
                openedSections={openedSections}
                timePeriod={timePeriod}
                dataProfile={dataProfile}
                customChartSelectedValues={customChartSelectedValues}
                onToggleCollapse={this._onToggleCollapse}
                onToggleFurtherDataCollapse={this._onToggleFurtherDataCollapse}
                fetchDataForSection={this._fetchDataForSection}
                setCustomChartSelectedValue={this._setCustomChartSelectedValue}
              />
            ) : (
              <UserStatistics
                data={newData}
                csvData={csvData}
                openedSections={openedSections}
                timePeriod={timePeriod}
                dataProfile={dataProfile}
                onToggleCollapse={this._onToggleCollapse}
                fetchDataForSection={this._fetchDataForSection}
              />
            )}
          </div>
          <BottomNavigation
            coldStorageId={coldStorageId}
            unitsIdList={unitsIdList}
            params={match}
            projectId={projectId}
          />
        </div>
      </>
    );
  }

  _onToggleCollapse = (sectionName) => {
    const { openedSections } = this.state;
    const foundSection = openedSections.find((value) => value === sectionName);
    let updatedSections;

    if (foundSection) {
      updatedSections = openedSections.filter(
        (value) => value !== foundSection
      );
    } else {
      this._fetchDataForSection(sectionName);
      updatedSections = [...openedSections, sectionName];
    }



    this.setState({ openedSections: updatedSections });
  };

  _onToggleFurtherDataCollapse = (sectionName) => {
    const { openedSections, customChartSelectedValues } = this.state;
    const foundSection = openedSections.find((value) => value === FURTHER_DATA);
    let updatedSections;

    if (foundSection) {
      updatedSections = openedSections.filter(
        (value) => value !== foundSection
      );
    } else {
      this._fetchDataForSection(
        sectionName,
        customChartSelectedValues.map((selected) => selected.value)
      );
      updatedSections = [...openedSections, FURTHER_DATA];
    }

    this.setState({ openedSections: updatedSections });
  };

  _onRangePickerAccept = ({ startDate, endDate }) => {
    ReactGA.event({
      category: STATISTICS.Category,
      action: STATISTICS.StatisticsDateSelect,
      label: STATISTICS.StatisticsDateSelect,
    });
    const { openedSections } = this.state;

    const startDateValue = startDate.clone().startOf("day");
    const endDateValue = endDate
      .clone()
      .add(1, "day")
      .startOf("day");

    this.setState({
      pickerStartDate: startDateValue,
      pickerEndDate: endDateValue,
    });

    if (openedSections.length > 0) {
      openedSections.forEach((sectionName) => {
        const chartsToUpdate = getSectionsByDataProfile(
          this.props.dataProfile,
          sectionName
        );

        chartsToUpdate.forEach((chart) => {
          this._fetchStatistics(chart, {
            startDate: startDateValue,
            endDate: endDateValue,
          });
        });
      });
    }

    const furtherDataSecttionOpened = openedSections.find(
      (sectionName) => sectionName === FURTHER_DATA
    );

    if (furtherDataSecttionOpened) {
      const { customChartSelectedValues } = this.state;

      this._fetchStatistics(customChartSelectedValues[0].value, {
        startDate: startDateValue,
        endDate: endDateValue,
      });
      this._fetchStatistics(customChartSelectedValues[1].value, {
        startDate: startDateValue,
        endDate: endDateValue,
      });
    }
  };

  _onDropDownSelector = (coldRoomId) => {
    const { openedSections } = this.state;

    if (openedSections.length > 0) {
      openedSections.forEach((sectionName) => {
        const chartsToUpdate = getSectionsByDataProfile(
          this.props.dataProfile,
          sectionName
        );

        chartsToUpdate.forEach((chart) => {
          this._fetchStatisticsFromDropDown(coldRoomId, chart);
        });
      });
    }

    const furtherDataSecttionOpened = openedSections.find(
      (sectionName) => sectionName === FURTHER_DATA
    );

    if (furtherDataSecttionOpened) {
      const { customChartSelectedValues } = this.state;

      this._fetchStatisticsFromDropDown(
        coldRoomId,
        customChartSelectedValues[0].value
      );
      this._fetchStatisticsFromDropDown(
        coldRoomId,
        customChartSelectedValues[1].value
      );
    }
  };

  _setCustomChartSelectedValue = (customChartSelectedValue, chartId) => {
    const { customChartSelectedValues } = this.state;
    const values = customChartSelectedValues;
    values[chartId] = customChartSelectedValue;

    this.setState({ customChartSelectedValues: values });
    this._fetchStatistics(customChartSelectedValue.value, null);
  };

  _fetchDataForSection = (sectionName, additionalCharts = null) => {
    let chartsToUpdate = getSectionsByDataProfile(
      this.props.dataProfile,
      sectionName
    );

    chartsToUpdate.forEach((section) => {
      this._fetchStatistics(section);
    });

    if (additionalCharts && additionalCharts.length > 0) {
      additionalCharts.forEach((chartType) => {
        this._fetchStatistics(chartType);
      });
    }
  };

  _fetchStatistics = (statisticsType, selectedPeriod) => {
    const { projectId, fetchUnitStatistics, fetchCsvData } = this.props;

    const { pickerStartDate, pickerEndDate } = this.state;

    let dates;

    if (selectedPeriod) {
      dates = {
        startDate: selectedPeriod.startDate.clone().startOf("day"),
        endDate:
          selectedPeriod.endDate &&
            selectedPeriod.endDate.isBefore(endDateDefault)
            ? selectedPeriod.endDate.clone()
            : endDateDefault,
      };
    } else {
      dates = {
        startDate: pickerStartDate,
        endDate: pickerEndDate,
      };
    }

    fetchUnitStatistics(
      localStorage.getItem("selectedColdRoom"),
      dates,
      projectId,
      statisticsType
    );
    //fetch Specific Data to export to CSV --> for now only temp, hum and weather station data
    if (
      statisticsType === "cellTemp" ||
      statisticsType === "cellHum" ||
      statisticsType === "externalTemp" ||
      statisticsType === "externalHum" ||
      statisticsType === "sunIrradiation" ||
      statisticsType === "rainGauge"
    ) {
      fetchCsvData(
        localStorage.getItem("selectedColdRoom"),
        dates,
        projectId,
        statisticsType
      );
    }
  };

  _fetchStatisticsFromDropDown = (coldRoomId, statisticsType) => {
    const { projectId, fetchUnitStatistics, fetchCsvData } = this.props;

    const { pickerStartDate, pickerEndDate } = this.state;

    let dates = {
      startDate: pickerStartDate,
      endDate: pickerEndDate,
    };

    fetchUnitStatistics(coldRoomId, dates, projectId, statisticsType);
    //fetch Specific Data to export to CSV --> for now only temp, hum and weather station data
    if (
      statisticsType === "cellTemp" ||
      statisticsType === "cellHum" ||
      statisticsType === "externalTemp" ||
      statisticsType === "externalHum" ||
      statisticsType === "sunIrradiation" ||
      statisticsType === "rainGauge"
    ) {
      fetchCsvData(coldRoomId, dates, projectId, statisticsType);
    }
  };
}

Statistics.propTypes = {
  fetchCsvData: PropTypes.func,
  fetchWeatherStationScvData: PropTypes.func,
  coldStorageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unitsIdList: PropTypes.array,
  data: PropTypes.object,
  loading: PropTypes.bool,
  sync: PropTypes.bool,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Statistics;
