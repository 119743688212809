import {
    ADMIN_USER_PROJECT_ID,
    DONE_FETCHING_PROJECT_USERS,
    FETCHING_PROJECT_USERS,
} from "../../constants/admin.types";
import api from "../../utils/api";



export const fetchAllProjectUsers = (projectId) => {
    return (dispatch) => {
        dispatch({
            type: FETCHING_PROJECT_USERS,
        });

        api
            .get(`/user/company-all`, {
                params: {
                    projectId,
                },
            })

            .then((res) => {
                dispatch({
                    type: DONE_FETCHING_PROJECT_USERS,
                    payload: res,
                });
            })
            .catch((err) => { });
    };
};

export const setAdminUsersProjectId = (projectId) => {
    return {
        type: ADMIN_USER_PROJECT_ID,
        payload: projectId
    }
};
