import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from './Login';
import * as authActions from '../../../actions/auth.actions';
import * as projectActions from '../../../actions/projects.actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(authActions.login, dispatch),
  fetchProjects: bindActionCreators(projectActions.fetchProjects, dispatch),
  setCurrentProjectToFirstProject: bindActionCreators(
    projectActions.setCurrentProjectIdToFirstProject,
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
