import React from 'react';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import * as colors from '../../constants/colors';
import { chartLayoutVariables } from '../../constants/chartOptions';
import ReferenceLabel from './components/ReferenceLabel';
import { checkIfChartDataExists } from '../../utils/chartUtils';
import Spinner from '../shared/Spinner';

const AreaLinearChart = props => {
  const renderLoader = props.loading;


  return (
    <div className="chart">
      <div className="chart__header">
        <div className="chart__title">{props.title}</div>
        <div className="legends">
          {props.legends.map((v, i) => (
            <div key={i} className="legends__item">
              {v.label && (
                <div
                  className="legends__item__dot"
                  style={{ backgroundColor: v.color }}
                />
              )}
              <div className="legends__item__label" style={{ color: v.color }}>
                {v.label}
              </div>
            </div>
          ))}
        </div>
      </div>
      {checkIfChartDataExists(props.legends.map(x => x.key), props.data) ? (
        <div className="chart__container">
          <ResponsiveContainer>
            <AreaChart
              width={510}
              height={300}
              data={props.data}
              margin={chartLayoutVariables.chartMargin}
              syncId={props.syncId}
            >
              <CartesianGrid vertical={false} horizontal={false} />
              <XAxis
                dataKey="name"
                isAnimationActive={false}
                stroke={colors.LIGHT_GREY}
                tickLine={false}
                axisLine={false}
                interval="preserveStartEnd"
                height={chartLayoutVariables.xAxisHeight}
                tick={{ transform: chartLayoutVariables.xAxisTransform }}
              />
              <YAxis
                type="number"
                domain={[props.minValue, props.maxValue]}
                tickLine={false}
                axisLine={false}
                textAnchor="start"
                stroke={colors.LIGHT_GREY}
                tickFormatter={props.yFormatter}
                tick={{ transform: chartLayoutVariables.yAxisTransform }}
              />

              <Tooltip content={props.tooltip} />

              {props.rangeLines.map((v, i) => (
                <ReferenceLine
                  key={i}
                  y={v.y}
                  x={v.x}
                  stroke={v.color}
                  strokeDasharray="6 6"
                  label={
                    <ReferenceLabel
                      fill={colors.MEDIUM_GREY}
                      value={v.label}
                      textAnchor={v.label}
                      fontSize={12}
                      viewBox={480}
                      dy={0}
                    />
                  }
                />
              ))}
              {props.legends.map((v, i) => (
                <defs key={`${v.key}-gradient-${i}`}>
                  <linearGradient
                    id={`${v.key}-gradient-${i}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor={v.color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={v.color} stopOpacity={0} />
                  </linearGradient>
                </defs>
              ))}
              {props.legends.map((v, i) => (
                <Area
                  key={i}
                  type="monotone"
                  dataKey={v.key}
                  stroke={v.color}
                  fillOpacity={0.1}
                  fill={`url(#${v.key}-gradient-${i})`}
                  strokeWidth={1}
                  dot={false}
                  activeDot={true}
                  isAnimationActive={true}
                />
              ))}
            </AreaChart>
          </ResponsiveContainer>
          <Spinner overlay active={renderLoader} />
        </div>
      ) : (
        <div
          className={`chart__container no-data ${props.className} ${
            renderLoader ? 'loading' : ''
          }`}
        >
          {renderLoader ? <Spinner /> : ''}
        </div>
      )}
    </div>
  );
};

AreaLinearChart.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  syncId: PropTypes.string,
  data: PropTypes.array,
  rangeLines: PropTypes.array,
  legends: PropTypes.array,
  tooltip: PropTypes.func,
  yFormatter: PropTypes.func
};

AreaLinearChart.defaultProps = {
  title: '',
  syncId: 'lineChartId',
  rangeLines: [
    { x: 0, y: 8230, label: '6°C MAX', color: colors.MEDIUM_GREY },
    { x: 0, y: 1800, label: '4°C MIN', color: colors.MEDIUM_GREY }
  ],
  legends: [
    { color: colors.ORANGE, key: 'uv', label: 'PULP TEMP' },
    { color: colors.FIR_GREEN, key: 'pv', label: 'ROOM TEMP' }
  ],
  data: [
    {
      name: 'A',
      uv: 4000,
      pv: 2400,
      amt: 2400
    }
  ],
  yFormatter: v => v
};

export default AreaLinearChart;
