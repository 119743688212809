import React from 'react';
import PropTypes from 'prop-types';
import { scrollToTop } from '../../../../../../utils/pageScroll';
import ButtonLink from '../../../../../../components/shared/ButtonLink';
import ArrowLink from '../../../../../../components/shared/ArrowLink';

const BottomNavigation = props => {
  const getNextUnit = () => {
    const { unitsIdList, coldStorageId } = props;
    let currentIndex = unitsIdList.indexOf(coldStorageId);
    scrollToTop();
    return unitsIdList[currentIndex + 1]
      ? unitsIdList[currentIndex + 1]
      : 'disabled';
  };

  const getPreviousUnit = () => {
    const { unitsIdList, coldStorageId } = props;
    let currentIndex = unitsIdList.indexOf(coldStorageId);
    scrollToTop();
    return unitsIdList[currentIndex - 1]
      ? unitsIdList[currentIndex - 1]
      : 'disabled';
  };

  const getBottomNavigationUrl = getUnit => {
    const { projectId } = props;
    if (projectId) {
      return `/projects/${projectId}/units/${() => getUnit()}/statistics`;
    }
    return `/units/${() => getUnit()}/statistics`;
  };

  return (
    <div className="statistics__bottom">
      <ArrowLink
        linkTo={getBottomNavigationUrl(getPreviousUnit)}
        direction="left"
        text="Previous unit"
      />
      <ButtonLink onClick={() => scrollToTop()}>Scroll to top</ButtonLink>
      <ArrowLink
        linkTo={getBottomNavigationUrl(getNextUnit)}
        direction="right"
        text="Next unit"
      />
    </div>
  );
};

BottomNavigation.propTypes = {
  coldStorageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unitsIdList: PropTypes.array
};

export default BottomNavigation;
