import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, tag, className = '', ...rest }) => {
  const tags = {
    primary: 'btn-primary',
    default: 'btn-default',
    secondary: 'btn-secondary',
    light: 'btn-light',
    grey: 'btn-grey',
    opposite: 'btn-opposite'
  };

  const classByTag = tags[tag] || tags.default;

  return (
    <button className={`btn ${classByTag} ${className}`} {...rest}>
      {children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  tag: PropTypes.oneOf([
    'primary',
    'default',
    'secondary',
    'light',
    'grey',
    'opposite'
  ])
};

Button.defaultProps = {
  type: 'button',
  tag: 'default'
};

export default Button;
