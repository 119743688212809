import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../../actions/settings/users.actions";
import Component from "./Users";

const mapStateToProps = (state) => {
  return {
    loading: state.settings.users.loading,
    data: state.settings.users.data,
    sync: state.settings.users.sync,
    error: state.settings.users.error,
    isDemo: state.auth.isDemo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: bindActionCreators(actions.fetchUsers, dispatch),
  inviteUser: bindActionCreators(actions.inviteUser, dispatch),
  deleteUser: bindActionCreators(actions.deleteUser, dispatch),
  updateUserEmailAlertStatus: bindActionCreators(
    actions.updateUserEmailAlertStatus,
    dispatch
  ),
});

const ComponentWithRouter = withRouter(Component);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithRouter);
