import * as authTypes from '../constants/auth.types';
import { PURGE } from 'redux-persist';

const initialState = {
  token: '',
  email: '',
  roles: [],
  id: null,
  userIsLoggedIn: false,
  loading: false,
  isDemo: false,
  features: [],
  paymentStatus: '',
  userCompanyRegion: ''
};

const reducer = {
  [authTypes.USER_AUTH_UPDATE]: (state, action = { payload: {} }) => {
    return {
      ...state,
      token: action.payload.token,
      email: action.payload.email,
      roles: action.payload.roles,
      id: action.payload.id,
      userIsLoggedIn: !!action.payload.token,
      isDemo: action.payload.isDemo,
      features: action.payload.features,
      paymentStatus: action.payload.paymentStatus,
      userCompanyRegion: action.payload.userCompanyRegion
    };
  },
  [authTypes.LOADING]: state => ({
    ...state,
    loading: true
  }),
  [authTypes.USER_AUTH_CLEAN]: () => ({ ...initialState }),
  [PURGE]: () => ({
    ...initialState
  }),
  [authTypes.DONE_CHANGE_PASSWORD]: state => ({
    ...state,
    loading: false,
    error: ''
  }),
  [authTypes.ERROR_CHANGE_PASSWORD]: (
    state,
    action = { payload: { error: 'Change password error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [authTypes.DONE_RECOVER_PASSWORD]: state => ({
    ...state,
    loading: false,
    error: ''
  }),
  [authTypes.ERROR_RECOVER_PASSWORD]: (
    state,
    action = { payload: { error: 'Recover password error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [authTypes.DONE_RESET_PASSWORD]: state => ({
    ...state,
    loading: false,
    error: ''
  }),
  [authTypes.ERROR_RESET_PASSWORD]: (
    state,
    action = { payload: { error: 'Password reset error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [PURGE]: () => ({
    ...initialState
  })
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
