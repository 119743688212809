import React, { useState } from 'react';
import { Icon, Message } from 'semantic-ui-react';
import './ReferCardWithDropDown.scss';

const DeviceContent = ({ device }) => {
    const {
        // deviceId,
        // deviceName,
        batteryPercent,
        deviceOwner,
        gpsEnabled,
        wifiEnabled,
        powerButtonEnabled,
        estimatedBatteryLifeMinutes,
        measurementInterval,
        transmissionInterval,
    } = device;

    const gpsIconColor = gpsEnabled ? 'green' : 'red';
    const wifiIconColor = wifiEnabled ? 'green' : 'red';
    const powerButtonIconColor = powerButtonEnabled ? 'green' : 'red';

    return (
        <div className='refer-in-message'>

            <div className='refer-in-message-child'>

                <div>
                    <div className='refer-in-message-header'>
                        <div> Estimated Battery Life:</div>
                        <div className='refer-card-id'><h3>{estimatedBatteryLifeMinutes}</h3></div>
                        <div>minutes</div>
                    </div>

                </div>

                <div>
                    <div className='refer-in-message-header'>
                        <div>  Measurement Interval: </div>
                        <div className='refer-card-id'><h3>{measurementInterval}</h3></div>
                        <div>seconds</div>
                    </div>
                </div>

                <div>

                    <div className='refer-in-message-header'>
                        <div> Transmission Interval: </div>
                        <div className='refer-card-id'><h3>{transmissionInterval}</h3></div>
                        <div>seconds</div>
                    </div>
                </div>

            </div>

            <div className='refer-in-message-child'>

                <div className='refer-message-content-grid-row'>
                    <div>
                        Battery: {batteryPercent}%
                    </div>

                    <div>
                        Owner: {deviceOwner}
                    </div>
                </div>

                <div className='refer-message-content-grid-row'>
                    <div>
                        GPS: <Icon name={gpsEnabled ? 'check circle' : 'times circle'} color={gpsIconColor} />
                    </div>

                    <div>
                        WiFi: <Icon name={wifiEnabled ? 'check circle' : 'times circle'} color={wifiIconColor} />
                    </div>
                </div>

                <div className='refer-message-content-grid-row'>
                    <div>
                        Power Button:  <Icon name={powerButtonEnabled ? 'check circle' : 'times circle'} color={powerButtonIconColor} />
                    </div>
                </div>

            </div>


        </div>
    );
};



const ReferCardWithDropDown = ({ content }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='refer-card'>
            <div className='refer-card-header-div' onClick={handleExpandClick}>
                <div className='refer-card-id'><h3>{content?.deviceName}</h3></div>
                <div className="refer-card-expand">
                    <Icon name={`chevron ${isExpanded ? 'up' : 'down'}`} />
                </div>
            </div>


            <div className={`refer-card-content ${isExpanded ? 'expanded' : ''}`} onClick={handleExpandClick}>
                <Message>

                    <div>
                        <div className='refer-in-message-header'><div>Device ID:</div> <div className='refer-card-id'><h3>{content?.deviceId}</h3></div></div>
                        <div className='refer-in-message-header'><div> Device Name:</div><div className='refer-card-id'><h3>{content?.deviceName}</h3></div></div>
                    </div>

                    <hr className='refer-in-message-header-line' />

                    <DeviceContent device={content} />

                </Message>

            </div>
        </div>
    );
};

export default ReferCardWithDropDown;