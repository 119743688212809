import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import Page from '../../components/Page';
import Title from '../../components/shared/Title';
import { urlRoute } from '../../utils/url';
import Navigation from '../../components/shared/Navigation';
import Contact from './routes/Contact';

class Service extends Component {
  getRoutes = () => ({
    contact: {
      label: 'Contact',
      link: urlRoute(`/service`),
      path: urlRoute(`/service`)
    }
  });

  render() {
    const routes = this.getRoutes();
    return (
      <Page isContainer className="service">
        <Title title="Service" />
        <Navigation routes={routes} />
        <Switch>
          <Route exact path={routes.contact.path} component={Contact} />
        </Switch>
        <div className="service__container" />
      </Page>
    );
  }
}

Service.propTypes = {
  fetchAlarms: PropTypes.func,
  data: PropTypes.array
};

export default Service;
