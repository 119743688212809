import React from 'react'
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';



function ReferLive(props) {
    const {
        shipmentLoggerLiveData
    } = props;


    return (
        <Table color={'yellow'} unstackable>

            <Table.Header>
                <Table.HeaderCell colSpan={1}>
                    <p className="table-text">Logger Serial</p>

                </Table.HeaderCell>

                <Table.HeaderCell colSpan={1}>
                    <p className="table-text">Temp.</p>

                </Table.HeaderCell>


                <Table.HeaderCell colSpan={1}>
                    <p className="table-text">Hum</p>

                </Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>
                    <p className="table-text">Light</p>

                </Table.HeaderCell>
            </Table.Header>


            <Table.Body className='border-1'>


                {shipmentLoggerLiveData?.map((el, idx) => (
                    <>

                        <Table.Row className="project-row">
                            <Table.Cell colSpan={1}><p className="table-text">{el?.device_serial ?? '-'}</p></Table.Cell>
                            <Table.Cell colSpan={1}><p className="table-text">{el?.temperature ?? '-'}</p></Table.Cell>
                            <Table.Cell colSpan={1}><p className="table-text">{el?.humidity ?? '-'}</p></Table.Cell>
                            <Table.Cell colSpan={1}><p className="table-text">{el?.ambient_light !== null ? Math.round(el?.ambient_light || 0) : '-'}</p></Table.Cell>

                        </Table.Row>

                    </>
                ))}
            </Table.Body>
        </Table>
    )
}

const mapStateToProps = ({ shipments }) => {
    const {
        shipmentLoggerLiveData,
    } = shipments.shipmentsReducer;

    return {
        shipmentLoggerLiveData,
    };
};


export default connect(mapStateToProps, {
})(ReferLive);