import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Label from "../../../../components/shared/Label";
import Spinner from "../../../../components/shared/Spinner";
// import Table from "../../../../components/shared/Table";

import { urlRoute } from "../../../../utils/url";
// import { isUndefined, isNull } from "lodash";
// import * as unitsStatus from "../../../../constants/unitsStatus.enum";
import "./_my-units.scss";
// import { toVarySubFeatureRendering } from "../../../../utils/featureUtil";
// import * as featureTypes from '../../../../constants/feature-types';
import { Segment, Table } from 'semantic-ui-react'
import { useMediaQuery } from 'react-responsive'


function MyUnitsList(props) {
  useEffect(() => {
    const { fetchUnits } = props;
    fetchUnits(props.projectId);
    // eslint-disable-next-line
  }, [])

  const _getLinkToUnits = (rowId) => {
    const { projectId } = props;
    localStorage.setItem("selectedColdRoom", rowId);
    if (props.moreThenOneProject) {
      return urlRoute(`/projects/${projectId}/units/statistics`);
    } else {
      return urlRoute(`/units/statistics`);
    }
  };

  const handleRowClick = (row) => {
    props.history.push(_getLinkToUnits(row.id));
  };


  const { loadingDetails } = props;
  const {
    data,
    loading,
    sync,
    error,
    lastSeen
    // showPulpTemp,
    // showEthylene,
    // userRoles,
    // userPaymentStatus
  } = props;




  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })



  return (
    <div className="my-units__container">
      <Spinner active={loading && !sync} overlay />
      <Label type="error" label={error} />
      {loadingDetails ? (
        <Spinner overlay />
      ) : (
        lastSeen ?
          <Segment>
            <div color='red'>{`Site is currently offline. Last seen ${new Date(lastSeen).toLocaleString()}`}</div>
          </Segment>
          : data.length ? <Table color={'yellow'} unstackable>


            <Table.Body className='border-1'>

              {data.map((el, idx) => (
                <>
                  <Table.Row onClick={() => { handleRowClick(el) }}>
                    <Table.Cell colSpan={3}> <p className="header-text"> {el.name} </p></Table.Cell>
                  </Table.Row>

                  <Table.Row className="line-break" onClick={() => { handleRowClick(el) }}>
                    <Table.Cell colSpan={1} ><p className="table-text">{el.humidity ? `${isDesktopOrLaptop ? `Humidity` : `Hum`} ${el.humidity} %` : "-"}</p></Table.Cell>
                    <Table.Cell colSpan={1}><p className="table-text">{el.temperature ? `${isDesktopOrLaptop ? `Temperature` : `Temp`} ${el.temperature} °C` : "-"}</p></Table.Cell>
                    <Table.Cell colSpan={1}><p className="table-text">{el.doorRoom === 1 ? "Door Closed" : el.doorRoom === 0 ? "Door Open" : "-"}</p></Table.Cell>
                  </Table.Row>

                </>
              ))}
            </Table.Body>
          </Table>
            :
            <Segment>
              <div color='red'>{`Site is currently live. Contact support if no live data is visible`}</div>
            </Segment>

      )}
    </div>
  );

}

MyUnitsList.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fetchUnits: PropTypes.func,
  loading: PropTypes.bool,
  loadingDetails: PropTypes.bool,
  data: PropTypes.array,
  sync: PropTypes.bool,
  history: PropTypes.object,
  moreThenOneProject: PropTypes.bool,
};

export default MyUnitsList;
