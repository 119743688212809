import { SHIPMENT_ACTIVE_SHIPMENT_ID, SHIPMENT_DATA, SHIPMENT_GET, SHIPMENT_LOGGERS_DATA, SHIPMENT_LOGGERS_GET, SHIPMENT_STATS_DATA, SHIPMENT_STATS_GET } from "../../constants/shipments.types"
import api from "../../utils/api";


export const shipmentSetActiveShipment = (shipment) => {

    return {
        type: SHIPMENT_ACTIVE_SHIPMENT_ID,
        payload: shipment,
    }
}

export const fetchAllShipments = () => {
    return (dispatch) => {
        dispatch({
            type: SHIPMENT_GET,
        });
        api
            .get(`reefer/shipments`)

            .then((res) => {
                dispatch({
                    type: SHIPMENT_DATA,
                    payload: res.data,
                });
            })
            .catch((err) => { });
    };
};

export const fetchShipmentLoggersLiveData = (deviceSerials) => {
    return (dispatch) => {
        dispatch({
            type: SHIPMENT_LOGGERS_GET,
        });
        api
            .get(`reefer/shipments/loggers`,
                {
                    params: {
                        deviceIds: deviceSerials
                    },
                }
            )

            .then((res) => {
                dispatch({
                    type: SHIPMENT_LOGGERS_DATA,
                    payload: res.data,
                });
            })
            .catch((err) => { });
    };
};

export const fetchShipmentStatsData = (deviceId, startDate, endDate) => {
    return (dispatch) => {
        dispatch({
            type: SHIPMENT_STATS_GET,
        });
        api
            .get(`reefer/shipments/live-data`,
                {
                    params: {
                        deviceId,
                        startDate,
                        endDate
                    },
                }
            )

            .then((res) => {
                console.log(res.data)
                dispatch({
                    type: SHIPMENT_STATS_DATA,
                    payload: res.data,
                });
            })
            .catch((err) => { });
    };
};



