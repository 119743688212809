export const LOADING_EN_CONSUMPTION_DATA = '@reports/ENERGY_CONSUMPTION/LOADING';
export const DONE_FETCH_EN_CONSUMPTION_DATA = '@reports/ENERGY_CONSUMPTION/DONE_FETCH';
export const ERROR_FETCH_EN_CONSUMPTION_DATA = '@reports/ENERGY_CONSUMPTION/ERROR_FETCH';

export const LOADING_PRICE_PER_KG_DATA = '@reports/PRICE_PER_KG/LOADING';
export const DONE_FETCH_PRICE_PER_KG_DATA = '@reports/PRICE_PER_KG/DONE_FETCH';
export const ERROR_FETCH_PRICE_PER_KG_DATA = '@reports/PRICE_PER_KG/ERROR_FETCH';

export const LOADING_MIN_THROUGHPUT_DATA = '@reports/MIN_THROUGHPUT/LOADING';
export const DONE_FETCH_MIN_THROUGHPUT_DATA = '@reports/MIN_THROUGHPUT/DONE_FETCH';
export const ERROR_FETCH_MIN_THROUGHPUT_DATA = '@reports/MIN_THROUGHPUT/ERROR_FETCH';

export const LOADING_CALCULATE_PRICING_DATA = '@reports/CALCULATE_PRICING/LOADING';
export const DONE_FETCH_CALCULATE_PRICING_DATA = '@reports/CALCULATE_PRICING/DONE_FETCH';
export const ERROR_FETCH_CALCULATE_PRICING_DATA = '@reports/CALCULATE_PRICING/ERROR_FETCH';

export const LOADING_COLDSTORAGE_REPORTS_DATA = '@reports/COLDSTORAGE/LOADING';
export const DONE_COLDSTORAGE_REPORTS_DATA = '@reports/COLDSTORAGE/DONE_FETCH';
export const ERROR_COLDSTORAGE_REPORTS_DATA = '@reports/COLDSTORAGE/ERROR_FETCH';
export const CLEAN_DATA_REPORTS_DATA = '@reports/COLDSTORAGE/CLEAN_DATA';

export const LOADING_TOTAL_HANDLED_PRODUCE = '@reports/TOTAL_HANDLED_PRODUCE/LOADING';
export const DONE_TOTAL_HANDLED_PRODUCE = '@reports/TOTAL_HANDLED_PRODUCE/DONE_FETCH';
export const ERROR_TOTAL_HANDLED_PRODUCE = '@reports/TOTAL_HANDLED_PRODUCE/ERROR_FETCH';

export const LOADING_REPORT_ACCESS = '@reports/REPORT_ACCESS/LOADING';
export const DONE_REPORT_ACCESS = '@reports/REPORT_ACCESS/DONE_FETCH';
export const ERROR_REPORT_ACCESS = '@reports/REPORT_ACCESS/ERROR_FETCH';