import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { isUndefined } from "lodash";

import ChartTooltip from "../../../../../../../components/Charts/components/ChartTooltip";
import AreaLinearChart from "../../../../../../../components/Charts/AreaChart";
import * as colors from "../../../../../../../constants/colors";
import { roundTwoDigits } from "../../../../../../../utils/numberFormat";
import { cleanChartDataToBeContinous } from "../../../../../../../utils/chartUtils";
import { getLabelNameFormat } from "../../../../../../../helpers/chart.helper";
import { getListFromData } from "../../../../../../../helpers/object.helper";

export const HumidityChart = ({ syncId, data, humSetpointData }) => {
  const _getData = () => {
    const dataList = data ? data.list : [];
    // const humSetPoint = humSetpointData ? humSetpointData.list : [];

    const nameLabelFormat = getLabelNameFormat(getListFromData(data));

    return cleanChartDataToBeContinous(dataList, "value").map((element, index) => {
      const startDate = moment.utc(element.recDateTime).local();

      return {
        name: startDate.format(nameLabelFormat),
        startDate: startDate.format("HH:mm - DD.MM.YYYY"),
        humid: element.value ? Math.round(element.value * 100) / 100 : null,
        // setpoint: humSetPoint?.length > 0 && !isUndefined(humSetPoint[index]) ? Math.round((humSetPoint[index].value * 100) / 100) : null
      };
    });
  };

  const _getCustomTooltip = (e) => {
    const data = ((e.payload || [])[0] || {}).payload || {};

    return (
      <ChartTooltip>
        <p>
          HUMIDITY: <strong>{data.humid ? `${roundTwoDigits(data.humid)}%` : '--'}</strong>
        </p>
        {/* <p>
          SETPOINT: <strong>{roundTwoDigits(data.setpoint)}%</strong>
        </p> */}
        <small>{data.startDate}</small>
      </ChartTooltip>
    );
  };

  const _isLoading = () => {
    return ((!isUndefined(data) && data.loading === true) || (!isUndefined(humSetpointData) && humSetpointData.loading === true));
  };

  const getLegends = () => {
    let arrLegends = [{ key: "humid", color: colors.FIR_GREEN, label: "HUMIDITY" }];

    // if (!isUndefined(humSetpointData) && humSetpointData.loading === false && humSetpointData?.list?.length > 0) arrLegends.push({ key: "setpoint", color: colors.RED, label: "SETPOINT" })
    return arrLegends
  };

  const rangeLines = [
    // { x: 0, y: 60, label: '60% MAX', color: colors.LIGHT_GREY },
    // { x: 0, y: 40, label: '40% MIN', color: colors.LIGHT_GREY }
  ];

  return (
    <AreaLinearChart
      title="Humidity"
      syncId={syncId}
      data={_getData()}
      tooltip={_getCustomTooltip}
      legends={getLegends()}
      rangeLines={rangeLines}
      loading={_isLoading()}
      maxValue={100}
      minValue={0}
    />
  );
};

HumidityChart.propTypes = {
  syncId: PropTypes.string,
  data: PropTypes.object,
};

HumidityChart.defaultProps = {
  syncId: "syncId",
  data: undefined,
};

export default HumidityChart;
