import React, { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ButtonLink from './ButtonLink';
import { MOBILE_SIZE } from '../../constants/responsive';

const DateTimePicker = props => {
  const calendarRef = useRef(null);
  const isMobileWithSize = window.innerWidth < MOBILE_SIZE;
  const [date, setDate] = useState(props.value || new Date());

  const handleChange = e => {
    setDate(e);
  };

  useMemo(() => {
    setDate(props.value);
  }, [props.value]);

  const handleAccept = e => {
    let dateValue = date;

    if (props.minDate && moment(date).isBefore(moment(props.minDate))) {
      dateValue = props.minDate;
    }

    calendarRef.current.setOpen(false);
    props.onAccept(dateValue);
  };

  const handleCancel = () => {
    calendarRef.current.setOpen(false);
  };

  return (
    <div className="date-time-picker">
      <DatePicker
        ref={calendarRef}
        onChange={handleChange}
        selected={date || new Date()}
        withPortal={isMobileWithSize}
        timeInputLabel={props.timeLabel}
        showTimeInput
        shouldCloseOnSelect={false}
        dateFormat={props.formatDate}
        timeCaption="time"
        minDate={props.minDate}
        timeFormat="HH:mm">
        <div className="react-datepicker__buttons">
          <ButtonLink tag="secondary" onClick={handleCancel}>
            Cancel
          </ButtonLink>
          <ButtonLink onClick={handleAccept}>Apply</ButtonLink>
        </div>
      </DatePicker>
    </div>
  );
};

DateTimePicker.defaultProps = {
  onChange: () => null,
  onAccept: () => null,
  formatDate: 'dd.MM.yy - hh:mm a',
  formatTime: 'hh:mm',
  timeLabel: '',
  date: new Date(),
  minDate: null
};

DateTimePicker.propTypes = {
  onChange: PropTypes.func,
  onAccept: PropTypes.func,
  formatDate: PropTypes.string,
  formatTime: PropTypes.string,
  timeLabel: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  value: PropTypes.any,
  minDate: PropTypes.object
};

export default DateTimePicker;
