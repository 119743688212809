import statisticsTypes from './statisticsTypes';
import { dataDisplayTypes } from '../pages/MyUnits/Details/routes/LiveData/dataConfig';

export const chartOptions = [
  { value: statisticsTypes.generatorStatus, label: 'Generator status', unit: '', displayType: dataDisplayTypes.onOff, },
  { value: statisticsTypes.doorRoom, label: 'Door Room Sensor', unit: '', displayType: dataDisplayTypes.onOff, },
  { value: statisticsTypes.ethyleneProbe, label: 'Ethylene probe', unit: 'PPM', displayType: dataDisplayTypes.number, fixed: 2 },
  { value: statisticsTypes.cellHum, label: 'Humidity', unit: '%', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.batteryTemp, label: 'Battery temp', unit: '°C', displayType: dataDisplayTypes.number, fixed: 1 },
  { value: statisticsTypes.piercingProbeTemp, label: 'Pulp temp', unit: '°C', displayType: dataDisplayTypes.number, fixed: 1 },
  { value: statisticsTypes.cellTemp, label: 'Room temp', unit: '°C', displayType: dataDisplayTypes.number, fixed: 1 },
  { value: statisticsTypes.mainPower, label: 'Total power', unit: 'kW', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.maxRoomPower, label: 'Room power', unit: 'kW', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.compressorPower, label: 'Compressor power', unit: 'kW', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.mainEnergyConsumption, label: 'Total consumption', unit: 'kWh', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.roomEnergyConsumption, label: 'Room consumption', unit: 'kWh', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.compressorEnergyConsumption, label: 'Compressor consumption', unit: 'kWh', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.stockWeights, label: 'Stock weights', unit: 'kg', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.inverterVoltage, label: 'Inverter output voltage', unit: 'V', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.batteryVoltage, label: 'Battery voltage', unit: 'V', displayType: dataDisplayTypes.number, fixed: 1 },
  { value: statisticsTypes.batteryCurrent, label: 'Battery current', unit: 'A', displayType: dataDisplayTypes.number, fixed: 1 },
  { value: statisticsTypes.thermalStorageColdTemp, label: 'Cold water', unit: '°C', displayType: dataDisplayTypes.number, fixed: 2 },
  { value: statisticsTypes.batteryCharge, label: 'Battery charge', unit: '%', displayType: dataDisplayTypes.number, fixed: 2 },
  { value: statisticsTypes.energyPvAux, label: 'Energy generated from photovoltaic', unit: 'kWh', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.pressSwitch, label: 'Pressure switch', unit: '', displayType: dataDisplayTypes.onOff },
  { value: statisticsTypes.thr, label: 'Compressor motor throttle', unit: '%', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.ethValve, label: 'Ethylene valve status', unit: '', displayType: dataDisplayTypes.onOff },
  { value: statisticsTypes.cprValve, label: 'Refrigerator gas valve status', unit: '', displayType: dataDisplayTypes.onOff },
  { value: statisticsTypes.pressLp, label: 'Low pressure', unit: 'Bar', displayType: dataDisplayTypes.number, fixed: 1 },
  { value: statisticsTypes.pressHp, label: 'High pressure', unit: 'Bar', displayType: dataDisplayTypes.number, fixed: 1 },
  { value: statisticsTypes.externalTemp, label: 'External temp', unit: '°C', displayType: dataDisplayTypes.number, fixed: 1 },
  { value: statisticsTypes.externalHum, label: 'External hum', unit: '%', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.sunIrradiation, label: 'Sun irradiation', unit: 'w/m2', displayType: dataDisplayTypes.number, fixed: 0 },
  { value: statisticsTypes.rainGauge, label: 'Rain Gauge', unit: 'mm', displayType: dataDisplayTypes.number, fixed: 0 },
];

export const chartLayoutVariables = {
  xAxisTransform: 'translate(0,-3)',
  yAxisTransform: 'translate(-16,0)',
  xAxisHeight: 5,
  chartMargin: { top: 12, right: 10, left: -30, bottom: 16 }
};
