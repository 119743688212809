import React from 'react';
import PropTypes from 'prop-types';

import DateTimePicker from '../shared/DateTimePicker';
import FormGroup from '../shared/FormGroup';

const DateTimePickerForm = props => {
  const { input, label, className, minDate } = props;
  const value = input.value ? new Date(input.value) : new Date();

  return (
    <FormGroup>
      <label>{label}</label>
      <DateTimePicker
        onAccept={v => {
          input.onChange(v);
          input.onBlur();
        }}
        minDate={minDate}
        className={`form-input ${className}`}
        value={value}
      />
    </FormGroup>
  );
};

DateTimePickerForm.propTypes = {
  minDate: PropTypes.object
}

export default DateTimePickerForm;
