export const USER_AUTH_UPDATE = '@auth/USER_AUTH_UPDATE';
export const USER_AUTH_CLEAN = '@auth/USER_AUTH_CLEAN';

export const LOADING = '@auth/LOADING';
export const DONE_CHANGE_PASSWORD = '@auth/DONE_CHANGE_PASSWORD';
export const ERROR_CHANGE_PASSWORD = '@auth/ERROR_CHANGE_PASSWORD';

export const DONE_RECOVER_PASSWORD = '@auth/DONE_RECOVER_PASSWORD';
export const ERROR_RECOVER_PASSWORD = '@auth/ERROR_RECOVER_PASSWORD';

export const DONE_RESET_PASSWORD = '@auth/DONE_RESET_PASSWORD';
export const ERROR_RESET_PASSWORD = '@auth/ERROR_RESET_PASSWORD';
