export const LOADING = "@users/LOADING";
export const DONE_FETCH = "@users/DONE_FETCH";
export const ERROR_FETCH = "@users/ERROR_FETCH";
export const CLEAN_DATA = "@users/CLEAN_DATA";

export const DONE_INVITE_USER = "@users/DONE_INVITE_USER";
export const ERROR_INVITE_USER = "@users/ERROR_INVITE_USER";

export const DONE_USER_DELETE = "@users/DONE_USER_DELETE";
export const ERROR_USER_DELETE = "@users/ERROR_USER_DELETE";

export const DONE_CHANGE_PASSWORD = "@users/DONE_CHANGE_PASSWORD";
export const ERROR_CHANGE_PASSWORD = "@users/ERROR_CHANGE_PASSWORD";

export const DONE_RECOVER_PASSWORD = "@users/DONE_RECOVER_PASSWORD";
export const ERROR_RECOVER_PASSWORD = "@users/ERROR_RECOVER_PASSWORD";
