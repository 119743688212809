import * as unitsTypes from '../../constants/unitDetails.types';
import { PURGE } from 'redux-persist';

const initialState = {
  loading: false,
  sync: false,
  data: []
};

const reducer = {
  [unitsTypes.LOADING_STOCK]: state => ({
    ...state,
    loading: true,
    sync: false
  }),
  [unitsTypes.DONE_FETCH_STOCK]: (
    state,
    action = { coldStorageId: 0, payload: { data: {} } }
  ) => ({
    ...state,
    loading: false,
    data: action.payload.data,
    sync: true
  }),
  [unitsTypes.ERROR_FETCH_STOCK]: (
    state,
    action = { coldStorageId: 0, payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [unitsTypes.DONE_ADD_STOCK_ITEM]: state => ({
    ...state,
    loading: false,
    sync: true
  }),
  [unitsTypes.ERROR_ADD_STOCK_ITEM]: (
    state,
    action = { payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [unitsTypes.DONE_UPDATE_STOCK_ITEM]: state => ({
    ...state,
    loading: false,
    sync: true
  }),
  [unitsTypes.ERROR_UPDATE_STOCK_ITEM]: (
    state,
    action = { payload: { error: 'Updating error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [unitsTypes.CLEAN_DATA_STOCK]: state => ({
    ...state,
    loading: false,
    data: [],
    sync: false
  }),
  [PURGE]: () => ({
    ...initialState
  })
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
