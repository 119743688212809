import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import arrow from '../../../../../assets/images/arrow-right.svg';
import ButtonLink from '../../../../../components/shared/ButtonLink';
import Dropdown from '../../../../../components/shared/Dropdown';
import AddStockItemForm from './components/AddStockItemForm';
import DeleteStockItemModal from './components/DeleteStockItemModal';
import Label from '../../../../../components/shared/Label';
import { sum } from '../../../../../utils/arrays/sumArrayField';
import { roundTwoDigits } from '../../../../../utils/numberFormat';
import EditStockItemModalWrapper from './components/EditStockItemModalWrapper';
import SplitStockItemModalWrapper from './components/SplitStockItemModalWrapper';

import DraggableTable from '../../../../../components/shared/DraggableTable';

import './_stock.scss';
import CustomDragLayer from '../../../../../components/shared/CustomDragLayer';
import CustomDragLayerDispatched from '../../../../../components/shared/CustomDragLayerDispatched';
import DispatchStockItemModalWrapper from './components/DispatchStockItemModalWrapper';

import ReactGA from "react-ga";

import { STOCK } from '../../../../../constants/analyticsEventTypes';


//TODO change this implementation to use only one file called withTracker

ReactGA.initialize("UA-123201104-3", { debug: false });
ReactGA.pageview("/stock");

const Stock = props => {
  const {
    fetchStockData,
    coldStorageId,
    projectId,
    updateStockItem,
    splitStockItem,
    loading,
    addStockItem,
    data
  } = props;
  const [isDeleteItemModalOpen, setIsDeleteItemModalOpen] = useState(false);
  const [isEditStockItemModalOpen, setIsEditStockItemModalOpen] = useState(false);
  const [isSplitStockItemModalOpen, setIsSplitStockItemModalOpen] = useState(false);
  const [isDispatchStockItemModalOpen, setIsDispatchStockItemModalOpen] = useState(false);
  const [stockItem, setStockItem] = useState({});

  useMemo(() => {
    fetchStockData(coldStorageId, projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coldStorageId]);

  const showDeleteStockItemModal = item => {
    ReactGA.event({
      category: STOCK.Category,
      action: STOCK.DeleteStock,
      label: STOCK.DeleteStock
    });
    setIsDeleteItemModalOpen(true);
    setStockItem(item);
  };

  const hideDeleteStockItemModal = () => {
    setIsDeleteItemModalOpen(false);
  };

  const showEditStockItemModal = item => {
    ReactGA.event({
      category: STOCK.Category,
      action: STOCK.EditStock,
      label: STOCK.EditStock
    });
    setIsEditStockItemModalOpen(true);
    setStockItem(item);
  };
  const hideEditStockItemModal = () => {
    setIsEditStockItemModalOpen(false);
  };

  const showDispatchModal = item => {
    ReactGA.event({
      category: STOCK.Category,
      action: STOCK.DispatchStock,
      label: STOCK.DispatchStock
    });
    setIsDispatchStockItemModalOpen(true);
    setStockItem(item);
  }

  const hideDispatchStockItemModal = () => {
    setIsDispatchStockItemModalOpen(false);
  }

  const showSplitStockItemModal = item => {
    ReactGA.event({
      category: STOCK.Category,
      action: STOCK.SplitStock,
      label: STOCK.SplitStock
    });
    setIsSplitStockItemModalOpen(true);
    setStockItem(item);
  };

  const hideSplitStockItemModal = item => {
    setIsSplitStockItemModalOpen(false);
  };

  const onChange = item => event => {
    if (event.value === 'delete') {
      showDeleteStockItemModal(item);
    } else {
      updateStockItem(item.id, item, 'inStock', coldStorageId, projectId);
    }
  };

  const onChangeStock = item => event => {
    if (event.value === 'edit') {
      showEditStockItemModal(item);
    } else if (event.value === 'split') {
      showSplitStockItemModal(item);
    } else {
      showDispatchModal(item);
    }
  };

  const handleDispatch = item => {
    const inDate = moment(item.in).utc();
    const outDate = moment(item.out).utc();
    if (outDate.isBefore(inDate)) {
      item.out = item.in;
    }

    updateStockItem(item.id, item, 'dispatched', coldStorageId, projectId);
  };

  const getTotalWeight = data => {
    return sum(data, 'weight');
  };

  const tableStockConfig = {
    noData: 'No batches in stock',
    className: 'stock-table',
    footer: [
      { key: 'total', width: 15, label: 'TOTAL' },
      {
        key: 'summary',
        width: 14,
        textAlignItem: 'right',
        component: _ => (
          // <Label
          //   label={`${formatNumber(roundTwoDigits(
          //     getTotalWeight(data.filter(x => x.status === 'inStock'))
          //   ))} kg`}
          //   type="bold"
          // />
          <Label
            label={`${roundTwoDigits(
              getTotalWeight(data.filter(x => x.status === 'inStock'))
            ).toLocaleString()} kg`}
            type="bold"
          />
        )
      }
    ],
    headers: [
      { key: 'name', label: 'Name', width: 20 },
      {
        key: 'weight',
        label: 'Weight',
        width: 16,
        textAlignItem: 'right',
        component: row => (
          <Label type="bold" label={`${roundTwoDigits(row.weight).toLocaleString()}  kg`} />
        )
      },
      // {
      //   key: 'amount',
      //   label: 'Amount',
      //   width: 16,
      //   textAlignItem: 'right',
      //   component: row => <span>{row.amount ? `${row.amount.toLocaleString()} items` : '--'} </span>
      // },
      {
        key: 'in',
        label: 'Input Time',
        width: 50,
        component: row => (
          <div className="time">
            <Label type="bold" label="Time In:" />
            {moment(row.in).format('DD.MM.YY - hh:mm a')}
          </div>
        )
      },
      {
        key: 'dispatch',
        label: '',
        width: 20,
        textAlignItem: 'right',
        className: 'laptop',
        component: row => (
          <ButtonLink onClick={() => showDispatchModal(row)}>
            Dispatch <img src={arrow} alt="right-arrow" />
          </ButtonLink>
        )
      },
      {
        key: 'option',
        label: '',
        textAlignItem: 'right',
        width: 10,
        component: row => (
          <Dropdown
            placeholder=""
            className="options"
            onChange={onChangeStock(row)}
            value="..."
            options={[
              { value: 'edit', label: 'Edit' },
              { value: 'split', label: 'Split' },
              { value: 'dispatch', label: 'Dispatch' }
            ]}
          />
        )
      },
    ]
  };

  const tableDispatchedConfig = {
    noData: 'No dispatched items',
    headers: [
      { key: 'name', label: 'Name', width: 20 },
      {
        key: 'weight',
        label: 'Weight',
        width: 16,
        textAlignItem: 'right',
        component: row => (
          <Label type="bold" label={`${roundTwoDigits(row.weight).toLocaleString()} kg`} />
        )
      },
      // {
      //   key: 'amount',
      //   label: 'Amount',
      //   textAlignItem: 'right',
      //   width: 16,
      //   component: row => <span>{row.amount.toLocaleString()} items</span>
      // },
      {
        key: 'in',
        label: 'Input Time',
        width: 30,
        component: row => (
          <div className="time">
            <Label type="bold" label="Time In:" />{' '}
            {moment(row.in).format('DD.MM.YY - hh:mm a')}
          </div>
        )
      },
      {
        key: 'out',
        label: 'Output Time',
        width: 30,
        component: row => (
          <div className="time">
            <Label type="bold" label="Time Out:" />{' '}
            {moment(row.out).format('DD.MM.YY - hh:mm a')}
          </div>
        )
      },
      {
        key: 'option',
        label: '',
        textAlignItem: 'right',
        width: 7,
        component: row => (
          <Dropdown
            placeholder=""
            className="options"
            onChange={onChange(row)}
            value="..."
            options={[
              { value: 'delete', label: 'Delete' },
              { value: 'backToStock', label: 'Back to stock' }
            ]}
          />
        )
      }
    ]
  };

  const stock = data ? data.filter(x => x.status === 'inStock') : [];
  const dispatched = data ? data.filter(x => x.status === 'dispatched') : [];

  return (
    <div className="stock">
      {isDeleteItemModalOpen && (
        <DeleteStockItemModal
          header="Delete Item"
          confirmText="Delete"
          onCancel={hideDeleteStockItemModal}
          content="Are you sure that you want to delete this item?"
          deleteItem={updateStockItem}
          loading={loading}
          coldStorageId={coldStorageId}
          item={stockItem}
        />
      )}
      {isEditStockItemModalOpen && (
        <EditStockItemModalWrapper
          header="Edit Item"
          confirmText="Save"
          onCancel={hideEditStockItemModal}
          content="Are you sure that you want to delete this item?"
          editItem={updateStockItem}
          loading={loading}
          coldStorageId={coldStorageId}
          item={stockItem}
          initialValues={{
            name: stockItem.name,
            weight: stockItem.weight,
            amount: stockItem.amount,
            in: stockItem.in
          }}
        />
      )}
      {isSplitStockItemModalOpen && (
        <SplitStockItemModalWrapper
          header="Split Item"
          confirmText="Split"
          onCancel={hideSplitStockItemModal}
          content="Do you want to split this item to create two seperate items?"
          editItem={splitStockItem}
          loading={loading}
          coldStorageId={coldStorageId}
          item={stockItem}
          // initialValues={{
          //   weightA: stockItem.weight / 2,
          //   weightB: stockItem.weight / 2,
          //   amountA: stockItem.amount / 2,
          //   amountB: stockItem.amount / 2,
          // }}
        />
      )}
      {isDispatchStockItemModalOpen && (
        <DispatchStockItemModalWrapper
          header="Dispatch Item"
          confirmText="Dispatch"
          onCancel={hideDispatchStockItemModal}
          dispatchItem={handleDispatch}
          loading={loading}
          item={stockItem}
          initialValues={{
            out: new Date()
          }}
        />
      )}
      <div className="stock__container">
        <div className="stock__table stocked">
          <div className="stock__table__name">Stock</div>
          <AddStockItemForm
            addStockItem={addStockItem}
            coldStorageId={coldStorageId}
            projectId={projectId}
          />
          <DraggableTable
            accepts="dispatched"
            items={stock}
            config={tableStockConfig}
            className="stock-table"
            updateStockItem={updateStockItem}
            coldStorageId={coldStorageId}
          />
          <CustomDragLayer
            items={stock}
            config={tableStockConfig}
            type="inStock"
          />
        </div>
        <div className="stock__table dispatched">
          <div className="stock__table__name">Dispatched</div>
          <DraggableTable
            accepts="inStock"
            items={dispatched}
            config={tableDispatchedConfig}
            className="dispatch-table"
            updateStockItem={updateStockItem}
            coldStorageId={coldStorageId}
          />
          <CustomDragLayerDispatched
            items={dispatched}
            config={tableDispatchedConfig}
            type="dispatched"
          />
        </div>
      </div>
    </div>
  );
};

Stock.propTypes = {
  fetchStockData: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  sync: PropTypes.bool,
  coldStorageId: PropTypes.number,
  projectId: PropTypes.number,
  addStockItem: PropTypes.func,
  updateStockItem: PropTypes.func
};

export default Stock;
