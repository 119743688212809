import React from 'react';
import PropTypes from 'prop-types';
import { urlRoute } from '../../utils/url';
import Page from '../../components/Page';
import './_home.scss';

const Home = ({ userIsLoggedIn, logout, history }) => {
  history.push(urlRoute('/units'));

  return (
    <Page className="page home">
      <h2>InspiraFarms</h2>
    </Page>
  );
};

Home.propTypes = {
  userIsLoggedIn: PropTypes.bool,
  logout: PropTypes.func
};

export default Home;
