import React from "react";
import PropTypes from "prop-types";
import {
  CartesianGrid,
  Bar,
  BarChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import * as colors from "../../constants/colors";
import { chartLayoutVariables } from "../../constants/chartOptions";
import ReferenceLabel from "./components/ReferenceLabel";
import { checkIfChartDataExists } from "../../utils/chartUtils";
import Spinner from "../shared/Spinner";

const BarredChart = (props) => {
  const renderLoader = props.loading;

  return (
    <div className="chart">
      <div className="chart__header">
        <div className="chart__title">{props.title}</div>
        <div className="legends">
          {props.legends.map((v, i) => (
            <div key={i} className="legends__item">
              <div
                className="legends__item__dot"
                style={{ backgroundColor: v.color }}
              />
              <div className="legends__item__label" style={{ color: v.color }}>
                {v.label}
              </div>
            </div>
          ))}
        </div>
      </div>
      {checkIfChartDataExists(
        props.legends.map((x) => x.key),
        props.data
      ) ? (
        <div className="chart__container">
          <ResponsiveContainer>
            <BarChart
              isAnimationActive={false}
              width={480}
              height={300}
              data={props.data}
              margin={chartLayoutVariables.chartMargin}
              syncId={props.syncId}
            >
              <CartesianGrid vertical={false} horizontal={false} />
              <XAxis
                dataKey="name"
                stroke={colors.LIGHT_GREY}
                interval="preserveStartEnd"
                tickLine={false}
                axisLine={false}
                height={chartLayoutVariables.xAxisHeight}
                tick={{ transform: chartLayoutVariables.xAxisTransform }}
              />
              <YAxis
                type="number"
                domain={[props.minValue, props.maxValue]}
                tickLine={false}
                axisLine={false}
                textAnchor="start"
                stroke={colors.LIGHT_GREY}
                tickFormatter={props.yFormatter}
                // tick={{ transform: chartLayoutVariables.yAxisTransform }}
                tick={false}
              />
              <Tooltip
                cursor={{ fill: colors.LIGHT_GREY }}
                content={props.tooltip}
              />

              {props.rangeLines.map((v, i) => (
                <ReferenceLine
                  key={i}
                  y={v.y}
                  x={v.x}
                  stroke={v.color}
                  strokeDasharray="6 6"
                  label={
                    <ReferenceLabel
                      fill={colors.MEDIUM_GREY}
                      value={v.label}
                      textAnchor={v.label}
                      fontSize={12}
                      viewBox={480}
                      dy={0}
                    />
                  }
                />
              ))}

              {props.legends.map((v, i) => (
                <Bar
                  key={i}
                  type="monotone"
                  dataKey={v.key}
                  fill={v.color}
                  dot={false}
                  isAnimationActive={false}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
          <Spinner overlay active={renderLoader} />
        </div>
      ) : (
        <div
          className={`chart__container no-data ${
            renderLoader ? "loading" : ""
          }`}
        >
          {renderLoader ? <Spinner /> : ""}
        </div>
      )}
    </div>
  );
};

BarredChart.propTypes = {
  title: PropTypes.string,
  syncId: PropTypes.string,
  data: PropTypes.array,
  rangeLines: PropTypes.array,
  legends: PropTypes.array,
  tooltip: PropTypes.func,
};

BarredChart.defaultProps = {
  title: "",
  syncId: "lineChartId",
  rangeLines: [
    { x: 0, y: 8230, label: "6°C MAX", color: colors.MEDIUM_GREY },
    { x: 0, y: 1800, label: "4°C MIN", color: colors.MEDIUM_GREY },
  ],
  legends: [
    { color: colors.ORANGE, key: "uv", label: "PULP TEMP" },
    { color: colors.FIR_GREEN, key: "pv", label: "ROOM TEMP" },
  ],
  data: [
    {
      name: "A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
  ],
};

export default BarredChart;
