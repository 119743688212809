import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../../actions/service/contact.actions';
import Component from './Contact';

const mapStateToProps = state => {
  return {
    loading: state.service.contact.loading,
    data: state.service.contact.data,
    sync: state.service.contact.sync
  };
};

const mapDispatchToProps = dispatch => ({
  fetchContact: bindActionCreators(actions.fetchContact, dispatch),
  sendMessage: bindActionCreators(actions.sendMessage, dispatch)
});

const ComponentWithRouter = withRouter(Component);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithRouter);
