import React from 'react';
import { reduxForm, Form } from 'redux-form';
import PropTypes from 'prop-types';

import { Modal } from '../../../../../../components/shared/Modal';
import Spinner from '../../../../../../components/shared/Spinner';
import { compareTwoValuesWithProp } from '../../../../../../utils/validators';
// import InputForm from '../../../../../../components/Inputs/InputForm';
// import Label from '../../../../../../components/shared/Label';


class SplitStockItemModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      editItem: props.editItem,
      item: props.item,
      confirmText: props.confirmText,
      onCancel: props.onCancel,
      coldStorageId: props.coldStorageId,
      loading: props.loading,
      header: props.header,
      handleSubmit: props.handleSubmit,
      submit: props.submit
    };
    this.handleChange = this.handleChange.bind(this);
  }
     
  componentDidMount(){
    // const { item } = { ...this.state };
    // const currentStateItem = item;
    // currentStateItem['weightA'] = parseFloat(item.weight/2);
    // currentStateItem['weightB'] = parseFloat(item.weight/2);
    //this.setState({ item: currentStateItem }); 
    
  }

   submit = async values => {
   // console.log(">>>>", this.state.item)
    
     const weight_values = {
      //  amountA: 0.5,
      //  amountB: 0.5,
       weightA: parseFloat(this.state.item.weightA),
       weightB: parseFloat(this.state.item.weightB)
     }
    try {
      await this.state.editItem(this.state.item.id, weight_values, 'inStock', this.state.coldStorageId);
      this.state.onCancel();
    } catch (e) {
      return Promise.reject(e);
    }
  };
  handleChange(evt) {
   // console.log(">>>>", evt.target.name)
   
   // const weight = evt.target.value;
   const total_weight = this.state.item.weight;
    const new_weight_value =  evt.target.value;
    let next_input_weight_value = parseFloat(total_weight - new_weight_value).toFixed(2);
  
   

    const { item } = { ...this.state };
   const currentState = item;
   switch (evt.target.name) {
     case 'weightA':
      currentState['weightB'] = next_input_weight_value;
      currentState['weightA'] = evt.target.value;
      this.setState({ item: currentState });
       break;
    case 'weightB':
      currentState['weightA'] = next_input_weight_value;
      currentState['weightB'] = evt.target.value;
      this.setState({ item: currentState });
         break;
   
     default:
       break;
   }
  }

  render() {
    return (
      <Form
        onSubmit={this.state.handleSubmit(this.submit)}
        className="stock__edit-form edit-form"
        name="splitForm" >
        <Modal
          header={this.state.header}
          confirmText={this.state.confirmText}
          cancelText="Cancel"
          onCancel={this.state.onCancel}
          onAccept={this.state.submit} >
          <div className="edit-form__inputs">

         
            Weight_A: <input
            name="weightA"
            defaultValue={parseFloat(this.props.item.weight/2)}
            value={this.state.item.weightA}
            onChange={this.handleChange}
            style={{border: '#ccc 1px solid',
              borderBottom: '#ccc 2px solid',
              padding:' 8px',
              width:'250px',
              color:'#1f2f4a',
              marginTop:'10px',
              fontSize:'1em',
              borderRadius:'4px'}}
            >
            </input>
            <br></br>
            
            {/* <Field
              name="weightA"
              component={InputForm}
              type="number"
              placeholder="Weight A"
              label="BATCH A – KG"
              min={0}
              step={1} 
              value={this.state.item.weightA}
              defaultValue={parseFloat(this.props.item.weight/2)}
              onChange={this.handleChange}
             /> */}






            {/* <Field
              name="amountA"
              component={InputForm}
              type="number"
              placeholder="Amount A"
              label="BATCH A – amount"
              min={0}
              step={1} /> */}
     Weight_B: <label name="saddsf"></label>
            <input
            name="weightB"
            defaultValue={parseFloat(this.props.item.weight/2)}
            value={this.state.item.weightB}
            onChange={this.handleChange}
            style={{borderRadius:'4px',
              border: '#ccc 1px solid',
              padding: '8px',
              color:'#1f2f4a',
              width:'250px',
              marginTop:'10px',
              fontSize:'1em'}}
            >
            </input>


            {/* <Field
              name="weightB"
              component={InputForm}
              type="number"
              placeholder="Weight B"
              label="BATCH B – KG"
              min={0}
              step={1} 
              value={this.state.item.weightB}
              defaultValue={parseFloat(this.props.item.weight/2)}
              onChange={this.handleChange}
              /> */}




            {/* <Field
              name="amountB"
              component={InputForm}
              type="number"
              placeholder="Amount B"
              label="BATCH B – amount"
              min={0}
              step={1} /> */}
          </div>
          <Spinner active={this.state.loading} overlay />
        </Modal>
      </Form>
    );
  }
}

SplitStockItemModalWrapper.propTypes = {
  header: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  content: PropTypes.any,
  inviteUser: PropTypes.func,
  loading: PropTypes.bool,
  item: PropTypes.object
};

export default reduxForm({
  form: 'splitStockItemModalWrapper',
  validate: (values, {item}) => ({
    weightA: compareTwoValuesWithProp(
      values.weightA,
      values.weightB,
      item.weight,
      `The sum of the split values (A+B) must match the original stock = ${item.weight}`,
      "The sum of the split values must match the original stock"
    ),
    amountA: compareTwoValuesWithProp(
      values.amountA,
      values.amountB,
      item.amount,
      `The sum of the split values (A+B) must match the original stock = ${item.amount}`,
      "The sum of the split values must match the original stock"
    ),
  })
})(SplitStockItemModalWrapper);
