import React from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Modal } from '../../../components/shared/Modal';
import Spinner from '../../../components/shared/Spinner';
import DateTimePickerForm from '../../../components/Inputs/DateTimePickerForm';

const DispatchStockItemModalWrapper = props => {
  const {
    dispatchItem,
    item,
    confirmText,
    onCancel,
    loading,
    header,
    handleSubmit
  } = props;

  const submit = async values => {
    const editedItem = item;
    editedItem.out = values.out;

    try {
      await dispatchItem(editedItem);
      onCancel();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      className="stock__edit-form edit-form" >
      <Modal
        header={header}
        confirmText={confirmText}
        cancelText="Cancel"
        onCancel={onCancel}
        onAccept={props.submit} >
        <div className="edit-form__inputs">
          <Field name="out" minDate={new Date(item.in)} component={DateTimePickerForm} label="DISPATCH TIME" />
        </div>
        <Spinner active={loading} overlay />
      </Modal>
    </Form>
  );
};

DispatchStockItemModalWrapper.propTypes = {
  header: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  inviteUser: PropTypes.func,
  loading: PropTypes.bool
};

export default reduxForm({ form: 'editStockItemModalWrapper' })(DispatchStockItemModalWrapper);
