import React, { useEffect, useState } from "react";
import {
  Radio,
  Dropdown,
  Card,
  Input,
  Label,
  Button,
  Menu,
  Loader,
  Icon
} from "semantic-ui-react";
import { useMediaQuery } from 'react-responsive'
import {
  fetchSiteAlarmSendingStatus,
  fetchUserAlarmSendingStatus,
  updateUserAlarmSendingStatus,
  updateSiteAlarmSendingStatus,
  fetchSiteThreshold,
  updateSiteThreshold,
  updateThresholdNumberValues,
  updateColdStorageThreshold,
  fetchColdRoomThreshold,
  newColdStoargeThresholdValue,
  updateColdStorageAlarmSendingStatus
} from "../../../../actions/alarms/setting.action";
import { connect } from "react-redux";
// import Spinner from "../../../../components/shared/Spinner";
import Table from "../../../../components/shared/Table";
import CollapseSection from "../../../../components/shared/CollapseSection";
import "./_settings.scss";
import { alarmTypeUiField } from "../../../../constants/new-alarm.enum";
//import { element } from "prop-types";
// import { getProjectUnitsForDropDown } from "../../../../utils/auth/user";
import { getAvailableCountryContactPrefix, returnUnitsDropDown } from "../../../../utils/dropDownUtil"
import {
  fetchCompanyContact,
  newCompanyContactValues,
  updateCompanyContact,
  contactInputDelete,
  contactInputAdd,
  fetchCompanySmsStatus,
  updateCompanySmsStatus
} from "../../../../actions/alarms/sms.action";
import { toVarySubFeatureRendering } from "../../../../utils/featureUtil";
import * as featureTypes from '../../../../constants/feature-types';
import NumberIndicatorModule from "../../../../components/Charts/NumberIndicatorModule";
import * as colors from "../../../../constants/colors"
import { verifyCodeApi } from "../../../../api/sms.api";

function Settings(props) {
  const [showCollapse, setShowCollapse] = useState("");

  const {
    projectsData,
    fetchSiteAlarmSendingStatus,
    siteAlarms,
    userAlarms,
    // fetchingData,
    // fetchingIndividualData,
    userId,
    fetchUserAlarmSendingStatus,
    updateUserAlarmSendingStatus,
    updateSiteAlarmSendingStatus,
    fetchSiteThreshold,
    updateSiteThreshold,
    // fetchingThreshold,
    //features,
    thresholdData,
    updateThresholdNumberValues,
    projectsDataDropDown,
    isDemo,
    updateColdStorageThreshold,
    fetchColdRoomThreshold,
    tempThresholdData,
    humThresholdData,
    newColdStoargeThresholdValue,
    postingColdRoomThreshold,
    fetchCompanyContact,
    updateCompanyContact,
    companyContact,
    newCompanyContactValues,
    roles,
    paymentStatus,
    updateColdStorageAlarmSendingStatus,
    coldStorageAlarmStatus,
    contactInputDelete,
    contactInputAdd,
    companySmsStatus,
    fetchCompanySmsStatus,
    updateCompanySmsStatus
  } = props;

  const [thresholdProject, setThresholdProject] = useState(
    projectsDataDropDown[0].value
  );

  const [activeTempHumProject, setActiveTempHumProject] = useState(projectsDataDropDown[0]?.value || null)
  const [activeTempHumProjectColdRoomsDropdown, setActiveTempHumProjectColdRoomsDropdown] = useState(returnUnitsDropDown(projectsDataDropDown[0].units))
  const [activeTempHumProjectColdRoomsDropdownDeafult, setActiveTempHumProjectColdRoomsDropdownDefault] = useState(returnUnitsDropDown(projectsDataDropDown[0].units)[0].value || null)


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })


  const tableConfig = {
    headers: [
      {
        key: "projectUid",
        textAlignItem: "center",
        component: (row) => (
          <p>
            {isDemo ? findSingleProject(row.projectUid).text : row.projectUid}
          </p>
        ),
      },
      {
        key: "type",
        textAlignItem: "center",
        component: (row) => <p>{alarmTypeUiField[row.type]}</p>,
      },

      {
        key: "sendingMode",

        component: (row) => (
          <div>
            <Radio
              color="yellow"
              toggle
              checked={row.sendingMode === 1 ? true : false}
              onClick={(e, data) => {
                updateSiteAlarmSendingStatus(
                  row.projectUid,
                  data.checked === true ? 1 : 0,
                  row.type,
                  row.id
                );
              }}
            />

            {row.sendingMode === 1 ? (
              <div><p className="table-text">notifications enabled </p></div>
            ) : (
              <div><p className="table-text">notifications disabled </p></div>
            )}
          </div>
        ),
      },
    ],
  };

  useEffect(() => {
    fetchSiteAlarmSendingStatus(projectsData);
    fetchUserAlarmSendingStatus(userId);
    fetchSiteThreshold(isDemo ? "DEMO" : thresholdProject, "voltageMain");
    fetchColdRoomThreshold(activeTempHumProjectColdRoomsDropdownDeafult, activeTempHumProject)
    fetchCompanyContact();
    fetchCompanySmsStatus();
    // eslint-disable-next-line
  }, []);

  const verifyCode = (index) => {

    let data = {
      phoneNumber: companyContact[index].countryCode + companyContact[index].internalPhoneNumber,
      code: companyContact[index].smsCode,
      id: companyContact[index].id,
      codeHash: companyContact[index].codeHash
    };

    verifyCodeApi(data)
      .then((res) => {
        if (res.data.verified) {
          // setVerifyOrNotYet(prevState => ({ ...prevState, index, verify: false }));
          newCompanyContactValues("verifyOrNotYet", false, index);
          newCompanyContactValues("phoneNumberVerified", true, index);
          newCompanyContactValues("phoneNumber", companyContact[index].internalPhoneNumber, index);
          newCompanyContactValues("areContactsDifferent", false, index);
          newCompanyContactValues("smsCode", '', index);
          newCompanyContactValues("smsCodeError", '', index);
          // setArecContactsDifferent(false)
          // setSmsCode('');
        }
      })
      .catch(() => { newCompanyContactValues("smsCodeError", 'Failed to verify code. Try again', index); })
      .finally(() => { });
  }

  const isNumberUsedBefore = (countryCode, currentPhoneNumber, index) => {

    return companyContact.filter((e, i) => i !== index && e.internalPhoneNumber.toString() === currentPhoneNumber && e.internalCountryCode === countryCode)

  }

  const verifyPhoneNumber = (countryCode, phoneNumber, index) => {

    newCompanyContactValues("smsCode", '', index);
    newCompanyContactValues("smsCodeError", '', index);

    if (phoneNumber.length === 9 || phoneNumber.length === 11) {

      if (isNumberUsedBefore(countryCode, phoneNumber, index).length > 0) {
        newCompanyContactValues("phoneNumberError", 'Number already exists', index);
        return;
      }

      newCompanyContactValues("phoneNumberError", '', index);
      return
    }

    // setPhoneNumberError('Please enter a valid phone number');
    newCompanyContactValues("phoneNumberError", 'Please enter a valid phone number', index);

  }

  const verifySmsCodeInput = (smsCode, index) => {

    if (smsCode.length === 7) {
      newCompanyContactValues("smsCodeError", '', index);
      return;
    }

    newCompanyContactValues("smsCodeError", 'please enter required number of digits', index);

  }




  const findSingleProject = (projectUid) => {
    return projectsDataDropDown.find((e) => e.value === projectUid);
  };



  const onTempHumProjectClick = (projectUid, units) => {

    if (postingColdRoomThreshold) {
      return;
    }

    let dat = returnUnitsDropDown(units)
    setActiveTempHumProject(projectUid);
    setActiveTempHumProjectColdRoomsDropdown(dat)
    setActiveTempHumProjectColdRoomsDropdownDefault(dat ? dat[0].value : null)
    fetchColdRoomThreshold(dat ? dat[0].value : null, projectUid)
  }

  const onTempHumProjectColdRoomChange = (coldStorageId) => {

    if (postingColdRoomThreshold) {
      return;
    }
    setActiveTempHumProjectColdRoomsDropdownDefault(coldStorageId)
    fetchColdRoomThreshold(coldStorageId, activeTempHumProject)
  }


  const saveColdStorageThresholdData = () => {

    if (!isTempHumAlarmThresholdInputValid()) {
      return;
    }
    updateColdStorageThreshold(activeTempHumProject, {
      idealValue: tempThresholdData?.idealValue,
      minimumAllowedValue: tempThresholdData?.minimumAllowedValue,
      maximumAllowedValue: tempThresholdData?.maximumAllowedValue,
      id: tempThresholdData?.id,
      type: 'temperature',
      projectUid: activeTempHumProject,
      coldStorageId: activeTempHumProjectColdRoomsDropdownDeafult

    }, {

      idealValue: humThresholdData?.idealValue,
      minimumAllowedValue: humThresholdData?.minimumAllowedValue,
      maximumAllowedValue: humThresholdData?.maximumAllowedValue,
      id: humThresholdData?.id,
      type: 'humidity',
      projectUid: activeTempHumProject,
      coldStorageId: activeTempHumProjectColdRoomsDropdownDeafult

    })
  }

  const isTempHumAlarmThresholdInputValid = () => {

    return tempThresholdData?.minimumAllowedValue === '' ||
      (!tempThresholdData?.setPointValue && tempThresholdData?.idealValue === '') ? false : true
  }

  const isVoltageThresholdInputValid = () => {

    if (thresholdData?.idealValue === '' || thresholdData?.allowedNegativeDeviation === '' || thresholdData?.allowedPositiveDeviation === '') {
      return false;
    }


    return true;
  }

  return (
    <div>
      {(
        <>
          <div className="enabled-disabled-container">
            <Radio
              color="yellow"
              toggle
              checked={userAlarms.sendingMode === 1 ? true : false}
              onClick={(e, data) => {
                updateUserAlarmSendingStatus(
                  userId,
                  data.checked === true ? 1 : 0
                );
              }}
            />
            <div>
              {userAlarms.sendingMode === 1 ? (
                <p className="table-text">notifications enabled</p>
              ) : (
                <p className="table-text">notifications disabled</p>
              )}
            </div>
          </div>

          {
            toVarySubFeatureRendering(featureTypes.ALARMS_SETTINGS_SMS, paymentStatus, roles) && <div class="enabled-disabled-container sms-disabled-container">
              <Radio
                color="yellow"
                toggle
                checked={companySmsStatus.sendingMode === 1 ? true : false}
                onClick={(e, data) => {
                  updateCompanySmsStatus(
                    companySmsStatus.id,
                    data.checked === true ? 1 : 0
                  );
                }}
              />
              <div>
                {companySmsStatus.sendingMode === 1 ? (
                  <p className="table-text">sms enabled</p>
                ) : (
                  <p className="table-text">sms disabled</p>
                )}
              </div>
            </div>}

          {toVarySubFeatureRendering(featureTypes.ALARMS_SETTINGS_SMS, paymentStatus, roles) &&


            companyContact.map((currentCompanyContact, index) => {

              return (
                <>
                  <div class="enabled-disabled-container" key={index}>
                    <div className="company-contact">

                      <Dropdown
                        placeholder="Prefix"
                        scrolling
                        fluid
                        search
                        selection
                        options={getAvailableCountryContactPrefix()}
                        // defaultValue={getAvailableCountryContactPrefix()[0].value}
                        onChange={(event, { value }) => {
                          // setVerifyOrNotYet(false);
                          newCompanyContactValues("verifyOrNotYet", false, index);

                          if (currentCompanyContact.internalPhoneNumber.toString() === currentCompanyContact.phoneNumber.toString() && value === currentCompanyContact.countryCode) {
                            newCompanyContactValues("areContactsDifferent", false, index);
                            // setArecContactsDifferent(false);
                          } else {
                            newCompanyContactValues("areContactsDifferent", true, index);
                            // setArecContactsDifferent(true);

                          }

                          newCompanyContactValues("internalCountryCode", value, index);
                          verifyPhoneNumber(value, currentCompanyContact.internalPhoneNumber, index);

                        }}
                        value={currentCompanyContact.internalCountryCode}
                      />
                    </div>

                    <div className="number-input">
                      <Input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}

                        onChange={(e) => {

                          // setVerifyOrNotYet(false);
                          newCompanyContactValues("verifyOrNotYet", false, index);

                          if (e.target.value === currentCompanyContact.phoneNumber.toString() && currentCompanyContact.internalCountryCode === currentCompanyContact.countryCode) {
                            newCompanyContactValues("areContactsDifferent", false, index);
                            // setArecContactsDifferent(false);
                          } else {
                            newCompanyContactValues("areContactsDifferent", true, index);
                            // setArecContactsDifferent(true);

                          }

                          newCompanyContactValues("internalPhoneNumber", e.target.value, index);

                          verifyPhoneNumber(currentCompanyContact.internalCountryCode, e.target.value, index)

                          // updateThresholdNumberValues(
                          //   "idealValue",
                          //   Math.abs(e.target.value)
                          // );
                        }}
                        // labelPosition="right"

                        placeholder={isDemo ? "Purchase a subscription to add a phone number. This is a demo account" : "00"}
                        value={currentCompanyContact.internalPhoneNumber}
                        className="number-input-width"
                        fluid
                        icon
                        disabled={isDemo}
                        // loading
                        error={currentCompanyContact.internalPhoneNumber.length !== 0 && currentCompanyContact.phoneNumberError}



                      >
                        <input />
                        <Icon color={`${currentCompanyContact.areContactsDifferent ? 'red' : currentCompanyContact.phoneNumberVerified ? 'green' : 'red'}`} name={`${currentCompanyContact.areContactsDifferent ? 'exclamation circle' : currentCompanyContact.phoneNumberVerified ? 'check circle outline' : 'exclamation circle'}`} />

                      </Input>
                      {currentCompanyContact.phoneNumberError === "Number already exists" && <small style={{ color: colors.RED, fontSize: '0.6em' }}>{currentCompanyContact.phoneNumberError}</small>}

                    </div>


                    {index > 0 && <div className="number-delete-icon">
                      <Icon fitted name=" trash alternate outline" className="add-minus-icon" onClick={() => { contactInputDelete(currentCompanyContact.id, index); index < companyContact.length ? verifyPhoneNumber(currentCompanyContact.internalPhoneNumber.toString(), index) : verifyPhoneNumber(companyContact[index - 1].internalPhoneNumber.toString(), index - 1) }} />
                    </div>}

                    {((index === companyContact.length - 1 && toVarySubFeatureRendering(featureTypes.ALARMS_SETTINGS_SMS_PLUS_ICON, paymentStatus, roles)) || (index === companyContact.length - 1 && !toVarySubFeatureRendering(featureTypes.ALARMS_SETTINGS_SMS_PLUS_ICON, paymentStatus, roles) && index < 1)) && <div className="number-icon">
                      <Icon fitted name="plus" className="add-minus-icon" onClick={() => { contactInputAdd(); verifyPhoneNumber('', index + 1) }} />
                    </div>}


                  </div>



                  {currentCompanyContact.verifyOrNotYet && <div class="enabled-disabled-container" >
                    <div className="number-input">
                      <Input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {

                          const val = Math.abs(e.target.value)

                          if (val === 0) {
                            newCompanyContactValues("smsCode", '', index);

                            // setSmsCode('')
                          } else {
                            newCompanyContactValues("smsCode", val, index);

                            // setSmsCode(val)
                          }

                          verifySmsCodeInput(val.toString(), index);

                        }}




                        // labelPosition="right"

                        placeholder="Enter verification code..."
                        value={currentCompanyContact.smsCode}
                        className="code-input-width"
                        fluid
                        error={currentCompanyContact.smsCode.length !== 0 && currentCompanyContact.smsCodeError}


                      >
                        <input />


                      </Input>
                      {currentCompanyContact.smsCodeError === "Failed to verify code. Try again" && <small style={{ color: colors.RED, fontSize: '0.6em' }}>{currentCompanyContact.smsCodeError}</small>}

                    </div>
                    <div className="number-delete-icon">
                      <Icon fitted name="close" className="add-minus-icon" onClick={() => { newCompanyContactValues("verifyOrNotYet", false, index) }} />
                    </div>

                  </div>
                  }



                  {((!currentCompanyContact.verifyOrNotYet && !currentCompanyContact.phoneNumberVerified) || (!currentCompanyContact.verifyOrNotYet && currentCompanyContact.areContactsDifferent)) && <div>



                    <Button
                      onClick={(e) => {



                        let dat = {};

                        dat.id = currentCompanyContact.id;
                        dat.phoneNumber = Math.abs(currentCompanyContact.internalPhoneNumber);
                        dat.companyId = currentCompanyContact.companyId;
                        dat.countryCode = currentCompanyContact.internalCountryCode;


                        // setVerifyOrNotYet(true);

                        newCompanyContactValues("verifyOrNotYet", true, index);

                        updateCompanyContact(dat, index)
                        // updateSiteThreshold({
                        //   ...thresholdData,
                        //   type: "voltageMain",
                        //   projectUid: isDemo ? "DEMO" : thresholdProject,
                        // })
                      }}
                      size="small"
                      color="yellow"
                      disabled={currentCompanyContact.phoneNumberError !== '' || isDemo}
                    // fluid
                    >
                      Get verification code
                    </Button>
                  </div>}

                  {currentCompanyContact.phoneNumberError === '' && currentCompanyContact.verifyOrNotYet && <div>
                    <Button
                      onClick={(e) => {

                        verifyCode(index);




                        // let dat = companyContact;
                        // dat.phoneNumber = Math.abs(internalCompanyPhoneNumber);


                        // updateCompanyContact(dat)
                        // updateSiteThreshold({
                        //   ...thresholdData,
                        //   type: "voltageMain",
                        //   projectUid: isDemo ? "DEMO" : thresholdProject,
                        // })
                      }}
                      size="small"
                      color="yellow"
                      disabled={currentCompanyContact.smsCodeError !== ''}
                    // fluid
                    >

                      Verify code
                    </Button>
                  </div>}


                </>


              )

            })









          }



          <CollapseSection
            name="Site Notifications"
            isOpened={showCollapse === "site" ? true : false}
            onToggleCollapse={() => {
              if (showCollapse === "site") {
                setShowCollapse("");
                return;
              }

              setShowCollapse("site");
              return;
            }}
          >
            {/* <div className="site-notifications"> */}
            <Table items={siteAlarms} config={tableConfig} />
            {/* </div> */}
          </CollapseSection>

          <CollapseSection
            name="Over/Under Voltage Thresholds"
            isOpened={showCollapse === "threshold" ? true : false}
            onToggleCollapse={() => {
              if (showCollapse === "threshold") {
                setShowCollapse("");
                return;
              }

              setShowCollapse("threshold");
              return;
            }}
          >
            <>
              <Card fluid color="yellow" header="Option 3">

                {
                  isDesktopOrLaptop ?

                    <div className="project-grid-separator">


                      <div className="project-grid-separator-left-left">

                        <Menu fluid vertical pointing>

                          {projectsDataDropDown.map(el => <Menu.Item as='a' onClick={(e) => {
                            setThresholdProject(el.value); fetchSiteThreshold(
                              isDemo ? "DEMO" : el.value,
                              "voltageMain"
                            );
                          }} color={thresholdProject === el.value ? "yellow" : null} name={el.text} active={thresholdProject === el.value} />)}

                        </Menu>

                      </div>

                      <div className="project-grid-separator-right-right">


                        <div className="threshold-card-inputs-temp-hum">
                          <div className="threshold-card-inputs-temp-hum-text-input">
                            <p>Desired value:</p>
                          </div>




                          <div className="threshold-card-inputs-temp-hum-text-input">
                            <Input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => {

                                const val = Math.abs(e.target.value)

                                if (val === 0) {
                                  updateThresholdNumberValues(
                                    "idealValue",
                                    ''
                                  )
                                } else {
                                  updateThresholdNumberValues(
                                    "idealValue",
                                    val
                                  );

                                }

                              }}
                              labelPosition="right"

                              placeholder="00"
                              value={thresholdData?.idealValue}
                              fluid
                            >
                              <input />
                              <Label color="yellow">Volts</Label>
                            </Input>
                          </div>

                        </div>
                        <div className="threshold-card-inputs-temp-hum">
                          <div className="threshold-card-inputs-temp-hum-text-input">
                            <p>Maximum Negative deviation:</p>
                          </div>


                          <div className="threshold-card-inputs-temp-hum-text-input">
                            <Input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => {

                                const val = Math.abs(e.target.value)

                                if (val === 0) {

                                  updateThresholdNumberValues(
                                    "allowedNegativeDeviation",
                                    ""
                                  );

                                } else {

                                  updateThresholdNumberValues(
                                    "allowedNegativeDeviation",
                                    val
                                  );

                                }

                              }}
                              labelPosition="right"

                              placeholder="00"
                              value={thresholdData?.allowedNegativeDeviation}
                              fluid
                            >
                              <input />
                              <Label color="yellow">%</Label>
                            </Input>
                          </div>

                        </div>

                        <div className="threshold-card-inputs-temp-hum">
                          <div className="threshold-card-inputs-temp-hum-text-input">
                            <p>Maximum Positive Deviation:</p>
                          </div>
                          <div className="threshold-card-inputs-temp-hum-text-input">
                            <Input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => {

                                const val = Math.abs(e.target.value)

                                if (val === 0) {
                                  updateThresholdNumberValues(
                                    "allowedPositiveDeviation",
                                    ""
                                  );
                                } else {

                                  updateThresholdNumberValues(
                                    "allowedPositiveDeviation",
                                    val
                                  );

                                }

                              }}
                              labelPosition="right"

                              placeholder="00"
                              value={thresholdData?.allowedPositiveDeviation}
                              fluid
                            >
                              <input />
                              <Label color="yellow">%</Label>
                            </Input>
                          </div>

                        </div>

                        {
                          !isVoltageThresholdInputValid() ?
                            <div className="threshold-card-temp-hum-validation-error">
                              Please enter valid numbers for all the inputs
                            </div> : null
                        }

                        <div className="threshold-card-inputs-temp-hum">

                          <Button
                            onClick={(e) => {

                              if (!isVoltageThresholdInputValid()) {
                                return
                              }
                              updateSiteThreshold({
                                ...thresholdData,
                                type: "voltageMain",
                                projectUid: isDemo ? "DEMO" : thresholdProject,
                              })
                            }}
                            size="large"
                            color="yellow"
                            disabled={!isVoltageThresholdInputValid()}
                          >
                            Update
                          </Button>
                        </div>


                      </div>
                    </div> :

                    <>


                      <div className="tab-project-list">

                        <Menu fluid tabular>

                          {projectsDataDropDown.map(el => <Menu.Item as='a' onClick={(e) => {
                            setThresholdProject(el.value); fetchSiteThreshold(
                              isDemo ? "DEMO" : el.value,
                              "voltageMain"
                            );
                          }} color={thresholdProject === el.value ? "yellow" : null} name={el.text} active={thresholdProject === el.value} />)}

                        </Menu>
                      </div>






                      <div className="threshold-card-inputs-temp-hum">
                        <div className="threshold-card-inputs-temp-hum-text-input">
                          <p>Desired value:</p>
                        </div>




                        <div className="threshold-card-inputs-temp-hum-text-input">
                          <Input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => {

                              const val = Math.abs(e.target.value)

                              if (val === 0) {
                                updateThresholdNumberValues(
                                  "idealValue",
                                  ''
                                )
                              } else {
                                updateThresholdNumberValues(
                                  "idealValue",
                                  val
                                );

                              }

                            }}
                            labelPosition="right"

                            placeholder="00"
                            value={thresholdData?.idealValue}
                            fluid
                          >
                            <input />
                            <Label color="yellow">Volts</Label>
                          </Input>
                        </div>

                      </div>
                      <div className="threshold-card-inputs-temp-hum">
                        <div className="threshold-card-inputs-temp-hum-text-input">
                          <p>Maximum Negative deviation:</p>
                        </div>


                        <div className="threshold-card-inputs-temp-hum-text-input">
                          <Input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => {

                              const val = Math.abs(e.target.value)

                              if (val === 0) {

                                updateThresholdNumberValues(
                                  "allowedNegativeDeviation",
                                  ""
                                );

                              } else {

                                updateThresholdNumberValues(
                                  "allowedNegativeDeviation",
                                  val
                                );

                              }

                            }}
                            labelPosition="right"

                            placeholder="00"
                            value={thresholdData?.allowedNegativeDeviation}
                            fluid
                          >
                            <input />
                            <Label color="yellow">%</Label>
                          </Input>
                        </div>

                      </div>

                      <div className="threshold-card-inputs-temp-hum">
                        <div className="threshold-card-inputs-temp-hum-text-input">
                          <p>Maximum Positive Deviation:</p>
                        </div>
                        <div className="threshold-card-inputs-temp-hum-text-input">
                          <Input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => {

                              const val = Math.abs(e.target.value)

                              if (val === 0) {
                                updateThresholdNumberValues(
                                  "allowedPositiveDeviation",
                                  ""
                                );
                              } else {

                                updateThresholdNumberValues(
                                  "allowedPositiveDeviation",
                                  val
                                );

                              }

                            }}
                            labelPosition="right"

                            placeholder="00"
                            value={thresholdData?.allowedPositiveDeviation}
                            fluid
                          >
                            <input />
                            <Label color="yellow">%</Label>
                          </Input>
                        </div>

                      </div>

                      {
                        !isVoltageThresholdInputValid() ?
                          <div className="threshold-card-temp-hum-validation-error">
                            Please enter valid numbers for all the inputs
                          </div> : null
                      }

                      <div className="threshold-card-inputs-temp-hum">

                        <Button
                          onClick={(e) => {

                            if (!isVoltageThresholdInputValid()) {
                              return
                            }
                            updateSiteThreshold({
                              ...thresholdData,
                              type: "voltageMain",
                              projectUid: isDemo ? "DEMO" : thresholdProject,
                            })
                          }}
                          size="large"
                          color="yellow"
                          disabled={!isVoltageThresholdInputValid()}
                        >
                          Update
                        </Button>
                      </div>



                    </>
                }






              </Card>
            </>
          </CollapseSection>

          {toVarySubFeatureRendering(featureTypes.ALARMS_SETTINGS_TEMP_HUM_THRESHOLD, paymentStatus, roles) &&
            <CollapseSection
              name="Temperature Thresholds"
              isOpened={showCollapse === "tempHum" ? true : false}
              onToggleCollapse={() => {
                if (showCollapse === "tempHum") {
                  setShowCollapse("");
                  return;
                }

                setShowCollapse("tempHum");
                return;
              }}
            >
              <>




                <Card fluid color="yellow">
                  <div className="tab-project-list">
                    <Menu fluid tabular>

                      {projectsDataDropDown.map(el => <Menu.Item as='a' onClick={(e) => onTempHumProjectClick(el.value, el.units)} color={activeTempHumProject === el.value ? "yellow" : null} name={el.text} active={activeTempHumProject === el.value} />)}

                    </Menu>
                  </div>

                  {
                    isDesktopOrLaptop ?

                      <div className="project-grid-separator">

                        <div className="project-grid-separator-left-left">

                          <Menu fluid vertical pointing>

                            {activeTempHumProjectColdRoomsDropdown.map(el => <Menu.Item as='a' onClick={(e) => onTempHumProjectColdRoomChange(el.value)} color={activeTempHumProjectColdRoomsDropdownDeafult === el.value ? "yellow" : null} name={el.text} active={activeTempHumProjectColdRoomsDropdownDeafult === el.value} />)}

                          </Menu>
                        </div>

                        <div className="project-grid-separator-right-right">
                          <div className="threshold-card-inputs-temp-hum">
                            <Radio
                              color="yellow"
                              toggle
                              checked={coldStorageAlarmStatus.sendingMode === 1 ? true : false}
                              onClick={(e, data) => {
                                updateColdStorageAlarmSendingStatus(
                                  activeTempHumProject,
                                  data.checked === true ? 1 : 0,
                                  coldStorageAlarmStatus.doorSendingMode,
                                  activeTempHumProjectColdRoomsDropdownDeafult,
                                  coldStorageAlarmStatus.id
                                );
                              }}
                            />
                            <div>
                              {coldStorageAlarmStatus.sendingMode === 1 ? (
                                "notifications enabled"
                              ) : (
                                "notifications disabled"
                              )}
                            </div>

                            <Radio
                              color="yellow"
                              toggle
                              checked={coldStorageAlarmStatus.doorSendingMode === 1 ? true : false}
                              onClick={(e, data) => {
                                updateColdStorageAlarmSendingStatus(
                                  activeTempHumProject,
                                  coldStorageAlarmStatus.sendingMode,
                                  data.checked === true ? 1 : 0,
                                  activeTempHumProjectColdRoomsDropdownDeafult,
                                  coldStorageAlarmStatus.id
                                );
                              }}
                            />

                            <div>
                              {coldStorageAlarmStatus.doorSendingMode === 1 ? (
                                "notifications enabled when door is open/closed"
                              ) : (
                                "notifications disabled when door is open"
                              )}
                            </div>
                          </div>



                          <div className="threshold-card-inputs-temp-hum">




                            <NumberIndicatorModule
                              title="Current temperature setpoint on site"
                              // subTitle="for the chosen time span"
                              color={colors.ORANGE}
                              entity="°C"
                              value={tempThresholdData?.setPointValue === null ? '' : tempThresholdData?.setPointValue
                              }
                            ></NumberIndicatorModule>










                          </div>


                          <div className="threshold-card-inputs-temp-hum">
                            <div className="threshold-card-inputs-temp-hum-divider-text" />


                          </div>










                          {tempThresholdData?.setPointValue === null ? <div className="threshold-card-inputs-temp-hum">
                            <div className="threshold-card-inputs-temp-hum-text-input">
                              <p>Desired target value in case a setpoint is not available from the site:</p>
                            </div>

                            <div className="threshold-card-inputs-temp-hum-text-input">
                              <Input
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(e) => {

                                  const val = Math.abs(e.target.value)

                                  if (val === 0) {

                                    newColdStoargeThresholdValue('tempThresholdData', {
                                      ...tempThresholdData, idealValue: ''
                                    })

                                  } else {

                                    newColdStoargeThresholdValue('tempThresholdData', {
                                      ...tempThresholdData, idealValue: val
                                    })

                                  }



                                }}
                                labelPosition="right"

                                placeholder="00"
                                value={tempThresholdData?.idealValue}
                                fluid
                              >
                                <input />
                                <Label color="yellow">°C</Label>
                              </Input>
                            </div>
                          </div> : null}





                          <div className="threshold-card-inputs-temp-hum">

                            <div className="threshold-card-inputs-temp-hum-text-input">
                              <p>Allowed Deviation:</p>
                            </div>

                            <div className="threshold-card-inputs-temp-hum-text-input">
                              <Input
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(e) => {

                                  const val = Math.abs(e.target.value)

                                  if (val === 0) {

                                    newColdStoargeThresholdValue('tempThresholdData', {
                                      ...tempThresholdData, minimumAllowedValue: ''
                                    })

                                  } else {

                                    newColdStoargeThresholdValue('tempThresholdData', {
                                      ...tempThresholdData, minimumAllowedValue: val
                                    })

                                  }


                                }}
                                labelPosition="right"

                                placeholder="00"
                                value={tempThresholdData?.minimumAllowedValue}
                                fluid
                              >
                                <input />
                                <Label color="yellow">+/- °C</Label>
                              </Input>
                            </div>



                          </div>



                          {
                            !isTempHumAlarmThresholdInputValid() ?
                              <div className="threshold-card-temp-hum-validation-error">
                                Please enter valid numbers for all the inputs
                              </div> : null
                          }

                          <div className="threshold-card-inputs-temp-hum">

                            {postingColdRoomThreshold ? <Loader active inline color="yellow">Updating...</Loader> : <Button
                              disabled={!isTempHumAlarmThresholdInputValid()}
                              onClick={(e) => {

                                saveColdStorageThresholdData()
                                // updateSiteThreshold({
                                //   ...thresholdData,
                                //   type: "voltageMain",
                                //   projectUid: isDemo ? "DEMO" : thresholdProject,
                                // })
                              }}
                              size="large"
                              color="yellow"
                            >
                              Update
                            </Button>}


                          </div>

                        </div>
                      </div>
                      :

                      <>

                        <div className="tab-project-list inner-tab-project-list">
                          <Menu fluid tabular>

                            {activeTempHumProjectColdRoomsDropdown.map(el => <Menu.Item as='a' onClick={(e) => onTempHumProjectColdRoomChange(el.value)} color={activeTempHumProjectColdRoomsDropdownDeafult === el.value ? "yellow" : null} name={el.text} active={activeTempHumProjectColdRoomsDropdownDeafult === el.value} />)}

                          </Menu>
                        </div>


                        <div className="threshold-card-inputs-temp-hum">
                          <Radio
                            color="yellow"
                            toggle
                            checked={coldStorageAlarmStatus.sendingMode === 1 ? true : false}
                            onClick={(e, data) => {
                              updateColdStorageAlarmSendingStatus(
                                activeTempHumProject,
                                data.checked === true ? 1 : 0,
                                coldStorageAlarmStatus.doorSendingMode,
                                activeTempHumProjectColdRoomsDropdownDeafult,
                                coldStorageAlarmStatus.id

                              );
                            }}
                          />
                          <div>
                            {coldStorageAlarmStatus.sendingMode === 1 ? (
                              "notifications enabled"
                            ) : (
                              "notifications disabled"
                            )}
                          </div>

                          <Radio
                            color="yellow"
                            toggle
                            checked={coldStorageAlarmStatus.doorSendingMode === 1 ? true : false}
                            onClick={(e, data) => {
                              updateColdStorageAlarmSendingStatus(
                                activeTempHumProject,
                                coldStorageAlarmStatus.sendingMode,
                                data.checked === true ? 1 : 0,
                                activeTempHumProjectColdRoomsDropdownDeafult,
                                coldStorageAlarmStatus.id

                              );
                            }}
                          />

                          <div>
                            {coldStorageAlarmStatus.doorSendingMode === 1 ? (
                              "notifications enabled when door is open/closed"
                            ) : (
                              "notifications disabled when door is open"
                            )}
                          </div>
                        </div>



                        <div className="setpoint-data-row">





                          <NumberIndicatorModule
                            title="Current temperature setpoint on site"
                            // subTitle="for the chosen time span"
                            color={colors.ORANGE}
                            entity="°C"
                            value={tempThresholdData?.setPointValue
                            }
                          ></NumberIndicatorModule>











                        </div>


                        <div className="threshold-card-inputs-temp-hum">
                          <div className="threshold-card-inputs-temp-hum-divider-text" />


                        </div>






                        {tempThresholdData?.setPointValue === null && <>


                          <div className="threshold-card-inputs-temp-hum">
                            <div className="threshold-card-inputs-temp-hum-text-input">
                              <p>Desired target value in case a setpoint is not available from the site:</p>
                            </div>

                            <div className="threshold-card-inputs-temp-hum-text-input">
                              <Input
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(e) => {

                                  const val = Math.abs(e.target.value)

                                  if (val === 0) {

                                    newColdStoargeThresholdValue('tempThresholdData', {
                                      ...tempThresholdData, idealValue: ''
                                    })

                                  } else {

                                    newColdStoargeThresholdValue('tempThresholdData', {
                                      ...tempThresholdData, idealValue: val
                                    })

                                  }



                                }}
                                labelPosition="right"

                                placeholder="00"
                                value={tempThresholdData?.idealValue}
                                fluid
                              >
                                <input />
                                <Label color="yellow">°C</Label>
                              </Input>
                            </div>
                          </div>



                        </>}

                        <div className="threshold-card-inputs-temp-hum">

                          <div className="threshold-card-inputs-temp-hum-text-input">
                            <p>Allowed Deviation:</p>
                          </div>

                          <div className="threshold-card-inputs-temp-hum-text-input">
                            <Input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => {

                                const val = Math.abs(e.target.value)

                                if (val === 0) {

                                  newColdStoargeThresholdValue('tempThresholdData', {
                                    ...tempThresholdData, minimumAllowedValue: ''
                                  })

                                } else {

                                  newColdStoargeThresholdValue('tempThresholdData', {
                                    ...tempThresholdData, minimumAllowedValue: val
                                  })

                                }


                              }}
                              labelPosition="right"

                              placeholder="00"
                              value={tempThresholdData?.minimumAllowedValue}
                              fluid
                            >
                              <input />
                              <Label color="yellow">+/- °C</Label>
                            </Input>
                          </div>



                        </div>



                        {
                          !isTempHumAlarmThresholdInputValid() ?
                            <div className="threshold-card-temp-hum-validation-error">
                              Please enter valid numbers for all the inputs
                            </div> : null
                        }

                        <div className="threshold-card-inputs-temp-hum">

                          {postingColdRoomThreshold ? <Loader active inline color="yellow">Updating...</Loader> : <Button
                            disabled={!isTempHumAlarmThresholdInputValid()}
                            onClick={(e) => {

                              saveColdStorageThresholdData()
                              // updateSiteThreshold({
                              //   ...thresholdData,
                              //   type: "voltageMain",
                              //   projectUid: isDemo ? "DEMO" : thresholdProject,
                              // })
                            }}
                            size="large"
                            color="yellow"
                          >
                            Update
                          </Button>}


                        </div>


                      </>
                  }

                </Card>
              </>
            </CollapseSection>

          }
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ projects, alarms, auth }) => {
  const { data } = projects;

  const { id, isDemo, features, roles, paymentStatus } = auth;

  const { fetchingData, fetchingIndividualData } = alarms.setting;




  const arr = data.map((e) => {
    return e.projectUid;
  });

  const arrDropDown = data.map((e, i) => {
    return {
      key: i,
      text: e.title,
      value: e.projectUid,
      // id: e.id,
      units: e.units
    };
  });

  return {
    projectsData: arr,
    projectsDataDropDown: arrDropDown,
    siteAlarms: alarms.setting.data,
    userAlarms: alarms.setting.individualData,
    fetchingData,
    userId: id,
    isDemo,
    fetchingIndividualData,
    fetchingThreshold: alarms.setting.fetchingThreshold,
    thresholdData: alarms.setting.thresholdData,
    tempThresholdData: alarms.setting.tempThresholdData,
    humThresholdData: alarms.setting.humThresholdData,
    coldStorageAlarmStatus: alarms.setting.coldStorageAlarmStatus,
    features,
    postingColdRoomThreshold: alarms.setting.postingColdRoomThreshold,
    fetchingCompanyContact: alarms.companyContact.fetchingData,
    updatingCompanyContact: alarms.companyContact.updatingData,
    companyContact: alarms.companyContact.data,
    roles,
    paymentStatus,
    companySmsStatus: alarms.companyContact.companySmsStatus
  };
};

export default connect(mapStateToProps, {
  fetchSiteAlarmSendingStatus,
  fetchUserAlarmSendingStatus,
  updateUserAlarmSendingStatus,
  updateSiteAlarmSendingStatus,
  fetchSiteThreshold,
  updateSiteThreshold,
  updateThresholdNumberValues,
  updateColdStorageThreshold,
  fetchColdRoomThreshold,
  newColdStoargeThresholdValue,
  fetchCompanyContact,
  newCompanyContactValues,
  updateCompanyContact,
  updateColdStorageAlarmSendingStatus,
  contactInputAdd,
  contactInputDelete,
  fetchCompanySmsStatus,
  updateCompanySmsStatus
})(Settings);
