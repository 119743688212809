import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import InputForm from '../../../../../components/Inputs/InputForm';
import RadioButtonInputForm from '../../../../../components/Inputs/RadioButtonInputForm';
import FormGroup from '../../../../../components/shared/FormGroup';
import * as roles from '../../../../../constants/roles';
import Label from '../../../../../components/shared/Label';

class AddUserForm extends Component {
  render() {
    const allUsers = [
      { value: roles.CLIENT, label: 'Standard user' },
      { value: roles.CLIENT_ADMIN, label: 'Admin' }
    ];

    return (
      <div>
        <div className="add-user__form">
          <Field
            name="email"
            component={InputForm}
            type="email"
            label="User Email:"
          />
          <FormGroup>
            <label>Role:</label>
            <div className="radio-input">
              {allUsers.map((v, i) => (
                <Field
                  name="role"
                  component={RadioButtonInputForm}
                  type="radio"
                  label={v.label}
                  value={v.value}
                  key={i}
                />
              ))}
            </div>
          </FormGroup>
        </div>
        <Label type="error" label={this.props.error} />
      </div>
    );
  }
}

AddUserForm.propTypes = {
  error: PropTypes.string
};

export default AddUserForm;
