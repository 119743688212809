import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

function PaperHeader({children, component}) {
    return (
        <div className="card-header row">
            <div className="col-8">
                <h3>
                {children}
                </h3>
            </div>
            <div className="col-4">
                {component}
            </div>
        </div>
    )
}

PaperHeader.propTypes = {
    title: PropTypes.string,
    component: PropTypes.elementType
}

export default PaperHeader

