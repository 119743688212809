import {
  REPORT_SUBSCRIPTION_FETCHING,
  REPORT_SUBSCRIPTION_FETCH_SUCCESS,
  REPORT_SUBSCRIPTION_POSTING,
  REPORT_SUBSCRIPTION_POST_SUCCESS,
} from "../../constants/report-subscription.types";
import { PURGE } from "redux-persist";

const INITIAL = {
  fetchingReportSubscription: false,
  postingReportSubscription: false,
  id: "",
  userId: "",
  emailFrequency: "",
  prefferedEmail: "",
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    case REPORT_SUBSCRIPTION_FETCHING:
      return { ...state, fetchingReportSubscription: true };

    case REPORT_SUBSCRIPTION_FETCH_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        userId: action.payload.userId,
        emailFrequency: action.payload.emailFrequency,
        prefferedEmail: action.payload.prefferedEmail,
        fetchingReportSubscription: false,
      };

    case REPORT_SUBSCRIPTION_POSTING:
      return { ...state, postingReportSubscription: true };

    case REPORT_SUBSCRIPTION_POST_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        userId: action.payload.userId,
        emailFrequency: action.payload.emailFrequency,
        prefferedEmail: action.payload.prefferedEmail,
        postingReportSubscription: false,
      };

    case PURGE:
      return {
        ...INITIAL
      }

    default:
      return state;
  }
};
