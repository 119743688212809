import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import Page from '../../components/Page';
import Title from '../../components/shared/Title';
import { urlRoute } from '../../utils/url';
import Navigation from '../../components/shared/Navigation';
import Users from './routes/Users';
// import { CLIENT_ADMIN, INSPIRA_FARMS_TECH } from '../../constants/roles';
import ChangePassword from './routes/ChangePassword';
import * as featureTypes from '../../constants/feature-types';
import { toRenderFeature } from '../../utils/featureUtil';

import withTracker from '../../withTracker';



class Settings extends Component {



  render() {

    const { features, paymentStatus } = this.props
    let routes = {
      changePassword: {
        label: 'Account',
        link: urlRoute(`/settings`),
        path: urlRoute(`/settings`)
      },
    }

    if (toRenderFeature(featureTypes.SETTINGS_USER, paymentStatus, features)) {
      routes.users = {
        label: 'Users',
        link: urlRoute(`/settings/users`),
        path: urlRoute(`/settings/users`)
      }
    }
    return (
      <Page isContainer className="settings">
        <Title title="Settings" />
        <Navigation routes={routes} />
        <Switch>
          {toRenderFeature(featureTypes.SETTINGS_USER, paymentStatus, features) &&
            <Route exact path={routes.users.path} component={withTracker(Users)} />}

          <Route
            exact
            path={routes.changePassword.path}
            component={ChangePassword}
          />
        </Switch>
        <div className="settings__container" />
      </Page>
    );
  }
}

Settings.propTypes = {
  data: PropTypes.array,
  features: PropTypes.array
};

export default Settings;
