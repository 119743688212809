export const LOADING = '@projects/LOADING';
export const DONE_FETCH = '@projects/DONE_FETCH';
export const ERROR_FETCH = '@projects/ERROR_FETCH';
export const CLEAN_DATA = '@projects/CLEAN_DATA';
export const SET_CURRENT_PROJECT_ID = '@projects/SET_CURRENT_PROJECT_ID';
export const SET_CURRENT_PROJECT_TO_FIRST_PROJECT = '@projects/SET_CURRENT_PROJECT_TO_FIRST_PROJECT';
export const FETCH_ALL_COMPANIES = '@projects/FETCH_ALL_COMPANIES';
export const FETCHING_ALL_COMPANIES = '@projects/FETCHING_ALL_COMPANIES';
export const UPDATE_LOCAL_COMPANY_DATA = '@projects/UPDATE_LOCAL_COMPANY_DATA'
export const UPDATE_LOCAL_PROJECT_DATA = '@projects/UPDATE_LOCAL_PROJECT_DATA'
export const UPDATE_LOCAL_ROOM_DATA = '@projects/UPDATE_LOCAL_ROOM_DATA'

export const ACTIVE_COMPANY_TAB = '@projects/ACTIVE_COMPANY_TAB'
export const ACTIVE_PROJECTS_TAB = '@projects/ACTIVE_PROJECTS_TAB'

export const POSTING_LOCAL_COMPANY_DATA = '@projects/POSTING_LOCAL_COMPANY_DATA';
export const DONE_POSTING_LOCAL_COMPANY_DATA = '@projects/DONE_POSTING_LOCAL_COMPANY_DATA';
export const DONE_PUTTING_LOCAL_COMPANY_DATA = '@projects/DONE_PUTTING_LOCAL_COMPANY_DATA';
export const FAIL_POSTING_LOCAL_COMPANY_DATA = '@projects/FAIL_POSTING_LOCAL_COMPANY_DATA';

export const POSTING_LOCAL_PROJECT_DATA = '@projects/POSTING_LOCAL_PROJECT_DATA';
export const DONE_POSTING_LOCAL_PROJECT_DATA = '@projects/DONE_POSTING_LOCAL_PROJECT_DATA';
export const DONE_PUTTING_LOCAL_PROJECT_DATA = '@projects/DONE_PUTTING_LOCAL_PROJECT_DATA';
export const FAIL_POSTING_LOCAL_PROJECT_DATA = '@projects/FAIL_POSTING_LOCAL_PROJECT_DATA';


export const POSTING_LOCAL_ROOMS_DATA = '@projects/POSTING_LOCAL_ROOMS_DATA';
export const DONE_POSTING_LOCAL_ROOMS_DATA = '@projects/DONE_POSTING_LOCAL_ROOMS_DATA';
export const DONE_PUTTING_LOCAL_ROOMS_DATA = '@projects/DONE_PUTTING_LOCAL_ROOMS_DATA';
export const FAIL_POSTING_LOCAL_ROOMS_DATA = '@projects/FAIL_POSTING_LOCAL_ROOMS_DATA';


export const POSTING_LOCAL_OTHER_UNITS_DATA = '@projects/POSTING_LOCAL_OTHER_UNITS_DATA';
export const DONE_POSTING_LOCAL_OTHER_UNITS_DATA = '@projects/DONE_POSTING_LOCAL_OTHER_UNITS_DATA';
export const DONE_PUTTING_LOCAL_OTHER_UNITS_DATA = '@projects/DONE_PUTTING_LOCAL_OTHER_UNITS_DATA';
export const FAIL_POSTING_LOCAL_OTHER_UNITS_DATA = '@projects/FAIL_POSTING_LOCAL_OTHER_UNITS_DATA';


export const APPEND_INITIAL_UNIT_DATA = '@projects/APPEND_INITIAL_UNIT_DATA';
export const UPDATE_UNIT_AT_INDEX = '@projects/UPDATE_UNIT_AT_INDEX';
export const UPDATE_PROFILE_AT_INDEX = '@projects/UPDATE_PROFILE_AT_INDEX';
export const ADD_NEW_UNIT = '@projects/ADD_NEW_UNIT';
export const SAVE_UNIT_DATA = '@projects/SAVE_UNIT_DATA';

export const UPDATE_ACTIVE_ROOM_FOR_UNIT_GROUPS = '@projects/UPDATE_ACTIVE_ROOM_FOR_UNIT_GROUPS';
export const ADD_UNIT_TO_ROOM = '@projects/ADD_UNIT_TO_ROOM'
export const REMOVE_UNIT_FROM_ROOM = '@projects/REMOVE_UNIT_FROM_ROOM'
export const UPDATE_UNIT_IN_ROOM = '@projects/UPDATE_UNIT_IN_ROOM'

export const CLEAR_PROJECT_STATE = '@projects/CLEAR_PROJECT_STATE';

export const DELETE_ROOM_AT_INDEX = '@projects/DELETE_ROOM_AT_INDEX';
export const CLEAR_ALL_UNITS = '@projects/CLEAR_ALL_UNITS'

export const CREATE_COMPANY_EMAIL = '@projects/CREATE_COMPANY_EMAIL'
