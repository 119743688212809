import React, { useState, useEffect } from "react";
import { Table, Dropdown, Button } from "semantic-ui-react";
import { fetchSite } from "../../../../actions/alarms/site.action";
import { connect } from "react-redux";
// import Spinner from "../../../../components/shared/Spinner";
import { alarmTypeUiField } from "../../../../constants/new-alarm.enum";

const color = "yellow";

const coldRoomsAlarmsFrequency = [
  { key: 0, text: "Latest", value: "latest" },
  { key: 1, text: "Today", value: "today" },
  { key: 2, text: "Yesterday", value: "yesterday" },
  { key: 3, text: "One Week", value: "oneWeek" },
  { key: 4, text: "One Month", value: "oneMonth" },
];

function Site(props) {
  const {
    projectsData,
    fetchSite,
    siteAlarms,
    fetchingData,
    fullProjectdata,
    isDemo,
  } = props;

  const date = new Date();
  const dateAsString = date.toString();
  const timezone = dateAsString.match(/\(([^)]+)\)$/)[1];

  const [selectedPeriod, setSelectedPeriod] = useState("latest");

  useEffect(() => {
    fetchSite(selectedPeriod, projectsData);
    // eslint-disable-next-line
  }, []);

  const findSingleProject = (projectUid) => {
    return fullProjectdata.find((e) => e.projectUid === projectUid);
  };
  return (

    <div className="reports">
      <div className="reports-select-container">

        <div className="report-datepicker">

          <div className="report-datepicker-tablet">

            <div className="report-datepicker-tablet-x">
              <p className="table-text">Select a Data interval:</p>

              <Dropdown
                placeholder="Data Interval"
                search
                selection
                options={coldRoomsAlarmsFrequency}
                defaultValue={selectedPeriod}
                onChange={(event, { value }) => {
                  setSelectedPeriod(value);
                }}
              />
            </div>
            <div className="report-datepicker-tablet-x">
              {fetchingData ? (
                <Button loading color="yellow" size="big">
                  Serching...
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    fetchSite(selectedPeriod, projectsData);
                  }}
                  color="yellow"
                  size="big"
                >
                  Search Alarms
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {(
        Object.entries(siteAlarms).map((e) => (
          <>
            <h1> {`${isDemo ? findSingleProject(e[0]).title : e[0]} Alarms`}</h1>
            <Table color={color} key={color}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={1} textAlign="left">
                    <p className="table-text">
                      Type
                    </p>
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan={1} textAlign="left">
                    <p className="table-text">
                      Status
                    </p>
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan={1} textAlign="left" />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {e[1].map((el) => (
                  <Table.Row>
                    <Table.Cell> <p className="table-text">{alarmTypeUiField[el.type]}</p></Table.Cell>
                    <Table.Cell>
                      <p className="table-text">
                        {el.connectionMode === 1 ? (
                          <b className="table-text" style={{ color: "green" }}>
                            OK {" "}
                          </b>
                        ) : (
                          <b className="table-text" style={{ color: "red" }}>
                            ALERTING {" "}
                          </b>
                        )}
                        Since
                        {new Date(el.createdAt).toLocaleString("en-AU", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          timezone,
                        })}
                      </p>
                    </Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        ))
      )}
    </div>
  );
}

const mapStateToProps = ({ projects, alarms, auth }) => {
  const { data } = projects;
  const { isDemo } = auth;

  const { fetchingData } = alarms.site;

  const arr = data.map((e) => {
    return e.projectUid;
  });

  return {
    projectsData: arr,
    siteAlarms: alarms.site.data,
    fetchingData,
    isDemo,
    fullProjectdata: data,
  };
};

export default connect(mapStateToProps, { fetchSite })(Site);
