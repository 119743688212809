import React from "react";
import { Card } from "semantic-ui-react";
import { Table } from "semantic-ui-react";
import { alarmTypeUiField } from "../../../../../../constants/new-alarm.enum";
import './_energy_table.scss';

const EnergyConsumptionReport = React.memo((props) => {
  // const { energyConsumption, energyConsumptionLoading, loadingPricingCalculate, pricingCalculate } = props;
  const { energyConsumption } = props;
  //  const { energyConsumption, energyConsumptionLoading } = props;


  return (
    <div className="energy-consumption-report report-table">
      <h3 className="report-header">General Reports</h3>

      <div className="report-content">
        <Card.Group
          style={{ marginLeft: "20px", marginBottom: "20px", fontSize: "12px" }}
        >
          <Card>
            <Card.Content>
              <Card.Header>Total Energy Consumption</Card.Header>
              <Card.Meta>For the selected period</Card.Meta>
              <Card.Description style={{ color: "teal" }}>
                {energyConsumption && energyConsumption.totalEnergyConsumption ? `${energyConsumption.totalEnergyConsumption.toLocaleString()} KWh` : '-'}
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header>Data Uptime</Card.Header>
              <Card.Meta>For the selected period</Card.Meta>
              {energyConsumption && energyConsumption.dataUptime && parseFloat(energyConsumption.dataUptime) > 50 ? (
                <Card.Description style={{ color: "green" }}>
                  {energyConsumption.dataUptime}%
                </Card.Description>
              ) : energyConsumption && energyConsumption.dataUptime && parseFloat(energyConsumption.dataUptime) < 51 ? (
                <Card.Description style={{ color: "red" }}>
                  {energyConsumption.dataUptime}%
                </Card.Description>
              ) : (
                <Card.Description style={{ color: "red" }}>
                  '-'
                </Card.Description>
              )}
            </Card.Content>
          </Card>
          {/* <Card>
            <Card.Content>
              <Card.Header>Over/Under voltages</Card.Header>
              <Card.Meta>For the selected period</Card.Meta>
              <Card.Description style={{ color: 'red' }}>
                30
              </Card.Description>
            </Card.Content>
          </Card> */}
        </Card.Group>
      </div>

      <div style={{ fontSize: "12px", margin: "20px" }}>
        <i style={{ fontSize: "12px" }}>
          Values shown below are for the selected period
        </i>

        <Table className="energy-table-width" colour="yellow" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell><p className="table-text">Avg. Temp.</p></Table.HeaderCell>
              <Table.HeaderCell><p className="table-text">Lowest Hum.</p></Table.HeaderCell>
              <Table.HeaderCell><p className="table-text">Door openings</p></Table.HeaderCell>
              {/* <Table.HeaderCell>Total Throughput(Metric Tonnes)</Table.HeaderCell>  */}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {energyConsumption && energyConsumption.rooms && Object.entries(energyConsumption.rooms).map(([k, v], index) => (
              <>
                <Table.Row key={index}>
                  <Table.Cell colSpan={3}><b className="table-text">{v.name}</b></Table.Cell>
                  {/* <Table.Cell>{item.stock < 20 ? (<a href='/'>Add stock here</a>) : item.stock}</Table.Cell> */}
                </Table.Row>
                <Table.Row key={index}>
                  <Table.Cell><p className="table-text">{`${v.avgTemp}°C`}</p></Table.Cell>
                  <Table.Cell><p className="table-text">{v.lowestHum ? `${v.lowestHum}%` : '-'}</p></Table.Cell>
                  <Table.Cell><p className="table-text">{v.doorOpenings ? `${v.doorOpenings}` : '-'}</p></Table.Cell>
                  {/* <Table.Cell>{item.stock < 20 ? (<a href='/'>Add stock here</a>) : item.stock}</Table.Cell> */}
                </Table.Row>
              </>

            ))}
          </Table.Body>
        </Table>

        <div className="alarms-table-header">
          <h2 className="table-text">
            Site Alarms Summary
          </h2>
        </div>

        <Table className="energy-table-width" colour="yellow" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell><p className="table-text">Type</p></Table.HeaderCell>
              <Table.HeaderCell><p className="table-text">Occurrence</p></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {energyConsumption && energyConsumption.siteAlarms && Object.entries(energyConsumption.siteAlarms).map(([k, v], index) => (

              <Table.Row key={index}>
                <Table.Cell><p className="table-text">{alarmTypeUiField[k]}</p></Table.Cell>
                <Table.Cell><p className="table-text">{v}</p></Table.Cell>

              </Table.Row>


            ))}
          </Table.Body>
        </Table>

        <div className="alarms-table-header">
          <h2 className="table-text">
            Coldrooms Alarms Summary
          </h2>
        </div>

        <Table className="energy-table-width" colour="yellow" unstackable>
          <Table.Header>
            <Table.Row>

              <Table.HeaderCell><p className="table-text">Type</p></Table.HeaderCell>
              <Table.HeaderCell><p className="table-text">Occurrence</p></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {energyConsumption && energyConsumption.roomAlarms && Object.entries(energyConsumption.roomAlarms).map(([k, v], index) => (
              <>
                <Table.Row key={index}>
                  <Table.Cell colSpan={2}><b className="table-text">{v.name}</b></Table.Cell>

                </Table.Row>

                <Table.Row key={index}>

                  <Table.Cell colSpan={1}><p className="table-text">Temp.</p></Table.Cell>
                  <Table.Cell colSpan={1}><p className="table-text">{v.temperature}</p></Table.Cell>
                </Table.Row>
              </>


            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
});

export default EnergyConsumptionReport;
