import React from 'react';
import PropTypes from 'prop-types';

const ButtonLink = ({ children, tag, className = '', ...rest }) => {
  const tags = {
    primary: 'btn-accept',
    secondary: 'btn-cancel'
  };
  const classByTag = tags[tag] || tags.primary;

  return (
    <button className={`link ${className} ${classByTag}`} {...rest}>
      {children}
    </button>
  );
};

ButtonLink.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  tag: PropTypes.oneOf(['primary', 'secondary'])
};

ButtonLink.defaultProps = {
  type: 'button',
  tag: 'primary'
};

export default ButtonLink;
