import React from 'react';
import PropTypes from 'prop-types';
import { scrollToTop } from '../../../../../../utils/pageScroll';
import ButtonLink from '../../../../../../components/shared/ButtonLink';
import { urlRoute } from '../../../../../../utils/url';
import ArrowLink from '../../../../../../components/shared/ArrowLink';

import '../../../../../../styles/_mobile-navigation.scss';

const MobileNavigation = props => {
  const getBackUrl = () => {
    const { projectId, isMultiProjectAccount } = props;
    if (projectId && isMultiProjectAccount) {
      return urlRoute(`/projects/${projectId}/units`);
    }
    return urlRoute('/units');
  };

  return (
    <div className="mobile-navigation">
      {props.showReturnButton && (
        <ArrowLink
          linkTo={getBackUrl()}
          direction="left"
          text={props.isMultiProjectAccount ? 'Project' : 'Units'} />
      )}
      <ButtonLink onClick={() => scrollToTop()}>scroll to top</ButtonLink>
    </div>
  );
};

MobileNavigation.propTypes = {
  coldStorageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unitsIdList: PropTypes.array,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isMultiProjectAccount: PropTypes.bool
};

MobileNavigation.defaultProps = {
  isMultiProjectAccount: false
};

export default MobileNavigation;
