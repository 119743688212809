import React from "react";
import PropTypes from "prop-types";
import { urlRoute } from "../../utils/url";
import Menu from "./components/Menu";
import UserData from "./components/UserData";
import "./_navbar.scss";
import Dropdown from "../shared/Dropdown";
// import { getUserRoles } from "../../utils/auth/user";
// import { checkIfRoleIsInspiraFarmsTech } from "../../utils/arrays/compareTwoArrays";
import * as featureTypes from '../../constants/feature-types'
import { toRenderFeature } from "../../utils/featureUtil";
import { isTokenValid } from "../../utils/auth/session";
import storeConfig from "../../store";

const Navbar = ({
  location,
  userEmail,
  userLoggedIn,
  moreThenOneProject,
  projectId,
  history,
  logout,
  activeAlerts,
  features,
  paymentStatus
}) => {
  const handleLogout = () => {
    storeConfig.persistor.purge();
    logout();
    history.push("/auth/login");
  };

  // const roles = getUserRoles();

  const authorized = isTokenValid()

  let links = [];


  // if (authorized && toRenderFeature(featureTypes.UNITS_LIVE_DATA, paymentStatus, features)) {
  //   links.push({
  //     label: "Units",
  //     link: urlRoute(
  //       moreThenOneProject ? `/projects/${projectId}/units` : `/units`
  //     ),

  //   })
  // }


  if (authorized && toRenderFeature(featureTypes.UNITS_LIVE_DATA, paymentStatus, features)) {
    links.push({
      label: "Projects",
      link: urlRoute(
        // moreThenOneProject ? `/projects/${projectId}/units` : `/units`
        '/projects'
      ),

    })
  }

  // if (authorized && toRenderFeature(featureTypes.INSIGHTS, paymentStatus, features)) {
  //   links.push({ label: "Loggers", link: urlRoute("/reefer") })
  // }

  if (authorized && toRenderFeature(featureTypes.INSIGHTS, paymentStatus, features)) {
    links.push({ label: "Shipments", link: urlRoute("/shipments") })
  }


  if (authorized && toRenderFeature(featureTypes.ALARMS, paymentStatus, features)) {
    links.push({
      label: "Alarms",
      link: urlRoute("/alarms/coldrooms"),
      badge: activeAlerts,
    })
  }

  // if (authorized && toRenderFeature(featureTypes.ALARMS, paymentStatus, features)) {
  //   links.push({
  //     label: "Alarm Settings",
  //     link: urlRoute("/alarms/coldrooms"),
  //     badge: activeAlerts,
  //   })
  // }

  if (authorized && toRenderFeature(featureTypes.STOCK, paymentStatus, features)) {
    links.push(
      { label: "Stock", link: urlRoute("/stock") },
    )
  }

  if (authorized && toRenderFeature(featureTypes.SERVICE, paymentStatus, features)) {
    links.push({ label: "Service", link: urlRoute("/service") },)
  }

  if (authorized && toRenderFeature(featureTypes.INSIGHTS, paymentStatus, features)) {
    links.push({ label: "Insights", link: urlRoute("/usageinsights") })
  }

  if (authorized && toRenderFeature(featureTypes.INSIGHTS, paymentStatus, features)) {
    links.push({ label: "Devices", link: urlRoute("/devices") })
  }

  if (authorized && toRenderFeature(featureTypes.INSIGHTS, paymentStatus, features)) {
    links.push({ label: "Admin", link: urlRoute("/admin") })
  }

  const handleSelectChange = (event) => {
    if (event.value === "logout") {
      handleLogout();
    }
    history.push(event.link);
  };
  return (
    <div className={`navbar-x ${authorized ? "menu" : ""}`}>
      <div className="navbar-x__content">
        <Menu
          userLoggedIn={authorized}
          links={links}
        />
        {authorized && (
          <div className="navbar-x__content-dropdown">
            <UserData
              handleLogout={handleLogout}
              userLoggedIn={authorized}
              userData={{ email: userEmail }}
              className="options"
            />
            <Dropdown
              placeholder=""
              className="navbar"
              value="..."
              selected={location.pathname}
              onChange={handleSelectChange}
              userLoggedIn={userLoggedIn}
              profile={
                <UserData
                  handleLogout={handleLogout}
                  userLoggedIn={userLoggedIn}
                  userData={{ email: userEmail }}
                  className="profile"
                />
              }
              options={[
                {
                  value: "settings",
                  label: "Settings",
                  link: urlRoute("/settings"),
                },
                // {
                //   value: "projects",
                //   label: "Switch projects",
                //   link: urlRoute("/projects"),
                //   onlyFor: [
                //     roles.INSPIRA_FARMS_TECH,
                //     roles.INSPIRA_FARMS_EMPLOYEE,
                //     roles.INSPIRA_FARMS_ADMIN,
                //     roles.UNDER_THE_RADAR
                //   ],
                // },
                { value: "logout", label: "Logout" },
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Navbar.propTypes = {
  userLoggedIn: PropTypes.bool,
  userEmail: PropTypes.string,
  activeAlerts: PropTypes.number,
  features: PropTypes.array
};

export default Navbar;
