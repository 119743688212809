import api from "../utils/api";
import * as unitsTypes from "../constants/units.types";
import { getCurrentProjectInfo } from "../utils/auth/user";

export const fetchUnits = (projectId) => async (dispatch) => {
  const projectInfo = getCurrentProjectInfo();
  dispatch({ type: unitsTypes.LOADING, projectId: projectInfo?.id });

  try {
    const response = await api.get(`project/${projectInfo.id}/units`, {
      params: {
        projectUid: projectInfo.projectUid,
      },
    });

    dispatch({
      type: unitsTypes.DONE_FETCH,
      projectId: projectInfo.id,
      payload: response,
    });

    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: unitsTypes.ERROR_FETCH,
      projectId: projectInfo.id,
      payload: {
        error: (e.response || {}).message || "No live data connection",
      },
    });

    return Promise.reject(e.response);
  }
};


export const fetchUnitsWithId = (projectId, projectUid) => async (dispatch) => {

  dispatch({ type: unitsTypes.LOADING, projectId });

  try {

    const response = await api.get(`project/${projectId}/units`, {
      params: {
        projectUid
      },
    });

    dispatch({
      type: unitsTypes.DONE_FETCH,
      projectId,
      payload: response,
    });


    return Promise.resolve([]);
  } catch (e) {
    dispatch({
      type: unitsTypes.ERROR_FETCH,
      projectId,
      payload: {
        error: (e.response || {}).message || "No live data connection",
      },
    });

    return Promise.reject(e.response);
  }
};

export const fetchUnitsDetails = (projectId, deviceSerials) => async (
  dispatch
) => {
  try {
    const response = await api.get(`/project/${projectId}/unit-details`, {});
    dispatch({
      type: unitsTypes.DONE_FETCH_DETAILS,
      projectId: projectId,
      payload: response,
    });

    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: unitsTypes.ERROR_FETCH,
      projectId: projectId,
      payload: {
        error: (e.response || {}).message || "Fetch units details data error",
      },
    });
    return Promise.reject(e.response);
  }
};

export function cleanUnits() {
  return {
    type: unitsTypes.CLEAN_DATA,
  };
}
