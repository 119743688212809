import statisticsTypes from './statisticsTypes';
import { chain } from 'lodash';

export const ROOM = 'Room';
export const GRID = 'Grid';
export const ENERGY_STORAGE = 'Energy storage';
export const ENERGY = 'Energy';
export const PHOTOVOLTAIC = 'Photovoltaic';
export const MACHINE = 'Machine';
export const ALARMS = 'Alarms';
export const WEATHER = 'Weather';
export const FURTHER_DATA = 'Further data';

export const chartsInSections = {
  
  [ROOM]: [
    statisticsTypes.cellTemp,
    statisticsTypes.piercingProbeTemp,
    statisticsTypes.piercingProbeTemp2,
    statisticsTypes.ethyleneProbe,
    statisticsTypes.cellHum,
    statisticsTypes.stockWeights,
    statisticsTypes.doorRoom,
    statisticsTypes.humSetpoint,
    statisticsTypes.tempSetpoint,
    statisticsTypes.CO2ambient
  ],
  [GRID]: [
    statisticsTypes.mainPower,
    statisticsTypes.maxRoomPower,
    statisticsTypes.compressorPower,
    statisticsTypes.mainEnergyConsumption,
    statisticsTypes.compressorEnergyConsumption,
    statisticsTypes.roomEnergyConsumption
  ],
  [ENERGY]: [
    statisticsTypes.batteryCharge,
    statisticsTypes.mainEnergyConsumption,
    statisticsTypes.compressorEnergyConsumption,
    statisticsTypes.roomEnergyConsumption
  ],
  [ENERGY_STORAGE]: [
    statisticsTypes.batteryCharge,
    statisticsTypes.thermalStorageColdTemp,
    statisticsTypes.tempWaterTank1,
    statisticsTypes.tempWaterTank2,
    statisticsTypes.inverterVoltage,
    statisticsTypes.batteryVoltage,
    statisticsTypes.batteryCurrent,
    statisticsTypes.batteryTemp,
  ],
  [FURTHER_DATA]: [],
  [PHOTOVOLTAIC]: [
    statisticsTypes.energyPvAux,
  ],
  [MACHINE]: [
    statisticsTypes.pressSwitch,
    statisticsTypes.thr,
    statisticsTypes.cprValve,
    statisticsTypes.ethValve,
    statisticsTypes.ge,
    statisticsTypes.pressLp,
    statisticsTypes.pressHp,
  ],
  [WEATHER]: [
    statisticsTypes.externalTemp,
    statisticsTypes.externalHum,
    statisticsTypes.sunIrradiation,
    statisticsTypes.rainGauge,
  ],
}

export const getSectionsByDataProfile = (dataProfile, sectionName) => {
  const dataProfileRestrictedFields = Object.keys(
    chain(dataProfile)
      .omit('id', 'name')
      .omitBy(value => value === true).value()
  );

  return chartsInSections[sectionName].filter(statisticsType => (
    !dataProfileRestrictedFields.includes(statisticsType)
  ));
}