import React, { useEffect } from "react";

import Page from "../../components/Page";
import Title from "../../components/shared/Title";
import Navigation from "../../components/shared/Navigation";
import Stock from '../Stock'
import { urlRoute } from "../../utils/url";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import { fetchUnitsWithId } from "../../actions/units.actions"



function StockPage(props) {

    useEffect(() => { }, [])

    const { projects } = props;

    const getRoutes = () => {

        let routesArray = {};

        for (let i = 0; i < projects.length; i++) {
            routesArray[projects[i].title] = {
                label: projects[i].title,
                link: urlRoute(`/stock/${projects[i].id}`),
                path: urlRoute(`/stock/:projectId`),
            }
        }

        return routesArray


    };

    // console.log(getRoutes())




    return (
        <Page isContainer>
            <Title title="Stock" />

            <Navigation routes={getRoutes()} />
            <Switch>
                {projects.map(el => {
                    return (
                        <Route exact path={`/stock/:projectId`} component={Stock} />
                    )
                })}

                <Route exact path="/stock">
                    <Redirect to={getRoutes()[projects[0].title].link} />
                </Route>


            </Switch>
        </Page>
    )
}

const mapStateToProps = ({ projects }) => {
    const {
        data
    } = projects;

    return {
        projects: data
    };
};

export default connect(mapStateToProps, {})(StockPage);


