import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { isRefreshTokenValid, isTokenValid } from "../../utils/auth/session";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const fpPromise = FingerprintJS.load();

class Initializer extends Component {
  componentDidMount() {
    (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get()
      this.props.saveBrowserFingerPrint(result.visitorId);
    })();

    this.checkAuthData();
    this.initMethods();
  }

  checkAuthData = () => {
    if (!isTokenValid() && isRefreshTokenValid()) {
      this.props.refreshToken();
    }
  };

  initMethods = () => {
    if (isTokenValid()) {
      this.props.fetchActiveAlarms();
    }
  };

  render() {
    return <Fragment />;
  }
}

Initializer.propTypes = {
  refreshToken: PropTypes.func,
  fetchActiveAlarms: PropTypes.func,
  saveBrowserFingerPrint: PropTypes.func,
};

export default Initializer;
