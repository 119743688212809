import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Input, Dropdown, Button, Icon } from "semantic-ui-react"
import { sendMailInvite } from '../../../../api/sms.api';
import { fectchCompanies } from '../../../../actions/projects.actions';

import './_invite.scss';

const roleOptions = [
    { key: 'Client', text: 'Client', value: 'Client' },
    { key: 'ClientAdmin', text: 'ClientAdmin', value: 'ClientAdmin' }
]


function Invite(props) {

    const {
        fectchCompanies,
        companies
    } = props;

    const [emailToInvite, setEmailToInvite] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [role, setRole] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        fectchCompanies()
        // eslint-disable-next-line
    }, [])


    const isButtonEnabled = () => {
        if (!companyId) return false
        const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailToInvite.match(validEmailRegex)) return false
        return true
    }

    const onSendMailInviteClicked = () => {
        setLoading(true)
        sendMailInvite(emailToInvite, companyId, role)
            .then(res => {
                setLoading(false)
                console.log(res)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
    }

    return (
        <div className="projects__container">
            <div className='email-input-margin-bottom'>
                <small>Admin email</small>
                <Input
                    onChange={(e) => {
                        setEmailToInvite(e.target.value)
                    }}
                    placeholder="Admin email address"
                    fluid
                    type='email'
                    value={emailToInvite}
                />
            </div>

            <div className='email-input-margin-bottom'>
                <Dropdown
                    placeholder="Role"
                    scrolling
                    fluid
                    search
                    selection
                    options={roleOptions}
                    onChange={(event, { value }) => {
                        setRole(value)
                    }}
                    value={role}
                />
            </div>

            <div className='email-input-margin-bottom'>
                <small>Company</small>
                <Dropdown
                    placeholder="ProjCompanyct"
                    scrolling
                    fluid
                    search
                    selection
                    options={companies}
                    onChange={(event, { value }) => {
                        setCompanyId(value);
                    }}
                    value={companyId}
                />
            </div>

            <Button
                onClick={() => {
                    onSendMailInviteClicked()
                }}
                color="yellow"
                size="big"
                className="button-full-width"
                disabled={!isButtonEnabled()}
            >

                <div className='button-plus-spinner'>
                    {loading ? 'Sending invite link..' : 'Send invite link'}
                    <div className='spinner-button'>
                        {loading ? <Icon name='circle notched' color='white' loading={loading} /> : null}
                    </div>
                </div>

            </Button>
        </div>
    )
}
const mapStateToProps = ({ projects }) => {
    const {
        companies
    } = projects;




    return {
        companies
    };
}

export default connect(mapStateToProps, {
    fectchCompanies
})(Invite);
