import React, { useState, useEffect } from "react";
// import Table from "../../components/shared/Table";
import { Card, Table, Button, Grid } from "semantic-ui-react";
import { fetchUserInsights } from "../../actions/userInsights/userInsight.action";
import { connect } from "react-redux";
import Page from "../../components/Page";
import Title from "../../components/shared/Title";
// import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

function UsageInsights(props) {
  const currentDate = new Date();
  const sevenDays = new Date(currentDate);
  sevenDays.setDate(currentDate.getDate() - 8);

  const [allOrIndividual, setAllOrIndividual] = useState("all");
  const [dates] = useState([
    sevenDays.toISOString().split("T")[0],
    currentDate.toISOString().split("T")[0],
  ]);

  const { fetchUserInsights } = props;
  useEffect(() => {
    fetchUserInsights(dates[0], dates[1]);
  }, [fetchUserInsights, dates]);

  const getUserCompany = (userId) => {
    return props.companies.find((company) => company.id === userId);
  };

  const onButtonClick = (value) => {
    setAllOrIndividual(value);
  };

  // const handleDatesChange = (event, data) =>
  //   setDates(data.value == null ? [] : data.value);

  const showAll = () => {
    return (
      <Card style={{ width: "80%", marginTop: "20px" }}>
        <Card.Content>
          <Card.Header>All portal users Summary</Card.Header>
          <Card.Meta style={{ marginBottom: "20px" }}>
            A brief summary of all the user insight data between the selcted
            period
          </Card.Meta>
          <Grid columns={2}>
            <Grid.Column width="4">
              <div style={{ marginBottom: "20px" }}>
                <p>
                  <span>
                    {" "}
                    <strong>Most Common Entry</strong>:{" "}
                    {props.commonEntry?.[0]?.feature},
                    {!props.commonEntry?.[0]?.featureFrequency
                      ? 0
                      : props.commonEntry?.[0]?.featureFrequency}{" "}
                    times
                  </span>

                  <br />
                  <span>
                    {" "}
                    <strong>Most Common Exit</strong>:{" "}
                    {props.commonExit?.[0]?.feature},
                    {!props.commonExit?.[0]?.featureFrequency
                      ? 0
                      : props.commonExit?.[0]?.featureFrequency}{" "}
                    times
                  </span>
                  <br />
                  <span>
                    {" "}
                    <strong>Most Clicked/Opened</strong>:{" "}
                    {props.mostFrequent?.[0]?.feature},
                    {!props.mostFrequent?.[0]?.featureFrequency
                      ? 0
                      : props.mostFrequent?.[0]?.featureFrequency}{" "}
                    times
                  </span>
                </p>
              </div>
            </Grid.Column>
            <Grid.Column width="4">
              <div style={{ marginBottom: "20px" }}>
                <p>
                  <span>
                    {" "}
                    <strong>Least Common Entry</strong>:{" "}
                    {
                      props.commonEntry?.[props.commonEntry?.length - 1]
                        ?.feature
                    }
                    ,
                    {!props.commonEntry?.[props.commonEntry?.length - 1]
                      ?.featureFrequency
                      ? 0
                      : props.commonEntry?.[props.commonEntry?.length - 1]
                          ?.featureFrequency}{" "}
                    times
                  </span>

                  <br />
                  <span>
                    {" "}
                    <strong>Least Common Exit</strong>:{" "}
                    {props.commonExit?.[props.commonExit?.length - 1]?.feature},
                    {!props.commonExit?.[props.commonExit?.length - 1]
                      ?.featureFrequency
                      ? 0
                      : props.commonExit?.[props.commonExit?.length - 1]
                          ?.featureFrequency}{" "}
                    times
                  </span>
                  <br />
                  <span>
                    {" "}
                    <strong>Least Clicked/Opened</strong>:{" "}
                    {
                      props.mostFrequent?.[props.mostFrequent?.length - 1]
                        ?.feature
                    }
                    ,
                    {!props.mostFrequent?.[props.mostFrequent?.length - 1]
                      ?.featureFrequency
                      ? 0
                      : props.mostFrequent?.[props.mostFrequent?.length - 1]
                          ?.featureFrequency}{" "}
                    times
                  </span>
                </p>
              </div>
            </Grid.Column>
          </Grid>
          <h3>
            A ranking based on the total time spent by all users on each feature
          </h3>
          {props.allUsers.map((val, idx) => {
            return (
              <p>
                {idx + 1}. {val["feature"]}:{" "}
                {new Date(val["totalTimeSpent"] * 1000)
                  .toISOString()
                  .substr(11, 8)}{" "}
              </p>
            );
          })}
        </Card.Content>
      </Card>
    );
  };

  const showIndividual = () => {
    return (
      <Table
        celled
        style={{ width: "95%", marginTop: "20px", "overflow-x": "scroll" }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell colSpan="9">
              Features with corresponding total time spent
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {Object.entries(props.singleUsers).map((val, idx) => {
            const userCompany = getUserCompany(parseInt(val[0]));
            return (
              <Table.Row>
                <Table.Cell>{userCompany?.firstName}</Table.Cell>
                <Table.Cell>{userCompany?.companyName}</Table.Cell>
                <Table.Cell>{userCompany?.email}</Table.Cell>
                {val[1].map((innerVal, innerIdx) => {
                  return (
                    <Table.Cell>
                      {innerVal["feature"]}
                      <br />
                      {new Date(innerVal["totalTimeSpent"] * 1000)
                        .toISOString()
                        .substr(11, 8)}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };
  return (
    <Page>
      <Title title="Insights" />
      {/* <SemanticDatepicker
        clearOnSameDateClick={false}
        datePickerOnly={true}
        onChange={handleDatesChange}
        maxDate={new Date()}
        type="range"
      /> */}

      {/* {dates.length === 2 ? (
        <>
          <p>
            <span>
              {dates[0].toString()} - {dates[1].toString()}
            </span>
          </p>
        </>
      ) : null} */}

      <Button.Group
        style={{ width: "60%", marginTop: "30px", marginBottom: "20px" }}
      >
        <Button
          positive={allOrIndividual === "all" ? true : false}
          onClick={() => {
            onButtonClick("all");
          }}
        >
          All user summary
        </Button>
        <Button.Or text="or" />
        <Button
          positive={allOrIndividual === "all" ? false : true}
          onClick={() => {
            onButtonClick("individual");
          }}
        >
          Individual user summary
        </Button>
      </Button.Group>
      {allOrIndividual === "all" ? showAll() : showIndividual()}
    </Page>
  );
}

const mapStateToProps = ({ userInsightReducer }) => {
  const {
    companies,
    allUsers,
    singleUsers,
    fetchingUserInsights,
    commonExit,
    commonEntry,
    mostFrequent,
  } = userInsightReducer;

  return {
    companies,
    allUsers,
    singleUsers,
    fetchingUserInsights,
    commonExit,
    commonEntry,
    mostFrequent,
  };
};

export default connect(mapStateToProps, { fetchUserInsights })(UsageInsights);
