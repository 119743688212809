import React from 'react'


function Files() {

    return (
        <div>Files</div>
    )
}

export default Files