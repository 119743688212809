import {
  FETCHING_SITE_ALARMS_STATUS,
  DONE_FETCHING_SITE_ALARMS_STATUS,
  DONE_FETCHING_INDIVIDUAL_USER_ALARM_STATUS,
  FETCHING_SITE_THRESHOLD,
  DONE_FETCHING_SITE_THRESHOLD,
  UPDATE_THRESHOLD_VALUES,
  DONE_UPDATING_COLD_ROOM_THRESHOLD,
  DONE_FETCHING_COLD_ROOM_THRESHOLD,
  UPDATE_COLD_ROOM_VALUES,
  UPDATING_COLD_ROOM_THRESHOLD,
  DONE_UPDATING_COLD_ROOM_ALARM_STATUS,
  DONE_UPDATING_SITE_ALARMS_STATUS
} from "../../constants/alarms.types";
import { PURGE } from "redux-persist";

const INITIAL = {
  fetchingData: false,
  data: [],
  fetchingIndividualData: false,
  individualData: {},
  fetchingThreshold: false,
  fetchingColdRoomThreshold: false,
  postingColdRoomThreshold: false,
  thresholdData: {
    idealValue: "",
    allowedNegativeDeviation: "",
    allowedPositiveDeviation: "",
  },
  tempThresholdData: {
    idealValue: '', maximumAllowedValue: '', minimumAllowedValue: '', id: '', setPointValue: ''
  },
  humThresholdData: {
    idealValue: '', maximumAllowedValue: '', minimumAllowedValue: '', id: '', setPointValue: ''
  },
  coldStorageAlarmStatus: { id: '', sendingMode: '', projectUid: '', coldStorageId: '' }
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    case FETCHING_SITE_ALARMS_STATUS:
      return { ...state, fetchingData: true };

    case DONE_FETCHING_SITE_ALARMS_STATUS:
      return {
        ...state,
        data: action.payload,
        fetchingData: false,
      };

    case DONE_UPDATING_SITE_ALARMS_STATUS:
      let current = state.data

      for (const obj of current) {
        if (obj.id === action.payload.id) {
          obj.sendingMode = action.payload.sendingMode;

          break;
        }
      }
      return {
        ...state,
        data: current
      }

    case DONE_FETCHING_INDIVIDUAL_USER_ALARM_STATUS:
      return {
        ...state,
        individualData: action.payload,
        fetchingIndividualData: false,
      };

    case FETCHING_SITE_THRESHOLD:
      return {
        ...state,
        fetchingThreshold: true,
      };

    case DONE_FETCHING_SITE_THRESHOLD:
      return {
        ...state,
        thresholdData: { ...state.thresholdData, ...action.payload },
        fetchingThreshold: false,
      };



    case UPDATE_THRESHOLD_VALUES:
      return {
        ...state,
        thresholdData: { ...state.thresholdData, ...action.payload },
      };

    case DONE_UPDATING_COLD_ROOM_THRESHOLD:
      return {
        ...state,
        postingColdRoomThreshold: false,
        tempThresholdData: { ...state.tempThresholdData, ...action.tempPayload },
        humThresholdData: { ...state.humThresholdData, ...action.humPayload },
      };

    case DONE_UPDATING_COLD_ROOM_ALARM_STATUS:
      return {
        ...state,
        coldStorageAlarmStatus: { ...state.coldStorageAlarmStatus, ...action.payload }
      }

    case DONE_FETCHING_COLD_ROOM_THRESHOLD:
      return {
        ...state,
        tempThresholdData: { ...state.tempThresholdData, ...action.tempPayload },
        humThresholdData: { ...state.humThresholdData, ...action.humPayload },
        coldStorageAlarmStatus: { ...state.coldStorageAlarmStatus, ...action.coldStorageAlarmStatus }
      };

    case UPDATE_COLD_ROOM_VALUES:
      return {
        ...state,
        [action.payloadKey]: { ...state[action.payloadKey], ...action.payloadValue },
      };

    case UPDATING_COLD_ROOM_THRESHOLD:
      return {
        ...state,
        postingColdRoomThreshold: true,
      };

    case PURGE:
      return {
        ...INITIAL
      }

    default:
      return state;
  }
};
