import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { anonLogin } from '../../actions/auth.actions';
import { Loader } from 'semantic-ui-react'
import './anon.scss';

function AnonLogin(props) {

    const { location } = props;

    const key = location.search.split('?logi=')[1]


    useEffect(() => {

        props.anonLogin(key)
            .then((res) => { props.history.push('/projects') })
            .catch((err) => { console.log(err) });

       // eslint-disable-next-line
    }, [])


    return (
        <div className='container-anon'>
            <Loader>Logging you in</Loader>
        </div>
    )
}

const mapStateToProps = () => { }

export default connect(mapStateToProps, {
    anonLogin
})(AnonLogin)