import weatherTemp from "../assets/sensor-defaults/weather_temp.json"
import weatherSolar from "../assets/sensor-defaults/weather_solar.json"
import weatherRain from "../assets/sensor-defaults/weather_rain.json"
import weatherHum from "../assets/sensor-defaults/weather_hum.json"
import voltageBattery from "../assets/sensor-defaults/voltage_battery.json"
import pressureHigh10 from "../assets/sensor-defaults/pressure_high_10.json"
import pressureLow10 from "../assets/sensor-defaults/pressure_low_10.json"
import pressureHigh50 from "../assets/sensor-defaults/pressure_high_50.json"
import pressureLow50 from "../assets/sensor-defaults/pressure_low_50.json"
import msrC2h42000 from "../assets/sensor-defaults/msr_c2h4_2000.json"
import msrC2h4200 from "../assets/sensor-defaults/msr_c2h4_200.json"
import msrC022 from "../assets/sensor-defaults/msr_c02_2.json"


export const defaultSensorValues = {
    weatherTemp,
    weatherHum,
    weatherRain,
    weatherSolar,
    voltageBattery,
    'pressureLow-10': pressureLow10,
    'pressureLow-50': pressureLow50,
    'pressureHigh-10': pressureHigh10,
    'pressureHigh-50': pressureHigh50,
    CO2ambient: msrC022,
    'ethyleneProbe-2000': msrC2h42000,
    'ethyleneProbe-200': msrC2h4200,


}

export const doesFucntionIncludeNumber = (value, minLimit, maxLimit, sensorName) => {

    sensorName = sensorName || ''
    if (value.includes('pressure')) return `${value}-${maxLimit}`
    if (value.includes('ethyleneProbe')) return `${value}-${maxLimit}`
    if (value.includes('co2')) return `${value}-${maxLimit}`
    return value
}