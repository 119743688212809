import React from 'react';
import PropTypes from 'prop-types';

const FormGroup = ({ children, className = '' }) => {
  return <div className={`form-group ${className}`}>{children}</div>;
};

FormGroup.propTypes = {
  className: PropTypes.string
};

export default FormGroup;
