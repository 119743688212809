import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from './Service';

const mapStateToProps = state => {
  const dataLength = state.alarms.active.data.length;
  return {
    data: state.alarms.active.data,
    dataLength: dataLength
  };
};

const ComponentWithRouter = withRouter(Component);

export default connect(mapStateToProps)(ComponentWithRouter);
