import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { reduxForm, Form, Field, SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';

import * as roles from '../../../constants/roles';
import { hasOneOrMoreSameValues } from '../../../utils/arrays/compareTwoArrays';
import { getUserRoles } from '../../../utils/auth/user';
import { urlRoute } from '../../../utils/url';
import { emailValidate, passwordValidate } from '../../../utils/validators';
import Label from '../../../components/shared/Label';
import InputForm from '../../../components/Inputs/InputForm';
import Page from '../../../components/Page';
import Button from '../../../components/shared/Button';
import Title from '../../../components/shared/Title';
import { AUTH } from '../../../constants/analyticsEventTypes';

import './_login.scss';

import ReactGA from 'react-ga';


class Login extends Component {
  submit = async values => {
    ReactGA.event({
      category: AUTH.Category,
      action: AUTH.Login,
      label: AUTH.Login
    });

    try {
      await this.props.login(values);

      if (
        hasOneOrMoreSameValues(getUserRoles(), [
          roles.INSPIRA_FARMS_TECH,
          roles.INSPIRA_FARMS_EMPLOYEE,
          roles.CLIENT_ADMIN,
          roles.DEMO_ACCOUNT,
          roles.CLIENT,
          roles.INSPIRA_FARMS_ADMIN,
          roles.UNDER_THE_RADAR,
          roles.INSPIRA_FARMS_TECH_REGION
        ])
      ) {

        this.props.history.push('/projects');
      } else {
        this.props.fetchProjects().then((projects) => {


          this.props.setCurrentProjectToFirstProject();
          this.props.history.push('/projects');
        });
      }
    } catch (e) {
      throw new SubmissionError({
        _error: 'Username or Password is incorrect'
      });
    }
  };

  render() {
    const { handleSubmit, submitting, submitFailed, error } = this.props;

    return (
      <Page isContainer className="login">
        <Title title="Login" className="login__title" />
        <Form onSubmit={handleSubmit(this.submit)} className="login__form">
          <Field
            name="email"
            component={InputForm}
            type="email"
            label="Email"
            placeholder="Enter email address"
          />
          <Field
            name="password"
            component={InputForm}
            type="password"
            label="Password"
            placeholder="Enter password"
          />
          {!submitting && submitFailed && <Label type="error" label={error} />}
          <Button type="submit" tag="primary" disabled={submitting}>
            {submitting ? 'Loading' : 'Login'}
          </Button>
        </Form>
        <div className="login__bottom">
          <Link className="link" to={urlRoute('/auth/password-recovery')}>
            Forgot password?
          </Link>
          <a className="link" href="mailto:mborghesi@inspirafarms.com">
            Contact support
          </a>
        </div>
      </Page>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func
};

export default reduxForm({
  form: 'login',
  validate: values => ({
    email: emailValidate(
      values.email,
      'Email is required',
      'Email is incorrect'
    ),
    password: passwordValidate(
      values.password,
      'Password is required',
      'Password is too short'
    )
  })
})(Login);
