import Component from './Logo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => {
  const moreThenOneProject = state.projects.data.length > 1;

  return {
    moreThenOneProject: moreThenOneProject || false,
  };
};

const ComponentWithRouter = withRouter(Component);

export default connect(mapStateToProps)(ComponentWithRouter);
