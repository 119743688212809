import React from "react";
import ChartTooltip from "../../../components/Charts/components/ChartTooltip";
import LinearChart from "../../../components/Charts/LinearChart"
import * as colors from "../../../constants/colors";
import { roundTwoDigits } from "../../../utils/numberFormat";
import { getReeferLabelNameFormat } from "../../../helpers/chart.helper";
import moment from "moment";

export const ReeferTemperatureChart = (props) => {

    const {
        syncId,
        shipmentData,
        shipmentDataLoad
    } = props;


    const legends = [{ key: "meanTemp", color: colors.CYAN, label: "CONTAINER TEMP" }];


    const _isLoading = () => {
        return shipmentDataLoad;
    };

    const _getTemperatureData = () => {

        return shipmentData.map(el => ({
            ...el,
            name: moment.utc(el.time).local().format(getReeferLabelNameFormat(shipmentData))
        }))
    }

    const _getCustomTooltip = (e) => {
        const data = ((e.payload || [])[0] || {}).payload || {};

        return (
            <ChartTooltip>
                <small>{moment(data.time).format('DD/MM HH:mm')}</small>
                <p>
                    ROOM TEMP: <strong>{roundTwoDigits(data?.meanTemp)}°C</strong>
                </p>

            </ChartTooltip>
        );
    };



    return (
        <LinearChart
            title="Temperature"
            syncId={syncId}
            data={_getTemperatureData()}
            tooltip={_getCustomTooltip}
            legends={legends}
            rangeLines={[]}
            loading={_isLoading()}
            maxValue={50}
            minValue={-4}
            xkey='time'
        />
    );

};

export default ReeferTemperatureChart;



