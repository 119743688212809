import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import * as systemRoles from '../../constants/roles';
import { hasOneOrMoreSameValues } from '../../utils/arrays/compareTwoArrays';
import { getUserRoles } from '../../utils/auth/user';

const RenderByRoles = ({ children, roles }) => {
  const userRoles = getUserRoles();

  const hasAccess = hasOneOrMoreSameValues(roles, userRoles);

  return (hasAccess || !roles) && <Fragment>{children}</Fragment>;
};

RenderByRoles.defaultProps = {
  roles: undefined
};

RenderByRoles.propTypes = {
  children: PropTypes.any,
  roles: PropTypes.arrayOf(PropTypes.oneOf([...values(systemRoles)]))
};

export default RenderByRoles;
