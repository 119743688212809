import { flatten } from 'lodash';

export const checkIfChartDataExists = (keys, data) => {
  let hasAnyData = flatten(keys.map(x => data.map(y => y[x]))).filter(
    x => x !== null
  );
// TODO: this has checked for 0 values before which created problems with on/off data... lets see if really needed. I guess not
  return hasAnyData.filter(x => x !== null).length > 0;
};

export const cleanChartDataToBeContinous = (data, key) => {
  if (data !== undefined && Array.isArray(data)) {
    return data.filter((element, index, array) => {
      if (element[key] !== null) {
        return true;
      } else if (
        index < array.length - 5 &&
        array[index + 1][key] !== null &&
        array[index + 2][key] !== null &&
        array[index + 3][key] !== null &&
        array[index + 4][key] !== null
      ) {
        return true;
      } else {
        return false;
      }
    });
  } else {
    return [];
  }
};
