export const passwordValidate = (value, message, description) => {
  if (value && value.length > 0) return undefined;
  return !value ? message : description;
};

export const passwordRepeatValidate = (
  password,
  repeatPassword,
  message,
  description
) => {
  if (!repeatPassword) return message;
  return password === repeatPassword ? undefined : description;
};

export const termsAccepted = (
  termsBoxStatus,
  message
)=> {
   if(!termsBoxStatus) return message;
}

export const emailValidate = (value, message, description) => {
  const regEx = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!value) return message;
  return value && regEx.test(value) ? undefined : description;
};

export const emptyField = (value, message) => {
  return !value ? message : undefined;
};

export const numberValidation = (value, message, description) => {
  if (!value) return message;
  return value < 0 ? description : undefined;
};

export const numberOrEmptyValidation = (value, message, description) => {
  if (value) {
    if (value % 1 !== 0) return description;
    return value < 0 ? message : undefined;
  };

  return undefined;
};

export const integerValidation = (value, message, description, details) => {
  if (!value) return message;
  if (value % 1 !== 0) return details;
  return value < 0 ? description : undefined;
};

export const compareTwoValuesWithProp = (value1, value2, propValue, message, description) => {
  if ((Number(value1) + Number(value2)) !== Number(propValue)) return message;
  return (value1 < 0 || value2 < 0) ? description : undefined;
};