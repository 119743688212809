import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContactItem from './components/ContactItem';
import Spinner from '../../../../components/shared/Spinner';
import ContactForm from './components/ContactForm';

import './_contact.scss';

class Contact extends Component {
  componentDidMount() {
    this.props.fetchContact();
  }

  getDropdownOptions = () => {
    const { data } = this.props;
    let options = [];
    data.map(x =>
      options.push({
        value: x.id,
        label: x.description
      })
    );
    return options;
  };
  render() {
    const { data, sendMessage, loading, sync } = this.props;
    let options = this.getDropdownOptions();

    return (
      <div className="contact">
        {!loading && sync ? (
          <div className="contact__container">
            <div className="contact__info">
              {data.map(v => (
                <ContactItem
                  type={v.contactType}
                  contactUsers={v.contactUsers}
                  description={v.description}
                  key={v.id}
                />
              ))}
            </div>
            <div className="contact__form form">
              <ContactForm
                initialValues={{ type: options[0] }}
                options={options}
                sendMessage={sendMessage}
                loading={loading}
                sync={sync}
              />
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

Contact.propTypes = {
  fetchContact: PropTypes.func,
  data: PropTypes.array
};

export default Contact;
