import React, { useState, memo } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import ChartTooltip from '../../../../../../../components/Charts/components/ChartTooltip';
import * as colors from '../../../../../../../constants/colors';
import Spinner from "../../../../../../../components/shared/Spinner";



function DoorOpenDoorClosedNewChart({ data }) {
  const { loading } = data;
  const [showTooltip, setShowTooltip] = useState(false);
  const [activeTooltipContent, setActiveTooltipContent] = useState({
    date: '',
    start: '',
    end: '',
    status: ''
  })

  const CustomTooltip = ({ active, payload, label }) => {

    // console.log(active, payload, label);
    if (active && payload && payload.length) {

      return (

        activeTooltipContent.status === 0 || activeTooltipContent.status === 1 ?
          <>
            <ChartTooltip>
              <small> {activeTooltipContent.status === 0 ? 'Door Open' : 'Door Closed'}</small>
              <br />
              <small> {activeTooltipContent.date}</small>
              <br />
              <small>{`${activeTooltipContent.start}-${activeTooltipContent.end}`}</small>
            </ChartTooltip>
          </> :
          null


      );
    }

    return null;
  };

  return (

    <div className="chart">
      <div className="chart__header">
        <div className="chart__title">Door Activity</div>
        <div className="legends">

          <div className="legends__item">

            <div className="legends__item">
              <div
                className="legends__item__dot"
                style={{ backgroundColor: colors.DOOR_WARM_RED }}
              />

              <div className="legends__item__label" style={{ color: colors.DOOR_WARM_RED }}>
                Door Closed
              </div>
            </div>
            {"  "}

            <div className="legends__item">
              <div
                className="legends__item__dot"
                style={{ backgroundColor: colors.DOOR_RED }}
              />

              <div className="legends__item__label" style={{ color: colors.DOOR_RED }}>
                Door Open
              </div>
            </div>
          </div>

        </div>
      </div>

      {loading === false ? (
        <div className="chart__container" style={{ height: '756px' }}>

          <ResponsiveContainer >

            <BarChart

              height={1000}
              data={data.list}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              isAnimationActive={false}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={true} horizontal={true} horizontalPoints={[0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380, 1440]} />
              <XAxis dataKey="name"
                stroke={colors.LIGHT_GREY}
                interval="preserveStartEnd"
                tickLine={false}
                axisLine={false}


              />
              <YAxis
                tickFormatter={(tick) => {
                  return `${tick / 60 < 10 ? `0${tick / 60}:00` : tick / 60 === 24 ? `23:59` : `${tick / 60}:00`}`;
                }} ticks={[0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380, 1440]} type="number" axisLine={false} interval={0} />

              <Tooltip active={showTooltip} content={<CustomTooltip />} />


              <Bar maxBarSize={60} stackId="a" dataKey="1" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['1d'],
                      start: entry['1s'],
                      end: entry['1e'],
                      status: entry['1o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)}
                    fill={entry['1c']}> </Cell>
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="2"  >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {

                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['2d'],
                      start: entry['2s'],
                      end: entry['2e'],
                      status: entry['2o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['2c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="3"  >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['3d'],
                      start: entry['3s'],
                      end: entry['3e'],
                      status: entry['3o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['3c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="4"  >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['4d'],
                      start: entry['4s'],
                      end: entry['4e'],
                      status: entry['4o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['4c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="5"  >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['5d'],
                      start: entry['5s'],
                      end: entry['5e'],
                      status: entry['5o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['5c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="6"  >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['6d'],
                      start: entry['6s'],
                      end: entry['6e'],
                      status: entry['6o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['6c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="7" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['7d'],
                      start: entry['7s'],
                      end: entry['7e'],
                      status: entry['7o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['7c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="8"  >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['8d'],
                      start: entry['8s'],
                      end: entry['8e'],
                      status: entry['8o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['8c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="9">
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['9d'],
                      start: entry['9s'],
                      end: entry['9e'],
                      status: entry['9o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['9c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="10" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['10d'],
                      start: entry['10s'],
                      end: entry['10e'],
                      status: entry['10o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['10c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="11" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['11d'],
                      start: entry['11s'],
                      end: entry['11e'],
                      status: entry['11o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['11c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="12" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['12d'],
                      start: entry['12s'],
                      end: entry['12e'],
                      status: entry['12o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['12c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="13" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['13d'],
                      start: entry['13s'],
                      end: entry['13e'],
                      status: entry['13o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['13c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="14" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['14d'],
                      start: entry['14s'],
                      end: entry['14e'],
                      status: entry['14o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['14c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="15" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['15d'],
                      start: entry['15s'],
                      end: entry['15e'],
                      status: entry['15o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['15c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="16" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['16d'],
                      start: entry['16s'],
                      end: entry['16e'],
                      status: entry['16o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['16c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="17" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['17d'],
                      start: entry['17s'],
                      end: entry['17e'],
                      status: entry['17o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['17c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="18" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['18d'],
                      start: entry['18s'],
                      end: entry['18e'],
                      status: entry['18o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['18c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="19" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['19d'],
                      start: entry['19s'],
                      end: entry['19e'],
                      status: entry['19o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['19c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="20" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['20d'],
                      start: entry['20s'],
                      end: entry['20e'],
                      status: entry['20o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['20c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="21" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['21d'],
                      start: entry['21s'],
                      end: entry['21e'],
                      status: entry['21o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['21c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="22" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['22d'],
                      start: entry['22s'],
                      end: entry['22e'],
                      status: entry['22o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['22c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="23" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['23d'],
                      start: entry['23s'],
                      end: entry['23e'],
                      status: entry['23o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['23c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="24" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['24d'],
                      start: entry['24s'],
                      end: entry['24e'],
                      status: entry['24o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['24c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="25" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['25d'],
                      start: entry['25s'],
                      end: entry['25e'],
                      status: entry['25o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['25c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="26" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['26d'],
                      start: entry['26s'],
                      end: entry['26e'],
                      status: entry['26o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['26c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="27" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['27d'],
                      start: entry['27s'],
                      end: entry['27e'],
                      status: entry['27o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['27c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="28" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['28d'],
                      start: entry['28s'],
                      end: entry['28e'],
                      status: entry['28o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['28c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="29" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['29d'],
                      start: entry['29s'],
                      end: entry['29e'],
                      status: entry['29o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['29c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="30" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['30d'],
                      start: entry['30s'],
                      end: entry['30e'],
                      status: entry['30o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['30c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="31" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['31d'],
                      start: entry['31s'],
                      end: entry['31e'],
                      status: entry['31o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['31c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="32" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['32d'],
                      start: entry['32s'],
                      end: entry['32e'],
                      status: entry['32o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['32c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="33" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['33d'],
                      start: entry['33s'],
                      end: entry['33e'],
                      status: entry['33o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['33c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="34" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['34d'],
                      start: entry['34s'],
                      end: entry['34e'],
                      status: entry['34o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['34c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="35" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['35d'],
                      start: entry['35s'],
                      end: entry['35e'],
                      status: entry['35o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['35c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="36" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['36d'],
                      start: entry['36s'],
                      end: entry['36e'],
                      status: entry['36o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['36c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="37" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['37d'],
                      start: entry['37s'],
                      end: entry['37e'],
                      status: entry['37o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['37c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="38" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['38d'],
                      start: entry['38s'],
                      end: entry['38e'],
                      status: entry['38o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['38c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="39" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['39d'],
                      start: entry['39s'],
                      end: entry['39e'],
                      status: entry['39o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['39c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="40" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['40d'],
                      start: entry['40s'],
                      end: entry['40e'],
                      status: entry['40o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['40c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="41" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['41d'],
                      start: entry['41s'],
                      end: entry['41e'],
                      status: entry['41o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['41c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="42" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['42d'],
                      start: entry['42s'],
                      end: entry['42e'],
                      status: entry['42o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['42c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="43" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['43d'],
                      start: entry['43s'],
                      end: entry['43e'],
                      status: entry['43o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['43c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="44" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['44d'],
                      start: entry['44s'],
                      end: entry['44e'],
                      status: entry['44o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['44c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="45" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['45d'],
                      start: entry['45s'],
                      end: entry['45e'],
                      status: entry['45o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['45c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="46" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['46d'],
                      start: entry['46s'],
                      end: entry['46e'],
                      status: entry['46o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['46c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="47" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['47d'],
                      start: entry['47s'],
                      end: entry['47e'],
                      status: entry['47o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['47c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="48" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['48d'],
                      start: entry['48s'],
                      end: entry['48e'],
                      status: entry['48o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['48c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="49" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['49d'],
                      start: entry['49s'],
                      end: entry['49e'],
                      status: entry['49o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['49c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="50" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['50d'],
                      start: entry['50s'],
                      end: entry['50e'],
                      status: entry['50o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['50c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="51" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['51d'],
                      start: entry['51s'],
                      end: entry['51e'],
                      status: entry['51o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['51c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="52" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['52d'],
                      start: entry['52s'],
                      end: entry['52e'],
                      status: entry['52o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['52c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="53" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['53d'],
                      start: entry['53s'],
                      end: entry['53e'],
                      status: entry['53o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['53c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="54" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['54d'],
                      start: entry['54s'],
                      end: entry['54e'],
                      status: entry['54o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['54c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="55" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['55d'],
                      start: entry['55s'],
                      end: entry['55e'],
                      status: entry['55o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['55c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="56" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['56d'],
                      start: entry['56s'],
                      end: entry['56e'],
                      status: entry['56o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['56c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="57" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['57d'],
                      start: entry['57s'],
                      end: entry['57e'],
                      status: entry['57o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['57c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="58" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['58d'],
                      start: entry['58s'],
                      end: entry['58e'],
                      status: entry['58o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['58c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="59" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['59d'],
                      start: entry['59s'],
                      end: entry['59e'],
                      status: entry['59o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['59c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="60" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['60d'],
                      start: entry['60s'],
                      end: entry['60e'],
                      status: entry['60o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['60c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="61" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['61d'],
                      start: entry['61s'],
                      end: entry['61e'],
                      status: entry['61o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['61c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="62" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['62d'],
                      start: entry['62s'],
                      end: entry['62e'],
                      status: entry['62o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['62c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="63" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['63d'],
                      start: entry['63s'],
                      end: entry['63e'],
                      status: entry['63o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['63c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="64" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['64d'],
                      start: entry['64s'],
                      end: entry['64e'],
                      status: entry['64o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['64c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="65" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['65d'],
                      start: entry['65s'],
                      end: entry['65e'],
                      status: entry['65o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['65c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="66" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['66d'],
                      start: entry['66s'],
                      end: entry['66e'],
                      status: entry['66o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['66c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="67" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['67d'],
                      start: entry['67s'],
                      end: entry['67e'],
                      status: entry['67o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['67c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="68" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['68d'],
                      start: entry['68s'],
                      end: entry['68e'],
                      status: entry['68o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['68c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="69" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['69d'],
                      start: entry['69s'],
                      end: entry['69e'],
                      status: entry['69o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['69c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="70" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['70d'],
                      start: entry['70s'],
                      end: entry['70e'],
                      status: entry['70o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['70c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="71" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['71d'],
                      start: entry['71s'],
                      end: entry['71e'],
                      status: entry['71o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['71c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="72" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['72d'],
                      start: entry['72s'],
                      end: entry['72e'],
                      status: entry['72o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['72c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="73" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['73d'],
                      start: entry['73s'],
                      end: entry['73e'],
                      status: entry['73o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['73c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="74" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['74d'],
                      start: entry['74s'],
                      end: entry['74e'],
                      status: entry['74o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['74c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="75" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['75d'],
                      start: entry['75s'],
                      end: entry['75e'],
                      status: entry['75o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['75c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="76" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['76d'],
                      start: entry['76s'],
                      end: entry['76e'],
                      status: entry['76o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['76c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="77" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['77d'],
                      start: entry['77s'],
                      end: entry['77e'],
                      status: entry['77o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['77c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="78" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['78d'],
                      start: entry['78s'],
                      end: entry['78e'],
                      status: entry['78o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['78c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="79" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['79d'],
                      start: entry['79s'],
                      end: entry['79e'],
                      status: entry['79o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['79c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="80" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['80d'],
                      start: entry['80s'],
                      end: entry['80e'],
                      status: entry['80o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['80c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="81" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['81d'],
                      start: entry['81s'],
                      end: entry['81e'],
                      status: entry['81o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['81c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="82" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['82d'],
                      start: entry['82s'],
                      end: entry['82e'],
                      status: entry['82o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['82c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="83" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['83d'],
                      start: entry['83s'],
                      end: entry['83e'],
                      status: entry['83o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['83c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="84" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['84d'],
                      start: entry['84s'],
                      end: entry['84e'],
                      status: entry['84o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['84c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="85" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['85d'],
                      start: entry['85s'],
                      end: entry['85e'],
                      status: entry['85o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['85c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="86" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['86d'],
                      start: entry['86s'],
                      end: entry['86e'],
                      status: entry['86o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['86c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="87" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['87d'],
                      start: entry['87s'],
                      end: entry['87e'],
                      status: entry['87o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['87c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="88" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['88d'],
                      start: entry['88s'],
                      end: entry['88e'],
                      status: entry['88o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['88c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="89" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['89d'],
                      start: entry['89s'],
                      end: entry['89e'],
                      status: entry['89o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['89c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="90" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['90d'],
                      start: entry['90s'],
                      end: entry['90e'],
                      status: entry['90o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['90c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="91" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['91d'],
                      start: entry['91s'],
                      end: entry['91e'],
                      status: entry['91o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['91c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="92" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['92d'],
                      start: entry['92s'],
                      end: entry['92e'],
                      status: entry['92o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['92c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="93" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['93d'],
                      start: entry['93s'],
                      end: entry['93e'],
                      status: entry['93o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['93c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="94" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['94d'],
                      start: entry['94s'],
                      end: entry['94e'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['94c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="95" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['95d'],
                      start: entry['95s'],
                      end: entry['95e'],
                      status: entry['95o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['95c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="96" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['96d'],
                      start: entry['96s'],
                      end: entry['96e'],
                      status: entry['96o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['96c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="97" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['97d'],
                      start: entry['97s'],
                      end: entry['97e'],
                      status: entry['97o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['97c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="98" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['98d'],
                      start: entry['98s'],
                      end: entry['98e'],
                      status: entry['98o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['98c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="99" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['99d'],
                      start: entry['99s'],
                      end: entry['99e'],
                      status: entry['99o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['99c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="100" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['100d'],
                      start: entry['100s'],
                      end: entry['100e'],
                      status: entry['100o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['100c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="101" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['101d'],
                      start: entry['101s'],
                      end: entry['101e'],
                      status: entry['101o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['101c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="102" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['102d'],
                      start: entry['102s'],
                      end: entry['102e'],
                      status: entry['102o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['102c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="103" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['103d'],
                      start: entry['103s'],
                      end: entry['103e'],
                      status: entry['103o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['103c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="104" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['104d'],
                      start: entry['104s'],
                      end: entry['104e'],
                      status: entry['104o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['104c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="105" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['105d'],
                      start: entry['105s'],
                      end: entry['105e'],
                      status: entry['105o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['105c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="106" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['106d'],
                      start: entry['106s'],
                      end: entry['106e'],
                      status: entry['106o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['106c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="107" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['107d'],
                      start: entry['107s'],
                      end: entry['107e'],
                      status: entry['107o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['107c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="108" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['108d'],
                      start: entry['108s'],
                      end: entry['108e'],
                      status: entry['108o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['108c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="109" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['109d'],
                      start: entry['109s'],
                      end: entry['109e'],
                      status: entry['109o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['109c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="110" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['110d'],
                      start: entry['110s'],
                      end: entry['110e'],
                      status: entry['110o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['110c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="111" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['111d'],
                      start: entry['111s'],
                      end: entry['111e'],
                      status: entry['111o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['111c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="112" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['112d'],
                      start: entry['112s'],
                      end: entry['112e'],
                      status: entry['112o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['112c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="113" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['113d'],
                      start: entry['113s'],
                      end: entry['113e'],
                      status: entry['113o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['113c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="114" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['114d'],
                      start: entry['114s'],
                      end: entry['114e'],
                      status: entry['114o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['114c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="115" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['115d'],
                      start: entry['115s'],
                      end: entry['115e'],
                      status: entry['115o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['115c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="116" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['116d'],
                      start: entry['116s'],
                      end: entry['116e'],
                      status: entry['116o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['116c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="117" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['117d'],
                      start: entry['117s'],
                      end: entry['117e'],
                      status: entry['117o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['117c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="118" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['118d'],
                      start: entry['118s'],
                      end: entry['118e'],
                      status: entry['118o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['118c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="119" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['119d'],
                      start: entry['119s'],
                      end: entry['119e'],
                      status: entry['119o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['119c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="120" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['120d'],
                      start: entry['120s'],
                      end: entry['120e'],
                      status: entry['120o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['120c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="121" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['121d'],
                      start: entry['121s'],
                      end: entry['121e'],
                      status: entry['121o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['121c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="122" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['122d'],
                      start: entry['122s'],
                      end: entry['122e'],
                      status: entry['122o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['122c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="123" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['123d'],
                      start: entry['123s'],
                      end: entry['123e'],
                      status: entry['123o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['123c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="124" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['124d'],
                      start: entry['124s'],
                      end: entry['124e'],
                      status: entry['124o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['124c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="125" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['125d'],
                      start: entry['125s'],
                      end: entry['125e'],
                      status: entry['125o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['125c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="126" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['126d'],
                      start: entry['126s'],
                      end: entry['126e'],
                      status: entry['126o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['126c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="127" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['127d'],
                      start: entry['127s'],
                      end: entry['127e'],
                      status: entry['127o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['127c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="128" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['128d'],
                      start: entry['128s'],
                      end: entry['128e'],
                      status: entry['128o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['128c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="129" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['129d'],
                      start: entry['129s'],
                      end: entry['129e'],
                      status: entry['129o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['129c']} />
                })}
              </Bar>
              <Bar maxBarSize={60} stackId="a" dataKey="130" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['130d'],
                      start: entry['130s'],
                      end: entry['130e'],
                      status: entry['130o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['130c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="131" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['131d'],
                      start: entry['131s'],
                      end: entry['131e'],
                      status: entry['131o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['131c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="132" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['132d'],
                      start: entry['132s'],
                      end: entry['132e'],
                      status: entry['132o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['132c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="133" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['133d'],
                      start: entry['133s'],
                      end: entry['133e'],
                      status: entry['133o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['133c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="134" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['134d'],
                      start: entry['134s'],
                      end: entry['134e'],
                      status: entry['134o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['134c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="135" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['135d'],
                      start: entry['135s'],
                      end: entry['135e'],
                      status: entry['135o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['135c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="136" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['136d'],
                      start: entry['136s'],
                      end: entry['136e'],
                      status: entry['136o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['136c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="137" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['137d'],
                      start: entry['137s'],
                      end: entry['137e'],
                      status: entry['137o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['137c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="138" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['138d'],
                      start: entry['138s'],
                      end: entry['138e'],
                      status: entry['138o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['138c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="139" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['139d'],
                      start: entry['139s'],
                      end: entry['139e'],
                      status: entry['139o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['139c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="140" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['140d'],
                      start: entry['140s'],
                      end: entry['140e'],
                      status: entry['140o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['140c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="141" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['141d'],
                      start: entry['141s'],
                      end: entry['141e'],
                      status: entry['141o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['141c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="142" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['142d'],
                      start: entry['142s'],
                      end: entry['142e'],
                      status: entry['142o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['142c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="143" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['143d'],
                      start: entry['143s'],
                      end: entry['143e'],
                      status: entry['143o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['143c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="144" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['144d'],
                      start: entry['144s'],
                      end: entry['144e'],
                      status: entry['144o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['144c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="145" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['145d'],
                      start: entry['145s'],
                      end: entry['145e'],
                      status: entry['145o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['145c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="146" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['146d'],
                      start: entry['146s'],
                      end: entry['146e'],
                      status: entry['146o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['146c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="147" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['147d'],
                      start: entry['147s'],
                      end: entry['147e'],
                      status: entry['147o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['147c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="148" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['148d'],
                      start: entry['148s'],
                      end: entry['148e'],
                      status: entry['148o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['148c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="149" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['149d'],
                      start: entry['149s'],
                      end: entry['149e'],
                      status: entry['149o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['149c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="150" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['150d'],
                      start: entry['150s'],
                      end: entry['150e'],
                      status: entry['150o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['150c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="151" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['151d'],
                      start: entry['151s'],
                      end: entry['151e'],
                      status: entry['151o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['151c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="152" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['152d'],
                      start: entry['152s'],
                      end: entry['152e'],
                      status: entry['152o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['152c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="153" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['153d'],
                      start: entry['153s'],
                      end: entry['153e'],
                      status: entry['153o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['153c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="154" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['154d'],
                      start: entry['154s'],
                      end: entry['154e'],
                      status: entry['154o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['154c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="155" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['155d'],
                      start: entry['155s'],
                      end: entry['155e'],
                      status: entry['155o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['155c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="156" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['156d'],
                      start: entry['156s'],
                      end: entry['156e'],
                      status: entry['156o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['156c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="157" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['157d'],
                      start: entry['157s'],
                      end: entry['157e'],
                      status: entry['157o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['157c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="158" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['158d'],
                      start: entry['158s'],
                      end: entry['158e'],
                      status: entry['158o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['158c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="159" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['159d'],
                      start: entry['159s'],
                      end: entry['159e'],
                      status: entry['159o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['159c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="160" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['160d'],
                      start: entry['160s'],
                      end: entry['160e'],
                      status: entry['160o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['160c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="161" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['161d'],
                      start: entry['161s'],
                      end: entry['161e'],
                      status: entry['161o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['161c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="162" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['162d'],
                      start: entry['162s'],
                      end: entry['162e'],
                      status: entry['162o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['162c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="163" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['163d'],
                      start: entry['163s'],
                      end: entry['163e'],
                      status: entry['163o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['163c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="164" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['164d'],
                      start: entry['164s'],
                      end: entry['164e'],
                      status: entry['164o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['164c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="165" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['165d'],
                      start: entry['165s'],
                      end: entry['165e'],
                      status: entry['165o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['165c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="166" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['166d'],
                      start: entry['166s'],
                      end: entry['166e'],
                      status: entry['166o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['166c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="167" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['167d'],
                      start: entry['167s'],
                      end: entry['167e'],
                      status: entry['167o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['167c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="168" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['168d'],
                      start: entry['168s'],
                      end: entry['168e'],
                      status: entry['168o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['168c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="169" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['169d'],
                      start: entry['169s'],
                      end: entry['169e'],
                      status: entry['169o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['169c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="170" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['170d'],
                      start: entry['170s'],
                      end: entry['170e'],
                      status: entry['170o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['170c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="171" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['171d'],
                      start: entry['171s'],
                      end: entry['171e'],
                      status: entry['171o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['171c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="172" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['172d'],
                      start: entry['172s'],
                      end: entry['172e'],
                      status: entry['172o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['172c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="173" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['173d'],
                      start: entry['173s'],
                      end: entry['173e'],
                      status: entry['173o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['173c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="174" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['174d'],
                      start: entry['174s'],
                      end: entry['174e'],
                      status: entry['174o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['174c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="175" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['175d'],
                      start: entry['175s'],
                      end: entry['175e'],
                      status: entry['175o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['175c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="176" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['176d'],
                      start: entry['176s'],
                      end: entry['176e'],
                      status: entry['176o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['176c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="177" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['177d'],
                      start: entry['177s'],
                      end: entry['177e'],
                      status: entry['177o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['177c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="178" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['178d'],
                      start: entry['178s'],
                      end: entry['178e'],
                      status: entry['178o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['178c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="179" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['179d'],
                      start: entry['179s'],
                      end: entry['179e'],
                      status: entry['179o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['179c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="180" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['180d'],
                      start: entry['180s'],
                      end: entry['180e'],
                      status: entry['180o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['180c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="181" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['181d'],
                      start: entry['181s'],
                      end: entry['181e'],
                      status: entry['181o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['181c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="182" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['182d'],
                      start: entry['182s'],
                      end: entry['182e'],
                      status: entry['182o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['182c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="183" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['183d'],
                      start: entry['183s'],
                      end: entry['183e'],
                      status: entry['183o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['183c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="184" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['184d'],
                      start: entry['184s'],
                      end: entry['184e'],
                      status: entry['184o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['184c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="185" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['185d'],
                      start: entry['185s'],
                      end: entry['185e'],
                      status: entry['185o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['185c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="186" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['186d'],
                      start: entry['186s'],
                      end: entry['186e'],
                      status: entry['186o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['186c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="187" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['187d'],
                      start: entry['187s'],
                      end: entry['187e'],
                      status: entry['187o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['187c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="188" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['188d'],
                      start: entry['188s'],
                      end: entry['188e'],
                      status: entry['188o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['188c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="189" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['189d'],
                      start: entry['189s'],
                      end: entry['189e'],
                      status: entry['189o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['189c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="190" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['190d'],
                      start: entry['190s'],
                      end: entry['190e'],
                      status: entry['190o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['190c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="191" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['191d'],
                      start: entry['191s'],
                      end: entry['191e'],
                      status: entry['191o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['191c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="192" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['192d'],
                      start: entry['192s'],
                      end: entry['192e'],
                      status: entry['192o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['192c']} />
                })}
              </Bar>


              <Bar maxBarSize={60} stackId="a" dataKey="193" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['193d'],
                      start: entry['193s'],
                      end: entry['193e'],
                      status: entry['193o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['193c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="194" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['194d'],
                      start: entry['194s'],
                      end: entry['194e'],
                      status: entry['194o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['194c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="195" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['195d'],
                      start: entry['195s'],
                      end: entry['195e'],
                      status: entry['195o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['195c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="196" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['196d'],
                      start: entry['196s'],
                      end: entry['196e'],
                      status: entry['196o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['196c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="197" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['197d'],
                      start: entry['197s'],
                      end: entry['197e'],
                      status: entry['197o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['197c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="198" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['198d'],
                      start: entry['198s'],
                      end: entry['198e'],
                      status: entry['198o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['198c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="199" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['199d'],
                      start: entry['199s'],
                      end: entry['199e'],
                      status: entry['199o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['199c']} />
                })}
              </Bar>

              <Bar maxBarSize={60} stackId="a" dataKey="200" >
                {data.list.map((entry, index) => {
                  return <Cell onMouseEnter={() => {
                    setShowTooltip(true);
                    setActiveTooltipContent(prev => ({
                      ...prev,
                      date: entry['200d'],
                      start: entry['200s'],
                      end: entry['200e'],
                      status: entry['200o'],
                    }))
                  }}
                    onMouseLeave={() => setShowTooltip(false)} fill={entry['200c']} />
                })}
              </Bar>








            </BarChart>
          </ResponsiveContainer>
          <Spinner overlay active={loading} />
        </div>
      ) : (
        <div
          className={`chart__container no-data ${loading ? "loading" : ""
            }`}
        >
          {loading ? <Spinner /> : ""}
        </div>
      )}

    </div>


  );
}

export default memo(DoorOpenDoorClosedNewChart);


