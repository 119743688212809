import {
    DONE_FETCHING_COMPANY_CONTACT,
    DONE_UPDATING_COMPANY_CONTACT,
    FETCHING_COMPANY_CONTACT,
    UPDATE_COMPANY_CONTACT_VALUES,
    UPDATING_COMPANY_CONTACT,
    CONTACT_REMOVE,
    CONTACT_ADD,
    DONE_FETCHING_COMPANY_SMS_STATUS,
    DONE_UPDATING_COMPANY_SMS_STATUS
} from "../../constants/alarms.types";
import { getAvailableCountryContactPrefix } from "../../utils/dropDownUtil";
import { PURGE } from "redux-persist";

const INITIAL = {
    fetchingData: false,
    updatingData: false,
    // internalPhoneNumber: "",
    verifyInput: false,
    verifying: false,
    smsCode: '',
    companySmsStatus: {
        id: '',
        sendingMode: '',
    },
    data: [{
        companyId: '',
        phoneNumber: '',
        countryCode: getAvailableCountryContactPrefix()[0].value,
        phoneNumberVerified: false,
        codeHash: '',
        internalPhoneNumber: '',
        internalCountryCode: getAvailableCountryContactPrefix()[0].value,
        verifyOrNotYet: false,
        areContactsDifferent: false,
        phoneNumberError: '',
        smsCode: '',
        smsCodeError: ''
    }]
};

export default (state = INITIAL, action) => {
    switch (action.type) {
        case FETCHING_COMPANY_CONTACT:
            return { ...state, fetchingData: true };

        case DONE_FETCHING_COMPANY_CONTACT:
            if (action.payload.length > 0) {
                return {
                    ...state,
                    fetchingData: false,
                    data: [...action.payload].map(e => ({ ...e, internalPhoneNumber: e.phoneNumber, verifyOrNotYet: false, areContactsDifferent: false, phoneNumberError: '', countryCode: e.countryCode ? e.countryCode : getAvailableCountryContactPrefix()[0].value, internalCountryCode: e.countryCode ? e.countryCode : getAvailableCountryContactPrefix()[0].value, smsCode: '', smsCodeError: '' }))
                };
            }
            return { ...state, fetchingData: false }

        case UPDATE_COMPANY_CONTACT_VALUES:
            const datArray = state.data
            const newObject = { ...state.data[action.payloadIndex], [action.payloadKey]: action.payloadValue }
            datArray[action.payloadIndex] = newObject;

            return { ...state, [action.payloadKey]: action.payloadValue, data: [...datArray] }

        case UPDATING_COMPANY_CONTACT:
            return { ...state, updatingData: true };

        case CONTACT_ADD:
            return {
                ...state, data: [...state.data, {
                    companyId: '',
                    phoneNumber: '',
                    countryCode: getAvailableCountryContactPrefix()[0].value,
                    internalCountryCode: getAvailableCountryContactPrefix()[0].value,
                    phoneNumberVerified: false,
                    codeHash: '',
                    internalPhoneNumber: '',
                    verifyOrNotYet: false,
                    areContactsDifferent: false,
                    phoneNumberError: '',
                    smsCode: '',
                    smsCodeError: '',
                }]
            };
        case CONTACT_REMOVE:
            const datArrayDel = state.data
            datArrayDel.splice(action.payloadIndex, 1);
            return { ...state, data: [...datArrayDel] };

        case DONE_UPDATING_COMPANY_CONTACT:
            const datArrayUpdate = state.data
            const newObjectUpdate = { ...state.data[action.payloadIndex], ...action.payload }
            datArrayUpdate.splice(action.payloadIndex, 1, newObjectUpdate);
            // console.log(newObjectUpdate)

            return { ...state, updatingData: false, verifyInput: true, data: [...datArrayUpdate] };

        case DONE_FETCHING_COMPANY_SMS_STATUS:
            return action.payload ? { ...state, companySmsStatus: action.payload } : { ...state };

        case DONE_UPDATING_COMPANY_SMS_STATUS:
            return { ...state, companySmsStatus: action.payload }

        case PURGE:
            return {
                ...INITIAL
            }


        default:
            return state;
    }
};