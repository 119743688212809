import React from 'react';
import PropTypes from 'prop-types';
// import avatarPlaceholder from '../../../assets/images/avatar_placeholder.png';

const UserData = props => {
  return (
    <div className={props.className}>
      <div className={`${props.className}__data`}>
        {props.userLoggedIn && (
          <div className="email">{props.userData.email}</div>
        )}
      </div>
      {/*{props.userLoggedIn && (*/}
      {/*<div className={`${props.className}__placeholder`}>*/}
      {/*<img src={avatarPlaceholder} alt="avatar-placeholder" />*/}
      {/*</div>*/}
      {/*)}*/}
    </div>
  );
};

UserData.propTypes = {
  handleLogout: PropTypes.func,
  userLoggedIn: PropTypes.bool,
  userData: PropTypes.shape({
    email: PropTypes.string
  })
};

export default UserData;
