import * as alarmsTypes from '../../constants/alarms.types';
// import { mergeArraysBy } from '../../utils/arrays/mergeArraysBy';
import { PURGE } from 'redux-persist';

const initialState = {
  data: [],
  loading: false,
  sync: false,
  error: null
};

const reducer = {
  [alarmsTypes.LOADING_ACTIVE]: state => ({
    ...state,
    loading: true
  }),
  [alarmsTypes.DONE_FETCH_ACTIVE]: (
    state,
    action = { payload: { data: [] } }
  ) => ({
    ...state,
    loading: false,
    sync: true,
    data: action.payload.data
  }),
  [alarmsTypes.ERROR_FETCH_ACTIVE]: (
    state,
    action = { payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [alarmsTypes.CLEAN_DATA_ACTIVE]: () => ({
    ...initialState
  }),
  [alarmsTypes.LOADING_RESOLVE]: (state, action) => ({
    ...state,
    data: state.data.map(x => {
      if (x.id === action.alarmId) {
        return { ...x, loading: true };
      }
      return x;
    })
  }),
  [alarmsTypes.DONE_RESOLVE]: (state, action) => ({
    ...state,
    data: state.data.map(x => {
      if (x.id === action.projectId) {
        return { ...x, loading: false };
      }
      return x;
    })
  }),
  [alarmsTypes.ERROR_RESOLVE]: (
    state,
    action = { payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    error: action.payload.error,
    data: state.data.map(x => {
      if (x.id === action.projectId) {
        return { ...x, loading: false };
      }
      return x;
    })
  }),
  [PURGE]: () => ({
    ...initialState
  })
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
