import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from './Alarms';
import * as actions from '../../actions/alarms/active.actions';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => {
  const dataLength = state.alarms.active.data.length;
  return {
    data: state.alarms.active.data,
    dataLength: dataLength,
    loading: state.alarms.active.loading,
    sync: state.alarms.active.sync,
    features: state.auth.features,
    paymentStatus: state.auth.paymentStatus
  };
};

const mapDispatchToProps = dispatch => ({
  fetchActiveAlarms: bindActionCreators(actions.fetchActiveAlarms, dispatch),
});


const ComponentWithRouter = withRouter(Component);

export default connect(mapStateToProps,mapDispatchToProps)(ComponentWithRouter);
