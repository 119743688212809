import api from "../../utils/api";
import * as unitDetailsTypes from "../../constants/unitDetails.types";
import { getProjectId } from "../../utils/auth/user";

const convertDateToISO = (obj) => {
  obj["startDate"] = obj["startDate"].toISOString();
  obj["endDate"] = obj["endDate"].toISOString();
  return obj;
};

export const fetchUnitStatistics = (
  coldStorageId,
  dates,
  projectId,
  statisticsType
) => async (dispatch) => {
  dispatch({
    type: unitDetailsTypes.LOADING_STATISTICS,
    coldStorageId: coldStorageId,
    statisticsType,
  });
  const parsedDates = convertDateToISO(dates);
  try {
    const response = await api.get(
      `/project/${projectId ||
        getProjectId()}/unit/${coldStorageId}/statisticsType/${statisticsType}/statistics?startDate=${
        parsedDates.startDate
      }&endDate=${parsedDates.endDate}`
    );

    dispatch({
      type: unitDetailsTypes.DONE_FETCH_STATISTICS,
      coldStorageId: coldStorageId,
      payload: { statisticsType, data: response.data },
    });

    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      coldStorageId: coldStorageId,
      type: unitDetailsTypes.ERROR_FETCH_STATISTICS,
      payload: { error: "Fetch single unit data error" },
    });

    return Promise.resolve(e.response);
  }
};

export const fetchCsvData = (
  coldStorageId,
  dates,
  projectId,
  statisticsType
) => async (dispatch) => {
  dispatch({
    type: unitDetailsTypes.LOADING_CSV_DATA,
    coldStorageId: coldStorageId,
    statisticsType,
  });

  try {
    const response = await api.get(
      `/project/${projectId ||
        getProjectId()}/unit/${coldStorageId}/statisticsType/${statisticsType}/statistics?startDate=${
        dates.startDate
      }&endDate=${dates.endDate}&csvdata=true`
    );
    dispatch({
      type: unitDetailsTypes.DONE_FETCH_CSV_DATA,
      coldStorageId: coldStorageId,
      payload: { statisticsType, data: response.data },
    });

    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      coldStorageId: coldStorageId,
      type: unitDetailsTypes.ERROR_FETCH_CSV_DATA,
      payload: { error: "Fetch CSV data error" },
    });

    return Promise.resolve(e.response);
  }
};
