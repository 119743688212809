import * as deviceTypes from "../constants/device.types";
import { PURGE } from 'redux-persist';

const initialState = {
  data: [],
  loading: false,
  sync: false,
   error: null,
    mappingStatus: {},
  loadingMappingStatus: false
};

const reducer = {
  [deviceTypes.LOADING_DEVICE_MAPINGS]: (state) => ({
    ...state,
    loading: true,
  }),
  [deviceTypes.DONE_DEVICE_MAPPING]: (
    state,
    action = { payload: { data: [] } }
  ) => ({
    ...state,
    loading: false,
    sync: true,
    error: "no error",
    data: action.payload.data,
  }),
  [deviceTypes.ERROR_DEVICE_MAPPING]: (
    state,
    action = { payload: { error: "Fetching error" } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),

  [deviceTypes.LOADING_DEVICE_MAPING_STATUS]: (state) => ({
    ...state,
    loadingMappingStatus: true,
  }),
  [deviceTypes.DONE_DEVICE_MAPPING_STATUS]: (
    state,
    action = { payload: { mappingStatus: {} } }
  ) => ({
    ...state,
    loadingMappingStatus: false,
    sync: true,
    error: "no error",
    mappingStatus: action.payload.mappingStatus,
  }),
  [deviceTypes.ERROR_DEVICE_MAPPING_STATUS]: (
    state,
    action = { payload: { error: "Fetching error" } }
  ) => ({
    ...state,
    loadingMappingStatus: false,
    error: action.payload.error,
  }),

  [PURGE]: () => ({
    ...initialState,
  }),
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
