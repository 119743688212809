import * as unitsTypes from '../../constants/unitDetails.types';
import { PURGE } from 'redux-persist';

const initialState = {
  loading: false,
  finalCsvStats: {},
};

const reducer = {
  [unitsTypes.LOADING_CSV_DATA]: (state, action) => {
    // const { coldStorageId } = action;

    // return {
    //   ...state,
    //   [coldStorageId]: {
    //     ...(state[coldStorageId] || {}),
    //     [action.statisticsType]: {
    //       loading: true,
    //       sync: true
    //     }
    //   }
    // };

    return {
      ...state,
      finalCurrentStats: {
        ...(state["finalCurrentStats"] || {}),
        [action.statisticsType]: {
          // ...(state['finalCurrentStats'][action.statisticsType] || {}),
          loading: true,
          sync: true,
        },
      },
    };
  },
  [unitsTypes.DONE_FETCH_CSV_DATA]: (state, action) => {

    // return {
    //   ...state,
    //   [coldStorageId]: {
    //     ...(state[coldStorageId] || {}),
    //     [statisticsType]: {
    //       ...(state[coldStorageId][statisticsType] || {}),
    //       list: data.dataResult,
    //       averageValue: data.averageValue,
    //       accumulatedValue: data.accumulatedValue,
    //       loading: false
    //     }
    //   }
    // };

    const {
      // coldStorageId,
      payload: { statisticsType, data },
    } = action;

    return {
      ...state,
      finalCurrentStats: {
        ...(state["finalCurrentStats"] || {}),
        [statisticsType]: {
          ...(state["finalCurrentStats"][statisticsType] || {}),
          list: data.dataResult,
          averageValue: data.averageValue,
          accumulatedValue: data.accumulatedValue,
          consumedValue: data.consumedValue,
          loading: false,
        },
      },
    };
  },
  [unitsTypes.ERROR_FETCH_CSV_DATA]: (
    state,
    action = { coldStorageId: 0, payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    finalCurrentStats: {
      ...(state["finalCurrentStats"] || {}),
      error: action.payload.error,
    },
  }),
  [PURGE]: () => ({
    ...initialState
  })
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
