import * as projectsTypes from '../constants/projects.types';
import { mergeArraysBy } from '../utils/arrays/mergeArraysBy';
import { PURGE } from 'redux-persist';


const initialState = {
  data: [],
  currentProject: null,
  loading: false,
  sync: false,
  error: null,
  companies: [],
  postingCompanyCommercial: false,
  postingCompanyCommercialError: false,
  postingCompanyCommercialMessage: '',
  postingCompanyCommercialMessageCounter: 0,
  companyCommercial: {
    // id: '',
    name: '',
  },
  createCompanyEmail: '',
  postingProjectCommercial: false,
  postingProjectCommercialError: false,
  postingProjectCommercialMessage: '',
  postingProjectCommercialMessageCounter: 0,


  postingRoomsCommercial: false,
  postingRoomsCommercialError: false,
  postingRoomsCommercialMessage: '',
  postingRoomsCommercialMessageCounter: 0,


  postingOtherUnitsCommercial: false,
  postingOtherUnitsCommercialError: false,
  postingOtherUnitsCommercialMessage: '',
  postingOtherUnitsCommercialMessageCounter: 0,

  projectCommercial: {
    title: '',
    uid: '',
    //id: '',
    companyId: '',
  },
  roomsCommercial: {
    projectId: '',
    //id: '',
    units: [],
    profiles: [],
    unitFields: [],
    otherFieldsDropDown: [],
    activeUnitForFieldSelection: '001',
    // unitFieldsCount: 1,
    //{uid: [strings]}
    // unitIds: []
    // uid: '',
    // customTitle: '',
    // dataProfileId: '',
  },

  activeProjectsTab: 'create',
  activeCompanyTab: 'create',


};

const reducer = {
  [projectsTypes.LOADING]: state => ({
    ...state,
    loading: true
  }),
  [projectsTypes.DONE_FETCH]: (state, action = { payload: { data: [] } }) => ({
    ...state,
    loading: false,
    sync: true,
    error: 'no error',
    data: mergeArraysBy(action.payload.data, state.data.items, 'id')
  }),
  [projectsTypes.ERROR_FETCH]: (
    state,
    action = { payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [projectsTypes.CLEAN_DATA]: state => ({
    ...state,
    loading: false,
    sync: false,
    data: []
  }),
  [projectsTypes.SET_CURRENT_PROJECT_ID]: (
    state,
    action = { payload: { currentProjectId: Number } }
  ) => ({
    ...state,
    currentProject: action.payload.currentProjectId
  }),

  [projectsTypes.SET_CURRENT_PROJECT_TO_FIRST_PROJECT]: state => ({
    ...state,
    currentProject: state.data[0].id
  }),

  [projectsTypes.FETCH_ALL_COMPANIES]: (state, action = { payload: [] }) => {

    const arr = action.payload.map((e, i) => {
      return {
        key: i,
        text: e.name,
        value: e.id,
      };
    });
    return {
      ...state,
      companies: arr
    }
  },

  [projectsTypes.UPDATE_LOCAL_COMPANY_DATA]: (state, action = { payloadKey: '', payloadValue: '' }) => {

    if (action.payloadKey === 'id' && !action.payloadValue) {

      return {
        ...state,
        companyCommercial: { name: '' }
      }

    }

    return {
      ...state,
      companyCommercial: { ...state.companyCommercial, [action.payloadKey]: action.payloadValue }
    }

  },

  [projectsTypes.UPDATE_LOCAL_PROJECT_DATA]: (state, action = { payloadKey: '', payloadValue: '' }) => {

    if (action.payloadKey === 'id' && !action.payloadValue) {

      return {
        ...state,
        projectCommercial: {
          title: '',
          uid: '',
          //id: '',
          companyId: '',
        }
      }

    }
    return {
      ...state,
      projectCommercial: { ...state.projectCommercial, [action.payloadKey]: action.payloadValue }
    }
  },

  [projectsTypes.UPDATE_LOCAL_ROOM_DATA]: (state, action = { payloadKey: '', payloadValue: '' }) => {

    // if (action.payloadKey === 'id' && !action.payloadValue) {

    //   return {
    //     ...state,
    //     projectCommercial: {
    //       title: '',
    //       uid: '',
    //       //id: '',
    //       companyId: '',
    //     }
    //   }

    // }
    return {
      ...state,
      roomsCommercial: { ...state.roomsCommercial, [action.payloadKey]: action.payloadValue }
    }
  },

  [projectsTypes.DONE_POSTING_LOCAL_COMPANY_DATA]: (state, action = { payload: {} }) => {
    return {
      ...state,
      postingCompanyCommercial: false,
      postingCompanyCommercialError: false,
      postingCompanyCommercialMessage: 'Company saved successfully',
      postingCompanyCommercialMessageCounter: state.postingCompanyCommercialMessageCounter + 1,
      companies: [...state.companies, {
        key: state.companies.length - 1,
        text: action.payload.name,
        value: action.payload.id,
      }],
      projectCommercial: { ...state.projectCommercial, companyId: action.payload.id }
    }
  },


  [projectsTypes.POSTING_LOCAL_COMPANY_DATA]: (state, action = { payload: {} }) => {
    return {
      ...state,
      postingCompanyCommercial: true,
      postingCompanyCommercialError: false,
      postingCompanyCommercialMessage: '',

    }
  },

  [projectsTypes.POSTING_LOCAL_PROJECT_DATA]: (state, action = { payload: {} }) => {
    return {
      ...state,
      postingProjectCommercial: true,
      postingProjectCommercialError: false,
      postingProjectCommercialMessage: ''
    }
  },


  [projectsTypes.POSTING_LOCAL_ROOMS_DATA]: (state, action = { payload: {} }) => {
    return {
      ...state,
      postingRoomsCommercial: true,
      postingRoomsCommercialError: false,
      postingRoomsCommercialMessage: ''
    }
  },

  [projectsTypes.POSTING_LOCAL_OTHER_UNITS_DATA]: (state, action = { payload: {} }) => {
    return {
      ...state,
      postingOtherUnitsCommercial: true,
      postingOtherUnitsCommercialError: false,
      postingOtherUnitsCommercialMessage: '',
    }
  },


  [projectsTypes.FAIL_POSTING_LOCAL_COMPANY_DATA]: (state, action = { payload: '' }) => {
    return {
      ...state,
      postingCompanyCommercial: false,
      postingCompanyCommercialError: true,
      postingCompanyCommercialMessage: action.payload,
      postingCompanyCommercialMessageCounter: state.postingCompanyCommercialMessageCounter + 1,
    }
  },

  [projectsTypes.FAIL_POSTING_LOCAL_PROJECT_DATA]: (state, action = { payload: '' }) => {


    return {
      ...state,
      postingProjectCommercial: false,
      postingProjectCommercialError: true,
      postingProjectCommercialMessage: action.payload,
      postingProjectCommercialMessageCounter: state.postingProjectCommercialMessageCounter + 1,

    }



  },

  [projectsTypes.FAIL_POSTING_LOCAL_ROOMS_DATA]: (state, action = { payload: '' }) => {

    return {
      ...state,
      postingRoomsCommercial: false,
      postingRoomsCommercialError: true,
      postingRoomsCommercialMessage: action.payload,
      postingRoomsCommercialMessageCounter: state.postingRoomsCommercialMessageCounter + 1,

    }


  },

  [projectsTypes.FAIL_POSTING_LOCAL_OTHER_UNITS_DATA]: (state, action = { payload: '' }) => {

    return {
      ...state,
      postingOtherUnitsCommercial: false,
      postingOtherUnitsCommercialError: true,
      postingOtherUnitsCommercialMessage: action.payload,
      postingOtherUnitsCommercialMessageCounter: state.postingOtherUnitsCommercialMessageCounter + 1,

    }


  },


  [projectsTypes.DONE_POSTING_LOCAL_ROOMS_DATA]: (state, action = { payload: [] }) => {

    const filterByUid = (uid) => {
      return action.payload.find(el => el.uid === uid)
    }

    let updatedUnits = [];

    for (let i = 0; i < state.roomsCommercial.units?.length; i++) {

      const currentUnit = state.roomsCommercial.units[i]

      if (currentUnit['id']) {
        updatedUnits.push(currentUnit)
      } else {
        updatedUnits.push({ ...currentUnit, id: filterByUid(currentUnit['uid'])?.id })
      }

    }



    return {
      ...state,
      postingRoomsCommercial: false,
      postingRoomsCommercialError: false,
      postingRoomsCommercialMessage: "Room data updated successfully",
      postingRoomsCommercialMessageCounter: state.postingRoomsCommercialMessageCounter + 1,
      roomsCommercial: {
        ...state.roomsCommercial,
        units: [...updatedUnits],

      }

    }

  },


  [projectsTypes.DONE_POSTING_LOCAL_OTHER_UNITS_DATA]: (state, action = { payload: '' }) => {

    return {
      ...state,
      postingOtherUnitsCommercial: false,
      postingOtherUnitsCommercialError: false,
      postingOtherUnitsCommercialMessage: "Other units data updated successfully",
      postingOtherUnitsCommercialMessageCounter: state.postingOtherUnitsCommercialMessageCounter + 1,

    }


  },



  [projectsTypes.DONE_POSTING_LOCAL_PROJECT_DATA]: (state, action = { payload: {}, payloadId: '', payloadUnits: [], payloadProfile: [] }) => {
    return {
      ...state,
      postingProjectCommercial: false,
      postingProjectCommercialError: false,
      postingProjectCommercialMessage: 'Project saved successfully',
      postingProjectCommercialMessageCounter: state.postingProjectCommercialMessageCounter + 1,

      data: [...state.data, action.payload],
      roomsCommercial: { ...state.roomsCommercial, projectId: action.payloadId, units: [...action.payloadUnits, { id: '', uid: '', customTitle: '', unitName: 'ColdRoom', title: '' }], profiles: [...action.payloadProfile, { id: '' }], unitFields: [] }
    }
  },



  [projectsTypes.DONE_PUTTING_LOCAL_COMPANY_DATA]: (state, action = { payload: {} }) => {

    const companyIndex = state.companies.findIndex(company => company.value === action.payload.id)





    if (companyIndex > -1) {
      const currArr = state.companies
      currArr[companyIndex] = { key: companyIndex, text: action.payload.name, value: action.payload.id }
      return {
        ...state,
        postingCompanyCommercial: false,
        postingCompanyCommercialError: false,
        postingCompanyCommercialMessage: 'Company saved successfully',
        postingCompanyCommercialMessageCounter: state.postingCompanyCommercialMessageCounter + 1,
        companies: [
          ...currArr
        ],
        projectCommercial: { ...state.projectCommercial, companyId: action.payload.id }
      }

    }

  },

  [projectsTypes.DONE_PUTTING_LOCAL_PROJECT_DATA]: (state, action = { payload: {}, payloadId: '', payloadUnits: [], payloadProfile: [] }) => {

    const projectIndex = state.data.findIndex(dat => dat.id === action.payload.id)


    const { units, dataProfile, otherUnits } = state.data[projectIndex]

    const findUnitsByIdPut = (id) => {
      return units.find(el => el.id === id)
    }


    let newUnits = []
    let newProfiles = []
    let newUnitFields = []

    for (let i = 0; i < units.length; i++) {
      newUnits.push({
        title: units[i]['customTitle'],
        id: units[i]['id'],
        uid: units[i]['uid'],
        customTitle: units[i]['customTitle'],
        unitName: 'ColdRoom',
        key: i,
        text: 'ColdRoom_' + units[i]['uid'] + '_' + units[i]['customTitle'],
        value: 'ColdRoom_' + units[i]['uid']
      })

      newProfiles.push({
        key: i,
        text: dataProfile[i]?.name,
        value: dataProfile[i]?.value,
        id: dataProfile[i]?.id
      })
    }

    for (let i = 0; i < otherUnits.length; i++) {

      const room = findUnitsByIdPut(otherUnits[i]['coldstorageId'])


      newUnitFields.push({
        name: otherUnits[i]['name'],
        unitUid: otherUnits[i]['uid'],
        id: otherUnits[i]['id'],
        coldStorageId: otherUnits[i]['coldstorageId'],
        uid: room?.uid,
        key: i,
        text: `${otherUnits[i]['name']}_${otherUnits[i]['uid']}`,
        value: `${otherUnits[i]['name']}_${otherUnits[i]['uid']}`

      })

    }



    if (projectIndex > -1) {

      const currArrTwo = state.data
      currArrTwo[projectIndex] = { ...action.payload, units, dataProfile, otherUnits }


      return {
        ...state,
        postingProjectCommercial: false,
        postingProjectCommercialError: false,
        postingProjectCommercialMessage: 'Project updated successfully',
        postingProjectCommercialMessageCounter: state.postingProjectCommercialMessageCounter + 1,
        data: [
          ...currArrTwo
        ],
        roomsCommercial: {
          ...state.roomsCommercial, projectId: action.payloadId,
          units: [...newUnits],
          profiles: [...newProfiles],
          unitFields: [...newUnitFields]
        }
      }

    }

  },




  [projectsTypes.APPEND_INITIAL_UNIT_DATA]: (state, action = { payloadId: '', payloadUnits: [], payloadProfile: [], payloadOtherUnits: [] }) => {

    let newUnits = []
    let newUnitFields = []
    let newProfiles = []


    const findUnitsById = (id) => {
      return action.payloadUnits.find(el => el.id === id)
    }

    for (let i = 0; i < action.payloadUnits.length; i++) {
      newUnits.push({
        title: action.payloadUnits[i]['customTitle'],
        id: action.payloadUnits[i]['id'],
        uid: action.payloadUnits[i]['uid'],
        customTitle: action.payloadUnits[i]['customTitle'],
        unitName: 'ColdRoom',
        key: i,
        text: 'ColdRoom_' + action.payloadUnits[i]['uid'] + '_' + action.payloadUnits[i]['customTitle'],
        value: 'ColdRoom_' + action.payloadUnits[i]['uid']
      })

      newProfiles.push({
        key: i,
        text: action.payloadProfile[i]?.name,
        value: action.payloadProfile[i]?.value,
        id: action.payloadProfile[i]?.id
      })
    }

    for (let i = 0; i < action.payloadOtherUnits.length; i++) {

      const room = findUnitsById(action.payloadOtherUnits[i]['coldstorageId'])

      newUnitFields.push({
        name: action.payloadOtherUnits[i]['name'],
        unitUid: action.payloadOtherUnits[i]['uid'],
        id: action.payloadOtherUnits[i]['id'],
        coldStorageId: action.payloadOtherUnits[i]['coldstorageId'],
        uid: room?.uid,
        key: i,
        text: `${action.payloadOtherUnits[i]['name']}_${action.payloadOtherUnits[i]['uid']}`,
        value: `${action.payloadOtherUnits[i]['name']}_${action.payloadOtherUnits[i]['uid']}`

      })

    }

    if (newUnits.length === 0) {

      newUnits.push({
        title: '',
        id: '',
        uid: '',
        customTitle: '',
        unitName: 'ColdRoom',
        key: newUnits?.length,
        text: 'ColdRoom_XX_XX',
        value: 'ColdRoom_XX'
      })


    }


    return {
      ...state,
      roomsCommercial: { projectId: action.payloadId, units: [...newUnits], profiles: [...newProfiles], unitFields: [...newUnitFields], activeUnitForFieldSelection: '001' }
    }

  },

  [projectsTypes.ADD_NEW_UNIT]: (state) => {

    return {
      ...state,
      roomsCommercial: { ...state?.roomsCommercial, units: [...state?.roomsCommercial?.units, { id: '', uid: '', customTitle: '' }], profiles: [...state?.roomsCommercial?.profiles, { id: '' }] }
    }

  },

  [projectsTypes.UPDATE_UNIT_AT_INDEX]: (state, action = {}) => {

    const currArr = state.roomsCommercial.units;
    currArr[action?.payloadIndex] = { ...state.roomsCommercial.units[action?.payloadIndex], [action?.payloadKey]: action?.payloadValue }

    return {
      ...state,
      roomsCommercial: { ...state.roomsCommercial, units: [...currArr] }
    }

  },

  [projectsTypes.DELETE_ROOM_AT_INDEX]: (state, action = {}) => {

    const currArr = state.roomsCommercial.units;

    const currArrP = state.roomsCommercial.profiles;



    currArr.splice(action.payload, 1)
    currArrP.splice(action.payload, 1)


    let currArrFields = state?.roomsCommercial?.unitFields
    // eslint-disable-next-line 
    currArrFields = currArrFields.reduce((p, c) => (c.uid !== currArr[action?.payload]?.uid && p.push(c), p), []);



    return {
      ...state,
      roomsCommercial: { ...state.roomsCommercial, units: [...currArr], profiles: [...currArrP], unitFields: [...currArrFields] }
    }

  },

  [projectsTypes.UPDATE_PROFILE_AT_INDEX]: (state, action = {}) => {

    const currArr2 = state.roomsCommercial.profiles;
    currArr2[action?.payloadIndex] = { ...state.roomsCommercial.profiles[action?.payloadIndex], [action?.payloadKey]: action?.payloadValue }

    return {
      ...state,
      roomsCommercial: { ...state.roomsCommercial, profiles: [...currArr2] }
    }

  },

  [projectsTypes.UPDATE_ACTIVE_ROOM_FOR_UNIT_GROUPS]: (state, action = {}) => {

    return {
      ...state,
      roomsCommercial: { ...state.roomsCommercial, activeUnitForFieldSelection: action.payload }
    }

  },

  [projectsTypes.ADD_UNIT_TO_ROOM]: (state) => {

    return {
      ...state,
      roomsCommercial: { ...state.roomsCommercial, unitFields: [...state?.roomsCommercial?.unitFields, { name: '', uid: '', key: '', text: '', value: '' }] }
    }

  },



  [projectsTypes.REMOVE_UNIT_FROM_ROOM]: (state, action = {}) => {


    let currArr = state?.roomsCommercial?.unitFields
    currArr.splice(action.payloadIndex, 1)

    if (!action.payloadId) {

      return {
        ...state,
        roomsCommercial: { ...state.roomsCommercial, unitFields: [...currArr] }
      }

    }

    let otherUnits = action.payloadOtherUnits

    otherUnits.splice(action.payloadIndex, 1)

    const projectIndex = state.data.findIndex(dat => dat.id === action.payloadProjectId)


    let newData = state.data
    newData[projectIndex] = { ...state.data[projectIndex], otherUnits: [...otherUnits] }


    return {
      ...state,
      data: [
        ...newData
      ],
      roomsCommercial: { ...state.roomsCommercial, unitFields: [...currArr] }
    }

  },


  [projectsTypes.UPDATE_UNIT_IN_ROOM]: (state, action = {}) => {


    let currArr = state?.roomsCommercial?.unitFields
    let currObj = currArr[action.payloadIndex]
    let newCurrObj = { ...currObj, [action.payloadKey]: action.payloadValue }
    currArr[action.payloadIndex] = newCurrObj;


    return {
      ...state,
      roomsCommercial: { ...state.roomsCommercial, unitFields: [...currArr] }
    }

  },

  [projectsTypes.ACTIVE_COMPANY_TAB]: (state, action = {}) => {

    return {
      ...state,
      activeCompanyTab: action.payload
    }

  },

  [projectsTypes.ACTIVE_PROJECTS_TAB]: (state, action = {}) => {

    return {
      ...state,
      activeProjectsTab: action.payload
    }

  },

  [projectsTypes.CLEAR_ALL_UNITS]: (state, action = {}) => {


    return {
      ...state,
      roomsCommercial: {
        projectId: '',
        id: '',
        units: [],
        profiles: [],
        unitFields: [],
        otherFieldsDropDown: [],
        activeUnitForFieldSelection: '001',
      }
    }

  },

  [projectsTypes.CREATE_COMPANY_EMAIL]: (state, action = {}) => {


    return {
      ...state,
      createCompanyEmail: action.payload
    }

  },



  [PURGE]: () => ({
    ...initialState
  })
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
