import React from 'react';
import { DragLayer } from 'react-dnd';

import '../../styles/_drag-layer.scss';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '78%',
  maxWidth: '1060px',
  height: '20%'
};

function getItemStyles(props) {
  const { initialOffset, currentOffset } = props;
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }
  let { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  const backgroundColor = 'white';
  const border = '1px solid #dee2e6';
  return {
    transform,
    WebkitTransform: transform,
    backgroundColor,
    border
  };
}
const CustomDragLayerDispatched = props => {
  const { item, isDragging, itemType, getProps } = props;
  const { config, type } = getProps;
  const renderItem = () => {
    if (itemType === type) {
      return (
        <div className="table__body__row">
          {config.headers.map((header, ind) => (
            <div
              key={ind}
              className={`table__body__row__td ${
                header.className ? header.className : ''
              }`}
              style={{
                flexBasis: `${header.width}%`,
                textAlign: header.textAlignItem || 'left',
                justifyContent:
                  header.textAlignItem === 'right' ? 'flex-end' : 'flex-start'
              }}
            >
              {header.component
                ? header.component(item)
                : `${header.prefix || ''}${item[header.key]}${header.suffix ||
                    ''}`}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  if (!isDragging) {
    return null;
  }
  return (
    <div className="drag-layer" style={layerStyles}>
      <div className="drag-layer__item" style={getItemStyles(props)}>
        {renderItem()}
      </div>
    </div>
  );
};
export default DragLayer((monitor, props) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  getProps: props
}))(CustomDragLayerDispatched);
