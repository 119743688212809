import { unionBy } from "lodash";
import * as usersTypes from "../../constants/users.types";
import { PURGE } from "redux-persist";

window.unionBy = unionBy;

const initialState = {
  data: [],
  loading: false,
  sync: false,
  error: "",
};

const reducer = {
  [usersTypes.LOADING]: (state) => ({
    ...state,
    loading: true,
    error: "",
  }),
  [usersTypes.DONE_FETCH]: (state, action = { payload: { data: [] } }) => ({
    ...state,
    loading: false,
    sync: true,
    data: [...unionBy(action.payload.data, state.data.items, "id")],
    error: "",
  }),

  [usersTypes.ERROR_FETCH]: (
    state,
    action = { payload: { error: "Fetching error" } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [usersTypes.DONE_INVITE_USER]: (state) => ({
    ...state,
    loading: false,
    sync: true,
    error: "",
  }),
  [usersTypes.ERROR_INVITE_USER]: (
    state,
    action = { payload: { error: "Fetching error" } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [usersTypes.DONE_USER_DELETE]: (state) => ({
    ...state,
    loading: false,
    sync: true,
    error: "",
  }),
  [usersTypes.ERROR_USER_DELETE]: (
    state,
    action = { payload: { error: "Fetching error" } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [usersTypes.ERROR_RECOVER_PASSWORD]: (
    state,
    action = { payload: { error: "Recover password error" } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error,
  }),
  [usersTypes.CLEAN_DATA]: () => ({
    ...initialState,
  }),
  [PURGE]: () => ({
    ...initialState,
  }),
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
