export const UNITS_LIVE_DATA = "units/live_data"
export const UNITS_STATS = "units/stats"
export const UNITS_REPORTS = "units/reports"
export const ALARMS = "alarms"
export const ALARMS_SETTINGS = "alarms/settings"
export const STOCK = "stock"
export const SERVICE = "service"
export const INSIGHTS = "insights"
export const SETTINGS_USER = "settings/user"

//for configuration
export const UNITS_LIVE_DATA_HUM_DOOR = "units/live_data/hum_door"
export const UNITS_STATS_SETPOINTS = "units/stats/setpoints"
export const ALARMS_SETTINGS_SMS = "alarms/settings/sms"
export const ALARMS_SETTINGS_TEMP_HUM_THRESHOLD = "alarms/settings/temp_hum_threshold"
export const ALARMS_SETTINGS_SMS_PLUS_ICON = "alarms/settings/sms/plus_icon"

//for redux
export const DONE_FETCHING_ALL_CONFIG = "doneFetchingAllConfig"
export const FETCHING_ALL_CONFIG = "fetchingAllConfig"