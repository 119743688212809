import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

const defaultConfig = {
  rowClick: () => null,
  showHeader: true,
  footer: [
    {
      key: "",
      label: "",
      component: null,
      width: "",
      textAlignHeader: "left",
      textAlignItem: "left",
    },
  ],
  headers: [
    {
      key: "",
      label: "",
      component: null,
      width: "",
      textAlignHeader: "left",
      textAlignItem: "left",
      suffix: "",
      prefix: "",
      className: "",
    },
  ],
};

const Table = ({ items, className = "", config = defaultConfig }) => {
  const columnNumbers = (config.headers || []).length;
  const customeWidths = config.headers.reduce((acc, v) => {
    acc += v.width || 0;
    return acc;
  }, 0);
  const customeCounter = config.headers.reduce((acc, v) => {
    acc += v.width ? 1 : 0;
    return acc;
  }, 0);
  const columnWidth = (100 - customeWidths) / (columnNumbers - customeCounter);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  const renderHeaders = () => {
    const { headers, showHeader } = config;

    return (
      showHeader &&
      headers.map((header, index) => (
        <div
          key={index}
          className={
            "table__header__th " +
            (header.hiddenOnMobile ? "hiddenOnMobile" : "shownOnMobile")
          }
          style={{
            flexBasis: `${100 / headers.length}%`,
            textAlign: header.textAlignHeader || "left",
          }}
        >
          {header.label}
        </div>
      ))
    );
  };

  const renderRows = () => {
    const { headers, noData } = config;
    const rowClick = config.rowClick ? config.rowClick : () => null;
    return items.length > 0 ? (
      items.map((item, index) => (
        <div
          key={index}
          className="table__body__row"
          onClick={() => rowClick(item)}
        >
          {headers.map((header, ind) => (
            <div
              key={ind}
              className={
                "table__body__row__td " +
                (header.hiddenOnMobile ? "hiddenOnMobile" : "shownOnMobile")
              }
              style={{
                flexBasis: `${header.width || columnWidth}%`,
                textAlign: isTabletOrMobileDevice
                  ? "right"
                  : header.textAlignItem || "left",
                wordBreak: isTabletOrMobileDevice ? "break-all" : null,
                fontSize: isTabletOrMobileDevice ? "15px" : null,
                justifyContent:
                  header.textAlignItem === "right" ? "flex-end" : "flex-start",
              }}
            >
              {renderCell(header, item)}
            </div>
          ))}
        </div>
      ))
    ) : (
      <div className="table__body__row empty">
        <div className="table__body__row__td">
          {noData ? noData : "No data"}
        </div>
      </div>
    );
  };

  const renderCell = (header, item) => {
    if (header.component) {
      return header.component(item);
    } else {
      return `${header.prefix || ""}${item[header.key]}${header.suffix || ""}`;
    }
  };

  const renderFooter = () => {
    const { footer } = config;

    return (
      footer && (
        <div className="table__footer__row">
          {footer.map((footer, i) => (
            <div
              key={i}
              className="table__footer__row__td"
              style={{
                flexBasis: `${footer.width || columnWidth}%`,
                textAlign: footer.textAlignItem || "left",
              }}
            >
              {footer.component ? footer.component() : footer.label}
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div>
      <div className={`table ${className}`}>
        <div className="table__header">{renderHeaders()}</div>
        <div className="table__body">{renderRows()}</div>
      </div>
      <div className="table__footer">{renderFooter()}</div>
    </div>
  );
};

Table.propTypes = {
  items: PropTypes.array,
  config: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingComponent: PropTypes.node,
  loadingRows: PropTypes.bool,
};

export default Table;
