import React from 'react';
import PropTypes from 'prop-types';

const ContactItem = props => {
  const { type, contactUsers, description } = props;

  return (
    <div className="contact__item item">
      <div className="item__title">{type}</div>
      {contactUsers.map((x, i) => {
        return (
          <div key={i} className="item__container">
            <div className="item__name">
              {x.firstName} {x.lastName}
            </div>
            <div className="item__position">{description}</div>
            <div className="item__email link">{x.email}</div>
          </div>
        );
      })}
    </div>
  );
};

ContactItem.propTypes = {
  type: PropTypes.string,
  user: PropTypes.string,
  position: PropTypes.string,
  email: PropTypes.array
};

export default ContactItem;
