
import { DONE_FETCHING_ALL_CONFIG } from "../../constants/feature-types";
import { PURGE } from "redux-persist";


const INITIAL = {
    data: {}
};

export default (state = INITIAL, action) => {
    switch (action.type) {


        case DONE_FETCHING_ALL_CONFIG:
            return {
                ...state,
                data: { ...action.payload.data }
            };

        case PURGE:
            return {
                ...INITIAL
            }

        default:
            return state;
    }
};
