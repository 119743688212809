import React, { useEffect } from 'react'
import { Table } from 'semantic-ui-react'
import Page from '../../components/Page/Page'
import Title from '../../components/shared/Title'
import { shipmentSetActiveShipment, fetchAllShipments, fetchShipmentLoggersLiveData } from '../../actions/shipments/shipments.actions'
import { urlRoute } from '../../utils/url'
import { connect } from 'react-redux'

function ShipmentList(props) {

    const {
        shipmentSetActiveShipment,
        fetchAllShipments,
        shipmentData,
        fetchShipmentLoggersLiveData,
    } = props

    useEffect(() => {
        fetchAllShipments();
        // eslint-disable-next-line
    }, []);

    const onShipmentRowClick = (row) => {
        shipmentSetActiveShipment(row)
        fetchShipmentLoggersLiveData(row?.loggerIds)
        props.history.push(urlRoute(`/shipments/${row?.shipmentId}/live`));
    }

    return (
        <Page isContainer className="projects">
            <Title title="Shipments" />
            <div className="projects__container">
                <Table color={'yellow'} unstackable>

                    <Table.Header>
                        <Table.HeaderCell colSpan={1}>
                            <p className="table-text">ID</p>

                        </Table.HeaderCell>

                        <Table.HeaderCell colSpan={1}>
                            <p className="table-text">Loggers</p>

                        </Table.HeaderCell>

                        <Table.HeaderCell colSpan={1}>
                            <p className="table-text"> Start</p>

                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan={1}>
                            <p className="table-text">ETA</p>

                        </Table.HeaderCell>
                        {/* <Table.HeaderCell colSpan={1}>
                            <p className="table-text">Status</p>

                        </Table.HeaderCell> */}
                    </Table.Header>


                    <Table.Body className='border-1'>


                        {shipmentData?.map((el, idx) => (
                            <>

                                <Table.Row className="project-row" onClick={() => { onShipmentRowClick(el) }}>
                                    <Table.Cell colSpan={1}><p className="table-text">{el.shipmentId}</p></Table.Cell>
                                    <Table.Cell colSpan={1}><p className="table-text">{el?.loggerIds?.length === 1 && el?.loggerIds[0] === null ? 0 : el?.loggerIds?.length}</p></Table.Cell>
                                    <Table.Cell colSpan={1}><p className="table-text">{el?.startDate}</p></Table.Cell>
                                    <Table.Cell colSpan={1}><p className="table-text">{el?.etaDate}</p></Table.Cell>
                                    {/* <Table.Cell colSpan={1}><p className="table-text">{el.isCompleted ? 'COMPLETED' : el.isDeparted ? 'DEPARTED' : el.isStarted ? 'STARTED' : 'WAITING'}</p></Table.Cell> */}
                                    {/* <Table.Cell colSpan={1}><Link className='link-text-left' to={moreThenOneProject ? urlRoute(`projects/projects/${el.id}/units`) : `/units`}><p>Enter</p></Link></Table.Cell> */}

                                </Table.Row>

                            </>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </Page>
    )
}


const mapStateToProps = ({ shipments }) => {
    const {
        shipmentData,
    } = shipments.shipmentsReducer;

    return {
        shipmentData
    }
};
export default connect(mapStateToProps, {
    shipmentSetActiveShipment,
    fetchAllShipments,
    fetchShipmentLoggersLiveData
})(ShipmentList);