import * as thresholdsTypes from '../../constants/alarms.types';
import { PURGE } from 'redux-persist';

const initialState = {
  data: [
    {
      alarmType: '',
      id: 0,
      maxValue: 0,
      minValue: 0
    }
  ],
  loading: false,
  sync: false,
  error: null
};

const reducer = {
  [thresholdsTypes.LOADING_THRESHOLDS]: state => ({
    ...state,
    loading: true
  }),
  [thresholdsTypes.DONE_FETCH_THRESHOLDS]: (
    state,
    action = { payload: { data: {} } }
  ) => ({
    ...state,
    loading: false,
    sync: true,
    data: action.payload.data
  }),
  [thresholdsTypes.ERROR_FETCH_THRESHOLDS]: (
    state,
    action = { payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [thresholdsTypes.CLEAN_DATA_THRESHOLDS]: state => ({
    ...state,
    loading: false,
    sync: false,
    data: {}
  }),
  [thresholdsTypes.UPDATE_VALUES_THRESHOLDS]: (state, action) => ({
    ...state,
    loading: true,
    sync: false,
    data: state.data.map(x => {
      if (x.id === action.id) {
        x = action.payload;
      }
      return x;
    })
  }),
  [thresholdsTypes.DONE_UPDATE_THRESHOLDS]: (state, action) => ({
    ...state,
    loading: false,
    data: state.data.map(x => {
      if (x.id === action.id) return { ...x, message: action.payload.message };
      return x;
    })
  }),
  [thresholdsTypes.CLEAN_MESSAGES_THRESHOLDS]: (state, action) => ({
    ...state,
    data: state.data.map(x => {
      if (x.id === action.id) return { ...x, message: '' };
      return x;
    })
  }),
  [thresholdsTypes.ERROR_UPDATE_THRESHOLDS]: (
    state,
    action = { payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    data: state.data.map(x => {
      if (x.id === action.id) return { ...x, error: action.payload.error };
      return x;
    }),
    error: action.payload.error
  }),
  [thresholdsTypes.SET_EMAIL_NOTIFICATION_THRESHOLDS]: state => ({
    ...state,
    loading: false,
    sync: false,
    data: {
      ...state.data,
      notify: !state.data.notify
    }
  }),
  [PURGE]: () => ({
    ...initialState
  })
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
