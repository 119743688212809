import one from "../../assets/device-config/one/device.json"
import compressor from "../../assets/device-config/compressor/device.json"
import ducatiMain from "../../assets/device-config/ducati_main/device.json"
import ducatiMonoL1 from "../../assets/device-config/ducati_mono/l1_device.json"
import ducatiMonoL2 from "../../assets/device-config/ducati_mono/l2_device.json"
import ducatiMonoL3 from "../../assets/device-config/ducati_mono/l3_device.json"
import ducatiPoly from "../../assets/device-config/ducati_poly/device.json"
import pegoEcp from "../../assets/device-config/pego_ecp/device.json"
import pegoVt from "../../assets/device-config/pego_vt/device.json"
import pump from "../../assets/device-config/pump/device.json"
import imeMonoL1 from "../../assets/device-config/ime_mono/l1_device.json";
import imeMonol2 from "../../assets/device-config/ime_mono/l2_device.json";
import imeMonoL3 from "../../assets/device-config/ime_mono/l3_device.json";
import imePoly from "../../assets/device-config/ime_poly/device.json"
import imeMain from "../../assets/device-config/ime_main/device.json"
import co2 from "../../assets/device-config/co2/device.json"
import pegoNector from "../../assets/device-config/pego_nector/device.json"
import carelTh from "../../assets/device-config/carel_th/device.json"
import ela4io from "../../assets/device-config/ela4io/device.json"
import msrElect from "../../assets/device-config/msr_elect/device.json"
import frerMain from "../../assets/device-config/frer_main/device.json";
import frerMonoL1 from "../../assets/device-config/frer_mono/l1_device.json";
import fremMonoL2 from "../../assets/device-config/frer_mono/l2_device.json";
import frerMonoL3 from "../../assets/device-config/frer_mono/l3_device.json";

import {
    SET_BASE_CONFIG,
    UPDATE_ROOT_CONFIG,
    UPDATE_COM_PORT,
    ADD_DEVICE_COUNT,
    ADD_OR_UPDATE_DEVICE_ID,
    ADD_OR_UPDATE_DEVICE_DETAILS,
    SET_DEVICE_UNDER_MEASUREMENT,
    UPDATE_DEVICE_MEASURE,
    UPDATE_PEGO_ROOM,
    DELETE_DEVICE_AT_INDEX,
    UPDATE_SENOSR_DEFAULTS_AT_INDEX,
} from "../../constants/device-config.types"

export const setBaseConfig = () => {

    return {
        type: SET_BASE_CONFIG
    }
}

export const updateInternalRootProjectConfig = (key, value) => {

    return {
        type: UPDATE_ROOT_CONFIG,
        payloadKey: key,
        payloadValue: value
    }

}

export const updateInternalComport = (key, value, name) => {

    return {
        type: UPDATE_COM_PORT,
        payloadKey: key,
        payloadValue: value,
        payloadName: name,

    }

}

export const addDeviceCount = () => {
    return {
        type: ADD_DEVICE_COUNT
    }

}

export const deleteDeviceAtIndex = (index) => {

    return {
        type: DELETE_DEVICE_AT_INDEX,
        payload: index

    }

}

export const updateInternalDeviceFields = (key, value, index) => {


    return {
        type: ADD_OR_UPDATE_DEVICE_DETAILS,
        payloadIndex: index,
        payloadValue: value,
        payloadKey: key
    }

}


export const updateInternalDeviceId = (value, index) => {

    return {
        type: ADD_OR_UPDATE_DEVICE_ID,
        payloadIndex: index,
        payloadValue: fetchCorrectJson(value)
    }

}

export const setDeviceUnderMeasure = (index) => {
    return {
        type: SET_DEVICE_UNDER_MEASUREMENT,
        payload: index
    }
}

export const updateDeviceMeasure = (deviceIndex, measureInput, key, value) => {

    return {
        type: UPDATE_DEVICE_MEASURE,
        deviceIndex,
        measureInput,
        key,
        value
    }

}

export const updatePegoRoom = (pegoDeviceIndex, pegoValue) => {
    return {
        type: UPDATE_PEGO_ROOM,
        pegoDeviceIndex,
        pegoValue
    }
}

export const updateSensorDefaultsAtIndex = (index, measureInput, values) => {

    return {
        type: UPDATE_SENOSR_DEFAULTS_AT_INDEX,
        defaultsValues: values,
        defaultsIndex: index,
        defaultsMeasureInput: measureInput,
    }

}

const fetchCorrectJson = (id) => {

    if (id === 'ONE') {
        return one
    }

    if (id === 'PEGO_VT') {
        return pegoVt
    }

    if (id === 'PEGO_ECP') {
        return pegoEcp
    }

    if (id === 'PUMP') {
        return pump
    }

    if (id === 'DUCATI_MAIN') {
        return ducatiMain
    }

    if (id === 'DUCATI_MONO_L1') {
        return ducatiMonoL1
    }

    if (id === 'DUCATI_MONO_L2') {
        return ducatiMonoL2
    }

    if (id === 'DUCATI_MONO_L3') {
        return ducatiMonoL3
    }

    if (id === 'DUCATI_POLY') {
        return ducatiPoly
    }

    if (id === 'COMPRESSOR') {
        return compressor
    }

    if (id === 'IME_MONO_L1') {
        return imeMonoL1
    }

    if (id === 'IME_MONO_L2') {
        return imeMonol2
    }

    if (id === 'IME_MONO_L3') {
        return imeMonoL3
    }

    if (id === 'IME_MAIN') {
        return imeMain
    }

    if (id === 'IME_POLY') {
        return imePoly
    }

    if (id === 'PEGO_NECTOR') {
        return pegoNector
    }

    if (id === 'CO2CAREL') {
        return co2
    }
    if (id === 'CAREL_TH') {
        return carelTh
    }

    if (id === 'ELA4IO') {
        return ela4io
    }

    if (id === 'MSR_ELECT') {
        return msrElect
    }

    if (id === 'FRER_MAIN') {
        return frerMain
    }

    if (id === 'FRER_MONO_L1') {
        return frerMonoL1
    }

    if (id === 'FRER_MONO_L2') {
        return fremMonoL2
    }

    if (id === 'FRER_MONO_L3') {
        return frerMonoL3
    }

}