import baseConfig from "../../assets/device-config/base.json"

import {
    SET_BASE_CONFIG,
    UPDATE_COM_PORT,
    ADD_DEVICE_COUNT,
    UPDATE_ROOT_CONFIG,
    ADD_OR_UPDATE_DEVICE_DETAILS,
    ADD_OR_UPDATE_DEVICE_ID,
    SET_DEVICE_UNDER_MEASUREMENT,
    UPDATE_DEVICE_MEASURE,
    UPDATE_PEGO_ROOM,
    ADD_OTHER_UNIT_DROPDOWN,
    DELETE_DEVICE_AT_INDEX,
    UPDATE_SENOSR_DEFAULTS_AT_INDEX,
    UPDATE_CONFIG_FROM_CLOUD
} from "../../constants/device-config.types";
import { PURGE } from "redux-persist";


const INITIAL = {
    config: {},
    devicesCount: 1,
    devicesForMeasuresDropdown: [],
    deviceUnderMeasure: 0,
};

export default (state = INITIAL, action) => {


    switch (action.type) {

        case UPDATE_CONFIG_FROM_CLOUD: {

            const devicesCount = action.config.devices.length;
            let devicesForMeasuresDropdown = []


            for (let i = 0; i < devicesCount; i++) {

                devicesForMeasuresDropdown.push({

                    key: i,
                    text: action.config.devices[i]?.deviceID +
                        `-${action.config.devices[i]?.commPort}` +
                        `-${action.config.devices[i]?.mbAddr}`,
                    value: i

                })

            }
            return {
                ...state,
                config: action.config,
                devicesCount: devicesCount === 0 ? 1 : devicesCount,
                devicesForMeasuresDropdown
            };
        }


        case SET_BASE_CONFIG:

            return {
                ...state,
                devicesCount: 1,
                devicesForMeasuresDropdown: [],
                deviceUnderMeasure: 0,
                config: { ...baseConfig, projectID: state.config.projectID, projectName: state.config.projectName }
            };

        case UPDATE_ROOT_CONFIG:
            return {
                ...state,
                config: { ...state.config, [action.payloadKey]: action.payloadValue }
            }

        case UPDATE_COM_PORT:

            return { ...state, config: { ...state.config, [action.payloadName]: { ...state.config[action.payloadName], [action.payloadKey]: action.payloadValue } } }

        case ADD_DEVICE_COUNT:
            return { ...state, devicesCount: state.devicesCount + 1 }

        case ADD_OR_UPDATE_DEVICE_DETAILS:
            if (state.config.devices[action.payloadIndex]) {
                const newDevicesArray = state.config.devices
                newDevicesArray[action.payloadIndex] = { ...state.config.devices[action.payloadIndex], [action.payloadKey]: action.payloadValue }

                const newDropDownArray = state.devicesForMeasuresDropdown
                newDropDownArray[action.payloadIndex] = {
                    key: action.payloadIndex,
                    text: state.config.devices[action.payloadIndex]?.deviceID +
                        `-${action.payloadKey === 'commPort' ? action.payloadValue : state.config.devices[action.payloadIndex]?.commPort}` +
                        `-${action.payloadKey === 'mbAddr' ? action.payloadValue : state.config.devices[action.payloadIndex]?.mbAddr}`,
                    value: action.payloadIndex
                }
                return { ...state, config: { ...state.config, devices: newDevicesArray }, devicesForMeasuresDropdown: newDropDownArray, }
            }
            return { ...state }

        case ADD_OR_UPDATE_DEVICE_ID:
            if (state.config.devices[action.payloadIndex]) {
                const newDevicesArray = state.config.devices
                newDevicesArray[action.payloadIndex] = { ...action.payloadValue, }

                const newDropDownArray = state.devicesForMeasuresDropdown
                newDropDownArray[action.payloadIndex] = {
                    key: action.payloadIndex,
                    text: action.payloadValue.deviceID +
                        `-${state.config.devices[action.payloadIndex]?.commPort}` +
                        `-${state.config.devices[action.payloadIndex]?.mbAddr}`,
                    value: action.payloadIndex
                }

                return { ...state, config: { ...state.config, devices: newDevicesArray }, devicesForMeasuresDropdown: newDropDownArray }

            } else {

                const newDropDownArray = state.devicesForMeasuresDropdown
                newDropDownArray[action.payloadIndex] = {
                    key: action.payloadIndex,
                    text: action.payloadValue.deviceID +
                        `-${action.payloadValue?.commPort}` +
                        `-${action.payloadValue?.mbAddr}`,
                    value: action.payloadIndex
                }
                return { ...state, config: { ...state.config, devices: [...state.config.devices, action.payloadValue] }, devicesForMeasuresDropdown: newDropDownArray }
            }

        case SET_DEVICE_UNDER_MEASUREMENT:
            return { ...state, deviceUnderMeasure: action.payload }

        case UPDATE_DEVICE_MEASURE:
            const { deviceIndex, measureInput, key, value } = action

            const newDevicesArray = state.config.devices
            let newDevice = state.config.devices[deviceIndex]
            let newMeasureObject = state.config.devices[deviceIndex].measure
            const newInputMeasureObject = { ...state.config.devices[deviceIndex].measure[measureInput], [key]: value }
            newMeasureObject = { ...newMeasureObject, [measureInput]: newInputMeasureObject }
            newDevice.measure = newMeasureObject;
            newDevicesArray[deviceIndex] = newDevice

            return { ...state, config: { ...state.config, devices: [...newDevicesArray] } }

        case UPDATE_PEGO_ROOM:
            const { pegoDeviceIndex, pegoValue } = action;

            const newPegoDevicesArray = state.config.devices;
            let newPegoDevice = state.config.devices[pegoDeviceIndex];
            let newPegoDeviceMeasureObject = newPegoDevice.measure


            Object.keys(newPegoDeviceMeasureObject).forEach((key) => {

                let newInputMeasureObjectAtKey = newPegoDevice.measure[key]
                newInputMeasureObjectAtKey = { ...newInputMeasureObjectAtKey, "unitID": pegoValue }

                newPegoDeviceMeasureObject = { ...newPegoDeviceMeasureObject, [key]: newInputMeasureObjectAtKey }
                newPegoDevice.measure = newPegoDeviceMeasureObject

            })


            newPegoDevicesArray[pegoDeviceIndex] = newPegoDevice



            return { ...state, config: { ...state.config, devices: [...newPegoDevicesArray] } }

        case UPDATE_SENOSR_DEFAULTS_AT_INDEX:

            const {
                defaultsValues,
                defaultsIndex,
                defaultsMeasureInput,
            } = action

            console.log(action)


            const newDefaultDevicesArray = state.config.devices
            let newDefaultsDevice = state.config.devices[defaultsIndex]
            let newDefaultsDeviceMeasureObject = state.config.devices[defaultsIndex].measure
            const newDefaultsInputMeasureObject = { ...state.config.devices[defaultsIndex].measure[defaultsMeasureInput], ...defaultsValues }
            newDefaultsDeviceMeasureObject = { ...newDefaultsDeviceMeasureObject, [defaultsMeasureInput]: newDefaultsInputMeasureObject }
            newDefaultsDevice.measure = newDefaultsDeviceMeasureObject;
            newDefaultDevicesArray[deviceIndex] = newDefaultsDevice

            return { ...state, config: { ...state.config, devices: [...newDefaultDevicesArray] } }





        case ADD_OTHER_UNIT_DROPDOWN:
            return { ...state, config: { ...state.config, devices: [...newPegoDevicesArray] } }

        case DELETE_DEVICE_AT_INDEX:

            let devices = state.config.devices;
            let devicesDrpDown = state.devicesForMeasuresDropdown;

            devices.splice(action.payload, 1)
            devicesDrpDown.splice(action.payload, 1)

            const newDevicesDropDown = devicesDrpDown.map((e, i) => ({
                key: i,
                text: e.text,
                value: i
            }));

            return { ...state, devicesCount: state.devicesCount - 1, devicesForMeasuresDropdown: [...newDevicesDropDown], config: { ...state.config, devices: [...devices] }, deviceUnderMeasure: 0 }

        case PURGE:
            return {
                ...INITIAL
            }

        default:
            return state;



    };
}
