import {
  USER_INSIGHTS_FETCHING,
  USER_INSIGHTS_FETCH_SUCCESS,
} from "../../constants/userInsights.types";
import { PURGE } from "redux-persist";

const INITIAL = {
  companies: [],
  allUsers: [],
  singleUsers: [],
  fetchingUserInsights: false,
  commonExit: [],
  commonEntry: [],
  mostFrequent: [],
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    case USER_INSIGHTS_FETCHING:
      return { ...state, fetchingUserInsights: true };

    case USER_INSIGHTS_FETCH_SUCCESS:
      return {
        ...state,
        companies: action.payloadCompanies,
        allUsers: action.payloadAllUsers,
        singleUsers: action.payloadSingleUsers,
        fetchingUserInsights: false,
        commonExit: action.payloadCommonExit,
        commonEntry: action.payloadCommonEntry,
        mostFrequent: action.payloadMostFrequent,
      };

    case PURGE:
      return {
        ...INITIAL
      }

    default:
      return state;
  }
};
