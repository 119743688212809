import React from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Modal } from '../../../components/shared/Modal';
import Spinner from '../../../components/shared/Spinner';
import { numberValidation, emptyField, numberOrEmptyValidation } from '../../../utils/validators';
import InputForm from '../../../components/Inputs/InputForm';
import DateTimePickerForm from '../../../components/Inputs/DateTimePickerForm';

const EditStockItemModalWrapper = props => {
  const {
    editItem,
    item,
    confirmText,
    onCancel,
    coldStorageId,
    loading,
    header,
    handleSubmit
  } = props;

  const submit = async values => {
    try {
      await editItem(item.id, values, 'inStock', coldStorageId);
      onCancel();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(submit)}
      className="stock__edit-form edit-form" >
      <Modal
        header={header}
        confirmText={confirmText}
        cancelText="Cancel"
        onCancel={onCancel}
        onAccept={props.submit} >
        <div className="edit-form__inputs">
          <Field
            name="name"
            component={InputForm}
            type="text"
            placeholder="Name"
            label="BATCH"
            value={item.name} />
          <Field
            name="weight"
            component={InputForm}
            type="number"
            placeholder="Weight"
            label="KG"
            min={0}
            step={0.01} />
          <Field
            name="amount"
            component={InputForm}
            type="number"
            placeholder="Amount"
            label="PIECES"
            min={0} />
          <Field name="in" component={DateTimePickerForm} label="INPUT TIME" />
        </div>
        <Spinner active={loading} overlay />
      </Modal>
    </Form>
  );
};

EditStockItemModalWrapper.propTypes = {
  header: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  content: PropTypes.any,
  inviteUser: PropTypes.func,
  loading: PropTypes.bool
};

export default reduxForm({
  form: 'editStockItemModalWrapper',
  validate: values => ({
    name: emptyField(values.name, 'Name is required'),
    weight: numberValidation(
      values.weight,
      'Weight is required',
      "Value shouldn't be negative"
    ),
    amount: numberOrEmptyValidation(
      values.amount,
      "Value shouldn't be negative",
      'Value should be integer'
    )
  })
})(EditStockItemModalWrapper);
