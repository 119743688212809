import React, { useEffect, useState, useRef } from 'react'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import moment from "moment";
import './_referlocations.scss'
// import 'mapbox-gl/dist/mapbox-gl.css';
import { connect } from 'react-redux'
import { fetchShipmentStatsData } from '../../../../actions/shipments/shipments.actions';
import { Dropdown } from "semantic-ui-react";
import RangeDatePicker from "../../../../components/shared/RangeDatePicker";
import { getReeferLabelNameFormat } from '../../../../helpers/chart.helper';

mapboxgl.accessToken = 'pk.eyJ1IjoiYW55dW5ndSIsImEiOiJjanB1NWM1Nm0wMXR6NDNtdDZwMWQxeDl5In0.DN-bODyzo5q_sXU1_doidg';


function ReferLocations(props) {
  const {
    fetchShipmentStatsData,
    shipmentData,
    shipmentActiveShipment,
  } = props;

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(36.76973699999999);
  const [lat, setLat] = useState(-1.191509);
  const [zoom, setZoom] = useState(10);

  const [pickerStartDate, setPickerStartDate] = useState(
    localStorage.getItem("pickerStartDate") !== null
      ? moment(localStorage.getItem("pickerStartDate"))
      : moment().subtract(1, "days").startOf("day")
  );

  const [pickerEndDate, setPickerEndDate] = useState(
    localStorage.getItem("pickerEndDate") !== null
      ? moment(localStorage.getItem("pickerEndDate"))
      : moment()
  );

  const [activeLogger, setActiveLogger] = useState(
    shipmentActiveShipment?.loggerIds[0]
  );

  const deviceOptions = () => {
    return shipmentActiveShipment?.loggerIds?.map((el, idx) => ({
      key: idx,
      text: el,
      value: el,
    }));
  };

  const onDatePickerAccept = ({ startDate, endDate }) => {
    const startDateValue = startDate.clone().startOf("day");
    const endDateValue = endDate
      .clone()
      .add(1, "day")
      .startOf("day");

    localStorage.setItem("pickerEndDate", endDateValue);
    localStorage.setItem("pickerStartDate", startDateValue);

    setPickerStartDate(startDateValue);
    setPickerEndDate(endDateValue);
  };

  const dummyLocationsCreator = (map) => {

    for (let i = 0; i < shipmentData.length; i++) {
      const container = document.createElement('div');
      container.className = 'marker-container';

      const el = document.createElement('div');
      el.className = i === shipmentData?.length - 1 ? 'marker latest-marker' : 'marker';
      container.appendChild(el);

      const number = document.createElement('div');
      number.className = i === shipmentData?.length - 1 ? 'latest-marker-number' : 'marker-number';
      number.textContent = i + 1;
      container.appendChild(number);



      new mapboxgl.Marker(container)
        .setLngLat([shipmentData[i]?.longitude, shipmentData[i]?.latitude])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<div className="marker-popup">
              <h3>${i + 1}</h3>
              <p className="marker-popup-content">
                ${shipmentData[i]?.longitude.toFixed(2)}, ${shipmentData[i]?.latitude.toFixed(2)},
                ${shipmentData[i]?.meanTemp.toFixed(2)},
                ${moment.utc(shipmentData[i]?.time).local().format(getReeferLabelNameFormat(shipmentData))}
              </p>
            </div>`
          )
        )
        .addTo(map);
    }
  };

  const fetchData = async (loggerId, startDate, endDate) => {
    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();
    fetchShipmentStatsData(loggerId, startDateString, endDateString);
  };

  useEffect(() => {
    fetchData(activeLogger, pickerStartDate, pickerEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLogger, pickerStartDate, pickerEndDate]);

  useEffect(() => {
    if (shipmentData.length === 0) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [
          lng,
          lat
        ],
        zoom: 6,
      });
    } else {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [
          shipmentData[shipmentData.length - 1]?.longitude,
          shipmentData[shipmentData.length - 1]?.latitude,
        ],
        zoom: zoom,
      });

      map.current.on("load", function () {
        dummyLocationsCreator(map.current);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentData]);

  useEffect(() => {
    if (!map.current) return;
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  return (
    <>
      <div className="reports">
        <div className="reports-select-container">
          <div className="report-datepicker">
            <div className="report-datepicker-tablet">
              <div className="report-datepicker-tablet-x">
                <p className="table-text">Select a logger and a date:</p>
                <Dropdown
                  className="dropdown-extras-container"
                  compact
                  placeholder="Select a device"
                  search
                  selection
                  options={deviceOptions()}
                  value={activeLogger}
                  onChange={(event, { value }) => {
                    setActiveLogger(value);
                  }}
                />
              </div>
              <div className="report-datepicker-tablet-x">
                <RangeDatePicker
                  onAccept={onDatePickerAccept}
                  starterDate={pickerStartDate ? pickerStartDate : null}
                  enderDate={pickerEndDate ? pickerEndDate : null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="sidebar">
          Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
        <div ref={mapContainer} className="map-container" />
      </div>
    </>
  );
}

const mapStateToProps = ({ shipments }) => {
  const {
    shipmentData,
    shipmentDataLoad,
    shipmentActiveShipment,
  } = shipments.shipmentsReducer;

  return {
    shipmentData,
    shipmentDataLoad,
    shipmentActiveShipment,
  };
};

export default connect(mapStateToProps, {
  fetchShipmentStatsData,
})(ReferLocations);