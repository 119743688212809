import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as reportsActions from "../../../../actions/reports.actions";
import { getProjectId } from "../../../../utils/auth/user";
import Component from "./Reports";
import {
  fetchReportSubscriptionStatus,
  saveReportSubscriptionStatus,
  updateReportSubscriptionStatus,
} from "../../../../actions/report-subscription/report-subscription.action";

const mapStateToProps = (state, props) => {
  const projectId = props.match.params.projectId || getProjectId() || null;
  const projectReportsState = state.reports.project;
  const reportAccess = state.reports.reportAccess;
  const auth = state.auth
  const reportsSubscriptionReducer = state.reportsSubscriptionReducer

  return {
    projectId: projectId,
    loadingReportAccess: reportAccess.loadingReportAccess,
    reportAccessCost: reportAccess.costReport,
    energyConsumptionLoading: projectReportsState.energyConsumption.loading,
    energyConsumption: projectReportsState.energyConsumption.value,
    pricePerKgLoading: projectReportsState.pricePerKg.loading,
    pricePerKg: projectReportsState.pricePerKg.value,
    minThroughputLoading: projectReportsState.minThroughput.loading,
    minThroughput: projectReportsState.minThroughput.value,
    pricingCalculateLoading: projectReportsState.pricingCalculate.loading,
    pricingCalculate: projectReportsState.pricingCalculate.value,
    totalHandledProduceLoading: projectReportsState.totalHandledProduce.loading,
    totalHandledProduce: projectReportsState.totalHandledProduce.value,
    auth,
    reportsSubscriptionReducer
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReportAccess: bindActionCreators(
    reportsActions.fetchReportAccess,
    dispatch
  ),
  fetchProjectEnergyConsumption: bindActionCreators(
    reportsActions.fetchProjectEnergyConsumption,
    dispatch
  ),
  fetchTotalHandledProduce: bindActionCreators(
    reportsActions.fetchTotalHandledProduce,
    dispatch
  ),
  fetchPricePerKg: bindActionCreators(reportsActions.fetchPricePerKg, dispatch),
  fetchMinThroughput: bindActionCreators(
    reportsActions.fetchMinThroughput,
    dispatch
  ),
  fetchPricingCalculate: bindActionCreators(
    reportsActions.fetchPricingCalculate,
    dispatch
  ),
  fetchReportSubscriptionStatus: bindActionCreators(
    fetchReportSubscriptionStatus,
    dispatch
  ),
  saveReportSubscriptionStatus: bindActionCreators(
    saveReportSubscriptionStatus,
    dispatch
  ),
  updateReportSubscriptionStatus: bindActionCreators(
    updateReportSubscriptionStatus,
    dispatch
  ),
});

const ComponentWithRouter = withRouter(Component);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithRouter);
