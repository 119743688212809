

export const returnUnitsDropDown = (units) => {

    if (units && units.length > 0) {

        return units.map((e, i) => {
            return {
                key: i,
                text: e.customTitle,
                value: e.id,
            };
        });

    }

    return null



};


export const getAvailableCountryContactPrefix = () => {
    return [
        {
            key: 1,
            text: `🇰🇪 ${'  '}+254 ${'  '} KEN`,
            value: "+254",
        },
        {
            key: 2,
            text: `🇿🇼 ${'  '} +263 ${'  '} ZIM`,
            value: "+263",
        },
        {
            key: 3,
            text: `🇿🇼 ${'  '} +49 ${'  '} GER`,
            value: "+49"
        }
    ]
}



