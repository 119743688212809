import api from '../utils/api';
import * as projectsTypes from '../constants/projects.types';
import { setBaseConfig } from './config/config.action';
import { SET_BASE_CONFIG, UPDATE_CONFIG_FROM_CLOUD } from '../constants/device-config.types';

export const fetchProjects = () => async dispatch => {
  dispatch({ type: projectsTypes.LOADING });

  try {
    const response = await api.get('/project');
    dispatch({ type: projectsTypes.DONE_FETCH, payload: response });
    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: projectsTypes.ERROR_FETCH,
      payload: { error: 'Fetch projects data error' }
    });
    return Promise.resolve(e.response);
  }
};

export const setCurrentProjectId = id => dispatch => {
  dispatch({
    type: projectsTypes.SET_CURRENT_PROJECT_ID,
    payload: { currentProjectId: id }
  });
};

export const setCurrentProjectIdToFirstProject = () => dispatch => {
  dispatch({ type: projectsTypes.SET_CURRENT_PROJECT_TO_FIRST_PROJECT });
};

export function cleanProjects() {
  return {
    type: projectsTypes.CLEAN_DATA
  };
}


export const fectchCompanies = () => {
  return (dispatch) => {
    dispatch({
      type: projectsTypes.FETCHING_ALL_COMPANIES,
    });

    api
      .get(`company`)

      .then((res) => {
        dispatch({
          type: projectsTypes.FETCH_ALL_COMPANIES,
          payload: res.data,
        });
      })
      .catch((err) => { });
  };
};

export const updateCompanyLocalData = (key, value) => {

  return {
    type: projectsTypes.UPDATE_LOCAL_COMPANY_DATA,
    payloadKey: key,
    payloadValue: value
  }

}

export const setActiveCompanyTab = (value) => {
  return {
    type: projectsTypes.ACTIVE_COMPANY_TAB,
    payload: value,
  }

}

export const setActiveProjectsTab = (value) => {
  return {
    type: projectsTypes.ACTIVE_PROJECTS_TAB,
    payload: value,
  }

}


export const updateProjectLocalData = (key, value) => {

  return {
    type: projectsTypes.UPDATE_LOCAL_PROJECT_DATA,
    payloadKey: key,
    payloadValue: value
  }

}

export const updateRoomLocalData = (key, value) => {

  return {
    type: projectsTypes.UPDATE_LOCAL_ROOM_DATA,
    payloadKey: key,
    payloadValue: value
  }

}

export const saveCompanyData = (data) => {
  return (dispatch) => {
    dispatch({
      type: projectsTypes.POSTING_LOCAL_COMPANY_DATA,
    });


    api
      .put(`company`, data)

      .then((res) => {

        if (data?.id) {

          dispatch({
            type: projectsTypes.DONE_PUTTING_LOCAL_COMPANY_DATA,
            payload: res.data,
          });

        } else {
          dispatch({
            type: projectsTypes.DONE_POSTING_LOCAL_COMPANY_DATA,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: projectsTypes.FAIL_POSTING_LOCAL_COMPANY_DATA,
          payload: "Company not created"
        });

      });
  };

}


export const saveRoomData = (data) => {
  return (dispatch) => {
    dispatch({
      type: projectsTypes.POSTING_LOCAL_ROOMS_DATA,
    });


    api
      .post(`coldstorage/update`, data)

      .then((res) => {

        dispatch({
          type: projectsTypes.DONE_POSTING_LOCAL_ROOMS_DATA,
          payload: res.data

        });


      })
      .catch((err) => {
        dispatch({
          type: projectsTypes.FAIL_POSTING_LOCAL_ROOMS_DATA,
          payload: "Room Data not updated"
        });

      });
  };

}


export const saveOtherUnitsData = (data) => {
  return (dispatch) => {
    dispatch({
      type: projectsTypes.POSTING_LOCAL_OTHER_UNITS_DATA,
    });


    api
      .post(`other-units/update`, data)

      .then((res) => {



        dispatch({
          type: projectsTypes.DONE_POSTING_LOCAL_OTHER_UNITS_DATA,

        });


      })
      .catch((err) => {
        dispatch({
          type: projectsTypes.FAIL_POSTING_LOCAL_OTHER_UNITS_DATA,
          payload: "Other units Data not updated"
        });

      });
  };

}


export const fetchUnitConfig = (projectID) => {
  return (dispatch) => {

    api
      .get(`configurator/config`, {
        params: {
          projectID
        }
      })

      .then((res) => {


        dispatch({
          type: UPDATE_CONFIG_FROM_CLOUD,
          config: res.data

        });


      })
      .catch((err) => {

        dispatch({
          type: SET_BASE_CONFIG,

        });

      });
  };

}




export const saveProjectData = (data) => {
  return (dispatch) => {
    dispatch({
      type: projectsTypes.POSTING_LOCAL_PROJECT_DATA,
    });



    api
      .put(`project`, data)

      .then((res) => {


        if (data?.id) {

          dispatch({
            type: projectsTypes.DONE_PUTTING_LOCAL_PROJECT_DATA,
            payload: res.data,
            payloadId: res.data.id,
            payloadUnits: res.data.units,
            payloadProfile: res.data.dataProfile,
          });

        } else {

          setBaseConfig()

          dispatch({
            type: projectsTypes.DONE_POSTING_LOCAL_PROJECT_DATA,
            payload: res.data,
            payloadId: res.data.id,
            payloadUnits: res.data.units,
            payloadProfile: res.data.dataProfile
          })

        }
      })
      .catch((err) => {

        dispatch({
          type: projectsTypes.FAIL_POSTING_LOCAL_PROJECT_DATA,
          payload: "Project data update failed"
        });
      });
  };

}


export const onUnitProjectSelect = (projectId, units, dataProfile, otherUnits) => {

  return {
    type: projectsTypes.APPEND_INITIAL_UNIT_DATA,
    payloadId: projectId,
    payloadUnits: units,
    payloadProfile: dataProfile,
    payloadOtherUnits: otherUnits
  }

}

export const addInternalRoom = () => {
  return {
    type: projectsTypes.ADD_NEW_UNIT
  }
}

export const updateRoomsInternal = (key, value, index) => {
  return {
    type: projectsTypes.UPDATE_UNIT_AT_INDEX,
    payloadKey: key,
    payloadValue: value,
    payloadIndex: index
  }
}

export const updateProfilesInternal = (key, value, index) => {
  return {
    type: projectsTypes.UPDATE_PROFILE_AT_INDEX,
    payloadKey: key,
    payloadValue: value,
    payloadIndex: index
  }
}

export const updateActiveRoomForUnitGroups = (value) => {
  return {
    type: projectsTypes.UPDATE_ACTIVE_ROOM_FOR_UNIT_GROUPS,
    payload: value
  }
}

export const addUnitToRoom = () => {
  return {
    type: projectsTypes.ADD_UNIT_TO_ROOM,


  }
}


export const removeUnitFromRoom = (index, otherUnits, unitFieldId, projectId) => {
  return {
    type: projectsTypes.REMOVE_UNIT_FROM_ROOM,
    payloadIndex: index,
    payloadId: unitFieldId,
    payloadOtherUnits: otherUnits,
    payloadProjectId: projectId
  }
}

export const updateUnitInRoom = (payloadKey, payloadValue, payloadIndex) => {



  return {
    type: projectsTypes.UPDATE_UNIT_IN_ROOM,
    payloadIndex,
    payloadKey,
    payloadValue
  }

}



export const deleteRoomAtIndex = (index) => {
  return {
    type: projectsTypes.DELETE_ROOM_AT_INDEX,
    payload: index
  }

}

export const clearAllUnits = () => {
  return {
    type: projectsTypes.CLEAR_ALL_UNITS,

  }
}

export const createCompanyEmailAction = (email) => {
  return {
    type: projectsTypes.CREATE_COMPANY_EMAIL,
    payload: email,
  }
}





