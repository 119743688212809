import * as reportsTypes from '../constants/reports.types';
import { PURGE } from 'redux-persist';

const initialState = {
  project: {
    energyConsumption: {
      value: null,
      loading: false,
      error: null,
      totalEnergyConsumption: null,
      dataUptime: null,
      rooms: {},
      roomAlarms: {},
      siteAlarms: {}
    },
    pricePerKg: {
      value: null,
      loading: false,
      error: null
    },
    minThroughput: {
      value: null,
      loading: false,
      error: null
    },
    pricingCalculate: {
      value: null,
      loading: false,
      error: null
    },
    totalHandledProduce: {
      value: null,
      loading: false,
      error: null
    },
  },
  coldstorage: {
    loading: false,
    error: null
  },
  reportAccess: {
    loadingReportAccess: true,
    error: false,
    costReport: false,
  }
}

const reducer = {
  [reportsTypes.LOADING_REPORT_ACCESS]: state => ({
    ...state,
    reportAccess: {
      ...state.reportAccess,
      loadingReportAccess: true
    }
  }),

  [reportsTypes.DONE_REPORT_ACCESS]: (state, action) => ({
    ...state,
    reportAccess: {
      ...state.reportAccess,
      loadingReportAccess: false,
      costReport: action.payload.costReport,
    }
  }),

  [reportsTypes.ERROR_REPORT_ACCESS]: (state, action) => ({
    ...state,
    reportAccess: {
      ...state.reportAccess,
      loadingReportAccess: false,
      error: true,
      costReport: false
    }
  }),

  [reportsTypes.LOADING_EN_CONSUMPTION_DATA]: state => ({
    ...state,
    project: {
      ...state.project,
      energyConsumption: {
        ...state.project.energyConsumption,
        loading: true
      }
    }
  }),

  [reportsTypes.DONE_FETCH_EN_CONSUMPTION_DATA]: (state, action) => ({
    ...state,
    project: {
      ...state.project,
      energyConsumption: {
        ...state.project.energyConsumption,
        loading: false,
        value: action.payload.energyConsumption
      }
    }
  }),

  [reportsTypes.ERROR_FETCH_EN_CONSUMPTION_DATA]: (state) => ({
    ...state,
    project: {
      ...state.project,
      energyConsumption: {
        ...state.project.energyConsumption,
        loading: false,
        error: 'Error ocurred during fetching data.',
      }
    }
  }),

  [reportsTypes.LOADING_TOTAL_HANDLED_PRODUCE]: state => ({
    ...state,
    project: {
      ...state.project,
      totalHandledProduce: {
        ...state.project.totalHandledProduce,
        loading: true
      }
    }
  }),

  [reportsTypes.LOADING_PRICE_PER_KG_DATA]: state => ({
    ...state,
    project: {
      ...state.project,
      pricePerKg: {
        ...state.project.pricePerKg,
        loading: true
      }
    }
  }),

  [reportsTypes.DONE_TOTAL_HANDLED_PRODUCE]: (state, action) => ({
    ...state,
    project: {
      ...state.project,
      totalHandledProduce: {
        ...state.project.totalHandledProduce,
        loading: false,
        value: action.payload.totalHandledProduce
      }
    }
  }),

  [reportsTypes.ERROR_TOTAL_HANDLED_PRODUCE]: (state) => ({
    ...state,
    project: {
      ...state.project,
      totalHandledProduce: {
        ...state.project.totalHandledProduce,
        loading: false,
        error: 'Error ocurred during fetching total handled produce data.',
      }
    }
  }),

  [reportsTypes.DONE_FETCH_PRICE_PER_KG_DATA]: (state, action) => ({
    ...state,
    project: {
      ...state.project,
      pricePerKg: {
        ...state.project.pricePerKg,
        loading: false,
        value: action.payload.pricePerKg
      }
    }
  }),

  [reportsTypes.ERROR_FETCH_PRICE_PER_KG_DATA]: (state) => ({
    ...state,
    project: {
      ...state.project,
      pricePerKg: {
        ...state.project.pricePerKg,
        loading: false,
        error: 'Error ocurred during fetching data.',
      }
    }
  }),

  [reportsTypes.LOADING_MIN_THROUGHPUT_DATA]: state => ({
    ...state,
    project: {
      ...state.project,
      minThroughput: {
        ...state.project.minThroughput,
        loading: true
      }
    }
  }),

  [reportsTypes.DONE_FETCH_MIN_THROUGHPUT_DATA]: (state, action) => ({
    ...state,
    project: {
      ...state.project,
      minThroughput: {
        ...state.project.minThroughput,
        loading: false,
        value: action.payload.minThroughput
      }
    }
  }),

  [reportsTypes.ERROR_FETCH_MIN_THROUGHPUT_DATA]: (state) => ({
    ...state,
    project: {
      ...state.project,
      minThroughput: {
        ...state.project.minThroughput,
        loading: false,
        error: 'Error ocurred during fetching data.',
      }
    }
  }),

  [reportsTypes.LOADING_CALCULATE_PRICING_DATA]: state => ({
    ...state,
    project: {
      ...state.project,
      pricingCalculate: {
        ...state.project.pricingCalculate,
        loading: true
      }
    }
  }),

  [reportsTypes.DONE_FETCH_CALCULATE_PRICING_DATA]: (state, action) => ({
    ...state,
    project: {
      ...state.project,
      pricingCalculate: {
        ...state.project.pricingCalculate,
        loading: false,
        value: action.payload.pricingCalculate
      }
    }
  }),

  [reportsTypes.ERROR_FETCH_CALCULATE_PRICING_DATA]: (state) => ({
    ...state,
    project: {
      ...state.project,
      pricingCalculate: {
        ...state.project.pricingCalculate,
        loading: false,
        error: 'Error ocurred during fetching data.',
      }
    }
  }),

  [PURGE]: () => ({
    ...initialState
  })
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;