import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import RenderByRoles from '../../RenderBy/RenderByRoles';
import Badge from '../../shared/Badge';
import NavLogo from './Logo';

const Menu = props => {
  const linkRender = props.links.map((v, i) => (
    <RenderByRoles roles={v.onlyFor} key={i}>
      <NavLink to={v.link} className="nav-item nav-header-text" activeClassName="active">
        {v.label}
        {!!v.badge && <Badge>{v.badge}</Badge>}
      </NavLink>
    </RenderByRoles>
  ));

  return (
    <div className="menu">
      <NavLogo textLogo={!props.userLoggedIn} />
      {props.userLoggedIn && linkRender}
    </div>
  );
};

Menu.propTypes = {
  userLoggedIn: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.any,
      badge: PropTypes.any,
      onlyFor: PropTypes.any
    })
  )
};

export default Menu;
