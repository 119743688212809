export const FEATURE_PRICING = {

    FREE: 'free',
    PAID: 'paid',

}

export const FEATURE_MODE = {

    DEV: 'dev',
    ALPHA: 'alpha',
    BETA: 'beta',
    STABLE: 'stable',

}

export const FEATURE_ENV = {
    LOCAL: 'local',
    DEV: 'dev',
    PROD: 'prod',
}

export const USER_PAYMENT_STATUS = {
    FREETRIAL: 'freeTrial',
    PAID: 'paid',
    DEFAULTED: 'defaulted',
}