import * as contactTypes from '../../constants/contact.types';
import { mergeArraysBy } from '../../utils/arrays/mergeArraysBy';
import { PURGE } from 'redux-persist';

const initialState = {
  data: [],
  loading: false,
  sync: false,
  error: null
};

const reducer = {
  [contactTypes.LOADING]: state => ({
    ...state,
    loading: true
  }),
  [contactTypes.DONE_FETCH]: (state, action = { payload: { data: [] } }) => ({
    ...state,
    loading: false,
    sync: true,
    data: mergeArraysBy(action.payload.data, state.data, 'id')
  }),
  [contactTypes.ERROR_FETCH]: (
    state,
    action = { payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [contactTypes.CLEAN_DATA]: () => ({
    ...initialState
  }),
  [contactTypes.DONE_SEND_MESSAGE]: (state, action) => ({
    ...state,
    loading: false,
    message: action.payload
  }),
  [contactTypes.ERROR_SEND_MESSAGE]: (
    state,
    action = { payload: { error: 'Fetching error' } }
  ) => ({
    ...state,
    loading: false,
    error: action.payload.error
  }),
  [PURGE]: () => ({
    ...initialState
  })
};

export default (state = initialState, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
