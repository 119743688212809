import moment from "moment";
import { last } from "lodash";

export const getLabelNameFormat = (dataList) => {
  if (dataList.length > 0) {
    return moment(last(dataList).recDatetimeEnd).diff(
      moment(dataList[0].recDateTime),
      "hour"
    ) <= 48
      ? "HH:mm"
      : "DD-MM";
  }

  return "";
};


export const getReeferLabelNameFormat = (dataList) => {

  if (dataList.length > 0) {
    return moment(last(dataList).time).diff(
      moment(dataList[0].time),
      "hour"
    ) <= 48
      ? "HH:mm"
      : "DD-MM";
  }

  return "";
};
