import api from "../../utils/api";
import { getCompanyId, getUserId, getProjectId } from "../../utils/auth/user";

import * as usersTypes from "../../constants/users.types";

export const fetchUsers = () => async (dispatch) => {
  dispatch({ type: usersTypes.LOADING });

  const projectId = getProjectId()

  try {
    const response = await api.get("/user/company", {
      params: {
        projectId,
      },
    });
    dispatch({
      type: usersTypes.DONE_FETCH,
      payload: response,
    });
    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: usersTypes.ERROR_FETCH,
      payload: {
        error: (e.response.data || {}).message || "Fetch users data error",
      },
    });
    return Promise.reject(e.response);
  }
};

export const updateUserEmailAlertStatus = (userId, sendingMode) => {
  return (dispatch) => {
    const companyId = getCompanyId();
    const toNotFetchUserId = getUserId();
    api
      .put(`useralarmstatus/${userId}`, {
        userId,
        sendingMode,
        companyId,
        toNotFetchUserId,
      })
      .then((res) => {
        dispatch({
          type: usersTypes.DONE_FETCH,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: usersTypes.ERROR_FETCH,
          payload: {
            error: (err.response.data || {}).message || "Post users data error",
          },
        });
      });
  };
};

export const inviteUser = (values) => async (dispatch) => {
  dispatch({ type: usersTypes.LOADING });
  const companyId = getCompanyId();
  try {
    const response = await api.post(`/user/company/${companyId}/invite`, {
      email: values.email,
      role: values.role,
    });
    await dispatch(fetchUsers());
    dispatch({
      type: usersTypes.DONE_INVITE_USER,
      payload: response,
    });
    return Promise.resolve(response.data);
  } catch (e) {
    const errorMessage = (e.response.data || {}).message;
    dispatch({
      type: usersTypes.ERROR_INVITE_USER,
      payload: {
        error:
          typeof errorMessage !== "object" ? errorMessage : "Invite user error",
      },
    });
    return Promise.reject(e.response);
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  dispatch({ type: usersTypes.LOADING });
  try {
    const response = await api.delete(`/user/delete/${userId}`);
    await dispatch(fetchUsers());
    dispatch({
      type: usersTypes.DONE_USER_DELETE,
      payload: response,
    });
    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: usersTypes.ERROR_USER_DELETE,
      payload: {
        error: (e.response.data || {}).message || "Delete user error",
      },
    });
    return Promise.reject(e.response);
  }
};

export function cleanUnits() {
  return {
    type: usersTypes.CLEAN_DATA,
  };
}
