import api from '../../utils/api';
import * as unitDetailsTypes from '../../constants/unitDetails.types';
import { getProjectId } from '../../utils/auth/user';


export const fetchUnitLiveData = (
  coldStorageId,
  projectId
) => async dispatch => {
  dispatch({
    type: unitDetailsTypes.LOADING_LIVE_DATA,
    coldStorageId: coldStorageId
  });

  try {
    const response = await api.get(
      `project/${projectId || getProjectId()}/unit/${coldStorageId}/livedata`
      //`project/${projectId || getProjectId()}/unit/livedata`
    );
    dispatch({
      type: unitDetailsTypes.DONE_FETCH_LIVE_DATA,
      coldStorageId: coldStorageId,
      payload: response
    });

    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      coldStorageId: coldStorageId,
      type: unitDetailsTypes.ERROR_FETCH_LIVE_DATA,
      payload: { error: 'Fetch single unit data error' }
    });

    return Promise.resolve(e.response);
  }
};
