import React from 'react'
import { tiveDevices } from '../../../../mock/tive'
import ReferCardWithDropDown from '../../shared/ReferCardWithDropDown'
import './device.scss'

function ReferDevice() {
    return (
        <div>
            {tiveDevices?.data.map((el, idx) => (
                <>
                    <ReferCardWithDropDown key={idx} content={el} />
                    <hr className='device-break-line' />
                </>
            ))}
        </div>
    )
}

export default ReferDevice