import jwtDecode from "jwt-decode";
import store from "./../../store";

import { hasOneOrMoreSameValues } from "../../utils/arrays/compareTwoArrays";
import { getSession } from "./session";

export const getUserDataFormRawToken = (token) => {
  return token ? jwtDecode(token) : null;
};

export const getUserData = () => {
  const { token } = getSession();
  return token ? jwtDecode(token) : null;
};

export const getUserRoles = () => {
  const { roles } = getUserData() || { roles: [] };
  return roles || [];
};

export const getUserPaymentStatus = () => {
  const state = store.store.getState();
  return state.auth.paymentStatus;
}

export const getUserId = () => {
  const { id } = getUserData() || { id: null };
  return id;
};

export const getProjectId = () => {
  const state = store.store.getState();
  const currentProjectId = state.projects.currentProject || null;
  return currentProjectId;
};

export const getCurrentProjectInfo = () => {
  const state = store.store.getState();
  const currentProjectId = state.projects.currentProject || null;

  if (currentProjectId !== null) {
    return state.projects?.data?.find((el) => el.id === currentProjectId);
  }

  return null;
};

export const getProjectUnitsForDropDown = (projectId) => {

  const currentPorjectInfo = getCurrentProjectInfo()


  if (currentPorjectInfo) {
    return currentPorjectInfo.units.map((e, i) => {
      return {
        key: i,
        text: e.customTitle,
        value: e.id
      };
    });
  }

  return null;
};

export const getFirstColdRoomId = () => {
  const state = store.store.getState();
  return state.units[getProjectId()]?.data?.find((el, i) => i === 0)?.id;
};

export const getColdRoomName = (id) => {
  const state = store.store.getState();
  return state.units[getProjectId()].data.find((el) => el.id === id).name;
};

export const getCompanyId = () => {
  const { companyId } = getUserData() || { companyId: null };
  return companyId;
};

export const getUserEmail = () => {
  const { email } = getUserData() || { id: null };
  return email;
};

export const userAllowedByRole = (roles) => {
  const userRoles = getUserRoles();
  const hasAccess = hasOneOrMoreSameValues(roles, userRoles);
  return hasAccess || !roles;
};
