import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as unitDetailsActions from "../../../../../actions/unitDetails/statistics.actions";
import Component from "./Statistics";
import {
  getProjectId,
  getFirstColdRoomId,
} from "../../../../../utils/auth/user";

const mapStateToProps = (state, props) => {
  const coldStorageId =
    localStorage.getItem("selectedColdRoom") === "undefined" ||
    localStorage.getItem("selectedColdRoom") === null
      ? Number.parseInt(getFirstColdRoomId())
      : Number.parseInt(localStorage.getItem("selectedColdRoom"));

  const projectId = getProjectId() || null;

  const statisticsSelector = state.unitDetails.statistics || {};
  const csvDataSelector = state.unitDetails.csvData || {};
  const unitsSelectorByProjectId = (state.units || {})[projectId || 1] || {
    data: [],
  };

  let unitDetailsByUnitSerial =
    (statisticsSelector || {})["finalCurrentStats"] || {};

   

  const csvDataByColdStorage =
    (csvDataSelector || {})["finalCurrentStats"] || {};

  const unitsIdList = unitsSelectorByProjectId.data
    ? unitsSelectorByProjectId.data.map((x) => x.id)
    : [];

  const dataProfile = state.units[projectId]?.data?.find(
    (unit) => unit.id === coldStorageId
  )?.dataProfile;

  return {
    dataProfile,
    coldStorageId,
    data: unitDetailsByUnitSerial || {},
    csvData: csvDataByColdStorage || {},
    timePeriod: unitDetailsByUnitSerial.timePeriod || "",
    sync: unitDetailsByUnitSerial.sync || false,
    loading: statisticsSelector.loading,
    unitsIdList: unitsIdList,
    projectId: projectId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUnitStatistics: bindActionCreators(
    unitDetailsActions.fetchUnitStatistics,
    dispatch
  ),
  fetchCsvData: bindActionCreators(unitDetailsActions.fetchCsvData, dispatch),
});

const ComponentWithRouter = withRouter(Component);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithRouter);
