import React, { useState } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
// import { Dropdown } from "semantic-ui-react";
//import { Route, Switch, Redirect } from 'react-router-dom';

import Page from "../../../components/Page";
import Title from "../../../components/shared/Title";
import { urlRoute } from "../../../utils/url";
import { toRenderFeature } from '../../../utils/featureUtil'
import Navigation from "../../../components/shared/Navigation";
import MyUnitsList from "./List";
import Reports from "./Reports";
//import {}Devices from './Devices';
import MobileNavigation from "../Details/routes/Statistics/components/MobileNavigation";

import withTracker from "../../../withTracker";
import Statistics from "./Routes/Statistics";
import * as featureTypes from '../../../constants/feature-types';
import NewLive from "./new-live/NewLive";
// import { RouterSharp } from "@material-ui/icons";

const MyUnitsGeneral = React.memo((props) => {
  const { projectId, moreThenOneProject, currentProjectInfo, features, paymentStatus } = props;
  const [subTitle, setSubTitle] = useState("");

  const _getLinkToUnits = (page) => {
    if (moreThenOneProject) {
      return urlRoute(`/projects/${projectId}/units${page}`);
    } else {
      return urlRoute(`/units${page}`);
    }
  };

  const giveSubTitleContent = (content) => {
    setSubTitle(content);
  };

  const _getPathToUnits = (page) => {
    if (moreThenOneProject) {
      return urlRoute(`/projects/:projectId/units${page}`);
    } else {
      return urlRoute(`/units${page}`);
    }
  };

  let routes = {
    overview: {
      label: "Live Data",
      link: _getLinkToUnits(""),
      path: _getPathToUnits(""),
    },
  }

  if (toRenderFeature(featureTypes.UNITS_STATS, paymentStatus, features)) {
    routes.statistics = {
      label: "Statistics",
      link: _getLinkToUnits(`/statistics`),
      path: _getPathToUnits(`/statistics`),
    }
  }

  if (toRenderFeature(featureTypes.UNITS_REPORTS, paymentStatus, features)) {
    routes.reports = {
      label: "Reports",
      link: _getLinkToUnits(`/reports`),
      path: _getPathToUnits(`/reports`),
    }
  }

  if (toRenderFeature(featureTypes.UNITS_REPORTS, paymentStatus, features)) {
    routes.newlive = {
      label: "New Live",
      link: _getLinkToUnits(`/new-live`),
      path: _getPathToUnits(`/new-live`),
    }
  }


  return (
    <Page isContainer className="my-units-general">
      <Title
        // title={moreThenOneProject ? `Project ${projectId} Units` : "My Units"}
        title={currentProjectInfo?.title}
        subTitle={subTitle}
      />

      <Navigation routes={routes} />
      <Switch>
        <Route
          exact
          path={routes.overview.path}
          component={withTracker(MyUnitsList)}
        />
        {toRenderFeature(featureTypes.UNITS_STATS, paymentStatus, features) &&
          <Route
            exact
            path={routes.statistics.path}
            component={withTracker(() => (
              <Statistics giveSubTitleContent={giveSubTitleContent} />
            ))}
          />}

        {toRenderFeature(featureTypes.UNITS_REPORTS, paymentStatus, features) &&
          <Route
            exact
            path={routes.reports.path}
            component={withTracker(Reports)}
          />}

        {toRenderFeature(featureTypes.UNITS_REPORTS, paymentStatus, features) &&
          <Route
            exact
            path={routes.newlive.paths}
            component={withTracker(NewLive)}
          />}



      </Switch>
      <MobileNavigation
        projectId={projectId}
        isMultiProjectAccount={moreThenOneProject}
        showReturnButton={false}
      />
    </Page>
  );
});

MyUnitsGeneral.propTypes = {
  fetchAlarms: PropTypes.func,
  data: PropTypes.array,
  currentProjectInfo: PropTypes.object,
  features: PropTypes.array
};

export default MyUnitsGeneral;
