import React, { useEffect } from 'react'
import { connect } from "react-redux";
import {
    Table,
    // Input,
    Dropdown,
    Icon
} from 'semantic-ui-react'
import { fetchAllProjectUsers, setAdminUsersProjectId } from '../../../../actions/admin/admin.action';

function User(props) {

    const {
        fetchAllProjectUsers,
        projectsDataDropDown,
        projectUsers,
        adminUserProjectId,
        setAdminUsersProjectId
    } = props


    useEffect(() => {
        if (adminUserProjectId) fetchAllProjectUsers(adminUserProjectId)
        // eslint-disable-next-line
    }, [adminUserProjectId])

    return (
        <div className="projects__container">
            <Dropdown
                placeholder="Project"
                scrolling
                fluid
                search
                selection
                options={projectsDataDropDown}
                onChange={(event, { value }) => {
                    setAdminUsersProjectId(value)
                }}
                value={adminUserProjectId}
            />
            <Table color={'yellow'} unstackable>

                <Table.Header>
                    <Table.HeaderCell colSpan={1}>
                        <p className="table-text">Email</p>

                    </Table.HeaderCell>

                    <Table.HeaderCell colSpan={1}>
                        <p className="table-text">Role</p>

                    </Table.HeaderCell>

                    <Table.HeaderCell colSpan={1}>
                        <p className="table-text">Active</p>

                    </Table.HeaderCell>

                    <Table.HeaderCell colSpan={1}>
                        <p className="table-text">Payment</p>

                    </Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Header>


                <Table.Body className='border-1'>


                    {projectUsers?.map((el, idx) => (
                        <>

                            <Table.Row className="project-row">
                                <Table.Cell colSpan={1}><p className="table-text">{el.email}</p></Table.Cell>
                                <Table.Cell colSpan={1}><p className="table-text">{el.roleName}</p></Table.Cell>
                                <Table.Cell colSpan={1}>{el.isActive ?
                                    <Icon name='dot circle' color='green' /> :
                                    <Icon name='dot circle' color='green' />}
                                </Table.Cell>
                                <Table.Cell colSpan={1}><p className="table-text">{el.userPaymentStatus}</p></Table.Cell>
                                <Table.Cell colSpan={1}>
                                    <Icon name='trash alternate outline' color='red' />
                                    <Icon name='dollar sign' color='red' />
                                    <Icon name='hand pointer' color='red' />
                                    <Icon name='repeat' color='red' />
                                    <Icon name='edit outline' color='red' />
                                </Table.Cell>

                            </Table.Row>

                        </>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

const mapStateToProps = ({ projects, admin }) => {
    const {
        data,
    } = projects;

    const { projectUsers, adminUserProjectId } = admin.adminUsersReducer;


    const arrDropDown = data.map((e, i) => {

        return {
            key: i,
            text: `${e.projectUid} - ${e.title}`,
            value: e.id,
        };
    });

    return {
        projectsDataDropDown: arrDropDown,
        projectUsers,
        adminUserProjectId
    };
}

export default connect(mapStateToProps, {
    fetchAllProjectUsers,
    setAdminUsersProjectId
})(User);
