import api from '../../utils/api';
import moment from 'moment';
import * as unitDetailsTypes from '../../constants/unitDetails.types';

export const fetchStockData = (coldStorageId, projectId) => async dispatch => {
  dispatch({
    type: unitDetailsTypes.LOADING_STOCK
  });

  try {
    const response = await api.get(`/project/${projectId}/unit/${coldStorageId}/stock`);

    dispatch({
      type: unitDetailsTypes.DONE_FETCH_STOCK,
      payload: response
    });

    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: unitDetailsTypes.ERROR_FETCH_STOCK,
      payload: { error: 'Fetch stock data error' }
    });

    return Promise.resolve(e.response);
  }
};

export const addStockItem = (coldStorageId, projectId, values) => async dispatch => {


  dispatch({
    type: unitDetailsTypes.LOADING_STOCK,
    coldStorageId: coldStorageId
  });

  try {
    const response = await api.post(`/project/${projectId}/unit/${coldStorageId}/stock`, {
      coldstorageId: String(coldStorageId),
      name: values.name,
      weight: Number(values.weight),
      amount: Number(values.amount),
      in: moment(values.in).toISOString()
    });

    dispatch({
      type: unitDetailsTypes.DONE_ADD_STOCK_ITEM,
      payload: response
    });
    dispatch(fetchStockData(coldStorageId, projectId));
    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: unitDetailsTypes.ERROR_ADD_STOCK_ITEM,
      payload: { error: 'Fetch stock data error' }
    });

    return Promise.resolve(e.response);
  }
};

export const updateStockItem = (
  stockId,
  values,
  status,
  coldStorageId,
  projectId
) => async dispatch => {
  dispatch({
    type: unitDetailsTypes.LOADING_STOCK
  });

  try {
    const response = await api.patch(`/stock/${stockId}`, {
      name: values.name,
      weight: Number(values.weight),
      amount: Number(values.amount),
      in: moment(values.in).toISOString(),
      out: values.out ? moment(values.out).toISOString() : moment().toISOString(),
      status: status
    });

    dispatch({
      type: unitDetailsTypes.DONE_UPDATE_STOCK_ITEM,
      payload: response
    });
    dispatch(fetchStockData(coldStorageId, projectId));
    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: unitDetailsTypes.ERROR_UPDATE_STOCK_ITEM,
      payload: { error: 'Fetch stock data error' }
    });

    return Promise.resolve(e.response);
  }
};

export const splitStockItem = (
  stockId,
  values,
  status,
  coldStorageId,
  projectId
) => async dispatch => {
  dispatch({
    type: unitDetailsTypes.LOADING_STOCK
  });
  try {
    const response = await api.post(`/stock/${stockId}/split`, values);

    dispatch({
      type: unitDetailsTypes.DONE_UPDATE_STOCK_ITEM,
      payload: response
    });
    dispatch(fetchStockData(coldStorageId, projectId));
    return Promise.resolve(response.data);
  } catch (e) {
    dispatch({
      type: unitDetailsTypes.ERROR_UPDATE_STOCK_ITEM,
      payload: { error: 'Fetch stock data error' }
    });

    return Promise.resolve(e.response);
  }
};
