export const fieldIdOptions = [
  { key: 154, value: "3phAveragePower", text: "3phAveragePower" },
  { key: 155, value: "3phPeakMaxPower", text: "3phPeakMaxPower" },
  { key: 125, value: "ambientHum", text: "ambientHum" },
  { key: 126, value: "ambientTemp", text: "ambientTemp" },
  { key: 93, value: "AOValueOut", text: "AOValueOut" },
  { key: 205, value: "blowerBoardTripped", text: "blowerBoardTripped" },
  { key: 502, value: "CO2ambient", text: "CO2ambient" },
  { key: 127, value: "coldWaterTemp", text: "coldWaterTemp" },
  { key: 238, value: "CompressortempAmbient", text: "CompressortempAmbient" },
  { key: 94, value: "cosphiMain", text: "cosphiMain" },
  { key: 95, value: "currentInvOutput", text: "currentInvOutput" },
  { key: 240, value: "currentL1", text: "currentL1" },
  { key: 232, value: "currentL1Main", text: "currentL1Main" },
  { key: 241, value: "currentL1Max", text: "currentL1Max" },
  { key: 235, value: "currentL1Room", text: "currentL1Room" },
  { key: 242, value: "currentL2", text: "currentL2" },
  { key: 233, value: "currentL2Main", text: "currentL2Main" },
  { key: 243, value: "currentL2Max", text: "currentL2Max" },
  { key: 236, value: "currentL2Room", text: "currentL2Room" },
  { key: 244, value: "currentL3", text: "currentL3" },
  { key: 234, value: "currentL3Main", text: "currentL3Main" },
  { key: 245, value: "currentL3Max", text: "currentL3Max" },
  { key: 237, value: "currentL3Room", text: "currentL3Room" },
  { key: 96, value: "currentMain", text: "currentMain" },
  { key: 259, value: "currentMainAvgL1", text: "currentMainAvgL1" },
  { key: 260, value: "currentMainAvgL2", text: "currentMainAvgL2" },
  { key: 261, value: "currentMainAvgL3", text: "currentMainAvgL3" },
  { key: 262, value: "currentMainL1", text: "currentMainL1" },
  { key: 263, value: "currentMainL2", text: "currentMainL2" },
  { key: 264, value: "currentMainL3", text: "currentMainL3" },
  { key: 265, value: "currentMainPeakL1", text: "currentMainPeakL1" },
  { key: 266, value: "currentMainPeakL2", text: "currentMainPeakL2" },
  { key: 267, value: "currentMainPeakL3", text: "currentMainPeakL3" },
  { key: 246, value: "currentN", text: "currentN" },
  { key: 220, value: "dieselGen", text: "dieselGen" },
  { key: 128, value: "doorRoom", text: "doorRoom" },
  { key: 247, value: "energy", text: "energy" },
  { key: 221, value: "energyAirCond", text: "energyAirCond" },
  { key: 39, value: "energyBlower", text: "energyBlower" },
  { key: 191, value: "energyChiller", text: "energyChiller" },
  { key: 222, value: "energyCRoom1", text: "energyCRoom1" },
  { key: 223, value: "energyCRoom2", text: "energyCRoom2" },
  { key: 146, value: "energyExCR1", text: "energyExCR1" },
  { key: 147, value: "energyExCR2", text: "energyExCR2" },
  { key: 76, value: "energyFAP", text: "energyFAP" },
  { key: 192, value: "energyL1", text: "energyL1" },
  { key: 193, value: "energyL2", text: "energyL2" },
  { key: 194, value: "energyL3", text: "energyL3" },
  { key: 26, value: "energyMain", text: "energyMain" },
  { key: 268, value: "energyMainNeg", text: "energyMainNeg" },
  { key: 269, value: "energyMainPos", text: "energyMainPos" },
  { key: 65, value: "energyMeter", text: "energyMeter" },
  { key: 54, value: "energyPumps", text: "energyPumps" },
  { key: 0, value: "energyRoom", text: "energyRoom" },
  { key: 1, value: "ethyleneProbe-2000", text: "ethyleneProbe-2000" },
  { key: 501, value: "ethyleneProbe-200", text: "ethyleneProbe-200" },
  {
    key: 239,
    value: "EvaporatortempAirRoomOut",
    text: "EvaporatortempAirRoomOut",
  },
  { key: 129, value: "evapTemp", text: "evapTemp" },
  { key: 86, value: "fanSetpointRoom", text: "fanSetpointRoom" },
  { key: 280, value: "FapstatusBreaker", text: "FapstatusBreaker" },
  {
    key: 281,
    value: "FapstatusBreakerTripped",
    text: "FapstatusBreakerTripped",
  },
  { key: 59, value: "flowRate", text: "flowRate" },
  { key: 97, value: "freqInvOutput", text: "freqInvOutput" },
  { key: 98, value: "freqInvReference", text: "freqInvReference" },
  { key: 156, value: "frequency", text: "frequency" },
  { key: 40, value: "frequencyMain", text: "frequencyMain" },
  { key: 286, value: "gasTempCondenserOUT", text: "gasTempCondenserOUT" },
  { key: 285, value: "gasTempEvapOUT", text: "gasTempEvapOUT" },
  { key: 130, value: "hotWaterTemp", text: "hotWaterTemp" },
  { key: 60, value: "hoursOperating", text: "hoursOperating" },
  { key: 2, value: "humAmbient", text: "humAmbient" },
  { key: 3, value: "humExt", text: "humExt" },
  { key: 131, value: "humRoom", text: "humRoom" },
  { key: 132, value: "humSetpoint", text: "humSetpoint" },
  { key: 4, value: "humSetpointRoom", text: "humSetpointRoom" },
  { key: 104, value: "levelValv", text: "levelValv" },
  { key: 206, value: "mainsBraker", text: "mainsBraker" },
  { key: 157, value: "minutesAvgPower", text: "minutesAvgPower" },
  { key: 158, value: "negActiveEnergy", text: "negActiveEnergy" },
  { key: 159, value: "negReactiveEnergy", text: "negReactiveEnergy" },
  { key: 61, value: "numbOfStart", text: "numbOfStart" },
  { key: 133, value: "pegoProductProbe", text: "pegoProductProbe" },
  { key: 160, value: "ph1Current", text: "ph1Current" },
  { key: 161, value: "ph1CurrentAvg", text: "ph1CurrentAvg" },
  { key: 162, value: "ph1CurrentPeak", text: "ph1CurrentPeak" },
  { key: 163, value: "ph1Voltage", text: "ph1Voltage" },
  { key: 164, value: "ph1VoltageMax", text: "ph1VoltageMax" },
  { key: 165, value: "ph1VoltageMin", text: "ph1VoltageMin" },
  { key: 166, value: "ph2Current", text: "ph2Current" },
  { key: 167, value: "ph2CurrentAvg", text: "ph2CurrentAvg" },
  { key: 168, value: "ph2CurrentPeak", text: "ph2CurrentPeak" },
  { key: 169, value: "ph2Voltage", text: "ph2Voltage" },
  { key: 170, value: "ph2VoltageMax", text: "ph2VoltageMax" },
  { key: 171, value: "ph2VoltageMin", text: "ph2VoltageMin" },
  { key: 172, value: "ph3Current", text: "ph3Current" },
  { key: 173, value: "ph3CurrentAvg", text: "ph3CurrentAvg" },
  { key: 174, value: "ph3CurrentPeak", text: "ph3CurrentPeak" },
  { key: 175, value: "ph3Voltage", text: "ph3Voltage" },
  { key: 176, value: "ph3VoltageMax", text: "ph3VoltageMax" },
  { key: 177, value: "ph3VoltageMin", text: "ph3VoltageMin" },
  { key: 5, value: "phProbe", text: "phProbe" },
  { key: 178, value: "posActiveEnergy", text: "posActiveEnergy" },
  { key: 179, value: "posReactiveEnergy", text: "posReactiveEnergy" },
  { key: 248, value: "power", text: "power" },
  { key: 148, value: "powerAirCond", text: "powerAirCond" },
  { key: 149, value: "powerAvgAirCond", text: "powerAvgAirCond" },
  { key: 150, value: "powerAvgBlower", text: "powerAvgBlower" },
  { key: 151, value: "powerAvgCRoom1", text: "powerAvgCRoom1" },
  { key: 152, value: "powerAvgCRoom2", text: "powerAvgCRoom2" },
  { key: 41, value: "powerBlower", text: "powerBlower" },
  { key: 42, value: "powerBlowerAvg", text: "powerBlowerAvg" },
  { key: 43, value: "powerBlowerMax", text: "powerBlowerMax" },
  { key: 195, value: "powerChiller", text: "powerChiller" },
  { key: 196, value: "powerChillerAvg", text: "powerChillerAvg" },
  { key: 197, value: "powerChillerMax", text: "powerChillerMax" },
  { key: 180, value: "powerFactor", text: "powerFactor" },
  { key: 77, value: "powerFAP", text: "powerFAP" },
  { key: 99, value: "powerInvOutput", text: "powerInvOutput" },
  { key: 198, value: "powerL1", text: "powerL1" },
  { key: 199, value: "powerL2", text: "powerL2" },
  { key: 200, value: "powerL3", text: "powerL3" },
  { key: 27, value: "powerMain", text: "powerMain" },
  { key: 28, value: "powerMainAvg", text: "powerMainAvg" },
  { key: 29, value: "powerMainMax", text: "powerMainMax" },
  { key: 249, value: "powerMax", text: "powerMax" },
  { key: 55, value: "powerPumps", text: "powerPumps" },
  { key: 56, value: "powerPumpsAvg", text: "powerPumpsAvg" },
  { key: 57, value: "powerPumpsMax", text: "powerPumpsMax" },
  { key: 6, value: "powerRoom", text: "powerRoom" },
  { key: 78, value: "powerRoomAvg", text: "powerRoomAvg" },
  { key: 79, value: "powerRoomMax", text: "powerRoomMax" },
  { key: 105, value: "pressEvapValv", text: "pressEvapValv" },
  { key: 62, value: "pressureDifferential", text: "pressureDifferential" },
  { key: 63, value: "pressureDischarge", text: "pressureDischarge" },
  { key: 82, value: "pressureHigh-10", text: "pressureHigh-10" },
  { key: 820, value: "pressureHigh-50", text: "pressureHigh-50" },
  { key: 83, value: "pressureLow-10", text: "pressureLow-10" },
  { key: 830, value: "pressureLow-50", text: "pressureLow-50" },
  { key: 64, value: "pressureSuction", text: "pressureSuction" },
  { key: 100, value: "probe", text: "probe" },
  { key: 207, value: "pumps", text: "pumps" },
  { key: 208, value: "pumpsTripped", text: "pumpsTripped" },
  { key: 30, value: "register1", text: "register1" },
  { key: 31, value: "register2", text: "register2" },
  { key: 32, value: "register3", text: "register3" },
  { key: 33, value: "register4", text: "register4" },
  { key: 209, value: "scmUnit", text: "scmUnit" },
  { key: 210, value: "scmUnitTripped", text: "scmUnitTripped" },
  { key: 181, value: "sectorOfPowerFactor", text: "sectorOfPowerFactor" },
  { key: 101, value: "setPoint", text: "setPoint" },
  { key: 106, value: "setpOverHeating", text: "setpOverHeating" },
  { key: 182, value: "signOfActivePower", text: "signOfActivePower" },
  { key: 183, value: "signOfReactivePower", text: "signOfReactivePower" },
  { key: 107, value: "speedFan", text: "speedFan" },
  { key: 108, value: "speedFan1", text: "speedFan1" },
  { key: 109, value: "speedFan2", text: "speedFan2" },
  { key: 110, value: "speedFan3", text: "speedFan3" },
  { key: 134, value: "standBy", text: "standBy" },
  { key: 279, value: "statusBreaker", text: "statusBreaker" },
  {
    key: 44,
    value: "statusBreakerBlowerboardTripped",
    text: "statusBreakerBlowerboardTripped",
  },
  {
    key: 45,
    value: "statusBreakerCompressor",
    text: "statusBreakerCompressor",
  },
  {
    key: 46,
    value: "statusBreakerCompressorTripped",
    text: "statusBreakerCompressorTripped",
  },
  { key: 47, value: "statusBreakerLoadsUps", text: "statusBreakerLoadsUps" },
  {
    key: 48,
    value: "statusBreakerLoadsUpsTripped",
    text: "statusBreakerLoadsUpsTripped",
  },
  { key: 49, value: "statusBreakerMain", text: "statusBreakerMain" },
  { key: 50, value: "statusBreakerPumps", text: "statusBreakerPumps" },
  {
    key: 51,
    value: "statusBreakerPumpsTripped",
    text: "statusBreakerPumpsTripped",
  },
  { key: 184, value: "statusBreakerTripped", text: "statusBreakerTripped" },
  { key: 224, value: "statusColdCall", text: "statusColdCall" },
  { key: 102, value: "statusCompressor", text: "statusCompressor" },
  { key: 87, value: "statusCoolingCall", text: "statusCoolingCall" },
  { key: 88, value: "statusDefrost", text: "statusDefrost" },
  { key: 7, value: "statusDoorRoom", text: "statusDoorRoom" },
  { key: 8006, value: "statusDoorRoom1", text: "statusDoorRoom1" },
  { key: 8007, value: "statusDoorRoom2", text: "statusDoorRoom2" },
  { key: 8008, value: "statusDoorRoom3", text: "statusDoorRoom3" },
  { key: 111, value: "statusEvapFan", text: "statusEvapFan" },
  { key: 112, value: "statusFan1Run", text: "statusFan1Run" },
  { key: 113, value: "statusFan2Run", text: "statusFan2Run" },
  { key: 114, value: "statusFan3Run", text: "statusFan3Run" },
  { key: 8, value: "statusFanTherm1", text: "statusFanTherm1" },
  { key: 9, value: "statusFanTherm2", text: "statusFanTherm2" },
  { key: 10, value: "statusFanTherm3", text: "statusFanTherm3" },
  { key: 11, value: "statusFanTherm4", text: "statusFanTherm4" },
  { key: 6007, text: "statusGenericWarning1", value: "statusGenericWarning1" },
  { key: 6008, text: "statusGenericWarning2", value: "statusGenericWarning2" },
  { key: 6009, text: "statusGenericWarning3", value: "statusGenericWarning3" },
  { key: 52, value: "statusGenset", text: "statusGenset" },
  { key: 121, value: "statusGrid", text: "statusGrid" },
  { key: 89, value: "statusHumidifier", text: "statusHumidifier" },
  { key: 66, value: "statusIceTank100", text: "statusIceTank100" },
  { key: 67, value: "statusIceTank25", text: "statusIceTank25" },
  { key: 68, value: "statusIceTank50", text: "statusIceTank50" },
  { key: 69, value: "statusIceTank75", text: "statusIceTank75" },
  { key: 70, value: "statusLevelWaterMax", text: "statusLevelWaterMax" },
  { key: 71, value: "statusLevelWaterMin", text: "statusLevelWaterMin" },
  { key: 90, value: "statusLight", text: "statusLight" },
  { key: 225, value: "statusLoading", text: "statusLoading" },
  { key: 226, value: "statusProgram", text: "statusProgram" },
  { key: 124, value: "statusRecipe", text: "statusRecipe" },
  { key: 227, value: "statusSaving", text: "statusSaving" },
  { key: 228, value: "statusStandby", text: "statusStandby" },
  { key: 12, value: "statusStandbyRoom", text: "statusStandbyRoom" },
  { key: 211, value: "tankIce100", text: "tankIce100" },
  { key: 212, value: "tankIce25", text: "tankIce25" },
  { key: 213, value: "tankIce50", text: "tankIce50" },
  { key: 214, value: "tankWaterLevelMax", text: "tankWaterLevelMax" },
  { key: 215, value: "tankWaterLevelMin", text: "tankWaterLevelMin" },
  { key: 188, value: "tempAirExchangerIn", text: "tempAirExchangerIn" },
  { key: 189, value: "tempAirExchangerOut", text: "tempAirExchangerOut" },
  { key: 135, value: "tempAirINluve", text: "tempAirINluve" },
  { key: 136, value: "tempAirOUTluve", text: "tempAirOUTluve" },
  { key: 10000, value: "tempAmbient3", text: "tempAmbient3" },
  { key: 13, value: "tempAirRoomIN", text: "tempAirRoomIN" },
  { key: 14, value: "tempAirRoomOut", text: "tempAirRoomOut" },
  { key: 15, value: "tempAmbient", text: "tempAmbient" },
  { key: 16, value: "tempAmbient2", text: "tempAmbient2" },
  { key: 122, value: "tempBattery", text: "tempBattery" },
  { key: 91, value: "tempCondenser", text: "tempCondenser" },
  { key: 92, value: "tempEvapForFanBlock", text: "tempEvapForFanBlock" },
  { key: 17, value: "tempEvaporator", text: "tempEvaporator" },
  { key: 115, value: "tempEvapValv", text: "tempEvapValv" },
  { key: 84, value: "tempExchAirIn", text: "tempExchAirIn" },
  { key: 85, value: "tempExchAirOut", text: "tempExchAirOut" },
  { key: 80, value: "tempExt", text: "tempExt" },
  { key: 116, value: "tempFan1", text: "tempFan1" },
  { key: 117, value: "tempFan2", text: "tempFan2" },
  { key: 118, value: "tempFan3", text: "tempFan3" },
  { key: 119, value: "tempOverHeat", text: "tempOverHeat" },
  { key: 137, value: "tempProduct", text: "tempProduct" },
  { key: 190, value: "tempProductProbe", text: "tempProductProbe" },
  { key: 18, value: "tempProductProbe1", text: "tempProductProbe1" },
  { key: 19, value: "tempProductProbe2", text: "tempProductProbe2" },
  { key: 478, value: "tempProductProbe3", text: "tempProductProbe3" },
  { key: 479, value: "tempProductProbe4", text: "tempProductProbe4" },
  { key: 138, value: "tempRoom", text: "tempRoom" },
  { key: 139, value: "tempSetpoint", text: "tempSetpoint" },
  { key: 20, value: "tempSetpointRoom", text: "tempSetpointRoom" },
  { key: 120, value: "tempSuctionValv", text: "tempSuctionValv" },
  { key: 21, value: "tempWaterCold", text: "tempWaterCold" },
  { key: 22, value: "tempWaterExchangerIn", text: "tempWaterExchangerIn" },
  { key: 23, value: "tempWaterExchangerOut", text: "tempWaterExchangerOut" },
  { key: 24, value: "tempWaterHot", text: "tempWaterHot" },
  { key: 72, value: "tempWaterTank1", text: "tempWaterTank1" },
  { key: 73, value: "tempWaterTank2", text: "tempWaterTank2" },
  { key: 216, value: "tempWaterTankA", text: "tempWaterTankA" },
  { key: 217, value: "tempWaterTankB", text: "tempWaterTankB" },
  { key: 74, value: "tempWaterTankIn", text: "tempWaterTankIn" },
  { key: 140, value: "tempWaterTankINluve", text: "tempWaterTankINluve" },
  { key: 75, value: "tempWaterTankOut", text: "tempWaterTankOut" },
  { key: 141, value: "tempWaterTankOUTluve", text: "tempWaterTankOUTluve" },
  { key: 142, value: "thermFan1", text: "thermFan1" },
  { key: 145, value: "thermFan2", text: "thermFan2" },
  { key: 143, value: "thermFan3", text: "thermFan3" },
  { key: 144, value: "thermFan4", text: "thermFan4" },
  { key: 185, value: "threephActivePower", text: "threephActivePower" },
  { key: 186, value: "threephApparentPower", text: "threephApparentPower" },
  { key: 187, value: "threephReactivePower", text: "threephReactivePower" },
  { key: 153, value: "timestamp", text: "timestamp" },
  { key: 218, value: "upsLoads", text: "upsLoads" },
  { key: 219, value: "upsLoadsTripped", text: "upsLoadsTripped" },
  { key: 123, value: "voltageBattery", text: "voltageBattery" },
  { key: 53, value: "voltageBlower", text: "voltageBlower" },
  { key: 201, value: "voltageChiller", text: "voltageChiller" },
  { key: 81, value: "voltageFAP", text: "voltageFAP" },
  { key: 202, value: "voltageL1", text: "voltageL1" },
  { key: 250, value: "voltageL1L2", text: "voltageL1L2" },
  { key: 251, value: "voltageL1L3", text: "voltageL1L3" },
  { key: 282, value: "voltageL1Main", text: "voltageL1Main" },
  { key: 252, value: "voltageL1Max", text: "voltageL1Max" },
  { key: 253, value: "voltageL1Min", text: "voltageL1Min" },
  { key: 229, value: "voltageL1Room", text: "voltageL1Room" },
  { key: 203, value: "voltageL2", text: "voltageL2" },
  { key: 254, value: "voltageL2L3", text: "voltageL2L3" },
  { key: 283, value: "voltageL2Main", text: "voltageL2Main" },
  { key: 255, value: "voltageL2Max", text: "voltageL2Max" },
  { key: 256, value: "voltageL2Min", text: "voltageL2Min" },
  { key: 230, value: "voltageL2Room", text: "voltageL2Room" },
  { key: 204, value: "voltageL3", text: "voltageL3" },
  { key: 284, value: "voltageL3Main", text: "voltageL3Main" },
  { key: 257, value: "voltageL3Max", text: "voltageL3Max" },
  { key: 258, value: "voltageL3Min", text: "voltageL3Min" },
  { key: 231, value: "voltageL3Room", text: "voltageL3Room" },
  { key: 34, value: "voltageMain", text: "voltageMain" },
  { key: 270, value: "voltageMainL1", text: "voltageMainL1" },
  { key: 271, value: "voltageMainL2", text: "voltageMainL2" },
  { key: 272, value: "voltageMainL3", text: "voltageMainL3" },
  { key: 273, value: "voltageMainMaxL1", text: "voltageMainMaxL1" },
  { key: 274, value: "voltageMainMaxL2", text: "voltageMainMaxL2" },
  { key: 275, value: "voltageMainMaxL3", text: "voltageMainMaxL3" },
  { key: 276, value: "voltageMainMinL1", text: "voltageMainMinL1" },
  { key: 277, value: "voltageMainMinL2", text: "voltageMainMinL2" },
  { key: 278, value: "voltageMainMinL3", text: "voltageMainMinL3" },
  { key: 58, value: "voltagePumps", text: "voltagePumps" },
  { key: 25, value: "voltageRoom", text: "voltageRoom" },
  { key: 103, value: "voltInvOutput", text: "voltInvOutput" },
  { key: 35, value: "weatherHum", text: "weatherHum" },
  { key: 36, value: "weatherRain", text: "weatherRain" },
  { key: 37, value: "weatherSolar", text: "weatherSolar" },
  { key: 38, value: "weatherTemp", text: "weatherTemp" },
  { key: 6000, text: "statusProductProbe", value: "statusProductProbe" },
  { key: 6001, text: "probeSetpointRoom", value: "probeSetpointRoom" },
  { key: 6002, text: "statusGeneralAlarm", value: "statusGeneralAlarm" },
  { key: 6003, text: "statusMaximumTemp", value: "statusMaximumTemp" },
  { key: 6004, text: "statusMinimunTemp", value: "statusMinimunTemp" },
  { key: 6005, text: "statusGenericAlarm", value: "statusGenericAlarm" },
  {
    key: 6006,
    text: "statusCompressorProtection",
    value: "statusCompressorProtection",
  },
];

export const otherRoomUnits = [
  "CompressorData",
  "EvaporatorData",
  "PumpData",
  "ThermalStorage",
  "AirCondition",
  "PowerData",
  "FapData",
];

export const otherRoomUnitsDropdown = [
  { key: 0, text: "CompressorData", value: "CompressorData" },
  { key: 1, text: "EvaporatorData", value: "EvaporatorData" },
  { key: 2, text: "PumpData", value: "PumpData" },
  { key: 3, text: "ThermalStorage", value: "ThermalStorage" },
  { key: 4, text: "AirCondition", value: "AirCondition" },
  { key: 5, text: "PowerData", value: "PowerData" },
  { key: 5, text: "FapData", value: "FapData" },
];

export const fieldIdOptionsSore = () => {};
