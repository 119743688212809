import React from 'react';
import { isNull, isUndefined } from 'lodash';

import './_costs-report.scss';

const CostsReport = React.memo(({
  totalHandledProduce,
  totalHandledProduceLoading,
  pricePerKgLoading,
  pricePerKg,
  minThroughput,
  minThroughputLoading,
  currency,
  pickerEndDate,
  pickerStartDate,
  selectedPeriod }) => {
  let currencyValue = pricePerKg ? pricePerKg[currency.label] : null;

  const _reachMinThroughput = () => {
    const startDate = pickerStartDate.clone().startOf('day');
    const endDate = selectedPeriod ? pickerEndDate.clone().endOf('day') : pickerEndDate.clone().add(1, 'days').startOf('day');
    const daysDifference = endDate.diff(startDate, 'days');

    return Math.round(minThroughput * daysDifference / 7);
  }

  const throughputValue = _reachMinThroughput();
  const pricePerKgValue = !pricePerKg || pricePerKg[currency.label] === null ? 0 : pricePerKg[currency.label];
  const totalHandledProduceValue = isNull(totalHandledProduce) ? 0 : totalHandledProduce
  const totalCost = Math.round(totalHandledProduceValue * pricePerKgValue * 100) / 100;

  return (
    <div className="costs-report report-table">
      <h3 className="report-header">Cost Report</h3>
      <div className="report-content">
        <div className="content-column cell-describer">
          <div className="total-handled-produce">
            <h4>Total handled produce</h4>
            {totalHandledProduceLoading
              ? <div className="cell-value">Loading...</div>
              : <div className="cell-value">{totalHandledProduceValue} kg</div>
            }
          </div>
          <div className="price-per-kilo">
            <h4>Price per kilo</h4>
            {pricePerKgLoading
              ? <div className="cell-value">Loading...</div>
              : <div className="cell-value">{isNull(pricePerKg) || isUndefined(currencyValue) ? '--' : currencyValue} {currency.label}</div>
            }
          </div>
        </div>
        <div className="content-column cell-describer">
          <div className="throughput-container">
            <div className="aimed-throughput">
              <h4>Aimed at throughput</h4>
              {minThroughputLoading
                ? <div className="cell-value">Loading...</div>
                : <div className="cell-value">{isNull(minThroughput) ? '--' : throughputValue} kg</div>
              }
            </div>
            {totalHandledProduce < throughputValue &&
              <div className="throughput-warning">
                If you do not reach your throughput goals repeatedly, we have to add additional fees in the future.
              </div>
            }
          </div>
          <div>
            <h4>{}</h4>
            <div className="cell-value"></div>
          </div>
        </div>
      </div>
      <div className="report-footer cell-describer">
        <div className="total-cost">
          <h4>Total cost</h4>
          <div className="cell-value">{totalCost} {currency.label}</div>
        </div>
      </div>
    </div >
  );
});

export default CostsReport;
