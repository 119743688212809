import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ className = '', type, label, ...rest }) => {
  return (
    label && (
      <div className={`label ${type} ${className}`} {...rest}>
        {label}
      </div>
    )
  );
};

Label.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'warning', 'bold'])
};

Label.defaultProps = {
  label: ''
};

export default Label;
