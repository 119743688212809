import { PURGE } from "redux-persist";
import { ADMIN_USER_PROJECT_ID, DONE_FETCHING_PROJECT_USERS, FETCHING_PROJECT_USERS } from "../../constants/admin.types";


const INITIAL = {
    fetchingProjectUsers: false,
    projectUsers: [],
    adminUserProjectId: ''
};

export default (state = INITIAL, action) => {

    switch (action.type) {

        case FETCHING_PROJECT_USERS:

            return {
                ...state,
                fetchingProjectUsers: true
            };

        case DONE_FETCHING_PROJECT_USERS:
            return {
                ...state,
                projectUsers: action.payload.data
            };

        case ADMIN_USER_PROJECT_ID:
            return {
                ...state,
                adminUserProjectId: action.payload
            }

        case PURGE:
            return {
                ...INITIAL
            };

        default:
            return state;

    };
}
