export const FIR_GREEN = '#048881';
export const ORANGE = '#ffb500';
export const RED = '#ff2222';
export const CYAN = '#4ac5d0';
export const BLACK = '#1f2f4a';
export const LIGHT_GREY = '#d3d3d3';
export const MEDIUM_GREY = '#8f8f8f';
export const DARK_BLACK = '#212529';
export const TRANSPARENT = '#00000000';
export const WHITE = '#fff';
export const BROWN = '#E97451';
export const GREEN = '#008000';
export const DOOR_RED = '#ffb500';
export const DOOR_WARM_RED = '#ABCA76';