import PropTypes from 'prop-types';
import React from 'react';

const Badge = props => (
  <div className={`badge ${props.className}`}>{props.children}</div>
);

Badge.defaultProps = {
  className: '',
  children: null
};

Badge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export default Badge;
