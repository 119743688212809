import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom";

import Page from "../../components/Page";
import Title from "../../components/shared/Title";
import { urlRoute } from "../../utils/url";
import Navigation from "../../components/shared/Navigation";
// import Device from './routes/devices/ReferDevice';
// import Shipment from './routes/shipments/Shipment';
import ReferStatistics from './routes/statistics/ReferStatistics';
import ReferDevice from './routes/devices/ReferDevice';
// import ReferAlarms from './routes/alerts/ReferAlarms';
import ReferLocations from './routes/locations/ReferLocations';
import ReferLive from './routes/live/ReferLive';
import { connect } from 'react-redux';





function Reefer(props) {

    const {
        shipmentActiveShipment
    } = props;

    const _getLinkToLive = () => {
        return urlRoute(`/shipments/${shipmentActiveShipment.shipmentId}/live`);
    };

    const routes = {
        live: {
            label: "Live Data",
            link: urlRoute(_getLinkToLive()),
            path: urlRoute(`/shipments/:shipmentId/live`),
        },
        statistics: {
            label: "Statistics",
            link: urlRoute(`/reefer/statistics`),
            path: urlRoute(`/reefer/statistics`),
        },
        // alarms: {
        //     label: "Alarms",
        //     link: urlRoute(`/reefer/alarms`),
        //     path: urlRoute(`/reefer/alarms`),
        // },
        locations: {
            label: "Locations",
            link: urlRoute(`/reefer/locations`),
            path: urlRoute(`/reefer/locations`),
        },
        devices: {
            label: "Devices",
            link: urlRoute(`/reefer/devices`),
            path: urlRoute(`/reefer/devices`),
        },


    };

    return (
        <Page isContainer>
            <Title title={shipmentActiveShipment.shipmentId} />

            <Navigation routes={routes} />
            <Switch>

                <Route exact path={routes.devices.path} component={ReferDevice} />
                <Route exact path={routes.statistics.path} component={ReferStatistics} />
                {/* <Route exact path={routes.alarms.path} component={ReferAlarms} /> */}
                {/* <Route exact path={routes.statistics.path} component={ReferStatistics} /> */}
                <Route exact path={routes.locations.path} component={ReferLocations} />
                <Route exact path={routes.live.path} component={ReferLive} />
                <Route exact path="/shipments">
                    <Redirect to={routes.live.path} />
                </Route>
            </Switch>
        </Page>
    )
}

const mapStateToProps = ({ shipments }) => {
    const {
        shipmentActiveShipment
    } = shipments.shipmentsReducer;

    return {
        shipmentActiveShipment
    }
};

export default connect(mapStateToProps, {

})(Reefer);
