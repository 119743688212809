import React from 'react';
import PropTypes from 'prop-types';

const Input = ({ className = '', ...rest }) => {
  return <input className={`form-input ${className}`} {...rest} />;
};

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func
};

Input.defaultProps = {
  type: 'text'
};

export default Input;
